import styled from "styled-components";
import media from "styled-media-query";

export const ButtonBasic = styled.button`
  cursor: pointer;
  /* padding: 8px 12px; */
  padding: ${(props) => (props.width ? "0px" : "8px 12px")};
  width: ${(props) => (props.width ? props.width : "")};
  height: ${(props) => (props.height ? props.height : "")};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border: none;
  font-size: 14px;
  line-height: 100%;
  background: ${(props) => (props.bgClr ? props.bgClr : "none")};
  color: ${(props) => (props.textClr ? props.textClr : "#000")};
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};

  ${media.greaterThan("768px")`
    font-size: 16px;

  `}
  ${media.greaterThan("1024px")`
    padding: ${(props) => (props.width ? "0px" : "16px 24px")};
    gap: 10px;
    font-size: 18px;

  `}

  .dropdown-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    border-radius: 6px;
    background: none;
    border: none;
    font-size: 18px;

    ${media.greaterThan("1024px")`
      padding: 8px 8px 8px 12px;
      font-size: 14px;
      background: ${(props) => props.bg};
    `}
    ${media.greaterThan("1440px")`
      font-size: 16px;
    `}
  }

  .dropdown-more {
    /* display: flex; */
    display: none;
    /* left: -200000px; */
    flex-direction: column;
    align-items: left;
    gap: 30px;
    padding-left: 31px;
    padding-top: 30px;
    padding-bottom: 60px;
    border-radius: 6px;
    background: #fff;
    /* background: #f0f; */
    text-decoration: none;
    list-style: none;
    margin: 0;

    width: 376px;
    z-index: 1;

    ${media.greaterThan("920px")`
      /* position: absolute; */
      display: flex;
      top: 110%;
      left: -20px;
    `}
  }

  .icon-container {
    position: relative;
    width: 20px;
    height: 20px;

    ${media.lessThan("1024px")`
      width: 35px;
      height: 35px;
    `}
  }

  .small-collection-solo {
    background: #fff;
    display: flex;
    gap: 17px;
    align-items: center;
    width: 100%;

    .card_texts {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .title {
        font-family: "ApercuProBoldNormal";
        font-size: 20px;
        line-height: 110%;
        letter-spacing: -0.03em;
        color: #1a191e;
      }

      .subtitle {
        font-size: 17px;
        line-height: 142.8%;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  .mobile-menu-more {
    ${media.greaterThan("920px")`
      display: none;
    `}
  }
`;

export const ShowMoreButton = styled.button``;
