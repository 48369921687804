import styled from "styled-components";
import media from "styled-media-query";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

const Styles = styled.div`
  display: flex;

  position: relative;
  height: 100%;
  ${media.greaterThan("768px")`
  
  `};
  ${media.greaterThan("1024px")`
    
  `};
  ${media.greaterThan("1440px")`
  
  `};

  .choose_files_input {
    position: absolute;
    left: 999999999px;
  }

  .children_container {
  }
`;

const ImageUploadMultiple = ({
  placeholder,
  acceptType,
  children,
  type,
  fileToUpload,
  setFileToUpload,
  imageListFinal,
  setImageListFinal,
}) => {
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  const [message, setMessage] = useState([]);
  const [imageInfos, setImageInfos] = useState([]);
  const progressInfosRef = useRef(null);
  const [newArray, setNewArray] = useState([]);

  const [selectedFilesName, setSelectedFilesName] = useState([]);

  const selectFiles = (event) => {
    let images = [];
    let imagesArray = [];
    // setImageListFinal();

    for (let i = 0; i < event.target.files.length; i++) {
      images.push(URL.createObjectURL(event.target.files[i]));
      imagesArray.push(event.target.files[i]);
      // newArray.push(event.target.files[i]);
    }

    setSelectedFiles(event.target.files);
    setImagePreviews(images);
    setNewArray(imagesArray);
    setProgressInfos({ val: [] });
    setMessage([]);
  };

  const uploadImages = async () => {
    setProgressInfos(0);
    console.log("this is the preview image: ", imagePreviews);
    console.log("this is the selected files: ", selectedFiles);
    console.log(
      "this is the selected files newwww arrrrayyyyy: ",
      selectedFiles
    );

    // selectedFiles.length &&
    //   selectFiles.map((file) => {
    //     return console.log("hello");
    //   });

    let formData = new FormData();
    formData.append("file", selectedFiles);

    for (let i = 0; i < newArray.length; i++) {
      // images.push(URL.createObjectURL(event.target.files[i]));
      // imagesArray.push(event.target.files[i]);
      let imageData = new FormData();
      imageData.append("file", newArray[i]);
      await axios
        .post("/upload/media/file", imageData)
        .then((res) => {
          console.log("the reeesssssponse: ", res.data);
          selectedFilesName.push(res?.data?.name);
          // selectedFilesName.push(res?.data?.name);
          // imageListFinal.push(res.data);
          console.log(
            "this is the selected File Name to actually upload: ",
            selectedFilesName
          );
          setImageListFinal(selectedFilesName);

          return res;
        })
        .catch((err) => {
          const errMsg = err.response.data.message;
          console.log("the errrrrror!: ", errMsg);
          setSelectedFilesName([]);
          setImageListFinal([]);
          toast.error(errMsg);
        });
      setSelectedFilesName([]);
    }

    // await axios
    //   .post("/upload/media/file", formData)
    //   .then((res) => {
    //     console.log("the reeesssssponse: ", res);
    //     if (type === "photoImage") {
    //       const avatarString = res?.data?.name;
    //       setAvatar(avatarString);
    //     }
    //     if ((type = "file")) {
    //       const fileString = res?.data?.name;
    //       setFileToUpload(fileString);
    //     }
    //     return res;
    //   })
    //   .catch((err) => {
    //     const errMsg = err.response.data.message;
    //     console.log("the errrrrror!: ", errMsg);
    //     setSelectedFiles(undefined);
    //     setAvatar("");
    //     return err;
    //   });

    //   // if (err.response && err.response.data && err.response.data.message) {
    //   //   setMessage(err.response.data.message);
    //   // } else {
    //   //   setMessage("Could not upload the Image!");
    //   // }
    //   console.log("the upload error: ", err);

    //   setCurrentFile(undefined);
    // });
  };

  const uploadRef = useRef(null);
  useEffect(() => {
    if (selectedFiles) {
      uploadImages();
    }
  }, [selectedFiles]);

  // newArray.length > 0 ? (
  //   newArray.map((item) => {
  //     return <div>{item}</div>;
  //   })
  // ) : (
  //   <div className="children_container">{children}</div>
  // )

  return (
    <Styles onClick={() => uploadRef.current.click()}>
      <div className="row">
        <div className="choose_files_input">
          <label className="btn btn-default p-0">
            <input
              type="file"
              multiple
              accept="image/*"
              onChange={selectFiles}
              ref={uploadRef}
            />
          </label>
        </div>
        {/* <div className="children_container">{children}</div> */}
        <div>
          {newArray.length > 0 ? (
            newArray.map((item, index) => {
              return (
                <div key={index} style={{ marginBottom: "5px" }}>
                  {index + 1}. {item.name}
                </div>
              );
            })
          ) : (
            // <div>Hello there</div>
            <div className="children_container">{children}</div>
          )}
        </div>

        {/* <div className="col-4">
          <button
            className="btn btn-success btn-sm"
            disabled={!selectedFiles}
            onClick={uploadImages}
          >
            Upload
          </button>
        </div> */}
      </div>
    </Styles>
  );
};

export default ImageUploadMultiple;
