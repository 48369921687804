import styled from "styled-components";
import media from "styled-media-query";

const CommonText = styled.div`
  font-size: 16px;
  line-height: 2;
  color: #1a191e;
  ${media.greaterThan("768px")`
    font-size: 18px;
  `};
  ${media.greaterThan("1024px")`
  font-size: 20px;

  `};
  ${media.greaterThan("1440px")`

  `};

  span {
    color: #fbbd29;
  }

  .bold_text {
    font-family: "ApercuProBoldNormal";
    color: #000000;
  }
`;

export default CommonText;
