import React from "react";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import calendarIcon from "../Assets/images/calendar_icon.svg";

import {
  BlankInput,
  Button,
  Input,
  SelectInput,
  ShowMore,
} from "../modules/common_module/components/basic/functional";

const Rough = () => {
  return (
    <div
      style={{
        padding: "50px 20px 0px 40px",
      }}
    >
      <ShowMore
        title={"customers"}
        options={[{ title: "first", subtitle: "hello there", link: "#" }]}
      />
    </div>
  );
};

export default Rough;
