import React, { useState } from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

const DateRange = () => {
  const [value, onChange] = useState([new Date(), new Date()]);

  return (
    <div style={{ padding: "10px" }}>
      <DateRangePicker
        onChange={onChange}
        value={value}
        calendarAriaLabel="Toggle calendar"
        // isOpen={true}
        // calendarIcon={null}
        // onCalendarOpen={() => alert("Calendar opened")}
        onCalendarClose={() => console.log("Calendar closed", value)}
      />
    </div>
  );
};

export default DateRange;
