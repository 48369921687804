import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import { toast } from "react-toastify";
import DashboardLayout from "../../components/layout/dashboardLayout";
import Header from "../../components/layout/Header";
import { Tab } from "@headlessui/react";
import media from "styled-media-query";
import Input from "../../components/dashboard/input";
import { useState } from "react";
import Validator from "../../utils/Validator";
import { Circles } from "react-loader-spinner";
import { set } from "lodash";
import useAppHook from "../../hooks/useAppHook";
import { useNavigate } from "react-router-dom";
import uploadPhoto from "../../Assets/images/upload_photo_properties.svg";
import ImageUploadMultiple from "../../utils/ImageUploadMultiple";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { BlankInput } from "../../modules/common_module/components/basic/functional";

const Style = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .box1 {
    height: 100px;
    width: 167px;
    background: #f8f8f8;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    margin-right: 8px;
  }
  .pill {
    width: 72px;
    height: 15px;
    background: #f8ad15;
    border-radius: 8px;
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
  }
  .white {
    color: white !important;
  }
  .line-header {
    display: flex;
    align-items: center;
    margin-bottom: 32.5px;
    p {
      font-family: "Apercu Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      /* identical to box height */
      margin-right: 5px;
      color: #000000;
    }
    div {
      flex: 1;
      border-bottom: 1.3px solid #cc8d2b;
      height: 1px;
    }
  }
  .pay {
    height: 43px;
    left: 1088px;
    top: 636px;
    margin-top: 4px;
    background: #f8ad15;
    border-radius: 5px;
    border: none;
    outline: none;
    appearance: none;
    width: 100%;
    color: white;
  }
  .toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 34px;
    p {
      margin-right: 11px;

      font-family: "DM Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 100%;
      /* identical to box height, or 13px */

      letter-spacing: -0.03em;

      /* background/primary */

      color: #fbbd29;
    }
  }
  .entry {
    background: #f8f8f8;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px 12px 12px;
    gap: 16px;
    height: 65px;
    margin-bottom: 10px;
    justify-content: space-between;
  }
  .rent {
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 160%;
    /* or 19px */

    text-align: center;

    /* text/default */

    color: #1a191e;
  }
  .fee {
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 120%;
    /* identical to box height, or 36px */

    letter-spacing: -0.03em;

    color: #000000;
  }
  .box2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 2px;

    width: 167px;
    height: 100px;

    background: #cc8d2b;
    border-radius: 10px;
  }
  .box-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
  }
  .tab {
    margin-right: 24px;
    background: white;
    appearance: none;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding-left: 0;
  }
  .tab-list {
    margin-bottom: 39px;
  }
  .selected {
    color: #fcba2d;
  }
  .deselected {
    color: #919499;
  }
  .main-content {
    overflow: scroll;
    flex: 1;
    padding: 46px 36px 0;
    background: #f8f8f8;
    ${media.lessThan("medium")`
    padding: 46px 16px 0;
  `}
  }
  .right-sidebar {
    ${media.lessThan("medium")`
    display: none;
  `}
    overflow: scroll;
    width: 375px;
    height: 100%;
    background: white;
    padding: 48px 18px;
  }

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  .inv {
    display: flex;
    justify-content: space-between;
    align-items: end;
  }
  .month {
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */

    letter-spacing: -0.03em;

    /* text/default */

    color: #1a191e;
  }
  .date {
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 160%;
    /* or 19px */

    color: rgba(26, 25, 30, 0.6);
  }
  .amount {
    p {
      font-family: "Apercu Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 160%;
      /* identical to box height, or 26px */

      /* text/default */

      color: #1a191e;
    }
  }
  .rep-title {
    margin-bottom: 19px;
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    /* or 25px */

    letter-spacing: -0.03em;

    /* text/default */

    color: #1a191e;
  }

  .developer-section {
    padding: 33px 35px;
    margin-top: 60px;
    background: #fff;
    border-radius: 15px;
    ${media.lessThan("medium")`
    padding: 16px 16px;
    `}
  }

  .active-tab {
  }
  .inactive-tab {
    color: rgba(0, 0, 0, 0.5) !important;
  }

  .tab1-container {
    gap: 36px;
    display: flex;
    ${media.lessThan("medium")`
    flex-direction: column;
    `}
    background: #ffffff;
    border-radius: 15px;
  }
  .profile-section {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .one {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 116px;
  }
  .image-description {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #919499;
    max-width: 200px;
    text-align: center;
  }
  .space {
    ${media.lessThan("medium")`
    margin-top: 15px;
    `}
  }
  .input-holder {
    display: flex;
    ${media.greaterThan("medium")`
    gap: 26px;
    `}
    ${media.lessThan("medium")`
    flex-direction: column;
    `}
  }
  .half-input {
    width: 50%;
    ${media.greaterThan("medium")`
    width: 100%;
    `}
  }
  .round {
    cursor: pointer;
    margin-top: 25px;
    margin-bottom: 28px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(217, 217, 217, 0.3);
    border-radius: 100%;
    height: 123px;
    width: 123px;
    display: flex;
    border: 1px solid #000000;
  }
  .passord-section {
    padding: 25px 28px;
    width: 100%;
    border: 1.3px solid rgba(196, 196, 196, 0.5);
    border-radius: 15px;
    ${media.lessThan("medium")`
    padding: 16px 16px;
    `}
  }
  .add-image {
    height: 34px;
    width: 34px;
    margin-bottom: 8px;
  }
  .photo {
    color: rgba(0, 0, 0, 0.3);
    font-size: 12px;
  }
  .input-container {
    margin-bottom: 15px;
  }
  .save {
    background: #f8ad15;
    border-radius: 14px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    width: 221px;
    height: 45px;
    margin: 25px auto 0;
  }
  .responsive-half {
    width: 50%;
    ${media.lessThan("medium")`
    
    width: 100%;
    `}
  }
  .responsive-one-third {
    width: 33%;
    ${media.lessThan("medium")`
    
    width: 100%;
    `}
  }
  .responsive-two-third {
    width: 66%;
    ${media.lessThan("medium")`
    
    width: 100%;
    `}
  }
  .section-finance {
    width: 53%;
    margin-top: 26px;
    border: 1.3px solid rgba(196, 196, 196, 0.5);
    border-radius: 15px;
    padding: 25px 30px;
    ${media.lessThan("medium")`
    padding: 16px 16px;
    
    width: 100%;
    `}
    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #919499;
      margin-bottom: 15px;
    }
  }
  .full {
    width: 100%;
  }
  .bank_info {
    display: flex;
  }
  .bank_info_status_container {
    display: none;
    ${media.greaterThan("medium")`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;
    `}
  }
  .tablet {
    overflow: hidden;
    border: none;
    outline: none;
    appearance: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    // padding: 8px 8px 8px 12px;
    justify-content: center;
    width: auto;
    height: 43px;
    overflow-x: scroll;
    ${media.lessThan("medium")`
    margin-right: 16px;
    `}
    margin-right: 80px;
    border-radius: 6px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 170%;
    cursor: pointer;
    color: #1a191e;
    background: white;
  }
  max-width: 100vw;
  .key-input {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #919499;
  }
  .top-space {
    margin-top: 15px;
  }
  .key-pill {
    height: 65px;
    padding: 25px 27px;
    border: 1.3px solid rgba(196, 196, 196, 0.5);
    border-radius: 14px;
    p {
      font-family: "Apercu Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;

      /* Black */

      color: #000000;
    }
  }
  .tablet-list {
    display: -webkit-box;
    margin-bottom: 23px;
    white-space: nowrap;
    overflow: scroll;
  }
  .collection {
    width: 50%;
  }
  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .light-input {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    color: #c4c4c4;
  }
  .bold-input {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    color: #474747;
  }
  // .text-right {
  //   text-align: right;
  // }

  .form_input {
    border: 1.3px solid rgba(196, 196, 196, 0.5);
    border-radius: 14px;
    /* margin-top: 19px; */
    height: 60px;
    padding: 16px 24px 16px 24px;
    width: 100%;
    color: #474747;

    ${media.greaterThan("768px")`
    height: 65px;
    padding: 16px 24px 16px 24px;
    
  `};
    ${media.greaterThan("1024px")`
    height: 65px;
    padding: 20px 34px 20px 34px;
  `};
    ${media.greaterThan("1440px")`
  `};
  }

  .form_input.message {
    height: 124px;
  }

  select {
    /* for Firefox */
    -moz-appearance: none;
    /* for Safari, Chrome, Opera */
    -webkit-appearance: none;
  }
  /* for IE10 */
  select::-ms-expand {
    display: none;
  }

  .button_container {
    display: flex;
    justify-content: center;

    ${media.greaterThan("1024px")`
      justify-content: right;
    `};
  }

  .right {
    margin-left: 0;
    margin-right: 0;
  }

  .mobile_only {
    ${media.greaterThan("768px")`
      display: none;
    `};
  }

  .photo_upload_container {
    padding-top: 65px;
  }

  .photo_upload_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f5f7fb;
    border: 1.3px solid rgba(196, 196, 196, 0.5);
    border-radius: 14px;
    padding: 0 5%;
    padding-top: 48px;
    padding-bottom: 90px;
  }

  .photo_title_text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #919499;
  }

  .photo_label {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    color: #919499;
    text-align: center;
  }
  .label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 15px;
    position: relative;
    color: #919499;
    width: 100%;
  }

  .tags-input {
    /* background: red; */
    border: none;
    width: 100%;
  }
  .react-tagsinput-input {
    width: fit-content;
  }
`;

const UploadProperty = () => {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [formIndex, setFormIndex] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { profileFormIndex, setProfileFormIndex } = useAppHook();

  const [propertyId, setPropertyId] = useState(undefined);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const ref = useRef(null);

  const refTab = useRef(null);

  const [userDetail, setUserDetail] = useState({});
  const [states, setStates] = useState([]);
  const [citiesList, setCitiesList] = useState([]);

  const [constants, setConstants] = useState({});

  const [propertyInformation, setPropertyInformation] = useState({
    property_title: "",
    city: "",
    property_type: "",
    state: "",
    address: "",
    property_price: "",
  });
  const [propertyInformationError, setPropertyInformationError] = useState({
    property_title: [],
    city: [],
    property_type: [],
    state: [],
    address: [],
    property_price: [],
  });

  const [propertyDescription, setPropertyDescription] = useState({
    brief_description: "",
    features: [],
    amenities: [],
    bedrooms: "",
    bathrooms: "",
  });
  const [propertyDescriptionError, setPropertyDescriptionError] = useState({
    brief_description: [],
    features: [],
    amenities: [],
    bedrooms: [],
    bathrooms: [],
  });

  const [photos, setPhotos] = useState([]);
  // const [photosError, setPhotosError] = useState([]);

  // const [imageListFinal, setImageListFinal] = useState([]);

  useEffect(() => {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    setUserDetail(info);
    if (info === null || info === undefined) {
      navigate("/signin");
    }
    const states = JSON.parse(localStorage.getItem("states"));
    setStates(states);

    const generalConst = JSON.parse(localStorage.getItem("constants"));
    setConstants(generalConst);
  }, []);

  useEffect(() => {
    if (propertyInformation.state === "") {
      return;
    } else {
      states.filter(
        (state) =>
          state.id === Number(propertyInformation.state) &&
          setCitiesList(state.lga)
      );
    }
  }, [propertyInformation.state, states]);

  useEffect(() => {}, []);

  useEffect(() => {
    setProfileFormIndex(formIndex);
  }, [formIndex]);

  const handleSavePropertyInformation = (e) => {
    e.preventDefault();
    setPropertyDescriptionError({
      property_title: [],
      city: [],
      property_type: [],
      state: [],
      address: [],
      property_price: [],
    });

    const validatorMethods = {
      property_title: "required",
      city: "required",
      property_type: "required",
      state: "required",
      address: "required",
      property_price: "required",
    };

    const validator = new Validator(propertyInformation, validatorMethods);
    validator.result
      .then(async () => {
        setSubmitting(true);

        const propertyInformationUpdateData = new FormData();

        propertyInformationUpdateData.append(
          "title",
          propertyInformation.property_title
        );
        propertyInformationUpdateData.append("city", propertyInformation.city);
        propertyInformationUpdateData.append(
          "property_type",
          propertyInformation.property_type
        );
        propertyInformationUpdateData.append(
          "state_id",
          propertyInformation.state
        );
        // propertyInformationUpdateData.append("city", propertyInformation.city);
        propertyInformationUpdateData.append(
          "address",
          propertyInformation.address
        );
        propertyInformationUpdateData.append(
          "price",
          propertyInformation.property_price
        );

        axios
          .post(
            "/landlord/create-property-information",
            propertyInformationUpdateData
          )
          .then((res) => {
            setSubmitting(false);
            console.log(
              "this is the response from property information: ",
              res
            );
            setPropertyId(res.data?.data?.id);
            toast.success("Property info update successful");

            handleSaveClickGeneral();
          })
          .catch((err) => {
            setSubmitting(false);
            const errMsg = err.response.data.message;
            toast.error(errMsg);
          });
        console.log("the property info: ", propertyDescription);
      })
      .catch((error) => {
        setSubmitting(false);
        console.log("this is the validation error: ", error);
        setPropertyInformationError((prev) => ({
          ...prev,
          property_title: error.error.property_title,
          city: error.error.city,
          property_type: error.error.property_type,
          address: error.error.address,
          state: error.error.state,
          property_price: error.error.property_price,
        }));
      });
  };

  const handleSavePropertyDescription = (e) => {
    e.preventDefault();
    setPropertyDescriptionError({
      brief_description: [],
      features: [],
      amenities: [],
      bedrooms: [],
      bathrooms: [],
    });

    const validatorMethods = {
      brief_description: "required",
      features: "required",
      amenities: "required",
      bedrooms: "required",
      bathrooms: "required",
    };

    const validator = new Validator(propertyDescription, validatorMethods);
    validator.result
      .then(async () => {
        setSubmitting(true);
        // const unique_id = uuid();
        // const small_id = unique_id.slice(0, 8);

        const propertyDescriptionUpdateData = new FormData();

        propertyDescriptionUpdateData.append("id", propertyId);
        propertyDescriptionUpdateData.append(
          "description",
          propertyDescription.brief_description
        );
        // propertyDescriptionUpdateData.append("fullname", propertyDescription.full_name);
        propertyDescriptionUpdateData.append(
          "features",
          propertyDescription.features
        );
        propertyDescriptionUpdateData.append(
          "amenities",
          propertyDescription.amenities
        );
        propertyDescriptionUpdateData.append(
          "bedrooms",
          propertyDescription.bedrooms
        );
        propertyDescriptionUpdateData.append(
          "bathrooms",
          propertyDescription.bathrooms
        );

        axios
          .post(
            "/landlord/create-property-description",
            propertyDescriptionUpdateData
          )
          .then((res) => {
            setSubmitting(false);
            toast.success("Property description uploaded successfully");

            handleSaveClickGeneral();
          })
          .catch((err) => {
            setSubmitting(false);
            const errMsg = err.response.data.message;
            toast.error(errMsg);
          });
        console.log("the property info: ", propertyDescription);
      })
      .catch((error) => {
        setSubmitting(false);
        console.log("this is the validation error: ", error);
        setPropertyDescriptionError((prev) => ({
          ...prev,
          brief_description: error.error.brief_description,
          amenities: error.error.amenities,
          features: error.error.features,
          bedrooms: error.error.bedrooms,
          bathrooms: error.error.bathrooms,
        }));
      });
  };

  const handleSavePhotos = async () => {
    console.log("photos to be uploadedddddd: ", photos);
    // const result = typeOf(photos)
    console.log("this is the type of data sent: ", typeof photos);
    let imageData = new FormData();
    imageData.append("id", propertyId);
    for (let i = 0; i < photos.length; i++) {
      imageData.append(`photo[${i}]`, photos[i]);
    }

    await axios
      .post("/landlord/create-property-photo", imageData)
      .then((res) => {
        console.log("the reeesssssponse: ", res.data);
        toast.success("Photos uploaded successfully");
        navigate("/dashboard/properties");
        return res;
      })
      .catch((err) => {
        const errMsg = err.response.data.message;
        console.log("the errrrrror!: ", errMsg);

        toast.error(errMsg);
      });
  };

  const handleTabClick = (index) => {
    // setFormIndex(index);
  };

  const doClick = () =>
    ref.current?.scrollIntoView({ block: "start", inline: "nearest" });

  const scrollTab = (index) => {
    refTab[index]?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };

  const handleSaveClickGeneral = () => {
    if (selectedIndex < 3) {
      setSelectedIndex((prev) => prev + 1);

      console.log("this is refTab: ", refTab);
      scrollTab(selectedIndex);
      doClick();
    }
  };

  return (
    <Style>
      <div className="main-content">
        <div className="header" id="profile_form_group">
          <Header
            title="Upload Property"
            subTitle="Make sure to complete the required fields. "
          />
        </div>
        <div className="developer-section" ref={ref}>
          <div>
            <Tab.Group
              manual
              selectedIndex={selectedIndex}
              // onChange={handleSaveClickGeneral}
              onChange={(index) => {
                console.log("Changed selected tab to:", index);
                setSelectedIndex(index);
              }}
            >
              <Tab.List className="tablet-list nobar" ref={refTab}>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "tablet",
                      "nobar",
                      selected ? "active-tab" : "inactive-tab"
                    )
                  }
                  onClick={() => handleTabClick(2)}
                >
                  Property information
                </Tab>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "tablet",
                      "nobar",
                      selected ? "active-tab" : "inactive-tab"
                    )
                  }
                  onClick={() => handleTabClick(3)}
                >
                  Property description
                </Tab>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "tablet",
                      "nobar",
                      selected ? "active-tab" : "inactive-tab"
                    )
                  }
                  onClick={() => handleTabClick(4)}
                >
                  Photos
                </Tab>
              </Tab.List>
              <Tab.Panels className="mt-2">
                <Tab.Panel className={classNames("", "")}>
                  {/* work information panel */}
                  <div className="tab2-container-full">
                    <div className="section-finance full">
                      <div className="input-holder input-container">
                        <div className="responsive-half">
                          <Input
                            label="Property Title"
                            star
                            // defaultValue={userDetail.fullname}
                            value={propertyInformation.property_title}
                            onChange={(e) =>
                              setPropertyInformation((prev) => ({
                                ...prev,
                                property_title: e.target.value,
                              }))
                            }
                          />
                          {/* <p
                            className="error message"
                            style={{ textAlign: "left", color: "#ff0000" }}
                          >
                            {workInfoError.employer_name.joinn(", ")}
                          </p> */}
                        </div>
                        <div className="responsive-half space">
                          <label htmlFor="state" className="label">
                            State
                          </label>
                          <select
                            value={propertyInformation.state}
                            name="state"
                            className="form_input"
                            style={{
                              color: "",
                              padding: "10px",
                              marginTop: "15px",
                            }}
                            onChange={(e) => {
                              setPropertyInformation((prev) => ({
                                ...prev,
                                state: e.target.value,
                              }));

                              console.log(
                                "employer state id: ",
                                propertyInformation.state
                              );
                            }}
                          >
                            <option value="" disabled>
                              State
                            </option>
                            {states?.map((state) => (
                              <option key={state.id} value={state.id}>
                                {state.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="input-holder input-container">
                        <div className="responsive-half">
                          <Input
                            label="Property Type"
                            star
                            value={propertyInformation.property_type}
                            onChange={(e) =>
                              setPropertyInformation((prev) => ({
                                ...prev,
                                property_type: e.target.value,
                              }))
                            }
                          />
                          {/* <p
                            className="error message"
                            style={{ textAlign: "left", color: "#ff0000" }}
                          >
                            {workInfoError.employer_email.joinn(", ")}
                          </p> */}
                        </div>
                        <div className="responsive-half space">
                          <label htmlFor="city" className="label">
                            City
                          </label>
                          <select
                            value={propertyInformation.city}
                            name="city"
                            className="form_input"
                            style={{
                              color: "",
                              padding: "10px",
                              marginTop: "15px",
                            }}
                            onChange={(e) => {
                              setPropertyInformation((prev) => ({
                                ...prev,
                                city: e.target.value,
                              }));
                            }}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            {citiesList?.map((city) => (
                              <option
                                key={city.id}
                                value={city.name}
                                // style={{ paddingBottom: "10px" }}
                              >
                                {city.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="input-holder input-container">
                        <div className="responsive-half">
                          <Input
                            label="Address"
                            star
                            value={propertyInformation.address}
                            onChange={(e) =>
                              setPropertyInformation((prev) => ({
                                ...prev,
                                address: e.target.value,
                              }))
                            }
                          />
                          {/* <p
                            className="error message"
                            style={{ textAlign: "left", color: "#ff0000" }}
                          >
                            {workInfoError.employer_email.joinn(", ")}
                          </p> */}
                        </div>
                        <div className="responsive-half space">
                          <Input
                            label="Property Price"
                            star
                            value={propertyInformation.property_price}
                            onChange={(e) =>
                              setPropertyInformation((prev) => ({
                                ...prev,
                                property_price: e.target.value,
                              }))
                            }
                          />
                          {/* <p
                            className="error message"
                            style={{ textAlign: "left", color: "#ff0000" }}
                          >
                            {workInfoError.employer_email.joinn(", ")}
                          </p> */}
                        </div>
                      </div>

                      <div className="button_container">
                        <button
                          onClick={(e) => handleSavePropertyInformation(e)}
                          className="save right"
                        >
                          Save and proceed
                        </button>
                      </div>
                    </div>
                  </div>
                </Tab.Panel>
                <Tab.Panel className={classNames("", "")}>
                  {/* PROPERTY DESCRIPTION */}
                  <div className="tab2-container-full">
                    <div className="section-finance full">
                      <div className="input-container">
                        <Input
                          label="Brief Description"
                          star
                          // defaultValue={userDetail.fullname}
                          value={propertyDescription.brief_description}
                          onChange={(e) =>
                            setPropertyDescription((prev) => ({
                              ...prev,
                              brief_description: e.target.value,
                            }))
                          }
                        />
                        {/* <p
                            className="error message"
                            style={{ textAlign: "left", color: "#ff0000" }}
                          >
                            {workInfoError.employer_name.joinn(", ")}
                          </p> */}
                      </div>

                      <div className="input-holder input-container">
                        <div className="responsive-half">
                          <BlankInput label={"Features"} errorMsg={[]}>
                            <TagsInput
                              value={propertyDescription.features}
                              onChange={(features) =>
                                setPropertyDescription((prev) => ({
                                  ...prev,
                                  features,
                                }))
                              }
                              className="tags-input"
                              inputProps={{ placeholder: "Add new feature" }}
                            />
                          </BlankInput>

                          {/* <p
                            className="error message"
                            style={{ textAlign: "left", color: "#ff0000" }}
                          >
                            {workInfoError.employer_email.joinn(", ")}
                          </p> */}
                        </div>
                        <div className="responsive-half space">
                          <BlankInput label={"Amenities"} errorMsg={[]}>
                            <TagsInput
                              value={propertyDescription.amenities}
                              onChange={(amenities) =>
                                setPropertyDescription((prev) => ({
                                  ...prev,
                                  amenities,
                                }))
                              }
                              className="tags-input"
                              inputProps={{ placeholder: "Add new amenity" }}
                            />
                          </BlankInput>
                        </div>
                      </div>

                      <div className="input-holder input-container">
                        <div className="responsive-half">
                          <Input
                            label="Bedroom"
                            star
                            value={propertyDescription.bedrroms}
                            onChange={(e) =>
                              setPropertyDescription((prev) => ({
                                ...prev,
                                bedrooms: e.target.value,
                              }))
                            }
                          />
                          {/* <p
                            className="error message"
                            style={{ textAlign: "left", color: "#ff0000" }}
                          >
                            {workInfoError.employer_email.joinn(", ")}
                          </p> */}
                        </div>
                        <div className="responsive-half space">
                          <Input
                            label="Bathrooms"
                            star
                            value={propertyDescription.bathrooms}
                            onChange={(e) =>
                              setPropertyDescription((prev) => ({
                                ...prev,
                                bathrooms: e.target.value,
                              }))
                            }
                          />
                          {/* <p
                            className="error message"
                            style={{ textAlign: "left", color: "#ff0000" }}
                          >
                            {workInfoError.employer_email.joinn(", ")}
                          </p> */}
                        </div>
                      </div>

                      <div className="button_container">
                        <button
                          onClick={(e) => handleSavePropertyDescription(e)}
                          className="save right"
                        >
                          Save and proceed
                        </button>
                      </div>
                    </div>
                  </div>
                </Tab.Panel>
                <Tab.Panel className={classNames("", "")}>
                  {/* PHOTOS */}
                  <div className="tab2-container-full">
                    <div className="section-finance full photo_upload_container">
                      <div className="photo_upload_box">
                        <ImageUploadMultiple
                          imageListFinal={photos}
                          setImageListFinal={setPhotos}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <>
                              <p className="photo_title_text">
                                Click or Drag photos here
                              </p>
                              <div>
                                <img
                                  src={uploadPhoto}
                                  alt=""
                                  className="upload_photo"
                                />
                                <p className="photo_label">Photos</p>
                                {/* <UploadImage /> */}
                              </div>
                            </>
                          </div>
                        </ImageUploadMultiple>
                      </div>
                      <div className="button_container">
                        <button
                          onClick={handleSavePhotos}
                          className="save right"
                        >
                          Save and proceed
                        </button>
                      </div>
                    </div>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </div>
    </Style>
  );
};

export default UploadProperty;
