import styled from "styled-components";
import media from "styled-media-query";
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";

const Styles = styled.div`
  display: flex;

  position: relative;
  height: 100%;
  ${media.greaterThan("768px")`
  
  `};
  ${media.greaterThan("1024px")`
    
  `};
  ${media.greaterThan("1440px")`
  
  `};

  .choose_files_input {
    position: absolute;
    left: 999999999px;
  }
`;

const ImageUpload = ({
  placeholder,
  acceptType,
  setHandleDocsSubmission,
  // handleDocsSubmission,
  children,
  type,
  avatar,
  setAvatar,
  fileToUpload,
  setFileToUpload,
}) => {
  // SECTION ONE
  const [currentFile, setCurrentFile] = useState(undefined);
  const [previewImage, setPreviewImage] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");

  const [imageInfos, setImageInfos] = useState([]);

  // SECTION TWO
  const selectFile = (event) => {
    setCurrentFile(event.target.files[0]);
    console.log("this is the event from trying to upload a file: ", event);
    setPreviewImage(URL.createObjectURL(event.target.files[0]));
    setProgress(0);
    setMessage("");
  };

  //  SECTION THREE
  const upload = async (onUploadProgress) => {
    setProgress(0);
    console.log("this is the preview image: ", previewImage);

    let formData = new FormData();
    formData.append("file", currentFile);

    console.log("the current image: ", currentFile);

    await axios
      .post("/upload/media/file", formData, { onUploadProgress })
      .then((res) => {
        console.log("the reeesssssponse: ", res);
        console.log("the onUpload progress is here: ", progress);
        if (type === "photoImage") {
          const avatarString = res?.data?.name;
          setAvatar(avatarString);
        }
        if ((type = "file")) {
          const fileString = res?.data?.name;
          setFileToUpload(fileString);
        }
        return res;
      })
      .catch((err) => {
        const errMsg = err.response.data.message;
        setProgress(0);
        console.log("the errrrrror!: ", errMsg);
        setCurrentFile(undefined);
        setAvatar("");
        return err;
      });

    //   // if (err.response && err.response.data && err.response.data.message) {
    //   //   setMessage(err.response.data.message);
    //   // } else {
    //   //   setMessage("Could not upload the Image!");
    //   // }
    //   console.log("the upload error: ", err);

    //   setCurrentFile(undefined);
    // });
  };

  // SECTION FOUR
  // useEffect(() => {
  //   UploadService.getFiles().then((response) => {
  //     setImageInfos(response.data);
  //   });
  // }, []);

  // MY ADDITION

  const uploadRef = useRef(null);
  useEffect(() => {
    if (currentFile) {
      upload((event) => {
        setProgress(Math.round((100 * event.loaded) / event.total));
      });
    }
  }, [currentFile]);

  return (
    <div>
      {/* <input type="file" accept="image/*" onChange={selectFile} />
      <button
        className="btn btn-success btn-sm"
        disabled={!currentFile}
        onClick={upload}
      >
        Upload
      </button> */}

      <Styles onClick={() => uploadRef.current.click()}>
        <div className="choose_files_input">
          <input
            type="file"
            name="file"
            accept={acceptType}
            onChange={selectFile}
            ref={uploadRef}
          />
        </div>
        {type === "photoImage" && !currentFile ? (
          <div>{children}</div>
        ) : type === "photoImage" && currentFile && avatar !== "" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div>{children}</div>
            <p style={{ color: "#919499", marginBottom: "15px" }}>
              Photo added! Click Save Changes to upload.
            </p>
          </div>
        ) : currentFile ? (
          <div className="after_choosing_display">
            <p style={{ color: "#919499", marginBottom: "15px" }}>
              {currentFile.name}
            </p>
          </div>
        ) : (
          <p>{placeholder}</p>
        )}
        {type !== "photoImage" && !currentFile && (
          <p style={{ marginBottom: "0" }}>Choose a file</p>
        )}
      </Styles>
    </div>
  );
};

export default ImageUpload;
