import styled from "styled-components";
import media from "styled-media-query";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";

import { FaUserCircle } from "react-icons/fa";

import checkDemoIcon from "../../../Assets/images/check_demo_icon.svg";
import forEntrepreneursIcon from "../../../Assets/images/for_entreprenuers_icon.svg";
import forStartups from "../../../Assets/images/for_startups_icon.svg";

import ShowMoreButton from "./ShowMoreButton";
import OrangeButton from "../../elements/OrangeButton";
// import useUserHook from "../../../hooks/useUserHook";

// import button from "./button";

//STYLINGS START HERE=====================================
const Styles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  font-family: "ApercuProMediumNormal";

  line-height: 170%;
  ${media.greaterThan("1024px")`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  /* gap: 20px; */

  `}

  .primary {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    flex: 1;

    p {
      font-size: 18px;

      ${media.greaterThan("1024px")`
        font-size: 14px;
      `}
      ${media.greaterThan("1440px")`
        font-size: 16px;
      `}
    }

    ${media.greaterThan("1024px")`
    flex-direction: row;
    align-items: center;
    `}
  }

  .secondary {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    align-items: flex-start;

    ${media.greaterThan("1024px")`
    flex-direction: row;
    align-items: center;
    gap: 4px;
    `}
  }
`;

// STYLINGS END HERE ========================

// FUNCTIONS START HERE ===========================

const NavBar = () => {
  const [userPresent, setUserPresent] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    const userAvailable = localStorage.getItem("userInfo");
    if (userAvailable) {
      setUserPresent(true);
      setUserDetails(JSON.parse(userAvailable));
    } else {
      setUserPresent(false);
    }
  }, [userPresent]);

  // console.log("this is the userDetails forom the navbar: ", userDetails);

  return (
    <Styles>
      <div className="primary">
        <Link to="/about" preventScrollReset={false}>
          <p>About Us</p>
        </Link>
        <Link to="/why-us" preventScrollReset={false}>
          <p>Why Landlords Technology</p>
        </Link>
        <ShowMoreButton
          bg={"#F8F8F8"}
          title={"Customers"}
          options={[
            {
              image: checkDemoIcon,
              title: "For Individuals/Businesses",
              subtitle: "We help you Pay",
              link: "/finance-with-landlordstech",
            },
            {
              image: checkDemoIcon,
              title: "For Landlords",
              subtitle: "We power your rental collection",
              link: "/landlords",
            },
            {
              image: forStartups,
              // title: "For StartUps",
              title: "For Rental/Rent Finance Platforms",
              subtitle: "We help your customers pay",
              link: "/startups",
            },
          ]}
        />
        <Link to="/support" preventScrollReset={false}>
          <p>Support</p>
        </Link>
        <Link to="/blogs">
          <p>Learn</p>
        </Link>
      </div>

      {userPresent ? (
        // <div>Profile</div>
        <div
          style={{
            marginLeft: "5%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <Link href="/dashboard">
            <FaUserCircle size={32} color={"#FBBD29"} />
          </Link> */}
          <Link
            to={
              userDetails?.profile_completed === true
                ? "/dashboard"
                : "/dashboard/profile"
            }
          >
            <OrangeButton padding_off bg>
              Dashboard
            </OrangeButton>
          </Link>
        </div>
      ) : (
        <div className="secondary">
          <Link to="/signin">
            <OrangeButton padding_off>Login</OrangeButton>
          </Link>
          <Link to={"/register"}>
            <OrangeButton padding_off bg>
              Open Account
            </OrangeButton>
          </Link>
        </div>
      )}
    </Styles>
  );
};

export default NavBar;
