import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DojahWidjet from "./dojaWidjet.tsx";
import messageIcon from "../../Assets/images/message_icon.svg";
import phoneIcon from "../../Assets/images/phone_icon.svg";
import logoAnother from "../../Assets/images/logo_another.svg";
import { Circles } from "react-loader-spinner";

// import { SlUser } from "react-icons/sl";
import { FaUserAlt, FaIdCardAlt } from "react-icons/fa";
// import { FaIdCardAlt } from "react-icons/";

import Button from "../../components/elements/Button";
import styled from "styled-components";
import media from "styled-media-query";
import ImagePartContent from "../../components/blocks/Authentication/ImagePartContent";

import { TiArrowBack } from "react-icons/ti";
import LogoAbsolutePlacer from "../../components/elements/LogoAbsolutePlacer";

import {
  FormPart,
  OverallAuthContainer,
} from "../../components/blocks/Authentication";

import { icons } from "react-icons";
import LogoContainerOther from "../../components/elements/LogoContainerOther";
import axios from "axios";
import { toast } from "react-toastify";
import useAppHook from "../../hooks/useAppHook";

const ResetViaEmailBox = styled.div`
  background: #ffffff;
  border: 2px solid rgba(41, 128, 151, 0.7);
  box-shadow: 0px 10px 15px rgba(41, 128, 151, 0.1);
  border-radius: 14px;
  padding: 5%;
  ${media.greaterThan("768px")`
  
  `};
  ${media.greaterThan("1024px")`

  `};
  ${media.greaterThan("1440px")`

  `};

  /* #contain {
    ::placeholder {
      color: "#C4C4C4";
    }
  } */
`;

const NeededFlexed = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  .icon_overall {
    min-width: 56px;
    min-height: 56px;
    border-radius: 50%;
    background: #f8ad15;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .texts {
    display: flex;
    flex-direction: column;
    gap: 11px;
    justify-content: space-between;

    .title {
      font-family: "ApercuProMediumNormal";
      font-size: 18px;
      line-height: 22px;
      color: #000000;
    }

    .subtitle {
      font-size: 14px;
      line-height: 17px;
      color: #c4c4c4;
    }
  }
`;

const ExtraBox = styled.div`
  width: 100%;
  ${media.greaterThan("768px")`
    width: 80%;
    margin: 0 auto;

  `};
  ${media.greaterThan("1024px")`
      max-width: 400px;
      /* padding-top: 90px; */
  `};
  ${media.greaterThan("1440px")`
    max-width: 552px;
  `};
`;

const ResetViaSms = styled.div`
  border: 1.3px solid rgba(196, 196, 196, 0.5);
  filter: drop-shadow(0px 10px 15px rgba(151, 151, 151, 0.08));
  border-radius: 14px;
  padding: 5%;
`;

const IdentityVerification = () => {
  const navigate = useNavigate();
  const { openDojahWidget, setOpenDojahWidget } = useAppHook();

  const [submitting, setSubmitting] = useState(false);

  const [dojahData, setDojahData] = useState(null);

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo.profile_completed !== true) {
      navigate("/dashboard/profile");
    }
  }, [navigate]);

  useEffect(() => {
    console.log("this is the dojahData outside: ", dojahData);
    if (dojahData !== null) {
      setSubmitting(true);
      if (dojahData.status === true) {
        axios
          .post(`/account/identification-verification`, {
            data: { ...dojahData },
          })
          .then((res) => {
            setSubmitting(false);
            toast.success("Verified Successfully");
            navigate("/signin");
          })
          .catch((error) => {
            console.log("the error: ", error);
            toast.error("Error validating account");
            setSubmitting(false);
          });
      }
    }
  }, [dojahData, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpenDojahWidget(true);
  };

  // console.log("this is the email: ", email);
  const [open, setOpen] = useState(false);
  return (
    <OverallAuthContainer>
      <div style={{ position: "relative" }} className="form_part">
        <LogoAbsolutePlacer>
          <LogoContainerOther
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
          >
            <img
              src={logoAnother}
              alt=""
              style={{ width: "100%", objectFit: "cover", display: "block" }}
            />
          </LogoContainerOther>
        </LogoAbsolutePlacer>
        <FormPart onSubmit={handleSubmit}>
          <h2 style={{ textAlign: "center", color: "#000" }}>
            Verify Identity
          </h2>
          <p className="subheading">Facial and ID card verification</p>

          {/* <ResetViaEmailBox>
            <NeededFlexed>
              <div className="icon_overall">
                <IconContainer>
                  <Image src={messageIcon} alt="" fill />
                </IconContainer>
              </div>
              <div className="texts">
                <p className="title">Reset via Email</p>
                <p className="subtitle">
                  We will send a link to reset your password
                </p>
              </div>
            </NeededFlexed>
            <div id="contain" className="input_label_container">
              <label
                style={{ position: "absolute", left: "-999999px" }}
                htmlFor="email"
              >
                Email
              </label>
              <input
                style={{
                  background: "#F5F7FB",
                }}
                type={"text"}
                id="email"
                placeholder="Email Address"
                className="form_input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <p className="error message" style={{ textAlign: "left" }}>
                {emailError.join(", ")}
              </p>
            </div>
          </ResetViaEmailBox> */}

          <ResetViaSms>
            <NeededFlexed>
              <div className="icon_overall">
                <FaUserAlt size={24} color={"#000"} style={{}} />
              </div>
              <div className="texts">
                <p className="title">Facial Verification</p>
                <p className="subtitle">
                  Stay in a well lit environment for facial verification
                </p>
              </div>
            </NeededFlexed>
          </ResetViaSms>
          <ResetViaSms>
            <NeededFlexed>
              <div className="icon_overall">
                <FaIdCardAlt />
              </div>
              <div className="texts">
                <p className="title">ID Card Verification</p>
                <p className="subtitle">
                  We will carry out ID card verification
                </p>
              </div>
            </NeededFlexed>
          </ResetViaSms>
          {/* {open && (
            <DojahWidjet
              setSubmitting={setSubmitting}
              setDojahData={setDojahData}
              // setOpen={setOpen}
              // open={open}
            />
          )} */}
          <DojahWidjet
            setSubmitting={setSubmitting}
            setDojahData={setDojahData}
            // setOpen={setOpen}
            // open={open}
          />
          <Button
            type="submit"
            bg={"#F8AD15"}
            clr="#FFFFFF"
            style={{
              width: "100%",
              justifyContent: "center",
              height: "65px",
            }}
            onClick={() => {
              // setSubmitting(true)
              setOpen(true);
            }}
          >
            {submitting && (
              <Circles
                height="30"
                width="30"
                color="#fff"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            )}
            Continue Verification
          </Button>
        </FormPart>
        {/* <ExtraBox>
          <Link href={"/signin"}>
            <ExtraTexts
              style={{
                marginTop: "20px",
                display: "flex",
                alignItems: "center",
                gap: "5px",
                alignSelf: "flex-start",
                // background: "#ff0000",
                // position: "absolute",
                // left: "0",
              }}
            >
              <TiArrowBack size={24} />
              <span>Back to sign in</span>
            </ExtraTexts>
          </Link>
        </ExtraBox> */}
      </div>
      <div className="picture_part">
        <ImagePartContent />
      </div>
    </OverallAuthContainer>
  );
};

export default IdentityVerification;

// IdentityVerification.getLayout = function PageLayout(page) {
//   return <>{page}</>;
// };
