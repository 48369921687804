import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import styled from "styled-components";
import media from "styled-media-query";
import DatePicker from "react-datepicker";
import { Circles } from "react-loader-spinner";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";

// import BaseApi from "../Api/BaseApi";
import axios from "axios";

import eyeOpen from "../Assets/images/eye_open_icon.svg";
import eyeClosed from "../Assets/images/eye_closed_icon.svg";
import calendarIcon from "../Assets/images/calendar_icon.svg";

import ImagePartContent from "../components/blocks/Authentication/ImagePartContent";
import {
  FormPart,
  ExtraTexts,
  OverallAuthContainer,
  FormPartContainer,
} from "../components/blocks/Authentication";
import Button from "../components/elements/Button";
import Validator from "../utils/Validator";
import useAppHook from "../hooks/useAppHook";
import Input from "../modules/common_module/components/basic/functional/inputs/Input";
import SelectInput from "../modules/common_module/components/basic/functional/inputs/SelectInput";
import BlankInput from "../modules/common_module/components/basic/functional/inputs/BlankInput";

const Style = styled.div`
  .half-input {
    width: 50% !important;
    ${media.lessThan("medium")`
    width: 100% !important;
    `}
  }
  .date-picker {
    border: none;
    width: 90%;
  }

  /* .password-hint {
    background: blue;
    position: absolute;
    bottom: 0;
    font-size: 12px;
    line-height: 14px;
    color: var(--clr-placeholder);
    padding: 0 4px;
  } */
`;

const Register = () => {
  const { registerCustomerType } = useAppHook();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState(null);
  const [errorToDisplay, setErrorToDisplay] = useState("");
  const [successMsg, setSuccessMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [customerType, setCustomerType] = useState([]);

  const [unVerified, setUnverified] = useState(true);

  const navigate = useNavigate();

  const [signupDetails, setSignupDetails] = useState({
    full_name: "",
    email: "",
    phone_number: "",
    province: "",
    city: "",
    province_address: "",
    password: "",
    password_confirmation: "",
    dob: "",
    customer_type: "",
  });

  const [signupError, setSignupError] = useState({
    full_name: [],
    email: [],
    phone_number: [],
    province: [],
    city: [],
    province_address: [],
    password: [],
    password_confirmation: [],
    dob: [],
    customer_type: [],
  });

  const [dateValue, setDateValue] = useState(
    new Date("2000-01-01T23:50:21.817Z")
  );
  // const [dateString, setDateString] = useState("hhh");
  const handleCalendarClose = () => {
    const year = String(dateValue.getFullYear());
    let month = String(dateValue.getMonth() + 1);
    let day = String(dateValue.getDate());

    if (month.length < 2) {
      month = `0${month}`;
    }
    if (day.length < 2) {
      day = `0${day}`;
    }

    setSignupDetails((prev) => ({
      ...prev,
      dob: `${year}-${month}-${day}`,
    }));
  };

  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);

  useEffect(() => {
    const fetchStatesList = async () => {
      axios
        .get("/state/list")
        .then((res) => {
          const statesAvailable = res.data.data;
          setStatesList(statesAvailable);
        })
        .catch((err) => {
          toast.error(err.message);
        });
    };

    fetchStatesList();
  }, []);

  useEffect(() => {
    if (signupDetails.province === "") {
      return;
    } else {
      statesList.filter(
        (state) =>
          state.id === Number(signupDetails.province) &&
          setCitiesList(state.lga)
      );
    }
  }, [citiesList, signupDetails.province, statesList]);

  useEffect(() => {
    const constants = JSON.parse(localStorage.getItem("constants"));
    if (constants) {
      setCustomerType(constants.customer_type);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("submit button clicked!");

    console.log("this is the signupdata: ", signupDetails);

    setErrors(null);
    setErrorToDisplay("");
    setSuccessMessage("");
    setSignupError({
      full_name: [],
      email: [],
      phone_number: [],
      province: [],
      city: [],
      province_address: [],
      password: [],
      password_confirmation: [],
      dob: [],
      customer_type: [],
    });

    const validatorMethods = {
      full_name: "required|min,2",
      email: "required|email",
      phone_number: "required|min,11",
      province: "required",
      city: "required",
      province_address: "required|min,5",
      password: "required|min,6|has-alpha|has-num|has-sym", //must have an uppercase, a lowercase and a symbol character
      password_confirmation: "required|same-as,:password",
      dob: "required",
      customer_type: "required",
    };

    const validator = new Validator(signupDetails, validatorMethods);
    validator.result
      .then(async () => {
        setSubmitting(true);
        const formData = new FormData();
        formData.append("email_address", signupDetails.email);
        formData.append("password", signupDetails.password);
        formData.append(
          "password_confirmation",
          signupDetails.password_confirmation
        );
        formData.append("fullname", signupDetails.full_name);
        formData.append("phone_number", signupDetails.phone_number);
        formData.append("province", signupDetails.province);
        formData.append("city", signupDetails.city);
        formData.append("address", signupDetails.province_address);
        formData.append("dob", signupDetails.dob);
        if (registerCustomerType === "landlord") {
          formData.append("customer_type", "landlord");
        } else if (registerCustomerType === "developer") {
          formData.append("customer_type", "developer");
        } else {
          formData.append("customer_type", signupDetails.customer_type);
        }
        // formData.append("customer_type", signupDetails.customer_type);

        axios
          .post("/user-registration", formData)
          .then((res) => {
            console.log("this is the final formData: ", formData);
            if (res.data.status_code === 201) {
              setSuccessMessage(
                "Account registration successful. Check your mail to verify"
              );
              localStorage.setItem("registeringEmail", signupDetails.email);
              localStorage.setItem("unverified", "yes");
              navigate("/verify-email");
              setSubmitting(false);
            } else {
              console.log(
                "here is another code response other than 201: ",
                res
              );
              toast.error("An error has occurred post registration");
              setSubmitting(false);
            }
          })
          .catch((error) => {
            console.log("this is the final formData: ", formData);
            toast.error("An error occurred during registration");
            setSubmitting(false);
            const errorMsgObj = error.response.data.errors;

            const errorMsg = Object.entries(errorMsgObj)[0][1];

            setErrorToDisplay(errorMsg);
          });
        // console.log("this is the registration data", formData);
      })
      .catch((error) => {
        setSubmitting(false);
        toast.error("Please make sure to fill in all details.");
        setSignupError((prev) => ({
          ...prev,
          full_name: error?.error.full_name,
          email: error?.error.email,
          phone_number: error?.error.phone_number,
          province: error?.error.province,
          city: error?.error.city,
          province_address: error?.error.province_address,
          password: error?.error.password,
          password_confirmation: error?.error.password_confirmation,
          customer_type: error?.error.customer_type,
          dob: error?.error.dob,
        }));
      });
  };

  return (
    <Style>
      <OverallAuthContainer>
        <div className="form_part">
          {/* <FormPartContainer> */}
          <FormPart>
            <h2 style={{ color: "#000" }}>Get started</h2>

            <div>
              <Input
                label={"Full Name"}
                inputFor={"register_email_or_username"}
                type={"text"}
                placeholder={"Names"}
                value={signupDetails.full_name}
                valueChange={(e) =>
                  setSignupDetails((prev) => ({
                    ...prev,
                    full_name: e.target.value,
                  }))
                }
                errorMsg={signupError.full_name}
              />
            </div>

            <div>
              <Input
                label={"Email Address"}
                inputFor={"register_email"}
                type={"email"}
                placeholder={"Email Address"}
                value={signupDetails.email}
                valueChange={(e) =>
                  setSignupDetails((prev) => ({
                    ...prev,
                    email: e.target.value,
                  }))
                }
                errorMsg={signupError?.email}
              />
            </div>

            <div>
              {registerCustomerType === "landlord" ? (
                <Input
                  label={"Customer Type"}
                  options={customerType}
                  inputFor={"customer_type"}
                  value={"Landlord"}
                />
              ) : (
                <SelectInput
                  label={"Customer Type"}
                  options={customerType}
                  inputFor={"customer_type"}
                  value={signupDetails.customer_type}
                  valueChange={(e) => {
                    setSignupDetails((prev) => ({
                      ...prev,
                      customer_type: e.target.value,
                    }));
                  }}
                  errorMsg={signupError.customer_type}
                />
              )}
            </div>

            <div className="sub_container">
              <div className="half-input">
                {/* <BlankInput
                  label={"Birth Date"}
                  icon={calendarIcon}
                  inputFor={"register_dob"}
                  errorMsg={signupError.dob}
                >
                  <DatePicker
                    className="date-picker"
                    selected={dateValue}
                    onChange={(date) => setDateValue(date)}
                    dateFormat="yyyy-MM-dd"
                    onCalendarClose={handleCalendarClose}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    maxDate={new Date("2005-12-12T23:50:21.817Z")}
                  />
                </BlankInput> */}
                <Input
                  label={"Birth Date"}
                  inputFor={"register_dob"}
                  type={"date"}
                  placeholder={"Date of Birth"}
                  value={signupDetails.dob}
                  valueChange={(e) =>
                    setSignupDetails((prev) => ({
                      ...prev,
                      dob: e.target.value,
                    }))
                  }
                  errorMsg={signupError.dob}
                />
              </div>

              <div className="half-input">
                <Input
                  label={"Phone Number"}
                  inputFor={"register_phone_number"}
                  type={"text"}
                  placeholder={"Phone Number"}
                  value={signupDetails.phone_number}
                  valueChange={(e) =>
                    setSignupDetails((prev) => ({
                      ...prev,
                      phone_number: e.target.value,
                    }))
                  }
                  errorMsg={signupError?.phone_number}
                />
              </div>
            </div>

            <div className="sub_container">
              <div className="half-input">
                <SelectInput
                  label={"State"}
                  options={statesList}
                  inputFor={"register_state"}
                  // placeholder={"state"}
                  value={signupDetails.province}
                  valueChange={(e) => {
                    setSignupDetails((prev) => ({
                      ...prev,
                      province: e.target.value,
                    }));
                  }}
                  errorMsg={signupError.province}
                />
              </div>

              {/* <div className="input_label_container half-input">
                <label htmlFor="city">City</label>
                <select
                  value={signupDetails.city}
                  name="city"
                  className="form_input"
                  style={
                    {
                      // color: "",
                    }
                  }
                  onChange={(e) => {
                    setSignupDetails((prev) => ({
                      ...prev,
                      city: e.target.value,
                    }));
                  }}
                >
                  <option value="" disabled>
                    City
                  </option>
                  {citiesList?.map((city) => (
                    <option
                      key={city.id}
                      value={city.id}
                      // style={{ paddingBottom: "10px" }}
                    >
                      {city.name}
                    </option>
                  ))}
                </select>
              </div> */}

              <div className="half-input">
                <SelectInput
                  label={"City"}
                  options={citiesList}
                  inputFor={"register_city"}
                  // placeholder={"city"}
                  value={signupDetails.city}
                  valueChange={(e) => {
                    setSignupDetails((prev) => ({
                      ...prev,
                      city: e.target.value,
                    }));
                  }}
                  errorMsg={signupError.city}
                />
              </div>
            </div>

            <div>
              <Input
                label={"Address"}
                textAreaInput
                inputFor={"register_full_address"}
                type={"text"}
                placeholder={"Full Address"}
                value={signupDetails.province_address}
                valueChange={(e) =>
                  setSignupDetails((prev) => ({
                    ...prev,
                    province_address: e.target.value,
                  }))
                }
                errorMsg={signupError.province_address}
              />
            </div>

            <div className="sub_container">
              {/* <div className="input_label_container half-input">
                <label htmlFor="password">
                  Password <span className="required_star">*</span>
                </label>
                <div style={{ position: "relative" }}>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    placeholder="Password"
                    className="form_input"
                    value={signupDetails.password}
                    onChange={(e) =>
                      setSignupDetails((prev) => ({
                        ...prev,
                        password: e.target.value,
                      }))
                    }
                  />

                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="password_visibility"
                  >
                    {showPassword ? (
                      <img
                        src={eyeOpen}
                        alt="password visible"
                        width={15}
                        height={15}
                      />
                    ) : (
                      <img
                        src={eyeClosed}
                        alt="password hidden"
                        width={15}
                        height={15}
                      />
                    )}
                  </button>
                </div>
                <p className="error message" style={{ textAlign: "left" }}>
                  {signupError.password.join(", ")}
                </p>
              </div> */}
              <div className="half-input" style={{ position: "relative" }}>
                <Input
                  password
                  label={"Password"}
                  inputFor={"register_password"}
                  type={"text"}
                  placeholder={"password"}
                  hints={"hint: add uppercase, lowercase and symbol"}
                  value={signupDetails.password}
                  valueChange={(e) =>
                    setSignupDetails((prev) => ({
                      ...prev,
                      password: e.target.value,
                    }))
                  }
                  errorMsg={signupError?.password}
                />
                {/* {signupError?.password?.length <= 0 ? (
                  <p className="password-hint">
                    password must be at least six characters, must have an
                    uppercase, a lowercase and a symbol character.
                  </p>
                ) : (
                  <></>
                )} */}
              </div>

              <div className="half-input">
                <Input
                  password
                  label={"Confirm Password"}
                  inputFor={"register_confirm_password"}
                  type={"text"}
                  placeholder={"Retype Password"}
                  value={signupDetails.password_confirmation}
                  valueChange={(e) =>
                    setSignupDetails((prev) => ({
                      ...prev,
                      password_confirmation: e.target.value,
                    }))
                  }
                  errorMsg={signupError?.password_confirmation}
                />
              </div>
            </div>

            <div style={{}}>
              {errorToDisplay !== "" && (
                <p
                  className="error message"
                  style={{ fontSize: "16px", paddingBottom: "10px" }}
                >
                  {errorToDisplay}
                </p>
              )}
              {successMsg !== "" && (
                <div
                  style={{
                    paddingBottom: "10px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <p
                    className="error message"
                    style={{
                      fontSize: "16px",
                      paddingBottom: "10px",
                      color: "#30e33f",
                    }}
                  >
                    {successMsg}
                  </p>
                </div>
              )}

              <Button
                type="submit"
                onClick={(e) => handleSubmit(e)}
                bg={"#F8AD15"}
                clr="#FFFFFF"
                style={{
                  width: "100%",
                  justifyContent: "center",
                  height: "65px",
                  marginTop: "15px",
                }}
              >
                {submitting && (
                  <Circles
                    height="30"
                    width="30"
                    color="#fff"
                    ariaLabel="circles-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                )}
                Sign Up
              </Button>
            </div>
          </FormPart>
          <ExtraTexts style={{ marginTop: "20px" }}>
            You dont Have an Account?{" "}
            <Link to={"/signin"}>
              <span>Sign In</span>
            </Link>
          </ExtraTexts>
        </div>
        <div className="picture_part">
          <ImagePartContent />
        </div>
      </OverallAuthContainer>
    </Style>
  );
};

export default Register;

Register.getLayout = function PageLayout(page) {
  return <>{page}</>;
};
