import { HiOutlineArrowRight } from "react-icons/hi";
import styled from "styled-components";
import media from "styled-media-query";
import { Link } from "react-router-dom";

import theBackground from "../Assets/images/woman_looking_computer_transparent.png";
import securedWidget from "../Assets/images/secured_onboarding_widget.svg";
import validateDataWidget from "../Assets/images/validate_your_customer_data_widget.svg";
import fullEarth from "../Assets/images/full_earth.svg";
import globe from "../Assets/images/globe.gif";

import NeedHelp from "../components/blocks/NeedHelp";
import Button from "../components/elements/Button";

const Styles = styled.div`
  padding-top: 10px;
  ${media.greaterThan("768px")`

  `};

  .colored_background {
    padding-top: 80px;
    padding-bottom: 120px;
    /* background-image: url("../Assets/images/woman_looking_computer_transparent.png"); */
    /* background: rgba(204, 141, 43, 1); */
    background: rgba(0, 0, 0, 0.9);
    /* height: 500px; */
    height: 100%;

    display: flex;
    /* gap: 100px; */
    flex-direction: column;

    ${media.greaterThan("768px")`
      padding-top: 120px;
      padding-bottom: 150px;
    `}
    ${media.greaterThan("1024px")`
      padding: 0 5%;
      padding-top: 180px;
      padding-bottom: 200px;
      gap: 50px;
      /* height:943px; */
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    `}
    ${media.greaterThan("1440px")`
      padding-top: 224px;
      padding-bottom: 254px;
    `}
  }
`;

const FlexedContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  /* gap: 50px; */
  padding: 0 5%;
  /* background-image: url(../public/woman_looking_desktop.png); */
  background-size: cover;

  ${media.greaterThan("768px")`
  padding: 80px 10%;
  /* flex-direction: row;
  align-items: center;
  
  padding: 0 5%;
  gap: 0px */
  `};
  ${media.greaterThan("1024px")`
  /* gap: 5%; */
  flex-direction: row;
  align-items: center;
  padding: 0 5%;

    justify-content: space-between;
  `};

  .left_part {
    display: flex;
    flex-direction: column;
    /* gap: 18px; */
    padding-top: 70px;
    margin-bottom: 50px;
    ${media.greaterThan("768px")`
      padding-top: 0;
      /* gap: 24px; */
    `};
    ${media.greaterThan("1024px")`
      margin-bottom: 0px;
      width: 50%;
      padding-top: 0;
      /* gap: 32px; */
    `};
  }
  .right_part {
    /* background: #00ffdd; */
    display: flex;
    flex-direction: column;
    ${media.greaterThan("768px")`
    `};
    ${media.greaterThan("1024px")`
    width: 50%;
    margin: 0 auto;
      
    `};
  }

  .background_image_container {
  }

  .the_background {
    /* max-inline-size: 100%; */
    block-size: auto;
    /* object-fit: cover; */
    display: none;
    ${media.greaterThan("768px")`
  
    `};
    ${media.greaterThan("1024px")`
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    `};
    /* z-index: -1; */
  }

  /* .colored_overlay {
    background: ;
  } */

  .widget_image_container {
  }
`;

const TitleText = styled.h2`
  font-family: "Tiempos Headline";
  font-size: 40px;
  line-height: 110%;
  letter-spacing: -0.03em;
  color: ${(props) => (props.clr ? props.clr : "#1a191e")};
  margin-bottom: 18px;
  ${media.greaterThan("768px")`
    font-size: 45px;
    margin-bottom: 24px;
  `};
  ${media.greaterThan("1024px")`
    font-size: 55px;
    margin-bottom: 32px;
  `};
  ${media.greaterThan("1440px")`
    font-size: 60px;
  `};
`;

const CommonText = styled.p`
  font-size: 20px;
  line-height: 1.5;
  color: ${(props) => (props.clr ? props.clr : "#1a191e")};
`;

const ButtonsFlexed = styled.div`
  display: flex;
  flex-direction: row;
  ${media.greaterThan("768px")`

  
  `};
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

const HorizontalBar = styled.div`
  background: #fbbd29;
  height: 7px;
  width: 209px;
  margin-top: 10px;
  margin-bottom: 18px;

  ${media.greaterThan("768px")`
    margin-top: 10px;
    margin-bottom: 30px;
  `};
  ${media.greaterThan("1024px")`
  
      
  `};
  ${media.greaterThan("1440px")`
      
  `};
`;

const BackgroundedOverall = styled.section`
  /* background: rgba(0, 0, 0, 0.9); */
  background: rgba(204, 141, 43, 0.5);
  .the_background_image {
    max-inline-size: 100%;
    block-size: auto;
    object-fit: cover;
    display: none;
    ${media.greaterThan("768px")`
  
    `};
    ${media.greaterThan("1024px")`
      display: block;
   
    `};
    /* z-index: -1; */
  }
`;

//  rgba(204,141,43,0.1)
const KycVerification = () => {
  return (
    <Styles>
      <FlexedContainer style={{ paddingBottom: "100px" }}>
        <div className="left_part">
          <TitleText>KYC Verification, onboard users with ease</TitleText>
          <CommonText style={{ marginBottom: "30px" }}>
            Verify Your Customers Automate your user registration process by
            automatically validating their identity. Explore our APIs, widgets
            and no-code tools to speed up onboarding and verify customer
            identity.
          </CommonText>
          <ButtonsFlexed>
            <Button bg={"#FBBD29"} clr="#fff">
              Open A Developer Account
            </Button>
            <Link to={"/blogs"}></Link>
            <Button>
              Learn More <HiOutlineArrowRight size={24} />
            </Button>
          </ButtonsFlexed>
        </div>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            // paddingTop: "80px",
          }}
          className="right_part"
        >
          <ImageContainer>
            <img
              src={globe}
              alt=""
              style={{ maxInlineSize: "100%", blockSize: "auto" }}
              // fill
              // sizes="(max-width: 768px) 100vw,
              // (max-width: 1200px) 50vw,
              // 33vw"
            />
          </ImageContainer>
        </div>
      </FlexedContainer>

      {/* <BackgroundedOverall>
        <img src={theBackground} alt="" className="the_background_image" />
      </BackgroundedOverall> */}
      <FlexedContainer className="colored_background">
        {/* <div className="background_image_container"> */}
        <img src={theBackground} alt="" className="the_background" />
        {/* </div> */}
        <div className="left_part">
          <img
            src={securedWidget}
            alt=""
            style={{
              // position: "absolute",
              objectFit: "contain",
              maxInlineSize: "100%",
              blockSize: "auto",
              maxWidth: "20%",
            }}
          />
          <div style={{ marginTop: "30px" }}>
            <TitleText style={{ color: "#fff", marginBottom: "0px" }}>
              Secured
              <br /> Onboarding Widget
            </TitleText>
            <HorizontalBar></HorizontalBar>
          </div>
          <CommonText style={{ color: "#fff" }}>
            The B2B solutions deploys Application Programming Interface (APIs)
            to enable property rental platforms integrate our flexible property
            rental financing and repayments on their existing rental portals.
          </CommonText>
        </div>
        <div className="right_part">
          <img
            src={validateDataWidget}
            alt=""
            style={{
              // position: "absolute",
              objectFit: "contain",
              maxInlineSize: "100%",
              blockSize: "auto",
              maxWidth: "20%",
            }}
          />
          <div style={{ marginTop: "30px" }}>
            <TitleText style={{ color: "#fff", marginBottom: "0px" }}>
              Validate your
              <br /> Customer data
            </TitleText>
            <HorizontalBar></HorizontalBar>
          </div>
          <CommonText style={{ color: "#fff" }}>
            We Ensure compliance by proving API service to help you verify that
            the data provided by users match government-issued IDs.
          </CommonText>
        </div>
        {/* <div className="colored_overlay"></div> */}
      </FlexedContainer>
      <NeedHelp />
    </Styles>
  );
};

export default KycVerification;
