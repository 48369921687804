import styled from "styled-components";
import media from "styled-media-query";

export const InputBasic = styled.div`
  /* padding: ${(props) => (props.width ? "0px" : "8px 12px")}; */
  position: relative;
  /* background: blue; */
  padding-top: 27px;

  label {
    position: absolute;
    top: 0;
    left: 0;
    /* font-family: "ApercuProMediumNormal";
    font-size: 14px;
    line-height: 17px;
    color: #000;
    padding: 0 10px; */

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 15px;
    /* position: relative; */
    color: #919499;
    width: 100%;
  }

  .star-required {
    color: var(--clr-danger);
  }

  .forgot-password {
    display: ${(props) => (props.password === true ? "block" : "none")};
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 20px;
    font-family: "ApercuProMediumNormal";
    font-size: 14px;
    line-height: 17px;
    color: var(--clr-pry);
  }

  .input-main-container {
    position: relative;
    border: 1.3px solid rgba(196, 196, 196, 0.5);
    border-radius: 14px;
    height: 54px;
    padding: 14px 16px;
    width: 100%;
    display: flex;
    align-items: center;
    ${media.greaterThan("768px")`
      height: 60px;
      padding: 16px 24px 16px 24px;
    
    `};
    ${media.greaterThan("1024px")`
      height: 65px;
    `};
    ${media.greaterThan("1440px")`
      padding: 20px 34px 20px 34px;

    `};

    .input-main {
      width: 100%;
      height: 100%;
      outline: none;
      border: none;
      font-size: 12px;
      line-height: 16px;

      ${media.greaterThan("768px")`
        font-size: 14px;
        line-height: 18px;
    
      `};
    }

    .input-main::placeholder {
      color: var(--clr-placeholder);
    }

    .extra-texts-container {
      background: red;
    }
  }

  .password_visibility {
    position: absolute;
    /* display: ${(props) =>
      props.type === "password" && props.showPassword === true
        ? "password"
        : `#d70000`}; */
    right: 25px;
    top: 50%;
    transform: translateY(-50%);

    ${media.greaterThan("768px")`
        right: 30px;
    
    `};
  }

  .error-message {
    /* color: var(--clr-danger); */
    color: ${(props) => (props.errorMsg === "" ? "rgba(0,0,0,0)" : `#d70000`)};
    font-size: 10px;
    line-height: 12px;
    padding: 2px 10px 2px;

    ${media.greaterThan("768px")`
        font-size: 12px;
        line-height: 14px;
    
      `};
  }

  .password-hint {
    /* background: blue; */
    /* position: absolute; */
    bottom: 0;
    font-size: 12px;
    line-height: 14px;
    color: var(--clr-placeholder);
    padding: 0 4px;
  }

  ${media.greaterThan("768px")`
    

  `}
  ${media.greaterThan("1024px")`
    

  `}

  .extra-texts-container {
    /* background: red; */
    position: absolute;
  }
`;

export const BlankInputTags = styled.div`
  /* padding: ${(props) => (props.width ? "0px" : "8px 12px")}; */
  position: relative;
  /* background: blue; */
  padding-top: 27px;

  label {
    position: absolute;
    top: 0;
    left: 0;
    /* font-family: "ApercuProMediumNormal";
    font-size: 14px;
    line-height: 17px;
    color: #000;
    padding: 0 10px; */

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 15px;
    /* position: relative; */
    color: #919499;
    width: 100%;
  }

  .input-main-container {
    position: relative;
    border: 1.3px solid rgba(196, 196, 196, 0.5);
    border-radius: 14px;
    min-height: 54px;
    padding: 14px 16px;
    width: 100%;
    display: flex;
    align-items: center;
    ${media.greaterThan("768px")`
      min-height: 60px;
      padding: 16px 24px 16px 24px;
    
    `};
    ${media.greaterThan("1024px")`
      min-height: 65px;
      /* padding: 20px 34px 20px 34px; */
    `};
    ${media.greaterThan("1440px")`
      padding: 20px 34px;
    `};

    .input-main {
      width: 100%;
      height: 100%;
      outline: none;
      border: none;
      font-size: 12px;
      line-height: 16px;

      ${media.greaterThan("768px")`
        font-size: 14px;
        line-height: 18px;
    
      `};
    }

    .input-main::placeholder {
      color: var(--clr-placeholder);
    }
  }

  .password_visibility {
    position: absolute;
    /* display: ${(props) =>
      props.type === "password" && props.showPassword === true
        ? "password"
        : `#d70000`}; */
    right: 25px;
    top: 50%;
    transform: translateY(-50%);

    ${media.greaterThan("768px")`
        right: 30px;
    
    `};
  }

  .error-message {
    /* color: var(--clr-danger); */

    color: ${(props) => (props.errorMsg === "" ? "rgba(0,0,0,0)" : `#d70000`)};
    font-size: 10px;
    line-height: 12px;
    padding: 2px 10px 2px;

    ${media.greaterThan("768px")`
        font-size: 12px;
        line-height: 14px;
    
      `};
  }

  .extra-texts-container {
    /* background: red; */
    position: absolute;
  }

  .children-container {
    flex: 1;
    width: 100%;
  }
`;
