import axios from "axios";
import React, { useState, useEffect } from "react";
import Dojah from "react-dojah";
import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
import { setCookie } from "../..//utils/cookiesHelper";
import useAppHook from "../../hooks/useAppHook";

// import { checkNotification } from "../../../redux/user/user.action";

interface Obj {
  firstname: string;
  lastname: string;
  dob?: string;
}
// interface User {
//   firstname: string;
//   lastname: string;
//   dob?: string;
// }

interface Prop {
  setSubmitting: Function;
  setDojahData: Function;
  // setOpen: Function;
  // open: Boolean;
}
// interface Prop {
//   setSubmitting?: React.Dispatch<React.SetStateAction<boolean>>;
// }

// , setOpen, open

const DojahWidjet = ({ setSubmitting, setDojahData }: Prop) => {
  // const navigate = useNavigate();
  // const [loginData, setLoginData] = useState<any>(null);
  const { openDojahWidget, setOpenDojahWidget } = useAppHook();

  // useEffect(() => {
  //   const data = JSON.parse(localStorage.getItem("login_formData")!);
  //   if (data) {
  //     console.log("the login_Data: ", data);
  //     setLoginData(data);
  //   }
  // }, []);
  /**
   *  This is your app ID
   * (go to your dashboard at
   * https://dojah.io/dashboard
   * to create an app and retrieve it)
   */
  const appID = "61cf4df649c296003276f510";

  /**
   *  This is your account public key
   *  (go to your dashboard at
   *  https://dojah.io/dashboard to
   *  retrieve it. You can also regenerate one)
   */
  const publicKey = "test_pk_LbsmUkWrpwx3fgSfwB8kjMvZ4";

  /**
   *  This is the widget type you'd like to load
   *  (go to your dashboard at
   *  https://dojah.io/dashboard to enable different
   *  widget types)
   */
  const type = "custom";

  /**
   *  These are the configuration options
   *  available to you are:
   *  {debug: BOOL, pages: ARRAY[page: STRING, config: OBJECT]}
   *
   *  The config object is as defined below
   *
   *  NOTE: The otp and selfie options are only
   *  available to the `verification` widget
   */
  const config = {
    debug: true,
    pages: [
      // {
      //   page: "government-data",
      //   config: {
      //     bvn: true,
      //     nin: false,
      //     dl: false,
      //     mobile: false,
      //     otp: false,
      //     selfie: false,
      //   },
      // },
      {
        page: "id",
        config: { passport: true, dl: true, bvn: true, nin: true },
      },
      { page: "selfie" },
    ],
  };

  /**
   *  These are the user's data to verify, options
   *  available to you possible options are:
   *  {first_name: STRING, last_name: STRING, dob: DATE STRING}
   *
   *  NOTE: Passing all the values will automatically skip
   *  the user-data page (thus the commented out `last_name`)
   */
  const userData = {
    // first_name: userDetails.firstname,
    // last_name: userDetails.lastname, // 'Nna'
    // dob: userDetails?.dob || null,
    residence_country: "NG",
  };

  /**
   *  These are the metadata options
   *  You can pass any values within the object
   */
  const metadata = {};

  /**
   * @param {String} type
   * This method receives the type
   * The type can only be one of:
   * loading, begin, success, error, close
   * @param {String} data
   * This is the data from doja
   */
  const response = async (type: any, data: any) => {
    console.log(type, data);
    if (type === "success") {
      await setDojahData(data);
      setOpenDojahWidget(false);
      // navigate("/signin");
      // if (data?.status === true) {
      //   // axios
      //   //   .post(`/account/identification-verification`, {
      //   //     data: { ...data },
      //   //   })
      //   //   .then((res) => {
      //   //     setSubmitting(false);
      //   //     toast.success("Verified Successfully");
      //   //     // navigate("/signin");
      //   //   })
      //   //   .catch((error) => {
      //   //     console.log("the error: ", error);
      //   //     toast.error("Error validating account");
      //   //     setSubmitting(false);
      //   //   });

      //   console.log("Inside the success function. Let's See");
      // }
      console.log("Inside the success function. Let's See");
    } else if (type === "error") {
      console.log("failed!");
      // navigate("/signin");
      // await setDojahData(data);
      // setOpenDojahWidget(false);
    } else if (type === "close") {
      console.log("the widget is closing now");
    } else if (type === "begin") {
    } else if (type === "loading") {
      setDojahData(null);
      console.log("loadding");
    }
  };

  // The Doja library accepts 3 props and
  // initiliazes the doja widget and connect process
  return (
    <>
      {openDojahWidget && (
        <Dojah
          response={response}
          appID={appID}
          publicKey={publicKey}
          type={type}
          config={config}
          userData={userData}
          metadata={metadata}
          // suppressContentEditableWarning={true}
        />
      )}

      {/* <Dojah
        response={response}
        appID={appID}
        publicKey={publicKey}
        type={type}
        config={config}
        userData={userData}
        metadata={metadata}
        // suppressContentEditableWarning={true}
      /> */}
    </>
  );
};

export default DojahWidjet;
