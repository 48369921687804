import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { removeCookie } from "../../utils/cookiesHelper";
import { useState } from "react";
import useAppHook from "../../hooks/useAppHook";

import homeDisabled from "../../Assets/images/homeicondisabled.svg";
import homeActive from "../../Assets/images/homeicon.svg";
import rentalDisabled from "../../Assets/images/rentalicondisabled.svg";
import rentalActive from "../../Assets/images/rentalicon.svg";
import paymentDisabled from "../../Assets/images/paymenticondisabled.svg";
import paymentActive from "../../Assets/images/paymenticon.svg";
import statisticsDisabled from "../../Assets/images/statisticsicondisabled.svg";
import statisticsActive from "../../Assets/images/statisticsicon.svg";
import inboxDisabled from "../../Assets/images/inboxicondisabled.svg";
import inboxActive from "../../Assets/images/inboxicon.svg";
import profileDisabled from "../../Assets/images/profileicondisabled.svg";
import profileActive from "../../Assets/images/profileicon.svg";
import developerDisabled from "../../Assets/images/developericondisabled.svg";
import developerActive from "../../Assets/images/developericon.svg";
import settingsDisabled from "../../Assets/images/settingicondisabled.svg";
import settingsActive from "../../Assets/images/settingicon.svg";
import webhooksDisabled from "../../Assets/images/webhookicondisabled.svg";
import webhooksActive from "../../Assets/images/webhookicon.svg";
import propertiesDisabled from "../../Assets/images/propertiesDisabled.svg";
import { toast } from "react-toastify";

const Style = styled.div`
  background: white;
  padding: 30px;
  // display:
  .logo {
    display: flex;
    margin-bottom: 77px;
  }
  .icons {
    height: 21px;
    width: 21px;
    margin-right: 15px;
  }
  .header {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    margin-bottom: 32px;
    color: #034b5e;
  }
  .nav {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 34px;
  }
  .active {
    color: #f8ad15;
  }
  .topp {
    margin-bottom: 42px;
    margin-bottom: 36px;
  }
  .divider {
    border-bottom: 1.3px solid rgba(196, 196, 196, 0.5);
    width: 100%;
    height: 1px;
    margin-bottom: 36px;
  }
  .logout {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    margin-left: 20px;
    color: #98a0b2;
  }
`;

const links = [
  {
    name: "Dashboard",
    link: "/dashboard",
    // image: "../../Assets/images/homeicondisabled.svg",
    image: homeDisabled,
    // activeimage: "../../Assets/images/homeicon.svg",
    activeimage: homeActive,
    userType: "all",
  },

  {
    name: "Rental Loan",
    link: "/dashboard/rental-loan",
    image: rentalDisabled,
    activeimage: rentalActive,
    userType: "tenant",
  },
  {
    name: "Properties",
    link: "/dashboard/properties",
    image: propertiesDisabled,
    activeimage: rentalActive,
    // userType: "landlord",
    userType: "landlord",
  },
  {
    name: "Payment",
    link: "/dashboard/payment",
    image: paymentDisabled,
    activeimage: paymentActive,
    userType: "tenant" && "landlord",
  },
  {
    name: "Invoice",
    link: "/dashboard/invoice",
    image: paymentDisabled,
    activeimage: paymentActive,
    userType: "tenant",
  },
  {
    name: "Repayment",
    link: "/dashboard/repayment",
    image: paymentDisabled,
    activeimage: paymentActive,
    userType: "developer",
  },
  // {
  //   name: "Statistics",
  //   userType: "all",
  //   link: "/dashboard/statistics",
  //   image: statisticsDisabled,
  //   activeimage: statisticsActive,
  // },
  // {
  //   name: "Inbox",
  //   link: "/dashboard/link",
  //   image: inboxDisabled,
  //   activeimage: inboxActive,
  //   userType: "all",
  // },
  {
    name: "Profile",
    link: "/dashboard/profile",
    image: profileDisabled,
    activeimage: profileActive,
    userType: "all",
  },
];

const link2 = [
  {
    name: "Developer",
    link: "/dashboard/developer",
    image: developerDisabled,
    activeimage: developerActive,
    userType: "developer",
    // userType: "all",
  },
  {
    name: "Webhooks",
    // link: "/dashboard/webhooks",
    link: "/developers",
    image: webhooksDisabled,
    activeimage: webhooksActive,
    userType: "developer",
    // userType: "all",
  },
  // {
  //   name: "Setting",
  //   userType: "all",
  //   link: "/dashboard/setting",
  //   image: settingsDisabled,
  //   activeimage: settingsActive,
  // },
];

const Sidebar = () => {
  const [userInfo, setUserInfo] = useState({});
  const { profileCompleted, setProfileCompleted } = useAppHook();
  useEffect(() => {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    if (info.profile_completed === true) {
      setProfileCompleted(true);
    } else {
      setProfileCompleted(false);
    }

    setUserInfo(info);
  }, []);

  // const { dashboardNav, setDashboardNav } = useAppHook();

  const navigate = useNavigate();
  const location = useLocation();

  const handleNavClick = (link) => {
    localStorage.setItem("dashboardNav", link);
    if (profileCompleted !== true && link !== "/dashboard/profile") {
      toast.error("Please complete your profile first");
    } else {
      navigate(link);
    }
  };
  return (
    <Style className="no-scrollbar">
      <div style={{ cursor: "pointer" }}>
        <img
          alt="logo-text"
          src={require("../../Assets/images/logotext.svg").default}
          // src={"../../Assets/images/logotext.svg"}
          className="logo"
        />
      </div>
      <div>
        <p className="header">General</p>
        <div className="topp">
          {links
            .filter(
              (nav) =>
                nav.userType === userInfo.customer_type ||
                nav.userType === "all"
            )
            .map((nav, i) => (
              <button
                key={i}
                // href={
                //   userInfo.profile_completed ? nav.link : "/dashboard/profile"
                // }
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
                className="nav"
                onClick={() => handleNavClick(nav.link)}
              >
                <img
                  alt=""
                  src={
                    location.pathname.split("/").length > 3 &&
                    location.pathname.includes(nav.link)
                      ? nav.activeimage
                      : location.pathname.split("/").length === 2 &&
                        location.pathname.includes(nav.link)
                      ? nav.activeimage
                      : nav.image
                  }
                  className={`icons`}
                />
                <p
                  className={`${
                    location.pathname.split("/").length > 3 &&
                    location.pathname.includes(nav.link)
                      ? "active"
                      : location.pathname.split("/").length === 2 &&
                        location.pathname.includes(nav.link)
                      ? "active"
                      : ""
                  }`}
                >
                  {nav.name}
                </p>
              </button>
            ))}
        </div>
        <div className="divider" />
        <p className="header">Others</p>
        <div className="topp">
          {link2
            .filter(
              (nav) =>
                nav.userType === "developer" &&
                (nav.userType === userInfo.customer_type ||
                  nav.userType === "all")
            )
            .map((nav, i) => (
              <button
                key={i}
                className="nav"
                onClick={() => handleNavClick(nav.link)}
              >
                <img
                  alt=""
                  src={
                    location.pathname.includes(nav.link)
                      ? nav.activeimage
                      : nav.image
                  }
                  className={`icons`}
                />
                <p
                  className={`${
                    location.pathname.includes(nav.link) && "active"
                  }`}
                >
                  {nav.name}
                </p>
              </button>
            ))}
        </div>
        <div
          // href="/login"
          onClick={() => {
            localStorage.removeItem("userInfo");
            localStorage.removeItem("general_settings");
            localStorage.removeItem("login_formData");
            localStorage.removeItem("registeringEmail");
            localStorage.removeItem("dashboardNav");
            localStorage.removeItem("unverified");
            localStorage.removeItem("loginResponse");
            axios
              .post("/account/logout")
              .then((res) => {
                navigate("/signin");
                removeCookie("user_token");
              })
              .catch((err) => {
                console.log(err);
                navigate("/signin");
                removeCookie("user_token");
              });
            // navigate("/signin");
            // removeCookie("user_token");
          }}
          style={{ display: "flex", marginTop: "70px", cursor: "pointer" }}
        >
          <img
            src={require("../../Assets/images/logout.svg").default}
            style={{ height: "26px", width: "26px" }}
            alt=""
          />
          <p className="logout">Logout</p>
        </div>
      </div>
    </Style>
  );
};

export default Sidebar;
