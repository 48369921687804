import styled from "styled-components";
import media from "styled-media-query";
// import Header from "../Header";
// import Footer from "../Footer";
// import { Component } from "react";

import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
// import { UserProvider } from "../context/UserProviderMain";
// import AppContext, { AppProvider } from "../context/AppProvider";
// import useUserHook from "../../../hooks/useUserHook";

import { Outlet } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";

const Styles = styled.div`
  position: relative;
  max-width: 1728px;
  margin-left: auto;
  margin-right: auto;
  /* padding: auto; */
  height: 100vh;

  .padding_top {
    /* height: 111px; */
    height: 63px;

    ${media.greaterThan("1024px")`
    height: 111px;
    `}
  }
`;

const FooterOnlyLayout = () => {
  return (
    <Styles>
      {/* <Header /> */}
      {/* <h1>Header</h1> */}
      {/* <div className="padding_top"></div> */}
      {/* {children} */}
      <Outlet />

      <Footer />
    </Styles>
  );
};

export default FooterOnlyLayout;
