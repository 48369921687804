import styled from "styled-components";
import media from "styled-media-query";

const ListingContainer = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 38px;
  ${media.greaterThan("768px")`
    font-size: 18px;
  `};
  ${media.greaterThan("1024px")`
  

  `};
  ${media.greaterThan("1440px")`

  `};

  li {
    display: flex;
    align-items: center;
    gap: 20px;

    ${media.greaterThan("768px")`
      gap: 30px
  `};
    ${media.greaterThan("1024px")`
  

  `};
    ${media.greaterThan("1440px")`
      gap: 58px;
  `};
  }
`;

export default ListingContainer;
