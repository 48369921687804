import React from "react";
import { BlankInputTags, InputBasic } from "./inputs.styles";

const BlankInput = ({
  label,
  icon,
  children,
  inputFor,
  hints,
  placeholder,
  errorMsg = [],
}) => {
  return (
    <BlankInputTags errorMsg={errorMsg}>
      <label htmlFor={inputFor}>{label}</label>
      <div className="input-main-container">
        <div className="children-container">
          {children}
          <div className="password_visibility">
            <img src={icon} alt="" className="password_visibilit" />
          </div>
        </div>
      </div>
      <div className="extra-texts-container">
        {hints !== "" && errorMsg.length <= 0 ? (
          <p className="password-hint">{hints}</p>
        ) : (
          <p className="error-message">{errorMsg.join(", ")}</p>
        )}
      </div>
    </BlankInputTags>
    // <InputBasic>
    //   <label htmlFor={inputFor}>{label}</label>
    //   <div className="input-main-container">
    //     <div>
    //       {children}
    //       <div className="password_visibility">
    //         <img src={icon} alt="" className="password_visibilit" />
    //       </div>
    //     </div>
    //   </div>
    //   <p className="error-message">{errorMsg.join(", ")}</p>
    // </InputBasic>
  );
};

export default BlankInput;
