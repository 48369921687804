import { useEffect, useState } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
// import Link from "next/link";
import { Link } from "react-router-dom";

import styled from "styled-components";
import media from "styled-media-query";
// import { Router, useRouter } from "next/router";
// import useUserHook from "../../../hooks/useUserHook";

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;

  ${media.greaterThan("1024px")`
    display: none;
  
  `}
  ${media.greaterThan("1440px")`

  `}
`;

const LinkButtonOverall = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  background: none;
  border: none;
  margin-bottom: 30px;
`;

const LinkButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  border-radius: 6px;
  background: none;
  border: none;
  font-size: 18px;
  margin-bottom: 30px;
`;

const ShowMore = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  padding-left: 15px;
  padding-top: 15px;

  li {
    list-style-type: none;
    margin-bottom: 20px;
    cursor: pointer;
  }
`;

const NavMobile = ({ setOpen }) => {
  const [showProducts, setShowProducts] = useState(false);
  const [showCustomers, setShowCustomers] = useState(false);
  const [showLearn, setShowLearn] = useState(false);

  const [userPresent, setUserPresent] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    const userAvailable = localStorage.getItem("userInfo");
    if (userAvailable) {
      setUserPresent(true);
      setUserDetails(JSON.parse(userAvailable));
    } else {
      setUserPresent(false);
    }
  }, [userPresent]);

  // const router = useRouter();

  const handleClick = (link) => {
    // router.push(`${link}`);
    setOpen(false);
  };

  return (
    <Styles>
      <Link to={"/about"} onClick={handleClick}>
        <LinkButton>About Us </LinkButton>
      </Link>
      <Link to={"/why-us"} onClick={handleClick}>
        <LinkButton>Why Landlords Technology </LinkButton>
      </Link>
      <LinkButtonOverall>
        <LinkButton
          style={{ marginBottom: "0px" }}
          onClick={() => setShowCustomers(!showCustomers)}
        >
          Customers <MdOutlineKeyboardArrowDown size={14} />
        </LinkButton>
        {showCustomers && (
          <ShowMore>
            <Link to={"/finance-with-landlordstech"} onClick={handleClick}>
              <li>For Individuals/Businesses</li>
            </Link>
            <Link to={"/landlords"} onClick={handleClick}>
              <li>For Landlords</li>
            </Link>
            <Link to={"/startups"} onClick={handleClick}>
              <li style={{ marginBottom: "0px" }}>
                For Rental/Rent Finance Platforms
              </li>
            </Link>
          </ShowMore>
        )}
      </LinkButtonOverall>
      <Link to={"/support"} onClick={handleClick}>
        <LinkButton>Support</LinkButton>
      </Link>
      <Link to="/blogs" onClick={handleClick}>
        <LinkButton>Learn</LinkButton>
      </Link>

      {userPresent ? (
        <Link to={"/dashboard"} onClick={handleClick}>
          <LinkButton>Dashboard</LinkButton>
        </Link>
      ) : (
        <>
          <Link to={"/signin"} onClick={handleClick}>
            <LinkButton>Login</LinkButton>
          </Link>
          <Link to={"/register"} onClick={handleClick}>
            <LinkButton>Open Account</LinkButton>
          </Link>
        </>
      )}
      {/* <LinkButtonOverall>
  <LinkButton
    style={{ marginBottom: "0px" }}
    onClick={() => setShowProducts(!showProducts)}
  >
    Products <MdOutlineKeyboardArrowDown size={14} />
  </LinkButton>
  {showProducts && (
    <ShowMore>
      <Link></Link>
      <Link to={"/why-us#rental-finance-api"} onClick={handleClick}>
        <li>Rental Finance</li>
      </Link>
      <Link to={"/kyc-verification"} onClick={handleClick}>
              <li>KYC Verification</li>
            </Link>
      <Link to={"/developer"} onClick={handleClick}>
              <li>Documents Processing</li>
            </Link>
      <Link to={"/developer"} onClick={handleClick}>
              <li style={{ marginBottom: "0px" }}>Mortgage Credit Systems</li>
            </Link>
    </ShowMore>
  )}
</LinkButtonOverall>; */}
    </Styles>
  );
};

export default NavMobile;
