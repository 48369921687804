import React, { useState } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import Hamburger from "hamburger-react";
import { motion, AnimatePresence } from "framer-motion";
import useOutsideClick from "../../hooks/useOutsideClick";
import Sidebar from "./Sidebar";
import { useEffect } from "react";
import useAppHook from "../../hooks/useAppHook";
import { useMemo } from "react";

const Style = styled.div`
  .side-barz {
    min-width: 256px;
    max-width: 256px;
    width: 256px;
    background: white;
    overflow-y: scroll;
    display: block;
  }
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 39px;
  ${media.lessThan("1024px")`
    flex-direction: column-reverse;
  `}

  .title {
    ${media.lessThan("medium")`
    margin-top: 16px;
  `}
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #1a191e;
    margin-bottom: 14px;
  }
  .subtitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #919499;
  }
  .search {
    padding: 9px 16px 9px 42px;
    box-shadow: 0px 10px 15px rgba(151, 151, 151, 0.08);
    border-radius: 10px;
    border: 0;
    height: 40px;
    width: 177px;
  }
  .search-container {
    position: relative;
  }
  .icon {
    height: 19px;
    width: 19px;
    position: absolute;
    top: 10px;
    left: 13px;
  }
  .notification-container {
    height: 40px;
    width: 40px;
    background: white;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    margin-right: 14px;
  }
  .avatar-container {
    height: 40px;
    width: 40px;
    background: white;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .bell {
    height: 17px;
    width: 18px;
  }
  .right {
    display: flex;
    ${media.lessThan("1024px")`
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding-bottom: 10px;
  `}
  }
  .avatar {
    height: 100%;
    width: 100%;
    max-inline-size: 100%;
    /* block-size: auto; */
    object-fit: cover;
  }
  .motion {
    position: fixed;
    top: 0;
    z-index: 100;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    max-width: 256px;
  }
  .motion-div {
    overflow-y: auto;
    width: 100%;
    max-width: 256px;
    position: relative;
  }
  .burger {
    display: none;
    ${media.lessThan("1024px")`
    display: block;
    `}
  }
`;
const Header = ({ title, subTitle }) => {
  const nestedVariants = {
    hidden: {
      height: "0px",
    },
    animate: {
      height: "100%",
    },
  };
  const [ref] = useOutsideClick(() => setOpen(false));
  const [isOpen, setOpen] = useState(false);

  const { profilePicture, setProfilePicture } = useAppHook();
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userInfo"));
    setProfilePicture(userDetails?.avatar);
  }, []);

  useMemo(() => {
    setAvatar(profilePicture);
  }, [profilePicture]);

  return (
    <Style>
      <div className="left">
        <p className="title">{title}</p>
        <p className="subtitle">{subTitle}</p>
      </div>
      <div className="right">
        <div className="burger">
          <Hamburger toggled={isOpen} size={20} toggle={setOpen} />
          <AnimatePresence>
            {isOpen && (
              <motion.div
                className="motion"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ ease: "easeInOut", duration: "0.5" }}
              >
                <motion.div
                  ref={ref}
                  className="motion-div"
                  initial={{ x: -1000 }}
                  animate={{ x: 0 }}
                  exit={{ x: -1000 }}
                  transition={{ ease: "easeInOut", duration: "0.5" }}
                >
                  <div className="side-barz nobar">
                    <Sidebar />
                  </div>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        {/* <div className="search-container">
          <img
            src={require("../../Assets/images/header-search-icon.svg").default}
            alt=""
            className="icon"
          />
          <input type="text" placeholder="Search.." className="search" />
        </div> */}
        {/* <div className="notification-container">
          <img
            src={require("../../Assets/images/bell.svg").default}
            className="bell"
            alt=""
          />
        </div> */}
        <div className="avatar-container">
          <img
            // src={require("../../Assets/images/avatar.png").default}
            src={avatar?.original_url}
            className="avatar"
            alt=""
          />
        </div>
      </div>
    </Style>
  );
};

export default Header;
