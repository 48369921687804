import styled from "styled-components";
import media from "styled-media-query";

const LogoAbsolutePlacer = styled.div`
  cursor: pointer;
  position: relative;
  width: 100%;
  /* background: red; */
  padding-top: 20px;
  ${media.greaterThan("768px")`
  left: 5%;
  `};
  ${media.greaterThan("1024px")`
  left: 5%;
  `};
`;

export default LogoAbsolutePlacer;
