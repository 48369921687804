import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Navigation from "./Navigation";
import { ToastContainer } from "react-toastify";
import Interceptors from "./utils/interceptors";
import { UserProvider } from "./context/userProvider";
import { AppProvider } from "./context/AppProvider";

const router = createBrowserRouter(Navigation);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Interceptors>
      <AppProvider>
        <UserProvider>
          <ToastContainer />
          <RouterProvider router={router} />
        </UserProvider>
      </AppProvider>
    </Interceptors>
  </React.StrictMode>
);
