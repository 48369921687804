import styled from "styled-components";
import media from "styled-media-query";
import Button from "../../elements/Button";

import googlePlayButton from "../../../Assets/images/google_play_image.png";
import appStoreButton from "../../../Assets/images/app_store_image.png";
import twoPhones from "../../../Assets/images/auth_two_phones.png";

const Styles = styled.div`
  display: none;
  height: 100vh;
  padding: 10%;
  padding-top: 10%;
  padding-bottom: 0;
  /* align-items: center; */
  flex-direction: column;
  gap: 11%;

  ${media.greaterThan("768px")`
    display: flex;
  `};
  ${media.greaterThan("1024px")`

  `};
  ${media.greaterThan("1440px")`

  `};

  .top_part {
    .texts {
      h2 {
        font-size: 36px;
        line-height: 49px;
        letter-spacing: 0.3px;
        color: #fff;
      }

      p {
        font-size: 12px;
        line-height: 32px;
        letter-spacing: 0.3px;
        color: #ffffff;
        font-family: "ApercuProMediumNormal";

        ${media.greaterThan("1024px")`
          font-size: 14px;
        `};
      }
    }
  }

  .twoPhones_container {
    position: relative;
    /* background: red; */
    flex: 1;
    min-height: 250px;
    max-width: 500px;

    ${media.greaterThan("768px")`
    `};
    ${media.greaterThan("1024px")`
    `};
    ${media.greaterThan("1440px")`
      

    `};
  }
`;

const BlackButtons = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 20px;
  /* margin-bottom: 40px; */
  div {
    position: relative;
    width: 100px;
    height: 52px;

    ${media.greaterThan("768px")`
      width: 100px;
        height: 40px;
    `};
    ${media.greaterThan("1024px")`
      width: 140px;
        height: 45px;
    `};
    ${media.greaterThan("1440px")`
      width: 180px;
        height: 52px;
    `};
  }
`;

const ImagePartContent = () => {
  return (
    // <ImagePartContainer>
    <Styles>
      <div className="top_part">
        <div className="texts">
          <h2>One app.</h2>
          <h2
            style={{
              color: "#F8AD15",
            }}
          >
            Work Magic
          </h2>
          <p>Install Landlord Technology application right now!</p>
        </div>
        <BlackButtons>
          <a href="/#" target="_blank" rel="noopener noreferrer">
            <div>
              <img
                src={googlePlayButton}
                alt="google play store link"
                style={{
                  // position: "absolute",
                  objectFit: "contain",
                  maxInlineSize: "100%",
                  blockSize: "auto",
                }}
              />
            </div>
          </a>
          <a href="/#" target="_blank" rel="noopener noreferrer">
            <div>
              <img
                src={appStoreButton}
                alt="app store link"
                style={{
                  // position: "absolute",
                  objectFit: "contain",
                  maxInlineSize: "100%",
                  blockSize: "auto",
                }}
              />
            </div>
          </a>
        </BlackButtons>
      </div>
      <div className="twoPhones_container">
        <img
          src={twoPhones}
          alt=""
          style={{
            maxInlineSize: "100%",
            blockSize: "auto",
            height: "100%",
          }}
        />

        {/* <img
          src={twoPhones}
          alt=""
          style={{
            objectFit: "conver",
            display: "block",
            width: "100%",
            position: "absolute",
            bottom: "0",
          }}
        /> */}
      </div>
    </Styles>
    // </ImagePartContainer>
  );
};

export default ImagePartContent;
