import { HiOutlineArrowSmRight } from "react-icons/hi";
import styled from "styled-components";
import media from "styled-media-query";
import { Link } from "react-router-dom";
import NeedHelp from "../components/blocks/NeedHelp";
import StillHaveQuestions from "../components/blocks/StillHaveQuestions";
import Button from "../components/elements/Button";

import EarthImage from "../Assets/images/earth.png";
import globe from "../Assets/images/globe.gif";
import DynamicScrollToTop from "../utils/DynamicScrollToTop";

const Styles = styled.div`
  position: relative;
  max-width: 888px;
  margin: 0 auto;

  ${media.greaterThan("768px")`
      max-width: 100%;
  `};
  ${media.greaterThan("1024px")`
    
  `};
  ${media.greaterThan("1440px")`
  `};
`;

const Section = styled.section`
  padding: 160px 5% 117px 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: rgba(183, 123, 47, 0.1);

  .buttons {
    display: flex;
    align-items: center;
    margin-top: 30px;
    gap: 10px;
  }
`;

const Title = styled.h2`
  font-family: "Tiempos Headline";
  font-size: 40px;
  line-height: 110%;
  letter-spacing: -0.03em;
  color: #1a191e;
  padding-bottom: 30px;
  max-width: 844px;

  ${media.greaterThan("768px")`
    font-size: 50px;
  `};
  ${media.greaterThan("1024px")`
    font-size: 60px;
      /* margin: 0 auto; */
  `};
  ${media.greaterThan("1440px")`
  `};
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
  color: #1a191e;
  max-width: 650px;

  ${media.greaterThan("768px")`
    font-size: 18px;
  `};
  ${media.greaterThan("1024px")`
    font-size: 20px;
     /* margin: 0 auto; */
  `};
  ${media.greaterThan("1440px")`
  `};
`;

const FlexedSection = styled.section`
  /* position: relative; */
  display: flex;
  flex-direction: column;
  padding: 150px 5%;
  gap: 50px;
  padding-right: 0;

  ${media.greaterThan("768px")`

  `};
  ${media.greaterThan("1024px")`
      flex-direction: row;
      align-items: center;
      gap: 5%;
  `};
  ${media.greaterThan("1440px")`
    /* gap: 10%; */
  `};

  .texts_part {
    flex: 1;
    ${media.greaterThan("768px")`

  `};
    ${media.greaterThan("1024px")`
      width: 100%
  `};
    ${media.greaterThan("1440px")`
      /* width: 45% */
  `};
  }
`;

const GlobeContainer = styled.div`
  height: 600px;
  width: 100%;

  ${media.greaterThan("768px")`

  `};
  ${media.greaterThan("1024px")`
  width: 50%;
  height: 919px;
  position: relative;
  `};
  ${media.greaterThan("1440px")`

  `};

  .globe_image {
    max-inline-size: 100%;
    object-fit: contain;
    margin: 0 auto;

    ${media.greaterThan("768px")`

  `};
    ${media.greaterThan("1024px")`
    position: absolute;
    left: 20%;
    width: 969px;
     height: 919px;
      
  `};
    ${media.greaterThan("1440px")`
    width: 969px;
    height: 919px;
    right: 20%;
      
  `};
  }
`;

const EarthContainer = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 400px;
  max-width: 500px;

  ${media.greaterThan("768px")`
  /* width: 500px; */
  /* max-width: 600px; */
  /* height: 560px; */
    
  `};
  ${media.greaterThan("1024px")`
  
  max-width: 800px;
  height: 750px;
    right: -10%;
  `};
  ${media.greaterThan("1440px")`
  /* width: 969px;
  max-width: 100%;
  height: 919px; */
  `};
`;

const Entrepreneurs = () => {
  return (
    <Styles>
      <DynamicScrollToTop />
      <Section>
        <Title>Get paid quickly with our, rental payment tools</Title>
        <Description>
          You don't have to wait for your tenants delayed payments, we pay on
          their behalf, integrate with us and we savage the rental issues
        </Description>
        <div className="buttons">
          <Link to="/register">
            <Button bg={"#F8AD15"} clr={"#fff"}>
              Open Developer Account
            </Button>
          </Link>
          <Link to="/blogs">
            <Button>
              Learn More <HiOutlineArrowSmRight size={20} />
            </Button>
          </Link>
        </div>
      </Section>
      <FlexedSection>
        <div className="texts_part">
          <Title>
            Push the responsibility of collecting your rentals by paying you
            upfront
          </Title>
          <Description>
            This causes low traction for these proptech startups and ultimately
            poor housing for the tenants. Where financing is provided by the
            startups/ business, interest rates are extremely high. This rate is
            between 40% - 60% on annualized basis.
          </Description>
        </div>

        <GlobeContainer>
          <img src={globe} alt="" className="globe_image" />
        </GlobeContainer>
      </FlexedSection>
      <NeedHelp />
    </Styles>
  );
};

export default Entrepreneurs;

//  <EarthContainer></EarthContainer>;
