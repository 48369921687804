import React, { useEffect, useState } from "react";
import styled from "styled-components";

import enterRight from "../../Assets/images/enter-right.svg";
import sadFaceEmoji from "../../Assets/images/sad_face_emoji.svg";

import DashboardLayout from "../../components/layout/dashboardLayout";
import Header from "../../components/layout/Header";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Tab } from "@headlessui/react";
import media from "styled-media-query";
import axios from "axios";
import RequestRentalFinanceModal from "../../components/dashboard/RequestRentalFinanceModal";
import requestRentalFinanceNotification from "../../Assets/images/request_rental_finance_notification.svg";
import Modal from "../../components/blocks/Modal";
import Button from "../../components/elements/Button";
import { FormPart } from "../../components/blocks/Authentication";
import { PaystackButton } from "react-paystack";
import { v4 as uuid } from "uuid";
import { useRef } from "react";
import { toast } from "react-toastify";
import removeExtraCharacter from "../../utils/removeExtraCharacter";
import numberWithCommas from "../../utils/numberWithCommas";
import Validator from "../../utils/Validator";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import monoConfig from "../../services/MonoInstance";
import { removeCookie } from "../../utils/cookiesHelper";
import { Input } from "../../modules/common_module/components/basic/functional";

const Style = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  .box1 {
    height: 100px;
    width: 167px;
    background: #f8f8f8;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    margin-right: 8px;
  }
  .pill {
    width: 72px;
    height: 15px;
    background: #f8ad15;
    border-radius: 8px;
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
  }
  .white {
    color: white !important;
  }
  .line-header {
    display: flex;
    align-items: center;
    margin-bottom: 32.5px;
    p {
      font-family: "Apercu Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      /* identical to box height */
      margin-right: 5px;
      color: #000000;
    }
    div {
      flex: 1;
      border-bottom: 1.3px solid #cc8d2b;
      height: 1px;
    }
  }
  .pay {
    height: 43px;
    left: 1088px;
    top: 636px;
    margin-top: 4px;
    background: #f8ad15;
    border-radius: 5px;
    border: none;
    outline: none;
    appearance: none;
    width: 100%;
    color: white;
  }
  .toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 34px;
    p {
      margin-right: 11px;

      font-family: "DM Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 100%;
      /* identical to box height, or 13px */

      letter-spacing: -0.03em;

      /* background/primary */

      color: #fbbd29;
    }
  }
  .entry {
    background: #f8f8f8;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px 12px 12px;
    gap: 16px;
    height: 65px;
    margin-bottom: 10px;
    justify-content: space-between;
  }
  .rent {
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 160%;
    /* or 19px */

    text-align: center;

    /* text/default */

    color: #1a191e;
  }
  .fee {
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 120%;
    /* identical to box height, or 36px */

    letter-spacing: -0.03em;

    color: #000000;
  }
  .box2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 2px;

    width: 167px;
    height: 100px;

    background: #cc8d2b;
    border-radius: 10px;
  }
  .box-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
  }
  .box-container.mobile-view {
    justify-content: center;
    ${media.greaterThan("769px")`
      display: none;
    `};
  }

  .tab {
    margin-right: 24px;
    background: white;
    appearance: none;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding-left: 0;
  }
  .tab-list {
    margin-bottom: 39px;
  }
  .selected {
    color: #fcba2d;
  }
  .deselected {
    color: #919499;
  }
  .main-content {
    ${media.lessThan("medium")`
    /* width: 100%; */
    padding: 46px 16px 0;
  `}
    overflow: scroll;
    flex: 1;
    padding: 46px 36px 0;
    background: #f8f8f8;
  }
  /* .right-sidebar {
    ${media.lessThan("medium")`
    display: none;
  `}
    overflow: scroll;
    width: 375px;
    height: 100%;
    background: white;
    padding: 48px 18px;
  } */
  .right-sidebar {
    display: none;
    ${media.greaterThan("768px")`
      display: block;
      overflow: scroll;
      /* width: 375px; */
      width: 30%;
      height: 100%;
      background: white;
      padding: 48px 18px;
    `}
    ${media.greaterThan("1440px")`
      display: block;
      overflow: scroll;
      width: 375px;
      height: 100%;
      background: white;
      padding: 48px 18px;
    `}
    /* overflow: scroll;
    width: 375px;
    height: 100%;
    background: white;
    padding: 48px 18px; */
  }
  .graph-section {
    height: 332px;
    background: white;
    border-radius: 15px;
    padding: 22px 27px;
    display: flex;
    flex-direction: column;
  }
  .graph-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
  }
  .dot {
    background: #fbbd29;
    height: 12px;
    width: 12px;
    border-radius: 100%;
    margin-right: 8px;
  }
  .graph-title {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    display: flex;
    align-items: center;
  }
  .graph {
    flex: 1;
  }
  .invoice-section {
    height: auto;
    background: white;
    border-radius: 15px;
    padding: 23px;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
  }
  .enter-icon {
    margin-left: 19px;
  }
  invoice-title {
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    /* identical to box height, or 24px */

    letter-spacing: -0.03em;

    /* text/default */

    color: #1a191e;
  }
  .view {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */

    letter-spacing: -0.03em;
    cursor: pointer;
    color: #fbbd29;
    display: flex;
    align-items: center;
  }
  .view_button {
    background: none;
    border: none;
  }
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  .inv {
    display: flex;
    justify-content: space-between;
    align-items: end;
  }
  .month {
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */

    letter-spacing: -0.03em;

    /* text/default */

    color: #1a191e;
  }
  .date {
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 160%;
    /* or 19px */

    color: rgba(26, 25, 30, 0.6);
  }
  .amount {
    p {
      font-family: "Apercu Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 160%;
      /* identical to box height, or 26px */

      /* text/default */

      color: #1a191e;
    }
  }
  .rep-title {
    margin-bottom: 19px;
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    /* or 25px */

    letter-spacing: -0.03em;

    /* text/default */

    color: #1a191e;
  }
  .mobile {
    display: none;
    ${media.lessThan("medium")`
      display: block;
    `}
  }
  .collection {
    width: 50%;
  }
  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .light-input {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    color: #c4c4c4;
  }
  .bold-input {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    color: #474747;
  }
`;

export const FormContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 10%;
  padding-bottom: 20px;
  background: #ffffff;
  /* top: -152px; */
  border-radius: 15px;
  ${media.greaterThan("768px")`
  padding: 0;
  padding-bottom: 20px;
    max-width: 80%;
    margin: 0 auto;
  `};
  ${media.greaterThan("1024px")`
    max-width: 723px;
    margin: 0 auto;
  `};
`;

const LandlordStyles = styled.div`
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: -0.03em;
    color: #1a191e;
    margin-bottom: 40px;
  }
  .box_container {
    background: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    width: 100%;
    height: 145px;
    padding: 15px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .selection_button {
    border-radius: 20px;
    background: none;
    border: none;
    color: #fff;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    width: 115px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .selection_button_active {
    background: #f8ad15;
    color: #000;
  }
  .amount_display {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #ffffff;
    padding-top: 5px;
  }

  .submit_button {
    background: #f8ad15;
    border: none;
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 43px;
    margin-top: 12px;
    margin-bottom: 86px;
    cursor: pointer;
  }

  .rental_loan_sidebar_heading {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #034b5e;
  }
  .rental_loan_sidebar_box {
    background: #effcff;
    border: 2px solid rgba(161, 229, 248, 0.7);
    box-shadow: 0px 10px 15px rgba(41, 128, 151, 0.1);
    border-radius: 14px;
    padding: 14px;
    padding-bottom: 18px;
    margin-top: 12px;

    .orange_image_container {
      width: 32px;
      height: 32px;
    }

    p {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 25px;
      padding-top: 6px;
      padding-left: 10px;

      color: #040404;
    }
  }

  .mobile_view_only {
    ${media.greaterThan("768px")`
      display: none;
    `}
  }
  .naira_text {
    display: none;
    ${media.greaterThan("768px")`
      display: block;
    `}
  }
`;

const Index = () => {
  const data = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  const invoice = [
    {
      month: "June",
      dueDate: "01/01/2022 02:34",
      amount: "₦70,299.00",
    },
    {
      month: "June",
      paymentDate: "01/01/2022 02:34",
      amount: "₦70,299.00",
    },
    {
      month: "June",
      paymentDate: "01/01/2022 02:34",
      amount: "₦70,299.00",
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [openRentalFinanceModal, setOpenRentalFinanceModal] = useState(false);

  const handleRequestRentalFinance = () => {
    setOpenRentalFinanceModal(true);
    // alert("request clicked!!");
  };

  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState({});
  const [loginResponse, setLoginResponse] = useState({});
  const [graphInfo, setGraphInfo] = useState([]);
  const [rentalLoanInfo, setRentalLoanInfo] = useState([]);
  const [repaymentInfo, setRepaymentInfo] = useState([]);
  const [paymentInfo, setPaymentInfo] = useState([]);
  const [duePaymentInfo, setDuePaymentInfo] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);

  const [paystackSettings, setPaystackSettings] = useState("");
  const [monoPbKeys, setMonoPbKeys] = useState("");
  const [paystackProps, setPaystackProps] = useState();

  console.log("rental loan info: ", rentalLoanInfo);
  console.log("repaymentInfo: ", repaymentInfo);
  console.log("paymentInfo: ", paymentInfo);

  //LANDLORDS VIEW
  const [collectOrSettle, setCollectOrSettle] = useState("collection");

  const handleCollectionClick = () => {
    setCollectOrSettle("collection");
  };
  const handleSettlementClick = () => {
    setCollectOrSettle("settlement");
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleMakePaymentClick = (amount) => {
    if (amount !== 0 || amount !== null) {
      setPaystackProps({
        email: userInfo.email_address,
        amount: amount,
        metadata: {
          name: userInfo.fullname,
          phone: userInfo.phone_number,
        },
        publicKey: paystackSettings.public_key,
      });

      console.log("this is the payStackProps: ", paystackProps);
    }

    const reference = "";
    const user_id = "";
    const rent_id = "";
    const type = "";
    const type_id = "";

    alert("Make Payment button clicked!");
  };

  useEffect(() => {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    setUserInfo(info);

    const loginInfo = JSON.parse(localStorage.getItem("loginResponse"));
    setLoginResponse(loginInfo);

    if (info === null || info === undefined) {
      navigate("/signin");
    }

    if (info?.customer_type === "tenant") {
      axios
        .get("/tenant/rental-stats")
        .then((res) => {
          console.log("RENTAL STATS: ", res.data.data);
          setGraphInfo(res.data.data);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }

    axios
      .get(`/${info.customer_type}/rental-loans?limit=10&offset=0&search`)
      .then((res) => {
        setRentalLoanInfo(res.data.data);
        axios
          .get(
            `/${info.customer_type}/rental-loan-repayments?rental_loan_id=${
              res.data.data.records.filter(
                (fi) => fi.status === "disbursed"
              )?.[0].id
            }`
          )
          .then((res) => {
            setRepaymentInfo(res.data.data);
          })
          .catch((err) => {
            console.log(err.response);
          });
        axios
          .get(
            `/${info.customer_type}/rental-due-payment?rental_loan_id=${res.data.data.records[0].id}`
          )
          .then((res) => {
            setPaymentInfo(res.data.data);
          })
          .catch((err) => {
            console.log(err.response);
          });
        axios
          .get(
            `/${info.customer_type}/rental-due-payment?rental_loan_id=${res.data.data.records[0].id}`
          )
          .then((res) => {
            setDuePaymentInfo(res.data.data);
          })
          .catch((err) => {
            console.log(err.response);
          });
        axios
          // .get(`/${info.customer_type}/payment-invoices?limit=10&offset=10`)
          .get(`/${info.customer_type}/payment-invoices`)
          .then((res) => {
            // setDuePaymentInfo(res.data.data);
            const result = res.data?.data.records;
            console.log("this is aaaaallllll the payment invoices: ", res);
            console.log("this is the payment invoices reduced: ", result);
            setInvoiceList(result);
          })
          .catch((err) => {
            console.log(err.response);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`/general-settings`)
      .then((res) => {
        setPaystackSettings(res?.data?.data?.data?.paystack);
        setMonoPbKeys(res?.data?.data?.data?.mono_pk);
        localStorage.setItem(
          "general_settings",
          JSON.stringify(res?.data?.data?.data)
        );
      })
      .catch((err) => {
        console.log("this is the error from general settings: ", err.response);
      });
  }, []);

  useEffect(() => {
    const response = JSON.parse(localStorage.getItem("general_settings"));
    console.log("total response: ", response);
    if (response) {
      setPaystackSettings(response.paystack);
    }
  }, []);

  const unique_id = uuid();
  const small_id = unique_id.slice(0, 8);
  // const user_id = paymentInfo[0]?.customer_id;
  // const rent_id = paymentInfo[0]?.rent_id;
  // const bbyRef = useRef(Math.floor(Math.random(9) * 100));
  // bbyRef.current = "123456789ghjkl";

  useEffect(() => {
    const user_id = paymentInfo[0]?.customer_id;
    const rent_id = paymentInfo[0]?.rent_id;

    setPaystackProps({
      email: userInfo.email_address,
      amount: 3000000,
      reference: `LT-${small_id}-${user_id}-${rent_id}`,
      // ref: bbyRef,
      metadata: {
        name: userInfo.fullname,
        phone: userInfo.phone_number,
      },
      publicKey: paystackSettings.public_key,
      text: "Make Payment",

      onSuccess: ({ reference }) => {
        // alert(
        //   `Your purchase was successful! Transaction reference: ${reference}`
        // );
        axios
          .post(
            "/payment/callback/paystack",
            {
              reference: reference,
              user_id: user_id,
              rent_id: rent_id,
              type: "rentals",
              type_id: paymentInfo?.id,
            },
            {
              headers: {
                "content-type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log("this is the response from paystack callback: ", res);
          })
          .catch((err) => {
            console.log("this is the error from the paystack callback : ", err);
            toast.error(err?.response?.data?.message);
          });
        // resetForm();
      },
      onClose: () => {
        alert("Wait! You have not completed your payment.");
        setPaystackProps((props) => ({
          ...props,
          reference: "",
        }));
      },
    });
  }, [
    userInfo.email_address,
    userInfo.fullname,
    userInfo.phone_number,
    paymentInfo,
    paystackSettings.public_key,
  ]);

  // useEffect(() => {
  //   console.log("RENTAL-lOAN-INFO: ", rentalLoanInfo);
  //   console.log("REPAYMENT-INFO: ", repaymentInfo);
  //   console.log("PAYMENT-INFO: ", paymentInfo);
  //   console.log("DUE-PAYMENT-INFO: ", duePaymentInfo);
  // }, [duePaymentInfo, paymentInfo, rentalLoanInfo, repaymentInfo]);

  const [requestData, setRequestData] = useState({
    property_description: "",
    amount: "",
  });
  const [requestDataError, setRequestDataError] = useState({
    property_description: [],
    amount: [],
  });

  const [submitting, setSubmitting] = useState(false);

  const monoKey = "test_pk_c17duss97o4ffg2bnt57";
  // const mono_key = "c17duss97o4ffg2bnt57";

  const monoConnect = useMemo(() => {
    // monoConfig(monoPbKeys).setup();
    // monoConfig(mono_key).setup();
    monoConfig(monoKey).setup();
    // monoConfig.setup();
    return monoConfig;
  }, []);

  // const handleRequestFinanceSubmit = async (e) => {
  //   e.preventDefault();
  //   console.log("mono pop up button has been clicked.");
  //   // console.log("this is the mono public key: ", monoPbKeys);
  //   console.log("this is the mono public key: ", monoKey);
  //   //
  //   if(loginResponse.ifProfileCompleted !== true){
  //     navigate("/dashboard/profile")
  //   }
  //   else if (userInfo.customer_type !== "tenant" && loginResponse.ifProfileCompleted === true && loginResponse.need_document_verification === false && loginResponse.need_facial_verification === false) {
  //     monoConnect(monoPbKeys).open();
  //   } else {
  //     navigate("/verify-identity")
  //   }

  const logout = () => {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("general_settings");
    localStorage.removeItem("login_formData");
    localStorage.removeItem("registeringEmail");
    localStorage.removeItem("dashboardNav");
    localStorage.removeItem("unverified");
    localStorage.removeItem("loginResponse");
    axios
      .post("/account/logout")
      .then((res) => {
        navigate("/signin");
        removeCookie("user_token");
      })
      .catch((err) => {
        console.log(err);
        navigate("/signin");
        removeCookie("user_token");
      });
    // navigate("/signin");
    // removeCookie("user_token");
  };

  const handleRequestFinanceSubmit = async (e) => {
    // e.preventDefault();
    // console.log("mono pop up button has been clicked.");
    // // console.log("this is the mono public key: ", monoPbKeys);
    // console.log("this is the mono public key: ", monoKey);
    // //

    // setRequestDataError({
    //   property_description: [],
    //   amount: [],
    // });

    // const validatorMethods = {
    //   property_description: "required|min,3",
    //   amount: "required|min,3",
    // };

    // const validator = new Validator(requestData, validatorMethods);
    // validator.result
    //   .then(async () => {
    //     setSubmitting(true);
    //     const formData = new FormData();
    //     formData.append("property", requestData.property_description);
    //     formData.append("amount", requestData.amount);
    //     console.log("Finance with llTech data: ", requestData);

    //     if (loginResponse.ifProfileCompleted === false) {
    //       navigate("/dashboard/profile");
    //     } else if (
    //       userInfo.customer_type === "tenant" &&
    //       loginResponse.need_document_verification === false &&
    //       loginResponse.need_facial_verification === false
    //     ) {
    //       monoConnect(monoPbKeys).open();
    //     } else {
    //       navigate("/verify-identity");
    //     }
    //   })

    e.preventDefault();

    // const userExist = JSON.parse(localStorage.getItem("userInfo")) || null;
    const userLoginResponse =
      JSON.parse(localStorage.getItem("loginResponse")) || null;

    const userInfo = JSON.parse(localStorage.getItem("userInfo")) || null;

    setRequestDataError({
      property_description: [],
      amount: [],
    });

    const validatorMethods = {
      property_description: "required|min,3",
      amount: "required|min,3",
    };

    const validator = new Validator(requestData, validatorMethods);
    validator.result
      .then(async () => {
        setSubmitting(true);
        const formData = new FormData();
        formData.append("property", requestData.property_description);
        formData.append("amount", requestData.amount);

        if (userInfo !== null && userLoginResponse !== null) {
          // monoConnect(mono_key).open();
          if (
            userLoginResponse?.isProfileCompleted === false ||
            userInfo?.profile_completed === false
          ) {
            // this place cannot be reached if the profile is not completed yet.
            toast.error("Please login again to implement profile completion");
            logout();
          } else if (
            userLoginResponse.need_document_verification === true ||
            userLoginResponse.need_facial_verification === true
          ) {
            navigate("/verify-identity");
          } else {
            monoConnect(monoPbKeys).open();
          }
        } else {
          navigate("/signin");
        }

        console.log("Finance with llTech data: ", data);
      })
      .catch((error) => {
        setSubmitting(false);
        setRequestDataError((prev) => ({
          ...prev,
          property_description: error?.error.property_description,
          amount: error?.error.amount,
        }));
      });
  };

  const handleViewAllInvoices = () => {
    navigate("/dashboard/invoice");
  };

  return (
    <Style>
      <div className="main-content nobar">
        <div className="header">
          <Header
            title="Dashboard"
            subTitle="Transparent transaction reports"
          />
        </div>
        {/* my addition */}
        <div className="box-container mobile-view">
          <div className="box1" style={{ background: "#fff" }}>
            <p className="pill">Financed Cost</p>
            <p className="rent">Rental Finance</p>
            <p className="fee">
              ₦
              {numberWithCommas(
                removeExtraCharacter(
                  rentalLoanInfo?.records?.filter(
                    (rec) => rec.status === "disbursed"
                  )?.[0]?.principal_amount || "0",
                  "."
                )
              )}
            </p>
          </div>
          <div className="box2">
            <p className="rent white">Repayment</p>
            <p className="fee white">
              ₦
              {numberWithCommas(
                (rentalLoanInfo?.records?.filter(
                  (rec) => rec.status === "disbursed"
                )?.[0]?.principal_amount || 0) -
                  (repaymentInfo?.filter((pay) => pay.status === "paid")?.[0]
                    ?.amount || 0)
              )}
            </p>
          </div>
        </div>
        <div>
          <div className="graph-section">
            <div className="graph-info">
              <p className="graph-title">
                <span className="dot" />
                Debit
              </p>
            </div>
            <div className="graph">
              <ResponsiveContainer width="100%" height="80%">
                <LineChart width={300} height={100} data={graphInfo}>
                  <Line
                    type="monotone"
                    dataKey="pv"
                    stroke="#18A0FB"
                    strokeWidth={2}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="invoice-section">
            <div className="top">
              <p className="invoice-title">Invoices</p>
              <button
                className="view view_button"
                onClick={handleViewAllInvoices}
              >
                View All <img src={enterRight} alt="" className="enter-icon" />
              </button>
            </div>
            {/* <div>
              {repaymentInfo.slice(0, 3).map((inv, i) => (
                <>
                  <div key={i} className="inv">
                    <div>
                      <p className="month">
                        {months[Number(inv.due_date.split("-")[1]) - 1]}
                      </p>
                      <p className="date">
                        {inv.paymentDate
                          ? `Payment date: ${inv.paymentDate}`
                          : `Due date: ${inv.due_date}`}
                      </p>
                    </div>
                    <div className="amount">
                      <p>{inv.amount}</p>
                    </div>
                  </div>
                  {repaymentInfo.length > i + 1 && (
                    <div
                      style={{
                        border: "0.2px solid #CC8D2B",
                        width: "95%",
                        margin: "13px auto",
                      }}
                    />
                  )}
                </>
              ))}
            </div> */}
            <div>
              {invoiceList.length ? (
                invoiceList.slice(0, 3).map((inv, i) => (
                  <div key={i}>
                    <div className="inv">
                      <div>
                        <p className="month">invoice #{inv.reference}</p>
                        <p className="date">
                          {inv.paymentDate
                            ? `Payment date: ${inv.paymentDate}`
                            : inv.due_date
                            ? `Due date: ${inv.due_date}`
                            : `Created date: ${inv.created_at}`}
                        </p>
                      </div>
                      <div className="amount">
                        <p>
                          ₦{" "}
                          {numberWithCommas(
                            removeExtraCharacter(inv.amount, ".")
                          )}
                        </p>
                      </div>
                    </div>
                    {invoiceList.length > i + 1 && (
                      <div
                        style={{
                          border: "0.2px solid #CC8D2B",
                          width: "95%",
                          margin: "13px auto",
                        }}
                      />
                    )}
                  </div>
                ))
              ) : (
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "60px 0px",
                  }}
                >
                  <img src={sadFaceEmoji} alt="" />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      maxWidth: "138px",
                      marginTop: "9.5px",
                    }}
                  >
                    <p
                      className=""
                      style={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        textAlign: "center",
                        color: "#000",
                        marginBottom: "2px",
                      }}
                    >
                      No Record, Just Yet!
                    </p>
                    <p
                      className="text-xs text-"
                      style={{
                        fontSize: "12px",
                        lineHeight: "18px",
                        textAlign: "center",
                        color: "#919499",
                      }}
                    >
                      Kindly Apply for a <br /> rental finance
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
          {userInfo?.customer_type === "tenant" ? (
            <div className="invoice-section mobile">
              <div className="top">
                <p className="invoice-title">Due Payment</p>
                {/* <p className="view">
                View All{" "}
                <img src="/enter-right.svg" alt="" className="enter-icon" />
              </p> */}
              </div>
              <div className="line-header">
                <p>Due Payment </p>
                <div />
              </div>
              <div>
                {paymentInfo.map((inv, i) => (
                  <div key={i} className="entry">
                    <div>
                      <p className="month">
                        {months[Number(inv.due_date.split("-")[1]) - 1].slice(
                          0,
                          3
                        )}
                      </p>
                      <p className="date">Due date: {inv.due_date}</p>
                    </div>
                    <div className="amount">
                      <p>
                        ₦{" "}
                        {numberWithCommas(
                          removeExtraCharacter(inv.amount, ".")
                        )}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              {/* <div>
              <button
                className="pay"
                // onClick={() => handleMakePaymentClick()}
              >
                Make Payment
              </button>
            </div> */}
              <PaystackButton className="pay" {...paystackProps} />
              <div style={{ marginTop: "14px" }}>
                <button
                  onClick={handleRequestRentalFinance}
                  className="pay"
                  style={{ background: "#000", color: "#fff" }}
                >
                  Request Rental Finance
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {/* <div className="right-sidebar">
        <p className="rep-title">Repayments</p>
        <div>
          <Tab.Group
            onChange={(e) => {
              console.log(e);
            }}
          >
            <Tab.List className="tab-list">
              <Tab
                className={({ selected }) =>
                  classNames("tab", selected ? "selected" : "deselected")
                }
              >
                Transaction
              </Tab>
              <Tab
                className={({ selected }) =>
                  classNames("tab", selected ? "selected" : "deselected")
                }
              >
                {({ selected }) => selected && "zzzii"}
                Details
              </Tab>
            </Tab.List>
            <Tab.Panels className="mt-2">
              <Tab.Panel className={classNames("", "")}>
                <div>
                  <div className="box-container">
                    <div className="box1">
                      <p className="pill">Financed Cost</p>
                      <p className="rent">Rental Finance</p>
                      <p className="fee">
                        ₦
                        {
                          rentalLoanInfo?.records?.filter(
                            (rec) => rec.status === "disbursed"
                          )?.[0]?.principal_amount
                        }
                      </p>
                    </div>
                    <div className="box2">
                      <p className="rent white">Repayment</p>
                      <p className="fee white">
                        ₦
                        {(rentalLoanInfo?.records?.filter(
                          (rec) => rec.status === "disbursed"
                        )?.[0]?.principal_amount || 0) -
                          (repaymentInfo?.filter(
                            (pay) => pay.status === "paid"
                          )?.[0]?.amount || 0)}
                      </p>
                    </div>
                  </div>
                  <div className="line-header">
                    <p>Payment Breakdown</p>
                    <div className="line" />
                  </div>
                  <div>
                    {repaymentInfo.slice(0, 3).map((inv, i) => (
                      <div key={i} className="entry">
                        <div>
                          <p className="month">
                            {months[Number(inv.due_date.split("-")[1]) - 1]}
                          </p>
                          <p className="date">Payment date: {inv.due_date}</p>
                        </div>
                        <div className="amount">
                          <p>{inv.amount}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="toggle">
                    <p>Hide Previous Payments</p>
                    <img alt="" src="up.svg" />
                  </div>
                  <div className="line-header">
                    <p>Due Payment </p>
                    <div />
                  </div>
                  <div>
                    {paymentInfo.map((inv, i) => (
                      <div key={i} className="entry">
                        <div>
                          <p className="month">
                            {months[Number(inv.due_date.split("-")[1]) - 1]}
                          </p>
                          <p className="date">Due date: {inv.due_date}</p>
                        </div>
                        <div className="amount">
                          <p>{inv.amount}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div>
                    <button className="pay">Make Payment</button>
                  </div>
                </div>
              </Tab.Panel>
              <Tab.Panel className={classNames("", "")}>
                <div className="row">
                  <div className="collection">
                    <p className="light-input">Account number</p>
                    <p className="bold-input">(603) 555-0123</p>
                  </div>
                  <div className="collection">
                    <p className="light-input text-right">Account name</p>
                    <p className="bold-input text-right">GTBank</p>
                  </div>
                </div>

                <div className="row">
                  <div className="collection">
                    <p className="light-input">BVN</p>
                    <p className="bold-input">****************</p>
                  </div>
                  <div className="collection">
                    <p className="light-input text-right">Branch</p>
                    <p className="bold-input text-right">****************</p>
                  </div>
                </div>
                <div className="row">
                  <div className="collection">
                    <p className="light-input">Credit interest rate</p>
                    <p className="bold-input">20%</p>
                  </div>
                  <div className="collection">
                    <p className="light-input text-right">Branch</p>
                    <p className="bold-input text-right">7,658%</p>
                  </div>
                </div>
                <div>
                  <button className="pay">Edit Information</button>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div> */}
      <div
        style={{
          position: "relative",
          // background: "red",
          paddingBottom: "120px",
        }}
        className="right-sidebar nobar"
      >
        <div>
          {userInfo.customer_type !== "landlord" ? (
            <>
              {" "}
              <p className="rep-title">Recent Activities</p>
              <div>
                <Tab.Group
                  onChange={(e) => {
                    console.log(e);
                  }}
                >
                  <Tab.List className="tab-list">
                    <Tab
                      className={({ selected }) =>
                        classNames("tab", selected ? "selected" : "deselected")
                      }
                    >
                      Transaction
                    </Tab>
                    <Tab
                      className={({ selected }) =>
                        classNames("tab", selected ? "selected" : "deselected")
                      }
                    >
                      {({ selected }) => selected && "zzzii"}
                      Details
                    </Tab>
                  </Tab.List>
                  <Tab.Panels className="mt-2">
                    <Tab.Panel className={classNames("", "")}>
                      <div style={{ background: "yello" }}>
                        <div className="box-container">
                          <div className="box1">
                            <p className="pill">Financed Cost</p>
                            <p className="rent">Rental Finance</p>
                            <p className="fee">
                              ₦
                              {numberWithCommas(
                                removeExtraCharacter(
                                  rentalLoanInfo?.records?.filter(
                                    (rec) => rec.status === "disbursed"
                                  )?.[0]?.principal_amount || "0",
                                  "."
                                )
                              )}
                            </p>
                          </div>
                          <div className="box2">
                            <p className="rent white">Repayment</p>
                            <p className="fee white">
                              ₦
                              {numberWithCommas(
                                (rentalLoanInfo?.records?.filter(
                                  (rec) => rec.status === "disbursed"
                                )?.[0]?.principal_amount || 0) -
                                  (repaymentInfo?.filter(
                                    (pay) => pay.status === "paid"
                                  )?.[0]?.amount || 0)
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="line-header">
                          <p>Payment Breakdown</p>
                          <div className="line" />
                        </div>
                        <div>
                          {repaymentInfo.slice(0, 3).map((inv, i) => (
                            <div key={i} className="entry">
                              <div>
                                <p className="month">
                                  {months[
                                    Number(inv.due_date.split("-")[1]) - 1
                                  ].slice(0, 3)}
                                </p>
                                <p className="date">
                                  Payment date: {inv.due_date}
                                </p>
                              </div>
                              <div className="amount">
                                <p>
                                  {" "}
                                  ₦{" "}
                                  {numberWithCommas(
                                    removeExtraCharacter(inv.amount, ".")
                                  )}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="toggle">
                          <p>Hide Previous Payments</p>
                          <img alt="" src="up.svg" />
                        </div>
                        <div className="line-header">
                          <p>Due Payment </p>
                          <div />
                        </div>
                        <div>
                          {paymentInfo.map((inv, i) => (
                            <div key={i} className="entry">
                              <div>
                                <p className="month">
                                  {months[
                                    Number(inv.due_date.split("-")[1]) - 1
                                  ].slice(0, 3)}
                                </p>
                                <p className="date">Due date: {inv.due_date}</p>
                              </div>
                              <div className="amount">
                                <p>
                                  ₦{" "}
                                  {numberWithCommas(
                                    removeExtraCharacter(inv.amount, ".")
                                  )}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            // position: "sticky",
                            // width: "339px",
                            // bottom: "0",
                            // right: "18px",
                          }}
                        >
                          <PaystackButton className="pay" {...paystackProps} />
                          <div style={{ marginTop: "14px", width: "100%" }}>
                            <button
                              onClick={handleRequestRentalFinance}
                              className="pay"
                              style={{ background: "#000", color: "#fff" }}
                            >
                              Request Rental Finance
                            </button>
                          </div>
                        </div>
                      </div>
                    </Tab.Panel>
                    <Tab.Panel className={classNames("", "")}>
                      <div className="row">
                        <div className="collection">
                          <p className="light-input">Account number</p>
                          <p className="bold-input">(603) 555-0123</p>
                        </div>
                        <div className="collection">
                          <p className="light-input text-right">Account name</p>
                          <p className="bold-input text-right">GTBank</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="collection">
                          <p className="light-input">BVN</p>
                          <p className="bold-input">****************</p>
                        </div>
                        <div className="collection">
                          <p className="light-input text-right">Branch</p>
                          <p className="bold-input text-right">
                            ****************
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="collection">
                          <p className="light-input">Credit interest rate</p>
                          <p className="bold-input">20%</p>
                        </div>
                        <div className="collection">
                          <p className="light-input text-right">Branch</p>
                          <p className="bold-input text-right">7,658%</p>
                        </div>
                      </div>
                      <div>
                        <button className="pay">Edit Information</button>
                      </div>
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </>
          ) : (
            <LandlordStyles>
              <p className="title">Overall Payments</p>
              <div className="box_container">
                <button
                  className={`selection_button ${
                    collectOrSettle === "collection"
                      ? "selection_button_active"
                      : ""
                  }`}
                  onClick={handleCollectionClick}
                >
                  Collection
                </button>
                <button
                  className={`selection_button ${
                    collectOrSettle === "settlement"
                      ? "selection_button_active"
                      : ""
                  }`}
                  onClick={handleSettlementClick}
                >
                  Settlements
                </button>

                <p className="amount_display">₦6,150,500.00</p>
              </div>
              <button className="submit_button">Submit Property</button>

              <div className="">
                <p className="rental_loan_sidebar_heading">
                  Request Rental Collection
                </p>
                <div className="rental_loan_sidebar_box">
                  <div className="orange_image_container">
                    <img
                      src={requestRentalFinanceNotification}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "",
                        maxInlineSize: "100%",
                        blockSize: "auto",
                      }}
                    />
                  </div>
                  <p>
                    Kindly note that not all application or request might be
                    successful, we reserve the right to disqualify any request
                    not meeting up to our due diligence test, use the{" "}
                    <span style={{ color: "#f00" }}>submit property</span>{" "}
                    button to send a rental collection request.
                  </p>
                </div>
              </div>
            </LandlordStyles>
          )}
        </div>
        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            position: "sticky",
            width: "100%",
            // bottom: "0",
            bottom: "0",
            // right: "0",
          }}
        >
          <PaystackButton className="pay" {...paystackProps} />
          <div style={{ marginTop: "14px", width: "100%" }}>
            <button
              onClick={handleRequestRentalFinance}
              className="pay"
              style={{ background: "#000", color: "#fff" }}
            >
              Request Rental Finance
            </button>
          </div>
        </div> */}
      </div>
      <>
        {/* <RequestRentalFinanceModal
                      openRentalFinanceModal={openRentalFinanceModal}
                      setOpenRentalFinanceModal={setOpenRentalFinanceModal}
                    /> */}
        <Modal
          onClose={() => {
            setOpenRentalFinanceModal(false);
          }}
          show={openRentalFinanceModal}
        >
          <FormContainer>
            <FormPart>
              <h2 style={{ color: "#000" }}>Apply For Rental Finance</h2>
              <div className="input_label_container">
                {/* <label htmlFor="description" style={{ color: "#919499" }}>
                  Property Description
                </label>
                <input
                  type={"text"}
                  id="description"
                  placeholder="2bed Room Apartment"
                  className="form_input"
                  value={requestData.property_description}
                  onChange={(e) =>
                    setRequestData((prev) => ({
                      ...prev,
                      property_description: e.target.value,
                    }))
                  }
                />
                <p className="error message" style={{ textAlign: "left" }}>
                  {requestDataError.property_description.join(", ")}
                </p> */}

                <Input
                  label={"Property Description"}
                  inputFor={"finance-request_description"}
                  type={"text"}
                  placeholder={"2 bed Room Apartment"}
                  value={requestData.property_description}
                  valueChange={(e) =>
                    setRequestData((prev) => ({
                      ...prev,
                      property_description: e.target.value,
                    }))
                  }
                  errorMsg={requestDataError.property_description}
                />
              </div>
              <div className="input_label_container">
                {/* <label htmlFor="amount_requested" style={{ color: "#919499" }}>
                  Amount Request *
                </label>
                <input
                  type={"number"}
                  id="amount_requested"
                  placeholder="NGN 340,000"
                  className="form_input"
                  value={requestData.amount}
                  onChange={(e) =>
                    setRequestData((prev) => ({
                      ...prev,
                      amount: e.target.value,
                    }))
                  }
                /> */}
                <Input
                  label={"Amount Request"}
                  inputFor={"amount_requested"}
                  type={"text"}
                  placeholder={"NGN 340,000"}
                  value={requestData.amount}
                  valueChange={(e) =>
                    setRequestData((prev) => ({
                      ...prev,
                      amount: e.target.value,
                    }))
                  }
                  errorMsg={requestDataError.amount}
                />
              </div>

              <Button
                bg={"#F8AD15"}
                clr="#FFFFFF"
                style={{
                  width: "100%",
                  justifyContent: "center",
                  height: "65px",
                }}
                onClick={(e) => handleRequestFinanceSubmit(e)}
              >
                Request{" "}
                {requestData.amount === ""
                  ? "NGN 340,000"
                  : `NGN ${numberWithCommas(requestData.amount)}`}
              </Button>
            </FormPart>
          </FormContainer>
        </Modal>
      </>
    </Style>
  );
};

// Index.layout = DashboardLayout;

export default Index;
