import styled from "styled-components";
import media from "styled-media-query";
import CodingFieldImageContainer from "./CodingFieldImageContainer";
import CommonText from "./CommonText";
import HelpfulEnough from "./HelpfulEnough";
import ListingContainer from "./ListingContainer";
import Numbering from "./Numbering";
import SubTitle from "./SubTitle";
import TitleText from "./TitleText";

import WebhooksImageOne from "../../../Assets/images/developers/webhooks_image_one.png";

const Styles = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${media.greaterThan("768px")`

  `};
  ${media.greaterThan("1024px")`

  `};
  ${media.greaterThan("1440px")`

  `};
`;

const WebhooksNav = () => {
  return (
    <Styles>
      <TitleText>Webhooks</TitleText>
      <div>
        <CommonText>
          Generally, when you make a request to an API endpoint, you expect to
          get a near-immediate response. However, some requests may take a long
          time to process, which can lead to timeout errors. In order to prevent
          a timeout error, a pending response is returned. Since your records
          need to be updated with the final state of the request, you need to
          either:
        </CommonText>

        <ListingContainer>
          <li>
            <Numbering size={"33px"}>1</Numbering>
            <CommonText>
              <span className="bold_text">Secret key:</span> The most powerful
              type of key. It can authorize any action on your account, so it
              should never be exposed to the public.
            </CommonText>
          </li>
          <li>
            <Numbering size={"33px"}>2</Numbering>
            <CommonText>
              Make a request for an update (popularly known as polling) or,
            </CommonText>
          </li>
          <li>
            <Numbering size={"33px"}>3</Numbering>
            <CommonText>Listen to events by using a webhook URL.</CommonText>
          </li>
        </ListingContainer>
      </div>

      <div style={{ paddingBottom: "257px" }}>
        <SubTitle style={{ textAlign: "left" }}>Create a webhook URL</SubTitle>
        <CommonText>
          Creating a webhook endpoint on your server is the same as writing any
          other API endpoint, but there are a few important details to note:
        </CommonText>

        <SubTitle style={{ textAlign: "left" }}>
          Verifying webhook signatures
        </SubTitle>
        <CommonText style={{ paddingBottom: "40px" }}>
          When enabling webhooks, you have the option to set a secret hash.
          Since webhook URLs are publicly accessible, the secret hash allows you
          to verify that incoming requests are from Landlords Technology. You
          can specify any value as your secret hash, but we recommend something
          random. You should also store it as an environment variable on your
          server.
        </CommonText>

        <CodingFieldImageContainer
          height={"300px"}
          mdHeight={"450px"}
          lgHeight={"598px"}
        >
          <img
            src={WebhooksImageOne}
            alt=""
            style={{
              position: "",
              maxInlineSize: "100%",
              blockSize: "auto",
            }}
          />
        </CodingFieldImageContainer>
      </div>

      <HelpfulEnough />
    </Styles>
  );
};

export default WebhooksNav;
