import { set } from "lodash";
import { createContext, useState, useEffect } from "react";
import usePersistState from "../hooks/usePersistState";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [dashboardNav, setDashboardNav] = useState("/dashboard");
  const [profileFormIndex, setProfileFormIndex] = useState(0);
  const [registerCustomerType, setRegisterCustomerType] = useState("");
  const [generalSettings, setGeneralSettings] = useState();
  const [monoPK, setMonoPK] = useState();
  const [profilePicture, setProfilePicture] = useState(null);
  const [openDojahWidget, setOpenDojahWidget] = useState(false);
  const [profileCompleted, setProfileCompleted] = useState(false);
  const [userDetails, setUserDetails] = useState(undefined);

  useEffect(() => {
    const activeNav = localStorage.getItem("dashboardNav");
    if (activeNav) {
      setDashboardNav(activeNav);
    } else {
      setDashboardNav("/dashboard");
    }
  }, [dashboardNav]);

  return (
    <AppContext.Provider
      value={{
        dashboardNav,
        setDashboardNav,
        profileFormIndex,
        setProfileFormIndex,
        registerCustomerType,
        setRegisterCustomerType,
        generalSettings,
        setGeneralSettings,
        monoPK,
        setMonoPK,
        profilePicture,
        setProfilePicture,
        openDojahWidget,
        setOpenDojahWidget,
        userDetails,
        setUserDetails,
        profileCompleted,
        setProfileCompleted,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
