import styled from "styled-components";
import media from "styled-media-query";
import CodingFieldImageContainer from "./CodingFieldImageContainer";
import CommonText from "./CommonText";
import HelpfulEnough from "./HelpfulEnough";
import ListingContainer from "./ListingContainer";
import Numbering from "./Numbering";
import SubTitle from "./SubTitle";
import TitleText from "./TitleText";

import EncryptImageOne from "../../../Assets/images/developers/encrypt_image_one.png";

const Styles = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${media.greaterThan("768px")`

  `};
  ${media.greaterThan("1024px")`

  `};
  ${media.greaterThan("1440px")`

  `};
`;

const EncryptionNav = () => {
  return (
    <Styles>
      <TitleText>Encryption</TitleText>
      <div>
        <CommonText>
          When using our direct card charge API to charge a card directly,
          you&apos;ll need to encrypt the payload containing the card details
          before making the request.
        </CommonText>
      </div>

      <div style={{ paddingBottom: "127px" }}>
        <CommonText>
          To encrypt the payload manually, you&apos;ll need your encryption key
          (from the Settings &gt; API section of your dashboard). You&apos;ll
          use the 3DES algorithm to encrypt the payload.
        </CommonText>
        <CommonText style={{ paddingBottom: "37px" }}>
          Here&apos;s an example of an encryption function in different
          languages. In each case, the function takes the payload as a hash,
          converts it to JSON, encrypts it and encodes it in base64:
        </CommonText>

        <CodingFieldImageContainer
          height={"300px"}
          mdHeight={"450px"}
          lgHeight={"526px"}
        >
          <img
            src={EncryptImageOne}
            alt=""
            style={{
              position: "",
              maxInlineSize: "100%",
              blockSize: "auto",
            }}
          />
        </CodingFieldImageContainer>
      </div>

      <HelpfulEnough />
    </Styles>
  );
};

export default EncryptionNav;
