import styled from "styled-components";
import { FooterAnchorTag, FooterTitle } from "./FooterStylings";

import { Outlet, Link } from "react-router-dom";

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`;

const FooterColumn = ({ content }) => {
  const { title, anchorLinks } = content;

  // const router = useRouter();

  // const handleClick = () => {
  //   if
  // }

  // const handleClick = (end) => {
  //   router.push(end);
  // };
  return (
    <Styles>
      <FooterTitle>{title}</FooterTitle>
      <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        {anchorLinks.map((anchorLink, index) => {
          if (anchorLink.link.includes("mailto")) {
            return (
              <Link
                to=""
                onClick={(e) => {
                  window.location.href = anchorLink.link;
                  e.preventDefault();
                }}
                key={index}
              >
                <FooterAnchorTag>{anchorLink.name}</FooterAnchorTag>
              </Link>
            );
          } else {
            return (
              <Link to={anchorLink.link} key={index}>
                <FooterAnchorTag>{anchorLink.name}</FooterAnchorTag>
              </Link>
            );
          }
        })}
      </div>
    </Styles>
  );
};

export default FooterColumn;
