import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import DashboardLayout from "../../components/layout/dashboardLayout";
import Header from "../../components/layout/Header";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Menu, Tab, Transition } from "@headlessui/react";
import media from "styled-media-query";
import axios from "axios";

import headerSearchIcon from "../../Assets/images/header-search-icon.svg";
import chev from "../../Assets/images/chev.svg";
import card from "../../Assets/images/card.svg";
import up from "../../Assets/images/up.svg";
import requestRentalFinanceNotification from "../../Assets/images/request_rental_finance_notification.svg";
import shareColored from "../../Assets/images/share_colored.svg";
import shareWhite from "../../Assets/images/share_white.svg";
import Button from "../../components/elements/Button";
import { FormPart } from "../../components/blocks/Authentication";
import Modal from "../../components/blocks/Modal";
import { FormContainer } from ".";
import removeExtraCharacter from "../../utils/removeExtraCharacter";
import numberWithCommas from "../../utils/numberWithCommas";
import { PaystackButton } from "react-paystack";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import DateRange from "../../utils/DateRange";
import { useNavigate } from "react-router-dom";

const Style = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  .box1 {
    height: 100px;
    width: 167px;
    background: #f8f8f8;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    margin-right: 8px;
  }
  .pill {
    width: 72px;
    height: 15px;
    background: #f8ad15;
    border-radius: 8px;
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
  }
  .white {
    color: white !important;
  }
  .line-header {
    display: flex;
    align-items: center;
    margin-bottom: 32.5px;
    p {
      font-family: "Apercu Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      /* identical to box height */
      margin-right: 5px;
      color: #000000;
    }
    div {
      flex: 1;
      border-bottom: 1.3px solid #cc8d2b;
      height: 1px;
    }
  }
  .pay {
    height: 43px;
    left: 1088px;
    top: 636px;
    margin-top: 4px;
    background: #f8ad15;
    border-radius: 5px;
    border: none;
    outline: none;
    appearance: none;
    width: 100%;
    color: white;
  }
  .mapper {
    display: flex;
    margin-bottom: 37px;
    align-items: center;
  }
  .card-holder {
    /* width: 10%; */
    display: none;

    ${media.greaterThan("768px")`
      display: block;
      margin-right: 2%;
    `};
    ${media.greaterThan("1024px")`
      display: block;
    `};
  }
  .card {
    width: 24.4px;
    height: 16px;
    ${media.greaterThan("768px")`
      display: block;
    `};
    ${media.greaterThan("1024px")`
      width: 29.4px;
      height: 21px;
    `};
  }
  .paid-holder {
    /* width: 20%; */
    flex: 1;
  }
  .paid {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    color: #474747;

    ${media.greaterThan("768px")`
      
    `};
    ${media.greaterThan("1024px")`
      font-size: 16px;
    line-height: 24px;
    `};
    ${media.greaterThan("1440px")`
      font-size: 18px;
    line-height: 27px;
    `};
  }

  .date-holder {
    width: 22%;

    ${media.greaterThan("768px")`
      
    `};
    ${media.greaterThan("1024px")`
      
    `};
    ${media.greaterThan("1440px")`
      width: 30%;
    `};
  }
  .date {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 12px;

    color: #c4c4c4;

    ${media.greaterThan("768px")`
      font-size: 10px;
      line-height: 16px;
    `};
    ${media.greaterThan("1024px")`
      font-size: 10px;
      line-height: 16px;
    `};
    ${media.greaterThan("1440px")`
      font-size: 12px;
      line-height: 18px;
    `};
  }
  .amount-holder {
    width: 20%;

    ${media.greaterThan("768px")`
      
    `};
    ${media.greaterThan("1024px")`
      
    `};
    ${media.greaterThan("1440px")`
      width: 25%;
    `};
  }
  .show {
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;

    color: #c4c4c4;
  }
  .amount {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    color: #474747;

    ${media.greaterThan("768px")`
      
    `};
    ${media.greaterThan("1024px")`
      font-size: 16px;
    line-height: 24px;
    `};
    ${media.greaterThan("1440px")`
      font-size: 18px;
    line-height: 27px;
    `};
  }

  .open-holder {
    /* flex: 1; */
    display: flex;
    /* justify-content: end; */
  }
  .open-button {
    /* width: 90px;
    height: 32px; */
    border-radius: 15.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    padding: 4px 8px;

    ${media.greaterThan("768px")`
      padding: 6px 16px;
      
    `};
    ${media.greaterThan("1024px")`
      font-size: 12px;
      line-height: 16px;
      padding: 8px 24px;
    `};
    ${media.greaterThan("1440px")`
      font-size: 14px;
      line-height: 21px;
    `};
  }
  .closed {
    color: #d70000b2;
    background: rgba(255, 88, 88, 0.4);
  }
  .opened {
    background: #007d50;
    background: rgba(174, 244, 202, 0.4);
  }

  .header-holder {
    margin-bottom: 49px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .search-holder {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .circle {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f8f8f8;
  }
  .dropdown-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    width: 105px;
    height: 39px;
    left: 876px;
    top: 185px;
    background: #f8f8f8;
    border-radius: 25px;
    outline: none;
    border: none;
    margin-left: 21px;
  }
  .toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 34px;
    p {
      margin-right: 11px;

      font-family: "DM Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 100%;
      /* identical to box height, or 13px */

      letter-spacing: -0.03em;

      /* background/primary */

      color: #fbbd29;
    }
  }
  .entry {
    background: #f8f8f8;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px 12px 12px;
    gap: 16px;
    height: 65px;
    margin-bottom: 10px;
    justify-content: space-between;
  }
  .rent {
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 160%;
    /* or 19px */

    text-align: center;

    /* text/default */

    color: #1a191e;
  }
  .fee {
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 120%;
    /* identical to box height, or 36px */

    letter-spacing: -0.03em;

    color: #000000;
  }
  .box2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 2px;

    width: 167px;
    height: 100px;

    background: #cc8d2b;
    border-radius: 10px;
  }
  .box-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
  }
  .tab {
    margin-right: 24px;
    background: white;
    appearance: none;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding-left: 0;
  }
  .tab-list {
    margin-bottom: 39px;
  }
  .selected {
    color: #fcba2d;
  }
  .deselected {
    color: #919499;
  }
  .main-content {
    overflow: scroll;
    flex: 1;
    padding: 46px 36px 0;
    background: #f8f8f8;
    ${media.lessThan("medium")`
    padding: 46px 16px 0;
  `}
  }
  .right-sidebar {
    display: none;
    overflow: scroll;
    /* width: 375px; */
    height: 100%;
    background: white;
    padding: 48px 18px;

    ${media.greaterThan("768px")`
      display: block;
      width: 33%;
    `};
    ${media.greaterThan("1024px")`

    `};
    ${media.greaterThan("1440px")`
      max-width: 375px;

    `};
  }
  .graph-section {
    background: white;
    border-radius: 15px;
    padding: 22px 5%;
    display: flex;
    flex-direction: column;

    ${media.greaterThan("768px")`
      padding: 22px 27px;
    `};
    ${media.greaterThan("1024px")`

    `};
    ${media.greaterThan("1440px")`
      

    `};
  }
  .graph-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
  }
  .dot {
    background: #fbbd29;
    height: 12px;
    width: 12px;
    border-radius: 100%;
    margin-right: 8px;
  }
  .graph-title {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    display: flex;
    align-items: center;
  }
  .graph {
    flex: 1;
  }
  .invoice-section {
    height: 279px;
    background: white;
    border-radius: 15px;
    padding: 23px;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
  }
  .enter-icon {
    margin-left: 19px;
  }
  invoice-title {
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    /* identical to box height, or 24px */

    letter-spacing: -0.03em;

    /* text/default */

    color: #1a191e;
  }
  .view {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */

    letter-spacing: -0.03em;
    cursor: pointer;
    color: #fbbd29;
    display: flex;
    align-items: center;
  }
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  .inv {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .month {
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */

    letter-spacing: -0.03em;

    /* text/default */

    color: #1a191e;
  }
  .date {
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 160%;
    /* or 19px */

    color: rgba(26, 25, 30, 0.6);
  }
  .amount {
    p {
      font-family: "Apercu Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 160%;
      /* identical to box height, or 26px */

      /* text/default */

      color: #1a191e;
    }
  }
  .rep-title {
    margin-bottom: 19px;
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    /* or 25px */

    letter-spacing: -0.03em;

    /* text/default */

    color: #1a191e;
  }
  .collection {
    width: 50%;
  }
  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .light-input {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
    color: #c4c4c4;
  }
  .bold-input {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    color: #474747;
  }

  .rental_loan_sidebar_heading {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #034b5e;
  }
  .rental_loan_sidebar_box {
    background: #effcff;
    border: 2px solid rgba(161, 229, 248, 0.7);
    box-shadow: 0px 10px 15px rgba(41, 128, 151, 0.1);
    border-radius: 14px;
    padding: 14px;
    padding-bottom: 18px;
    margin-top: 12px;

    .orange_image_container {
      width: 32px;
      height: 32px;
    }

    p {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 25px;
      padding-top: 6px;
      padding-left: 10px;

      color: #040404;
    }
  }

  .mobile_view_only {
    ${media.greaterThan("768px")`
      display: none;
    `}
  }

  .status_box {
    width: 72px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;

    ${media.greaterThan("768px")`
      
    `}
    ${media.greaterThan("1024px")`
      
    `}
  }
  .paid {
    background: #f8ad15;
    color: #fff;
  }
  .unpaid {
    background: rgba(255, 88, 88, 0.4);
    color: #f00;
  }
  .pending {
    background: rgba(25, 252, 4, 0.4);
    color: #fff;
  }

  .invoice_details_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .invoice_number {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #1a191e;
    margin: 8px 0px 20px;
  }
  .share-icon {
    width: 23px;
    height: 22px;
    max-inline-size: 100%;
    block-size: auto;
  }

  .invoice_button {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin-right: 3%;
    }
  }

  .date_overall_container {
    position: relative;
    /* background: red; */
  }
  .absolute_date_range {
    position: absolute;
    right: 0;
  }
`;

const Invoice = () => {
  const navigate = useNavigate();
  const invoice = [
    {
      month: "June",
      dueDate: "01/01/2022 02:34",
      amount: "₦70,299.00",
    },
    {
      month: "June",
      paymentDate: "01/01/2022 02:34",
      amount: "₦70,299.00",
    },
    {
      month: "June",
      paymentDate: "01/01/2022 02:34",
      amount: "₦70,299.00",
    },
  ];

  const items = [
    {
      status: "Paid",
      date: "15 Jan 2022",
      amount: "NGN 87,900",
      opened: "Closed",
    },
    {
      status: "Paid",
      date: "15 Jan 2022",
      amount: "NGN 87,900",
      opened: "Closed",
    },
    {
      status: "Paid",
      date: "15 Jan 2022",
      amount: "NGN 87,900",
      opened: "Closed",
    },
    {
      status: "Unpaid",
      date: "15 Jan 2022",
      amount: "NGN 87,900",
      opened: "Opened",
    },
  ];
  const [userInfo, setUserInfo] = useState({});

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const [graphInfo, setGraphInfo] = useState([]);
  const [rentalLoanInfo, setRentalLoanInfo] = useState([]);
  const [repaymentInfo, setRepaymentInfo] = useState([]);
  const [paymentInfo, setPaymentInfo] = useState([]);
  const [duepaymentInfo, setDuePaymentInfo] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});

  const [paystackSettings, setPaystackSettings] = useState("");
  const [paystackProps, setPaystackProps] = useState();
  const [openDate, setOpenDate] = useState(false);

  useEffect(() => {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    setUserInfo(info);
    if (info === null || info === undefined) {
      navigate("/signin");
    }

    axios
      // .get(`/${info.customer_type}/payment-invoices?limit=10&offset=10`)
      .get(`/${info.customer_type}/payment-invoices`)
      .then((res) => {
        // setDuePaymentInfo(res.data.data);
        const result = res.data?.data.records;
        console.log("this is aaaaallllll the payment invoices: ", res);
        console.log("this is the payment invoices reduced: ", result);
        setInvoiceList(result);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "Novermber",
    "December",
  ];

  const [openRentalFinanceModal, setOpenRentalFinanceModal] = useState(false);
  const handleRequestRentalFinance = () => {
    setOpenRentalFinanceModal(true);
    // alert("request clicked!!");
  };

  const viewMoreInvoiceInfo = (item) => {
    setSelectedItem(item);
  };

  useEffect(() => {
    axios
      .get(`/general-settings`)
      .then((res) => {
        setPaystackSettings(res?.data?.data?.data?.paystack);
        localStorage.setItem(
          "general_settings",
          JSON.stringify(res?.data?.data?.data)
        );
      })
      .catch((err) => {
        console.log("this is the error from general settings: ", err.response);
      });
  }, []);

  useEffect(() => {
    const response = JSON.parse(localStorage.getItem("general_settings"));
    console.log("total response: ", response);
    if (response) {
      setPaystackSettings(response.paystack);
    }
  }, []);

  const unique_id = uuid();
  const small_id = unique_id.slice(0, 8);
  // const user_id = paymentInfo[0]?.customer_id;
  // const rent_id = paymentInfo[0]?.rent_id;
  // const bbyRef = useRef(Math.floor(Math.random(9) * 100));
  // bbyRef.current = "123456789ghjkl";

  useEffect(() => {
    // const unique_id = uuid();
    // const small_id = unique_id.slice(0, 8);
    // const user_id = userInfo?.customer_uuid;

    // const rent_id = paymentInfo[0]?.rent_id;
    //didn't use rent_id because it causes unecessary stress.
    //in a situation where there's more than one payment nko?

    const user_id = paymentInfo[0]?.customer_id;
    const rent_id = paymentInfo[0]?.rent_id;

    setPaystackProps({
      email: userInfo.email_address,
      amount: 3000000,
      reference: `LT-${small_id}-${user_id}-${rent_id}`,
      // ref: bbyRef,
      metadata: {
        name: userInfo.fullname,
        phone: userInfo.phone_number,
      },
      publicKey: paystackSettings.public_key,
      text: "Make Payment",

      onSuccess: ({ reference }) => {
        // alert(
        //   `Your purchase was successful! Transaction reference: ${reference}`
        // );
        axios
          .post(
            "/payment/callback/paystack",
            {
              reference: reference,
              user_id: user_id,
              rent_id: rent_id,
              type: "rentals",
              type_id: paymentInfo?.id,
            },
            {
              headers: {
                "content-type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log("this is the response from paystack callback: ", res);
          })
          .catch((err) => {
            console.log("this is the error from the paystack callback : ", err);
            toast.error(err?.response?.data?.message);
          });
        // resetForm();
      },
      onClose: () => {
        alert("Wait! You have not completed your payment.");
        setPaystackProps((props) => ({
          ...props,
          reference: "",
        }));
      },
    });
  }, [
    userInfo.email_address,
    userInfo.fullname,
    userInfo.phone_number,
    paymentInfo,
    paystackSettings.public_key,
  ]);

  const RightSideContent = ({ item }) => {
    return (
      <>
        {/* <div> */}
        {item.reference ? (
          <>
            <div className="invoice_details_header">
              <div>
                <div
                  className={`status_box ${
                    item.status === "confirmed"
                      ? "paid"
                      : item.status === "failed"
                      ? "unpaid"
                      : "pending"
                  }`}
                >
                  {item.status === "confirmed"
                    ? "Paid"
                    : item.status === "failed"
                    ? "Unpaid"
                    : "Pending"}
                </div>
                <div className="invoice_number">
                  Invoices # {item.reference}
                </div>
              </div>
              <img src={shareColored} alt="" className="share_icon" />
            </div>

            <div className="row">
              <div className="collection">
                <p className="light-input">Service</p>
                <p className="bold-input">Rental repayment</p>
              </div>
              <div className="collection">
                <p className="light-input text-right">Month</p>
                <p className="bold-input text-right">Feb, 2023</p>
              </div>
            </div>
            <div className="row">
              <div className="collection">
                <p className="light-input">Transaction reference</p>
                <p className="bold-input">Ref: 00999999899</p>
              </div>
              <div className="collection">
                <p className="light-input text-right">Payment Channel</p>
                <p className="bold-input text-right">Flutterwave</p>
              </div>
            </div>
            <div>
              <button className="pay invoice_button">
                <img src={shareWhite} alt="" className="share_icon" />
                Share Invoice
              </button>
            </div>
          </>
        ) : (
          <p>Invoice details will appear here</p>
        )}
        {/* </div> */}
      </>
    );
  };

  return (
    <Style>
      <div className="main-content nobar">
        <div className="header">
          <Header title="Invoices" subTitle="See transaction invoice" />
        </div>
        <div>
          <div className="graph-section">
            <div className="header-holder">
              <p>Invoices</p>
              <div className="search-holder">
                {/* <div className="circle">
                  <img
                    src={headerSearchIcon}
                    // src="/header-search-icon.svg"
                    alt=""
                    style={{
                      position: "",
                      maxInlineSize: "100%",
                      blockSize: "auto",
                    }}
                  />
                </div> */}
                <div>
                  {/* <Menu as="div" className="">
                    <div>
                      <Menu.Button className="dropdown-button">
                        Date
                        <img
                          alt=""
                          className=""
                          src={chev}
                          aria-hidden="true"
                          style={{
                            position: "",
                            maxInlineSize: "100%",
                            blockSize: "auto",
                          }}
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="">
                        <div className="">
                          <Menu.Item>
                            {({ active }) => (
                              <div>
                                <DateRange />
                              </div>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu> */}

                  <div className="date_overall_container">
                    <div
                      className="dropdown-button"
                      onClick={() => setOpenDate(!openDate)}
                    >
                      Date
                      <img
                        alt=""
                        className=""
                        src={chev}
                        aria-hidden="true"
                        style={{
                          position: "",
                          maxInlineSize: "100%",
                          blockSize: "auto",
                        }}
                      />
                    </div>
                    {openDate && (
                      <div className="absolute_date_range">
                        <DateRange />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              {invoiceList.map((item, i) => (
                <>
                  <div
                    key={i}
                    className="inv"
                    onClick={() => viewMoreInvoiceInfo(item)}
                  >
                    <div>
                      <p className="month">invoice #{item.reference}</p>
                      <p className="date">
                        {item.paymentDate
                          ? `Payment date: ${item.paymentDate}`
                          : item.due_date
                          ? `Due date: ${item.due_date}`
                          : `Created date: ${item.created_at}`}
                      </p>
                      <div
                        className={`status_box ${
                          item.status === "confirmed"
                            ? "paid"
                            : item.status === "failed"
                            ? "unpaid"
                            : "pending"
                        }`}
                      >
                        {item.status === "confirmed"
                          ? "Paid"
                          : item.status === "failed"
                          ? "Unpaid"
                          : "Pending"}
                      </div>
                    </div>
                    <div className="amount">
                      <p>
                        ₦{" "}
                        {numberWithCommas(
                          removeExtraCharacter(item.amount, ".")
                        )}
                      </p>
                    </div>
                  </div>
                  {invoiceList.length > i + 1 && (
                    <div
                      style={{
                        border: "0.2px solid #F8F8F8",
                        width: "95%",
                        margin: "13px auto",
                      }}
                    />
                  )}
                </>
              ))}
              {/* <div>
                <p className="show">Show all</p>
              </div> */}
            </div>

            {/* <div className="mobile_view_only" style={{ marginTop: "14px" }}>
              <button onClick={handleRequestRentalFinance} className="pay">
                Request Rental Finance
              </button>
            </div> */}
          </div>
        </div>
      </div>
      <div className="right-sidebar">
        {/* <div
          className={`status_box ${
            item.status === "confirmed"
              ? "paid"
              : item.status === "failed"
              ? "unpaid"
              : "pending"
          }`}
        >
          {item.status === "confirmed"
            ? "Paid"
            : item.status === "failed"
            ? "Unpaid"
            : "Pending"}
        </div> */}
        {/* <div>Paid</div>
        <div>
          {paymentInfo.map((inv, i) => (
            <div key={i} className="entry">
              <div>
                <p className="month">
                  {months[Number(inv.due_date.split("-")[1]) - 1]}
                </p>
                <p className="date">Due date: {inv.due_date}</p>
              </div>
              <div className="amount">
                <p>{inv.amount}</p>
              </div>
            </div>
          ))}
        </div>
        <PaystackButton className="pay" {...paystackProps} /> */}

        <RightSideContent item={selectedItem} />
      </div>
    </Style>
  );
};

// Rental.layout = DashboardLayout;

export default Invoice;
