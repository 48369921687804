import styled from "styled-components";
import media from "styled-media-query";
import CommonText from "./CommonText";
import HelpfulEnough from "./HelpfulEnough";
import ListingContainer from "./ListingContainer";
import Numbering from "./Numbering";
import TitleText from "./TitleText";

const Styles = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${media.greaterThan("768px")`

  `};
  ${media.greaterThan("1024px")`

  `};
  ${media.greaterThan("1440px")`

  `};
`;

const QuickstartNav = () => {
  return (
    <Styles>
      <TitleText>QuickStart</TitleText>
      <CommonText>
        With our APIs, you can make and receive rental payments as we pay
        onbehalf of your customers. Here&apos;s what you need to do:
      </CommonText>
      <ListingContainer style={{ marginBottom: "150px" }}>
        <li>
          <Numbering>1</Numbering>
          <CommonText>
            <span>Create an account</span>, if you haven&apos;t already.
          </CommonText>
        </li>
        <li>
          <Numbering>2</Numbering>
          <CommonText>
            On your dashboard, switch to Test Mode and get{" "}
            <span>your API keys.</span>
          </CommonText>
        </li>
        <li>
          <Numbering>3</Numbering>
          <CommonText>
            If you want Landlords technology to collect payments from customers,
            visit the <span>Collecting Payments overview.</span>
          </CommonText>
        </li>
        <li>
          <Numbering>4</Numbering>
          <CommonText>
            Build your integration, using one of our{" "}
            <span>libraries or plugins.</span>
          </CommonText>
        </li>
        <li>
          <Numbering>5</Numbering>
          <CommonText>
            When you&apos;re ready, switch to Live Mode and ship it.
          </CommonText>
        </li>
      </ListingContainer>

      <HelpfulEnough style={{ paddingLeft: "5%" }} />
    </Styles>
  );
};

export default QuickstartNav;
