import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";

import { Tabs as TabsContainer, Tab as TabItem } from "react-tabs-scrollable";
// import "react-tabs-scrollable/dist/rts.css";

import addImage from "../../Assets/images/addimage.svg";
import calendarIcon from "../../Assets/images/calendar_icon.svg";

import DashboardLayout from "../../components/layout/dashboardLayout";
import Header from "../../components/layout/Header";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Tab } from "@headlessui/react";
import media from "styled-media-query";
import Input from "../../components/dashboard/input";
import { useState } from "react";
import Validator from "../../utils/Validator";
import { Circles } from "react-loader-spinner";
import { set } from "lodash";
import useAppHook from "../../hooks/useAppHook";
import { useNavigate } from "react-router-dom";
import { backInOut } from "framer-motion";

import ImageUpload from "../../utils/ImageUpload";
import {
  SelectInput,
  Input as InputMain,
  BlankInput,
} from "../../modules/common_module/components/basic/functional";
import { MdCancel } from "react-icons/md";

const Style = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  .box1 {
    height: 100px;
    width: 167px;
    background: #f8f8f8;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    margin-right: 8px;
  }
  .pill {
    width: 72px;
    height: 15px;
    background: #f8ad15;
    border-radius: 8px;
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
  }
  .white {
    color: white !important;
  }
  .line-header {
    display: flex;
    align-items: center;
    margin-bottom: 32.5px;
    p {
      font-family: "Apercu Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      /* identical to box height */
      margin-right: 5px;
      color: #000000;
    }
    div {
      flex: 1;
      border-bottom: 1.3px solid #cc8d2b;
      height: 1px;
    }
  }
  .pay {
    height: 43px;
    left: 1088px;
    top: 636px;
    margin-top: 4px;
    background: #f8ad15;
    border-radius: 5px;
    border: none;
    outline: none;
    appearance: none;
    width: 100%;
    color: white;
  }
  .toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 34px;
    p {
      margin-right: 11px;

      font-family: "DM Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 100%;
      /* identical to box height, or 13px */

      letter-spacing: -0.03em;

      /* background/primary */

      color: #fbbd29;
    }
  }
  .entry {
    background: #f8f8f8;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px 12px 12px;
    gap: 16px;
    height: 65px;
    margin-bottom: 10px;
    justify-content: space-between;
  }
  .rent {
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 160%;
    /* or 19px */

    text-align: center;

    /* text/default */

    color: #1a191e;
  }
  .fee {
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 120%;
    /* identical to box height, or 36px */

    letter-spacing: -0.03em;

    color: #000000;
  }
  .box2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 2px;

    width: 167px;
    height: 100px;

    background: #cc8d2b;
    border-radius: 10px;
  }
  .box-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
  }
  .tab {
    margin-right: 24px;
    background: white;
    appearance: none;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding-left: 0;
  }
  .tab-list {
    margin-bottom: 39px;
  }
  .selected {
    color: #fcba2d;
  }
  .deselected {
    color: #919499;
  }
  .main-content {
    overflow: scroll;
    overflow-x: hidden;
    flex: 1;
    padding: 46px 36px 0;
    background: #f8f8f8;
    ${media.lessThan("medium")`
    padding: 46px 16px 0;
  `}
  }
  .right-sidebar {
    ${media.lessThan("medium")`
    display: none;
  `}
    overflow: scroll;
    width: 375px;
    height: 100%;
    background: white;
    padding: 48px 18px;
  }

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  .inv {
    display: flex;
    justify-content: space-between;
    align-items: end;
  }
  .month {
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */

    letter-spacing: -0.03em;

    /* text/default */

    color: #1a191e;
  }
  .date {
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 160%;
    /* or 19px */

    color: rgba(26, 25, 30, 0.6);
  }
  .amount {
    p {
      font-family: "Apercu Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 160%;
      /* identical to box height, or 26px */

      /* text/default */

      color: #1a191e;
    }
  }
  .rep-title {
    margin-bottom: 19px;
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    /* or 25px */

    letter-spacing: -0.03em;

    /* text/default */

    color: #1a191e;
  }

  .developer-section {
    padding: 33px 35px;
    margin-top: 60px;
    background: #fff;
    border-radius: 15px;
    ${media.lessThan("medium")`
    padding: 16px 16px;
    `}
  }

  .active-tab {
    /* background: red !important; */
    /* transform: translateX(-100%); */
  }
  .inactive-tab {
    color: rgba(0, 0, 0, 0.5) !important;
  }

  .tab1-container {
    gap: 36px;
    display: flex;
    ${media.lessThan("medium")`
    flex-direction: column;
    `}
    background: #ffffff;
    border-radius: 15px;
  }
  .profile-section {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .one {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 116px;
  }
  .image-description {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #919499;
    max-width: 200px;
    text-align: center;
  }
  .space {
    ${media.lessThan("medium")`
    margin-top: 15px;
    `}
  }
  .input-holder {
    display: flex;
    ${media.greaterThan("medium")`
    gap: 26px;
    `}
    ${media.lessThan("medium")`
    flex-direction: column;
    `}
  }
  .half-input {
    width: 50%;
    ${media.greaterThan("medium")`
    width: 100%;
    `}
  }
  .round {
    cursor: pointer;
    margin-top: 25px;
    margin-bottom: 28px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(217, 217, 217, 0.3);
    border-radius: 100%;
    height: 123px;
    width: 123px;
    display: flex;
    border: 1px solid #000000;
  }
  .passord-section {
    padding: 25px 28px;
    width: 100%;
    border: 1.3px solid rgba(196, 196, 196, 0.5);
    border-radius: 15px;
    ${media.lessThan("medium")`
    padding: 16px 16px;
    `}
  }
  .add-image {
    height: 34px;
    width: 34px;
    margin-bottom: 8px;
  }
  .photo {
    color: rgba(0, 0, 0, 0.3);
    font-size: 12px;
  }
  /* .input-container {
    margin-bottom: 15px;
  } */
  .save {
    background: #f8ad15;
    border-radius: 14px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    width: 221px;
    height: 45px;
    margin: 25px auto 0;
    cursor: pointer;
  }
  .responsive-half {
    width: 50%;
    ${media.lessThan("medium")`
    
    width: 100%;
    `}
  }
  .responsive-one-third {
    width: 33%;
    ${media.lessThan("medium")`
    
    width: 100%;
    `}
  }
  .responsive-two-third {
    width: 66%;
    ${media.lessThan("medium")`
    
    width: 100%;
    `}
  }
  .section-finance {
    width: 53%;
    margin-top: 26px;
    border: 1.3px solid rgba(196, 196, 196, 0.5);
    border-radius: 15px;
    padding: 25px 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    ${media.lessThan("medium")`
    padding: 16px 16px;
    gap: 20px;
    width: 100%;
    `}/* p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #919499;
      margin-bottom: 15px;
    } */
  }
  .full {
    width: 100%;
  }
  .bank_info {
    display: flex;
    flex-direction: row;
  }
  .bank_info_status_container {
    display: none;
    ${media.greaterThan("medium")`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;
    `}
  }
  .tablet {
    overflow: hidden;
    border: none;
    outline: none;
    appearance: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    // padding: 8px 8px 8px 12px;
    justify-content: center;
    width: auto;
    height: 43px;
    overflow-x: scroll;
    ${media.lessThan("medium")`
    margin-right: 16px;
    `}
    margin-right: 80px;
    border-radius: 6px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 170%;
    cursor: pointer;
    color: #1a191e;
    background: white;
  }
  max-width: 100vw;
  .key-input {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #919499;
  }
  .top-space {
    margin-top: 15px;
  }
  .key-pill {
    height: 65px;
    padding: 25px 27px;
    border: 1.3px solid rgba(196, 196, 196, 0.5);
    border-radius: 14px;
    p {
      font-family: "Apercu Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;

      /* Black */

      color: #000000;
    }
  }
  .tablet-list {
    display: -webkit-box;
    margin-bottom: 23px;
    white-space: nowrap;
    /* background: red; */
    /* overflow: scroll; */
    overflow: scroll;
  }
  .tab-list-main {
    display: -webkit-box;
    margin-bottom: 23px;
    white-space: nowrap;
    overflow: scroll;
    background: green;
    /* overflow: hidden; */
  }

  .collection {
    width: 50%;
  }
  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .light-input {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    color: #c4c4c4;
  }
  .bold-input {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    color: #474747;
  }
  // .text-right {
  //   text-align: right;
  // }

  .form_input {
    border: 1.3px solid rgba(196, 196, 196, 0.5);
    border-radius: 14px;
    margin-top: 19px;
    margin-bottom: 2px;
    height: 65px;
    /* padding: 16px 24px 16px 24px; */
    padding: 22px 24px;
    width: 100%;
    color: #474747;
    ${media.lessThan("medium")`
    height: 40px;
    padding: 16px 16px;

    `}/* ${media.greaterThan("768px")`
    height: 50px;
    padding: 16px 24px 16px 24px;
    
  `};
    ${media.greaterThan("1024px")`
    height: 65px;
    padding: 20px 34px 20px 34px;
  `};
    ${media.greaterThan("1440px")`
  `}; */
  }

  .form_input.message {
    height: 124px;
  }

  .text-input {
    height: 65px;
    border: 1.3px solid rgba(196, 196, 196, 0.5);
    border-radius: 14px;
    padding: 22px 24px;
    margin-bottom: 2px;
    ${media.lessThan("medium")`
    height: 40px;
    padding: 16px 16px;

    `}
  }

  select {
    /* for Firefox */
    -moz-appearance: none;
    /* for Safari, Chrome, Opera */
    -webkit-appearance: none;
  }
  /* for IE10 */
  select::-ms-expand {
    display: none;
  }

  .label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 15px;
    position: relative;
    color: #919499;
    width: 100%;
  }

  .button_container {
    display: flex;

    ${media.greaterThan("1024px")`
      justify-content: right;
    `};
  }

  .right {
    margin-left: 0;
    margin-right: 0;
  }

  .mobile_only {
    ${media.greaterThan("768px")`
      display: none;
    `};
  }
  .date-picker {
    border: none;
    width: 90%;
  }

  .employment_letter_name-display {
    display: flex;
    /* background: red; */
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`;

const Profile = () => {
  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);
  const [submittingPassword, setSubmittingPassword] = useState(false);
  const [formIndex, setFormIndex] = useState(0);
  // const [selectedIndex, setSelectedIndex] = useState(0);
  const {
    profileFormIndex,
    setProfileFormIndex,
    setProfilePicture,
    profileCompleted,
    setProfileCompleted,
  } = useAppHook();

  const [activeTab, setActiveTab] = useState(0);

  const [personalInfoData, setPersonalInfoData] = useState({
    full_name: "",
    email_address: "",
    birth_date: "",
    phone_number: "",
    state: "",
    city: "",
    address: "",
  });

  const [personalInfoError, setPersonalInfoError] = useState({
    full_name: [],
    email_address: [],
    birth_date: [],
    phone_number: [],
    province: [],
    city: [],
    address: [],
  });

  const [avatar, setAvatar] = useState("");

  const [passwordData, setPasswordData] = useState({
    old_password: "",
    password: "",
    password_confirmation: "",
  });

  const [passwordDataError, setPasswordDataError] = useState({
    old_password: [],
    password: [],
    password_confirmation: [],
  });

  const [nextOfKinInfo, setNextOfKinInfo] = useState({
    Next_of_Kin_Title: "",
    Next_of_Kin_Full_Name: "",
    Next_of_Kin_Phone_Number: "",
    Next_of_Kin_Email: "",
    Next_of_Kin_Relationship: "",
    Next_of_Kin_State: "",
    Next_of_Kin_LGA: "",
    Next_of_Kin_Address: "",
    Next_of_Kin_Employer_Name: "",
    // kin_nin: "",
  });
  const [nextOfKinInfoError, setNextOfKinInfoError] = useState({
    Next_of_Kin_Title: [],
    Next_of_Kin_Full_Name: [],
    Next_of_Kin_Phone_Number: [],
    Next_of_Kin_Email: [],
    Next_of_Kin_Relationship: [],
    Next_of_Kin_State: [],
    Next_of_Kin_LGA: [],
    Next_of_Kin_Address: [],
    Next_of_Kin_Employer_Name: [],
    // kin_nin: [],
  });

  // const [employmentLetter, setEmploymentLetter] = useState();
  // const [employmentLetterError, setEmploymentLetterError] = useState([]);

  const [workInfo, setWorkInfo] = useState({
    Employer_Name: "",
    Employer_Address: "",
    Employer_LGA: "",
    Employer_Email: "",
    Employer_State: "",
    Employer_Country: "",
    Employer_Telephone_Number: "",
    Date_Employed: "",
    Employment_Type: "",
    Monthly_Income: "",
    Employment_Letter: "",
    Company_Name: "",
  });

  // const [employmentLetterError, setEmploymentLetterError] = useState([]);

  const [workInfoError, setWorkInfoError] = useState({
    Employer_Name: [],
    Employer_Address: [],
    Employer_LGA: [],
    Employer_Email: [],
    Employer_State: [],
    Employer_Country: [],
    Employer_Telephone_Number: [],
    Date_Employed: [],
    Employment_Type: [],
    Monthly_Income: [],
    Employment_Letter: [],
    Company_Name: [],
  });

  const [bankInfo, setBankInfo] = useState({
    bank_name: "",
    account_name: "",
    account_number: "",
    bvn: "",
  });
  const [bankInfoError, setBankInfoError] = useState({
    bank_name: [],
    account_name: [],
    account_number: [],
    bvn: [],
  });

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const ref = useRef(null);

  const refTab = useRef(null);
  const uploadRef = useRef(null);

  const [userDetail, setUserDetail] = useState({});
  const [states, setStates] = useState([]);
  const [banksList, setBanksList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [kinCitiesList, setKinCitiesList] = useState([]);
  const [employerCitiesList, setEmployerCitiesList] = useState([]);

  const [constants, setConstants] = useState({});

  useEffect(() => {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    setUserDetail(info);
    if (info === null || info === undefined) {
      navigate("/signin");
    }
    setPersonalInfoData((prev) => ({
      ...prev,
      full_name: info.fullname,
      email_address: info.email_address,
      birth_date: info.birth_date,
      phone_number: info.phone_number,
      // city: info.city,
      state: info.state_id,
      address: info.address,
      city: info.city,
    }));

    setNextOfKinInfo((prev) => ({
      ...prev,
      Next_of_Kin_Title: info.kin_title,
      Next_of_Kin_Full_Name: info.kin_fullname,
      Next_of_Kin_Phone_Number: info.kin_phone_number,
      Next_of_Kin_Email: info.kin_email,
      Next_of_Kin_Relationship: info.kin_relationship,
      Next_of_Kin_State: info.kin_state_id,
      Next_of_Kin_LGA: info.kin_lga_id,
      Next_of_Kin_Address: info.kin_address,
      Next_of_Kin_Employer_Name: info.kin_employer_name,
      kin_nin: info.kin_nin,
    }));

    setWorkInfo((prev) => ({
      ...prev,
      Employer_Name: info.employer_name,
      Employer_Address: info.employer_address,
      Employer_LGA: info.employer_city,
      Employer_Email: info.employer_email,
      Employer_State: info.employer_state_id,
      // employer_country_id: info.employer_country_id,
      Employer_Telephone_Number: info.employer_telephone_number,
      Date_Employed: info.employed_date,
      Employment_Type: info.employment_type,
      Monthly_Income: info.monthly_income,
      Employment_Letter: info?.employment_letter?.file_name,
      Company_Name: info.company_name,
    }));

    setBankInfo((prev) => ({
      ...prev,
      account_name: info.account_name,
      account_number: info.account_number,
      bank_name: info.bank_id,
    }));

    // setEmploymentLetter(info.employment_letter?.file_name);

    const states = JSON.parse(localStorage.getItem("states"));
    setStates(states);
  }, []);

  useEffect(() => {
    const generalConst = JSON.parse(localStorage.getItem("constants"));
    if (generalConst?.kin_title.length > 0) {
      setConstants(generalConst);
    }
  }, []);

  useEffect(() => {
    axios
      .get("/bank/list")
      .then((res) => {
        console.log("this is the result from banks: ", res);
        setBanksList(res.data?.data);
      })
      .catch((err) => {
        const errMsg = err.response.data.message;
        toast.error(errMsg);
      });

    // axios
    //   .get("/bank/list")
    //   .then((res) => {
    //     console.log("this is the result from banks: ", res);
    //     setBanksList(res.data?.data);
    //   })
    //   .catch((err) => {
    //     const errMsg = err.response.data.message;
    //     toast.error(errMsg);
    //   });
  }, []);

  useEffect(() => {
    if (personalInfoData.state === "") {
      return;
    } else {
      states?.filter(
        (state) =>
          state.id === Number(personalInfoData.state) &&
          setCitiesList(state.lga)
      );
    }

    if (nextOfKinInfo.Next_of_Kin_State === "") {
      return;
    } else {
      states?.filter(
        (state) =>
          state.id === Number(nextOfKinInfo.Next_of_Kin_State) &&
          setKinCitiesList(state.lga)
      );
    }

    if (workInfo.Employer_State === "") {
      return;
    } else {
      states?.filter(
        (state) =>
          state.id === Number(workInfo.Employer_State) &&
          setEmployerCitiesList(state.lga)
      );
    }
  }, [
    citiesList,
    personalInfoData.state,
    states,
    nextOfKinInfo.Next_of_Kin_State,
    workInfo.Employer_State,
  ]);

  useEffect(() => {
    setProfileFormIndex(formIndex);
  }, [formIndex]);

  const handleSaveClickProfile = async (e) => {
    e.preventDefault();
    setPersonalInfoError({
      full_name: [],
      dob: [],
      phone_number: [],
      state: [],
      city: [],
      address: [],
    });

    const validatorMethods = {
      full_name: "required|min,2",
      dob: "",
      phone_number: "min,11",
      state: "",
      city: "min,2",
      address: "min,5",
    };

    const validator = new Validator(personalInfoData, validatorMethods);
    validator.result
      .then(async () => {
        setSubmitting(true);

        const personalInfoUpdateData = new FormData();
        personalInfoUpdateData.append("fullname", personalInfoData.full_name);
        // personalInfoUpdateData.append("fullname", personalInfoData.full_name);
        personalInfoUpdateData.append("dob", personalInfoData.birth_date);
        personalInfoUpdateData.append("province", personalInfoData.state);
        personalInfoUpdateData.append("city", personalInfoData.city);
        personalInfoUpdateData.append("address", personalInfoData.address);
        personalInfoUpdateData.append("country_id", String(154));
        personalInfoUpdateData.append("avatar", avatar);

        axios
          .post("/account/profile-update", personalInfoUpdateData)
          .then((res) => {
            setSubmitting(false);
            toast.success("profile update successful");

            handleSaveClickGeneral();
          })
          .catch((err) => {
            setSubmitting(false);
            console.log("this is the error on personalInfoUpdate: ", err); //DONT'T FORGET TO COME FIX THIS PLACE BACK UP
            // const errMsg = err?.response?.data?.message;
            // toast.error(errMsg);
          });
      })
      .catch((error) => {
        setSubmitting(false);
        console.log("this is the validation error: ", error);
        setPersonalInfoError((prev) => ({
          ...prev,
          full_name: error.error.full_name,
          dob: error.error.dob,
          old_password: error.error.old_password,
          password: error.error.password,
          password_confirmation: error.error.password_confirmation,
        }));
      });
  };

  const handleSaveNextOfKinInfo = (e) => {
    e.preventDefault();
    setNextOfKinInfoError({
      Next_of_Kin_Title: [],
      Next_of_Kin_Full_Name: [],
      Next_of_Kin_Phone_Number: [],
      Next_of_Kin_Email: [],
      Next_of_Kin_Relationship: [],
      Next_of_Kin_State: [],
      Next_of_Kin_LGA: [],
      Next_of_Kin_Address: [],
      Next_of_Kin_Employer_Name: [],
    });

    const validatorMethods = {
      Next_of_Kin_Title: "required|min,2",
      Next_of_Kin_Full_Name: "required|min,2",
      Next_of_Kin_Phone_Number: "required|min,11",
      Next_of_Kin_Email: "required",
      Next_of_Kin_Relationship: "required",
      Next_of_Kin_State: "required",
      Next_of_Kin_LGA: "required",
      Next_of_Kin_Address: "required",
      Next_of_Kin_Employer_Name: "required|min,3",
      // kin_
    };

    console.log("the next of kin info: ", nextOfKinInfo);

    const validator = new Validator(nextOfKinInfo, validatorMethods);
    validator.result
      .then(async () => {
        setSubmitting(true);

        const nextOfKinInfoUpdateData = new FormData();
        nextOfKinInfoUpdateData.append(
          "kin_title",
          nextOfKinInfo.Next_of_Kin_Title
        );
        // nextOfKinInfoUpdateData.append("fullname", nextOfKinInfo.full_name);
        nextOfKinInfoUpdateData.append(
          "kin_fullname",
          nextOfKinInfo.Next_of_Kin_Full_Name
        );
        nextOfKinInfoUpdateData.append(
          "kin_address",
          nextOfKinInfo.Next_of_Kin_Address
        );
        nextOfKinInfoUpdateData.append(
          "kin_email",
          nextOfKinInfo.Next_of_Kin_Email
        );
        nextOfKinInfoUpdateData.append(
          "kin_phone_number",
          nextOfKinInfo.Next_of_Kin_Phone_Number
        );
        nextOfKinInfoUpdateData.append(
          "kin_relationship",
          nextOfKinInfo.Next_of_Kin_Relationship
        );
        nextOfKinInfoUpdateData.append(
          "kin_state_id",
          nextOfKinInfo.Next_of_Kin_State
        );
        nextOfKinInfoUpdateData.append(
          "kin_lga_id",
          nextOfKinInfo.Next_of_Kin_LGA
        );
        nextOfKinInfoUpdateData.append(
          "kin_employer_name",
          nextOfKinInfo.Next_of_Kin_Employer_Name
        );
        // nextOfKinInfoUpdateData.append("kin_nin", nextOfKinInfo.kin_nin);
        nextOfKinInfoUpdateData.append("kin_country_id", String(154));

        axios
          .post("/account/profile-update-next", nextOfKinInfoUpdateData)
          .then((res) => {
            setSubmitting(false);
            toast.success("next of kin update successful");

            handleSaveClickGeneral();
          })
          .catch((err) => {
            setSubmitting(false);
            const errMsg = err.response.data.message;
            toast.error(errMsg);
          });
      })
      .catch((error) => {
        setSubmitting(false);
        console.log("this is the validation error: ", error);
        setNextOfKinInfoError((prev) => ({
          ...prev,
          Next_of_Kin_Title: error.error?.Next_of_Kin_Title,
          Next_of_Kin_Full_Name: error.error?.Next_of_Kin_Full_Name,
          Next_of_Kin_Email: error.error?.Next_of_Kin_Email,
          Next_of_Kin_Relationship: error.error?.Next_of_Kin_Relationship,
          Next_of_Kin_LGA: error.error?.Next_of_Kin_LGA,
          Next_of_Kin_State: error.error?.Next_of_Kin_State,
          Next_of_Kin_Address: error.error?.Next_of_Kin_Address,
          Next_of_Kin_Employer_Name: error.error?.Next_of_Kin_Employer_Name,
          Next_of_Kin_Phone_Number: error.error?.Next_of_Kin_Phone_Number,
        }));
      });
  };

  const handleSaveWorkInfo = (e) => {
    e.preventDefault();
    setWorkInfoError({
      Employer_Name: [],
      Employer_Address: [],
      Employer_LGA: [],
      Employer_Email: [],
      Employer_State: [],
      // Employer_Country: [],
      Employer_Telephone_Number: [],
      Date_employed: [],
      Employment_Type: [],
      Monthly_Income: [],
      Employment_Letter: [],
      Company_Name: [],
    });

    const validatorMethods = {
      Employer_Name: "required|min,2",
      Employer_Email: "required|min,2",
      Employer_Telephone_Number: "required|min,11",
      Employer_State: "required",
      Employer_LGA: "required|min,2",
      Company_Name: "",
      Employer_Address: "required|min,5",
      // kin_nin: "min,8",
      Date_Employed: "required",
      Employment_Type: "required",
      Monthly_Income: "required",
      Employment_Letter: "required",
    };

    const validator = new Validator(workInfo, validatorMethods);
    validator.result
      .then(async () => {
        setSubmitting(true);

        const WorkInfoUpdateData = new FormData();
        WorkInfoUpdateData.append("employer_name", workInfo.Employer_Name);
        // WorkInfoUpdateData.append("fullname", workInfo.full_name);
        WorkInfoUpdateData.append(
          "employer_address",
          workInfo.Employer_Address
        );
        WorkInfoUpdateData.append("employer_city", workInfo.Employer_LGA);
        WorkInfoUpdateData.append("employer_email", workInfo.Employer_Email);
        WorkInfoUpdateData.append(
          "employer_telephone_number",
          workInfo.Employer_Telephone_Number
        );
        WorkInfoUpdateData.append("employer_state_id", workInfo.Employer_State);
        WorkInfoUpdateData.append("employed_date", workInfo.Date_Employed);

        WorkInfoUpdateData.append("employment_type", workInfo.Employment_Type);
        WorkInfoUpdateData.append("monthly_income", workInfo.Monthly_Income);
        WorkInfoUpdateData.append("company_name", workInfo.Company_Name);
        // WorkInfoUpdateData.append(
        //   "employment_letter",
        //   workInfo.employment_letter
        // );
        WorkInfoUpdateData.append(
          "employment_letter",
          workInfo.Employment_Letter
        );

        WorkInfoUpdateData.append("employer_country_id", String(154));

        // if (
        //   employmentLetter !== "" &&
        //   employmentLetter !== undefined &&
        //   employmentLetter !== null
        // ) {
        axios
          .post("/account/profile-update-employment", WorkInfoUpdateData)
          .then((res) => {
            setSubmitting(false);
            toast.success("work info update successful");

            handleSaveClickGeneral();
          })
          .catch((err) => {
            setSubmitting(false);
            const errMsg = err.response.data.message;
            toast.error(errMsg);
          });
        // } else {
        //   toast.error("Please add your employment letter");
        // }
      })
      .catch((error) => {
        setSubmitting(false);
        console.log("this is the validation error: ", error);

        setWorkInfoError((prev) => ({
          ...prev,
          Employer_Name: error.error.Employer_Name,
          Employer_Address: error.error.Employer_Address,
          Employer_LGA: error.error.Employer_LGA,
          Employer_Email: error.error.Employer_Email,
          Employer_State: error.error.Employer_State,
          // employer_country_id: error.error.employer_country_id,
          Employer_Telephone_Number: error.error.Employer_Telephone_Number,
          Date_Employed: error.error.Date_Employed,
          Employment_Type: error.error.Employment_Type,
          Monthly_Income: error.error.Monthly_Income,
          Employment_Letter: error.error.Employment_Letter,
          Company_Name: error.error.Company_Name,
        }));

        // setEmploymentLetterError(error.error.Employment_Letter);
      });
  };

  const handleSaveBankInfo = (e) => {
    e.preventDefault();
    setBankInfoError({
      bank_name: [],
      account_name: [],
      account_number: [],
      bvn: [],
    });

    const validatorMethods = {
      // employer_name: "required|min,2",
      bank_name: "required",
      account_number: "required|min,10",
      account_name: "required|min,3",
      // bvn: "min,5",
    };

    const validator = new Validator(bankInfo, validatorMethods);
    validator.result
      .then(async () => {
        setSubmitting(true);

        const bankInfoUpdateData = new FormData();
        bankInfoUpdateData.append("bank_id", bankInfo.bank_name);

        bankInfoUpdateData.append("account_number", bankInfo.account_number);
        bankInfoUpdateData.append("account_name", bankInfo.account_name);

        axios
          .post("/account/profile-update-bank", bankInfoUpdateData)
          .then((res) => {
            setSubmitting(false);
            toast.success("Bank Details update successful");

            setProfileCompleted(true);
            setTimeout(() => {
              navigate("/dashboard");
            }, 1000);

            handleSaveClickGeneral();
            // navigate("/signin");
          })
          .catch((err) => {
            setSubmitting(false);
            const errMsg = err.response.data.message;
            toast.error(errMsg);
          });
      })
      .catch((error) => {
        setSubmitting(false);
        console.log("this is the validation error: ", error);
        setBankInfoError((prev) => ({
          ...prev,
          bank_name: error.error.bank_id,
          account_name: error.error.account_name,
          account_number: error.error.account_number,
        }));
      });
  };

  const handleSaveClickPassword = async (e) => {
    e.preventDefault();
    setPasswordDataError({
      old_password: [],
      password: [],
      password_confirmation: [],
    });

    // let validatorMethods

    // if (personalInfoData.old_password.length) {}
    const validatorMethods = {
      old_password: "required",
      password: "required|min,6|has-alpha|has-num|has-sym",
      password_confirmation: "required|same-as,:password",
    };

    const validator = new Validator(passwordData, validatorMethods);
    validator.result
      .then(async () => {
        setSubmittingPassword(true);
        const passwordUpdateData = new FormData();
        passwordUpdateData.append("old_password", passwordData.old_password);
        passwordUpdateData.append("password", passwordData.password);
        passwordUpdateData.append(
          "password_confirmation",
          passwordData.password_confirmation
        );

        axios
          .post("/account/update-password", passwordUpdateData, {
            headers: {
              "content-type": "multipart/form-data",
            },
          })
          .then((res) => {
            setSubmittingPassword(false);
            toast.success("Password update successful");
          })
          .catch((err) => {
            setSubmittingPassword(false);
            const errMsg = err.response.data.message;
            toast.error(errMsg);
          });
      })
      .catch((error) => {
        setSubmittingPassword(false);
        console.log("this is the validation error: ", error);
        setPasswordDataError((prev) => ({
          ...prev,
          old_password: error.error.old_password,
          password: error.error.password,
          password_confirmation: error.error.password_confirmation,
        }));
      });
  };

  const handleTabClick = (index) => {
    // setFormIndex(index);
  };

  const doClick = () =>
    ref.current?.scrollIntoView({ block: "start", inline: "nearest" });

  const scrollTab = (index) => {
    refTab[index]?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };

  const handleSaveClickGeneral = () => {
    if (activeTab < 4) {
      setActiveTab((prev) => prev + 1);
      // navigate("/dashboard/profile#profile_form_group");
      // window.scroll(0, 0);
      // document.body.scrollTop = 0;
      console.log("this is refTab: ", refTab);
      scrollTab(activeTab);
      doClick();
      select(activeTab);
    } else {
      // setProfileCompleted(true);
      // navigate("/signin");
    }
  };

  //JAVASCRIPT TO MAKE THE TABS SCROLL ON ACTIVE
  let divE1 = document.getElementById("scroll-pane");
  let tab2 = document.getElementById("profile_form_3");

  // let divE2 = document.getElementsByClassName("tab-list");
  let selectedTrEl = undefined;

  function select(index, id) {
    console.log("the scroller func activated!!");
    console.log("this is the selected div: ", divE1);
    // let trEl = divE1.getElementsByTagName("Tab")[index];
    // let trEl = divE1.getElementsById("scroll-pane")[index];
    let trEl = document.getElementsById("profile_form_2");
    if (selectedTrEl) {
      // selectedTrEl.className = "";
    }
    selectedTrEl = trEl;
    // selectedTrEl.className = "selected";
    let scrollTo = selectedTrEl.offsetLeft;
    // divE1.scrollLeft = scrollTo;
    // divE1.scrollLeft = 20;
    // divE2.scrollLeft = 20;
    // tab2.scrollIntoView(true);
    divE1.scrollTo(200, 0);
    // divE1.scrollTo({
    //   top: 0,
    //   left: 100,
    //   behavior: "smooth",
    // });
  }

  // console.log("this is active index: ", activeTab);

  // console.log("this is the date: ", workInfo.employed_date);

  return (
    <Style>
      <div className="main-content">
        <div className="header" id="profile_form_group">
          <Header
            title="Edit Profile"
            subTitle="You may also edit your profile"
          />
        </div>
        <div className="developer-section" ref={ref}>
          <div style={{}}>
            <Tab.Group
              // manual
              selectedIndex={activeTab}
              // onChange={handleSaveClickGeneral}
              onChange={() => {
                setActiveTab(activeTab);
              }}
            >
              <Tab.List
                className="tablet-list nobar"
                id={"scroll-pane"}
                ref={refTab}

                // style={{ background: "red" }}
              >
                {/* <div className="tab-list-main" id={"scroll-pan"}> */}
                {/* <TabsContainer
                  activeTab={activeTab}
                  tabsScrollAmount={1}
                  className="border-none bg-transparent"
                > */}
                {/* <TabItem> */}
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "tablet",
                      "nobar",
                      selected ? "active-tab" : "inactive-tab"
                    )
                  }
                  // onClick={() => handleTabClick(0)}
                  // id="profile_form_0"
                >
                  <p id="profile_form_0">Personal Information</p>
                </Tab>
                {/* </TabItem> */}
                {/* <TabItem> */}{" "}
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "tablet",
                      "nobar",
                      selected ? "active-tab" : "inactive-tab"
                    )
                  }
                  // onClick={() => handleTabClick(1)}
                  // id="profile_form_1"
                >
                  <p id="profile_form_1">Next Of Kin Information</p>
                </Tab>
                {/* </TabItem> */}
                {/* <TabItem> */}
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "tablet",
                      "nobar",
                      selected ? "active-tab" : "inactive-tab"
                    )
                  }
                  // onClick={() => handleTabClick(2)}
                  // id="profile_form_2"
                >
                  <p id="profile_form_2">Work Information</p>
                </Tab>
                {/* </TabItem> */}
                {/* <TabItem> */}
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "tablet",
                      "nobar",
                      selected ? "active-tab" : "inactive-tab"
                    )
                  }
                  // onClick={() => handleTabClick(3)}
                  // id="profile_form_3"
                >
                  <p id="profile_form_3">Bank Information</p>
                </Tab>
                {/* </TabItem> */}
                {/* <Tab
                  className={({ selected }) =>
                    classNames(
                      "tablet",
                      "nobar",
                      selected ? "active-tab" : "inactive-tab"
                    )
                  }
                  onClick={() => handleTabClick(4)}
                  id="profile_form_4"
                >
                  Supporting Documents
                </Tab> */}
                {/* </TabsContainer> */}
                {/* </div> */}
              </Tab.List>
              <Tab.Panels className="mt-2">
                <Tab.Panel className={classNames("", "")}>
                  {/* PERSONAL INFORMATION */}
                  <div className="tab1-container">
                    <div className="section-finance">
                      <div className="input-container">
                        <Input
                          label="Full name"
                          star="true" // defaultValue={userDetail.fullname}
                          value={personalInfoData.full_name}
                          onChange={(e) =>
                            setPersonalInfoData((prev) => ({
                              ...prev,
                              full_name: e.target.value,
                            }))
                          }
                        />
                        <p
                          className="error message"
                          style={{ textAlign: "left", color: "#ff0000" }}
                        >
                          {/* {personalInfoError.full_name.join(", ")} */}
                        </p>
                      </div>
                      <div className="input-container">
                        <Input
                          label="Email Address"
                          star="true"
                          defaultValue={personalInfoData.email_address}
                          readOnly
                          // onChange={(e) =>
                          //   setPersonalInfoData((prev) => ({
                          //     ...prev,
                          //     email_address: e.target.value,
                          //   }))
                          // }
                        />
                      </div>

                      <div className="input-holder input-container">
                        <div className="responsive-half">
                          <Input
                            label="Birth Date"
                            type="date"
                            defaultValue={personalInfoData.birth_date}
                            onChange={(e) =>
                              setPersonalInfoData((prev) => ({
                                ...prev,
                                birth_date: e.target.value,
                              }))
                            }
                          />
                        </div>
                        <div className="responsive-half space">
                          <Input
                            label="Phone Number"
                            defaultValue={personalInfoData.phone_number}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="input-holder input-container">
                        <div className="responsive-half">
                          <label htmlFor="state" className="label">
                            State
                          </label>
                          <select
                            value={personalInfoData.state}
                            name="state"
                            className="form_input"
                            style={{
                              color: "",
                              padding: "10px",
                            }}
                            onChange={(e) => {
                              setPersonalInfoData((prev) => ({
                                ...prev,
                                state: e.target.value,
                              }));
                            }}
                          >
                            <option value="" disabled>
                              State
                            </option>
                            {states?.map((state) => (
                              <option key={state.id} value={state.id}>
                                {state.name}
                              </option>
                            ))}
                          </select>
                          {/* </div> */}
                        </div>
                        <div className="responsive-half">
                          {/* <Input
                            label="City"
                            defaultValue={personalInfoData.city}
                          /> */}
                          <div className="input_label_container half-input">
                            <label htmlFor="city" className="label">
                              City
                            </label>
                            <select
                              value={personalInfoData.city}
                              name="city"
                              className="form_input"
                              style={{
                                color: "",
                                padding: "10px",
                              }}
                              onChange={(e) => {
                                setPersonalInfoData((prev) => ({
                                  ...prev,
                                  city: e.target.value,
                                }));
                              }}
                            >
                              <option value="" disabled>
                                City
                              </option>
                              {citiesList?.map((city) => (
                                <option
                                  key={city.id}
                                  value={city.id}
                                  // style={{ paddingBottom: "10px" }}
                                >
                                  {city.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="input-container">
                        <Input
                          textarea="true"
                          label="Address"
                          row={3}
                          defaultValue={personalInfoData.address}
                          onChange={(e) =>
                            setPersonalInfoData((prev) => ({
                              ...prev,
                              address: e.target.value,
                            }))
                          }
                        />
                      </div>
                      <div>
                        <button
                          onClick={(e) => handleSaveClickProfile(e)}
                          className="save"
                        >
                          {submitting && (
                            <Circles
                              height="30"
                              width="30"
                              color="#fff"
                              ariaLabel="circles-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                              visible={true}
                            />
                          )}{" "}
                          Save Changes
                        </button>
                      </div>
                    </div>

                    <div className="profile-section">
                      <div className="one">
                        <ImageUpload
                          type="photoImage"
                          acceptType={"image/*"}
                          avatar={avatar}
                          setAvatar={setAvatar}
                          // setProfilePicture={setProfilePicture}
                        >
                          <div className="round">
                            <img alt="" src={addImage} className="add-image" />
                            <p className="photo">Photo</p>
                            {/* <UploadFile acceptType={"image/*"} /> */}
                          </div>
                        </ImageUpload>
                      </div>
                      <div className="password-section">
                        <div className="input-container">
                          <Input
                            label="Current Password"
                            placeholder="****************"
                            password="true"
                            itype="password"
                            value={passwordData.old_password}
                            onChange={(e) => {
                              setPasswordData((prev) => ({
                                ...prev,
                                old_password: e.target.value,
                              }));
                            }}
                          />
                          <p
                            className="error message"
                            style={{ textAlign: "left" }}
                          >
                            {/* {passwordDataError.old_password.join(", ")} */}
                          </p>
                        </div>
                        <div className="input-container">
                          <Input
                            label="New Password"
                            itype="password"
                            password="true"
                            placeholder="****************"
                            value={passwordData.password}
                            onChange={(e) => {
                              setPasswordData((prev) => ({
                                ...prev,
                                password: e.target.value,
                              }));
                            }}
                          />
                          <p
                            className="error message"
                            style={{ textAlign: "left" }}
                          >
                            {/* {passwordDataError.password.join(", ")} */}
                          </p>
                        </div>
                        <div className="input-container">
                          <Input
                            label="Confirm New Password"
                            itype="password"
                            placeholder="****************"
                            password="true"
                            noeye="true"
                            value={passwordData.password_confirmation}
                            onChange={(e) => {
                              setPasswordData((prev) => ({
                                ...prev,
                                password_confirmation: e.target.value,
                              }));
                            }}
                          />
                          <p
                            className="error message"
                            style={{ textAlign: "left" }}
                          >
                            {/* {passwordDataError.password_confirmation.join(", ")} */}
                          </p>
                        </div>
                      </div>
                      <div>
                        <button
                          onClick={(e) => handleSaveClickPassword(e)}
                          className="save"
                        >
                          {submittingPassword && (
                            <Circles
                              height="30"
                              width="30"
                              color="#fff"
                              ariaLabel="circles-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                              visible={true}
                            />
                          )}{" "}
                          Save password
                        </button>
                      </div>
                    </div>
                  </div>
                </Tab.Panel>
                <Tab.Panel className={classNames("", "")}>
                  {/* next of kin section */}
                  <div className="tab2-container-full">
                    <div className="section-finance full">
                      <div>
                        <SelectInput
                          label={"Next Of Kin Title"}
                          options={constants?.kin_title}
                          inputFor={"nextOfKin_title"}
                          value={nextOfKinInfo.Next_of_Kin_Title}
                          valueChange={(e) => {
                            setNextOfKinInfo((prev) => ({
                              ...prev,
                              Next_of_Kin_Title: e.target.value,
                            }));
                          }}
                          errorMsg={nextOfKinInfoError?.Next_of_Kin_Title}
                        />
                      </div>

                      <div>
                        <InputMain
                          label={"Next Of Kin Full Name"}
                          inputFor={"profile_nextOfKin_fullName"}
                          type={"text"}
                          placeholder={"Full Name"}
                          value={nextOfKinInfo.Next_of_Kin_Full_Name}
                          valueChange={(e) =>
                            setNextOfKinInfo((prev) => ({
                              ...prev,
                              Next_of_Kin_Full_Name: e.target.value,
                            }))
                          }
                          errorMsg={nextOfKinInfoError?.Next_of_Kin_Full_Name}
                        />
                      </div>

                      <div>
                        <InputMain
                          label={"Next Of Kin Email Address"}
                          inputFor={"profile_nextOfKin_email"}
                          type={"email"}
                          placeholder={"Email"}
                          value={nextOfKinInfo.Next_of_Kin_Email}
                          valueChange={(e) =>
                            setNextOfKinInfo((prev) => ({
                              ...prev,
                              Next_of_Kin_Email: e.target.value,
                            }))
                          }
                          errorMsg={nextOfKinInfoError?.Next_of_Kin_Email}
                        />
                      </div>

                      <div className="input-container">
                        <SelectInput
                          label={"Relationship"}
                          options={constants.kin_relationship}
                          inputFor={"profile_nextOfKin_relationship"}
                          // placeholder={"state"}
                          value={nextOfKinInfo.Next_of_Kin_Relationship}
                          valueChange={(e) => {
                            setNextOfKinInfo((prev) => ({
                              ...prev,
                              Next_of_Kin_Relationship: e.target.value,
                            }));
                          }}
                          errorMsg={
                            nextOfKinInfoError?.Next_of_Kin_Relationship
                          }
                        />
                      </div>

                      <div className="input-holder input-container">
                        <div className="responsive-half">
                          <InputMain
                            label={"Next Of Kin Employer Name"}
                            inputFor={"profile_nextOfKin_employer_name"}
                            type={"text"}
                            placeholder={"Name"}
                            value={nextOfKinInfo.Next_of_Kin_Employer_Name}
                            valueChange={(e) =>
                              setNextOfKinInfo((prev) => ({
                                ...prev,
                                Next_of_Kin_Employer_Name: e.target.value,
                              }))
                            }
                            errorMsg={
                              nextOfKinInfoError.Next_of_Kin_Employer_Name
                            }
                          />
                        </div>

                        <div className="responsive-half space">
                          <InputMain
                            label={"Next of Kin Phone Number"}
                            inputFor={"profile_nextOfKin_phone_name"}
                            type={"text"}
                            placeholder={"Name"}
                            value={nextOfKinInfo.Next_of_Kin_Phone_Number}
                            valueChange={(e) =>
                              setNextOfKinInfo((prev) => ({
                                ...prev,
                                Next_of_Kin_Phone_Number: e.target.value,
                              }))
                            }
                            errorMsg={
                              nextOfKinInfoError.Next_of_Kin_Phone_Number
                            }
                          />
                        </div>
                      </div>
                      <div className="input-holder input-container">
                        <div className="responsive-half">
                          <SelectInput
                            label={"Next of Kin State"}
                            options={states}
                            inputFor={"profile_nextOfKin_state"}
                            // placeholder={"state"}
                            value={nextOfKinInfo.Next_of_Kin_State}
                            valueChange={(e) => {
                              setNextOfKinInfo((prev) => ({
                                ...prev,
                                Next_of_Kin_State: e.target.value,
                              }));
                            }}
                            errorMsg={nextOfKinInfoError.Next_of_Kin_State}
                          />
                        </div>
                        <div className="responsive-half">
                          <div className="input_label_container half-input">
                            <SelectInput
                              label={"Next of Kin LGA"}
                              options={kinCitiesList}
                              inputFor={"profile_nextOfKin_city"}
                              // placeholder={"state"}
                              value={nextOfKinInfo.Next_of_Kin_LGA}
                              valueChange={(e) => {
                                setNextOfKinInfo((prev) => ({
                                  ...prev,
                                  Next_of_Kin_LGA: e.target.value,
                                }));
                              }}
                              errorMsg={nextOfKinInfoError.Next_of_Kin_LGA}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="input-container">
                        <InputMain
                          textAreaInput
                          label={"Next Of Kin Address"}
                          inputFor={"profile_nextOfKin_address"}
                          type={"text"}
                          placeholder={"Address"}
                          value={nextOfKinInfo.Next_of_Kin_Address}
                          valueChange={(e) =>
                            setNextOfKinInfo((prev) => ({
                              ...prev,
                              Next_of_Kin_Address: e.target.value,
                            }))
                          }
                          errorMsg={nextOfKinInfoError.Next_of_Kin_Address}
                        />
                      </div>

                      <div>
                        <button
                          onClick={(e) => handleSaveNextOfKinInfo(e)}
                          className="save"
                        >
                          {submitting && (
                            <Circles
                              height="30"
                              width="30"
                              color="#fff"
                              ariaLabel="circles-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                              visible={true}
                            />
                          )}{" "}
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </Tab.Panel>
                <Tab.Panel className={classNames("", "")}>
                  {/* work information panel */}
                  <div className="tab2-container-full">
                    <div className="section-finance full">
                      <div className="input-holder input-container">
                        <div className="responsive-two-third">
                          <InputMain
                            label={"Employer name"}
                            inputFor={"profile_employer_name"}
                            type={"text"}
                            placeholder={"Name"}
                            value={workInfo.Employer_Name}
                            valueChange={(e) =>
                              setWorkInfo((prev) => ({
                                ...prev,
                                Employer_Name: e.target.value,
                              }))
                            }
                            errorMsg={workInfoError?.Employer_Name}
                          />
                        </div>
                        <div className="responsive-one-third space">
                          <SelectInput
                            label={"Employer State"}
                            options={states}
                            inputFor={"workInfo_employer_state"}
                            value={workInfo.Employer_State}
                            valueChange={(e) => {
                              setWorkInfo((prev) => ({
                                ...prev,
                                Employer_State: e.target.value,
                              }));
                            }}
                            errorMsg={workInfoError?.Employer_State}
                          />
                        </div>
                      </div>

                      <div className="input-holder input-container">
                        <div className="responsive-two-third">
                          <InputMain
                            label={"Employer Email Address"}
                            inputFor={"profile_employer_email"}
                            type={"text"}
                            placeholder={"Email"}
                            value={workInfo.Employer_Email}
                            valueChange={(e) =>
                              setWorkInfo((prev) => ({
                                ...prev,
                                Employer_Email: e.target.value,
                              }))
                            }
                            errorMsg={workInfoError?.Employer_Email}
                          />
                        </div>
                        <div className="responsive-one-third space">
                          <SelectInput
                            label={"Employer City"}
                            options={employerCitiesList}
                            inputFor={"workInfo_employer_city"}
                            value={workInfo.Employer_LGA}
                            valueChange={(e) => {
                              setWorkInfo((prev) => ({
                                ...prev,
                                Employer_LGA: e.target.value,
                              }));
                            }}
                            errorMsg={workInfoError?.Employer_LGA}
                          />
                        </div>
                      </div>

                      <div className="input-holder input-container">
                        <div className="responsive-two-third">
                          <InputMain
                            label={"Company Name (Optional)"}
                            inputFor={"workInfo_company_name"}
                            type={"text"}
                            placeholder={"Company"}
                            value={workInfo.Company_Name}
                            valueChange={(e) =>
                              setWorkInfo((prev) => ({
                                ...prev,
                                Company_Name: e.target.value,
                              }))
                            }
                            errorMsg={workInfoError?.Company_Name}
                          />
                        </div>
                        <div className="responsive-one-third space">
                          <InputMain
                            label={"Employer's Phone Number"}
                            inputFor={"workInfo_phone_number"}
                            type={"text"}
                            placeholder={"Phone Number"}
                            value={workInfo.Employer_Telephone_Number}
                            valueChange={(e) =>
                              setWorkInfo((prev) => ({
                                ...prev,
                                Employer_Telephone_Number: e.target.value,
                              }))
                            }
                            errorMsg={workInfoError?.Employer_Telephone_Number}
                          />
                        </div>
                      </div>

                      <div className="input-holder input-container">
                        <div className="responsive-two-third">
                          <SelectInput
                            label={"Employment Type"}
                            options={constants.employment_type}
                            inputFor={"employment_type"}
                            value={workInfo.Employment_Type}
                            valueChange={(e) => {
                              setWorkInfo((prev) => ({
                                ...prev,
                                Employment_Type: e.target.value,
                              }));
                            }}
                            errorMsg={workInfoError?.Employment_Type}
                          />
                        </div>
                        <div className="responsive-one-third space">
                          <InputMain
                            label={"Date Employed"}
                            inputFor={"workInfo_employed_date"}
                            type={"date"}
                            placeholder={"01-10-2000"}
                            value={workInfo.Date_Employed}
                            valueChange={(e) =>
                              setWorkInfo((prev) => ({
                                ...prev,
                                Date_Employed: e.target.value,
                              }))
                            }
                            errorMsg={workInfoError?.Date_Employed}
                          />
                        </div>
                      </div>

                      <div className="input-holder input-container">
                        <div className="responsive-two-third">
                          <InputMain
                            label={"Employer Address"}
                            inputFor={"workInfo_monthly_income"}
                            type={"text"}
                            placeholder={"Your employer address"}
                            value={workInfo.Employer_Address}
                            valueChange={(e) =>
                              setWorkInfo((prev) => ({
                                ...prev,
                                Employer_Address: e.target.value,
                              }))
                            }
                            errorMsg={workInfoError?.Employer_Address}
                          />
                        </div>

                        <div className="responsive-one-third space">
                          <InputMain
                            label={"Monthly Income"}
                            inputFor={"workInfo_monthly_income"}
                            type={"text"}
                            placeholder={"Your monthly income"}
                            value={workInfo.Monthly_Income}
                            valueChange={(e) =>
                              setWorkInfo((prev) => ({
                                ...prev,
                                Monthly_Income: e.target.value,
                              }))
                            }
                            errorMsg={workInfoError?.Monthly_Income}
                          />
                        </div>
                      </div>
                      <div className="input-container">
                        <BlankInput
                          label={"Employment Letter"}
                          inputFor={"employment_letter"}
                          // value={workInfo.employment_letter}
                          errorMsg={workInfoError.Employment_Letter}
                        >
                          {workInfo.Employment_Letter !== "" &&
                          workInfo.Employment_Letter !== undefined ? (
                            <div className="employment_letter_name-display">
                              <p>
                                {/* {employmentLetter || workInfo.Employment_Letter} */}
                                {workInfo.Employment_Letter}
                              </p>
                              <button
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                onClick={() => {
                                  // setEmploymentLetter("");
                                  // setEmploymentLetterError([]);

                                  setWorkInfo((prev) => ({
                                    ...prev,
                                    Employment_Letter: "",
                                  }));
                                }}
                              >
                                <MdCancel />
                              </button>
                            </div>
                          ) : (
                            <ImageUpload
                              type="fileUpload"
                              acceptType={"file"}
                              // fileToUpload={employmentLetter}
                              fileToUpload={workInfo.Employment_Letter}
                              // setFileToUpload={setEmploymentLetter}
                              setFileToUpload={(file) =>
                                setWorkInfo((prev) => ({
                                  ...prev,
                                  Employment_Letter: file,
                                }))
                              }
                            />
                          )}

                          {/* </div> */}
                        </BlankInput>
                      </div>
                      <div className="button_container">
                        <button
                          onClick={(e) => handleSaveWorkInfo(e)}
                          className="save right"
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </Tab.Panel>
                <Tab.Panel className={classNames("", "")}>
                  {/* BANK INFORMATION */}
                  <div className="tab2-container-full">
                    <div className="section-finance bank_info full">
                      <div style={{ flex: "1" }}>
                        <div style={{ marginBottom: "30px" }}>
                          <SelectInput
                            label={"Bank Name"}
                            options={banksList}
                            inputFor={"profile_bank-id"}
                            value={bankInfo.bank_name}
                            valueChange={(e) => {
                              setBankInfo((prev) => ({
                                ...prev,
                                bank_name: e.target.value,
                              }));
                            }}
                            errorMsg={bankInfoError.bank_name}
                          />
                        </div>

                        <div style={{ marginBottom: "30px" }}>
                          <InputMain
                            label={"Account Name"}
                            inputFor={"profile_account-name"}
                            type={"text"}
                            placeholder={"Name"}
                            value={bankInfo.account_name}
                            valueChange={(e) =>
                              setBankInfo((prev) => ({
                                ...prev,
                                account_name: e.target.value,
                              }))
                            }
                            errorMsg={bankInfoError.account_name}
                          />
                        </div>

                        <div style={{ marginBottom: "30px" }}>
                          <InputMain
                            label={"Bank Account Number"}
                            inputFor={"profile_account-number"}
                            type={"text"}
                            placeholder={"Account number"}
                            value={bankInfo.account_number}
                            valueChange={(e) => {
                              setBankInfo((prev) => ({
                                ...prev,
                                account_number: e.target.value,
                              }));
                            }}
                            errorMsg={bankInfoError.account_number}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <input type={"checkbox"} />{" "}
                          <p
                            style={{ marginBottom: "0px", paddingLeft: "16px" }}
                          >
                            Allow Direct Debit of my account
                          </p>
                        </div>

                        <div className="button_container mobile_only">
                          <button
                            onClick={(e) => handleSaveBankInfo(e)}
                            className="save right"
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                      <div className="bank_info_status_container">
                        <h4>Hello!!!</h4>
                        <div className="button_container">
                          <button
                            onClick={(e) => handleSaveBankInfo(e)}
                            className="save right"
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Panel>
                {/* //supporting document will go here */}
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </div>
    </Style>
  );
};

// Profile.layout = DashboardLayout;

export default Profile;

//  <Tab.Panel className={classNames("", "")}>
//    {/* SUPPORTING DOCUMENTS */}
//    <div className="tab1-container">
//      <div className="section-finance full">
//        <div className="input-holder input-container">
//          <div className="responsive-half">
//            <p>Employment Letter</p>
//            <div className="form_input" style={{ cursor: "pointer" }}>
//              <UploadFile
//                placeholder={"Upload"}
//                // setHandleDocsSubmission={setHandleDocsSubmission}
//                handleDocsSubmission={handleDocsSubmission}
//                uploadRef={uploadRef}
//              />
//            </div>
//          </div>
//          <div className="responsive-half space">
//            <p>Government Recognised ID Card</p>
//            <div className="form_input" style={{ cursor: "pointer" }}>
//              <UploadFile
//                placeholder={"Upload"}
//                // setHandleDocsSubmission={setHandleDocsSubmission}
//                handleDocsSubmission={handleDocsSubmission}
//                uploadRef={uploadRef}
//              />
//            </div>
//          </div>
//        </div>

//        <div className="input-holder input-container">
//          <div className="responsive-half">
//            {/* <Input
//                             label="Account Statement"
//                             // defaultValue={userDetail.fullname}
//                             value={"Upload"}
//                             onChange={(e) =>
//                               setPersonalInfoData((prev) => ({
//                                 ...prev,
//                                 full_name: e.target.value,
//                               }))
//                             }
//                           /> */}
//            <p>Account Statement</p>
//            <div className="form_input" style={{ cursor: "pointer" }}>
//              <UploadFile
//                placeholder={"Upload"}
//                // setHandleDocsSubmission={setHandleDocsSubmission}
//                handleDocsSubmission={handleDocsSubmission}
//                uploadRef={uploadRef}
//              />
//            </div>
//          </div>
//          <div className="responsive-half space">
//            <p>Proof Of Address</p>
//            <div className="form_input" style={{ cursor: "pointer" }}>
//              <UploadFile
//                placeholder={"Upload"}
//                // setHandleDocsSubmission={setHandleDocsSubmission}
//                handleDocsSubmission={handleDocsSubmission}
//                uploadRef={uploadRef}
//              />
//            </div>
//          </div>
//        </div>
//        <div className="button_container">
//          <button
//            onClick={(e) => {
//              console.log("docs submission clicked!!!");
//              handleDocsSubmission();
//              handleSaveClickProfile(e);
//            }}
//            className="save right"
//          >
//            Save Changes
//          </button>
//        </div>
//      </div>
//    </div>
//  </Tab.Panel>;
