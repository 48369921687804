import styled from "styled-components";
import media from "styled-media-query";

const Button = styled.button`
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 5px;
  border: none;
  /* font-family: "ApercuProMediumNormal"; */
  font-size: ${(props) => (props.open_acct ? "12px" : "14px")};
  line-height: 100%;

  background: ${(props) => (props.bg ? props.bg : "none")};
  color: ${(props) => (props.clr ? props.clr : "#000")};

  ${media.greaterThan("1024px")`
    padding: 16px 24px;
    gap: 6px;
    font-size: 18px;

    `}/* ${media.greaterThan("1440px")`
    padding: 16px 24px;
    gap: 6px;
    font-size: 18px;

    `} */

    /* .black_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    white-space: nowrap;
    margin-left: 96px;
  } */
`;

export default Button;
