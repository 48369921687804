/* eslint-disable no-undef */
import { useEffect, useRef } from "react";

export default function useOutsideClick(callback) {
  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback(ref, event);
        // console.log(event.target);
        // console.log(ref.current);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [callback]);

  return [ref];
}
