import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import { toast } from "react-toastify";
import axios from "axios";
import { TiArrowBack } from "react-icons/ti";

import messageIcon from "../Assets/images/message_icon.svg";
import phoneIcon from "../Assets/images/phone_icon.svg";
import logoAnother from "../Assets/images/logo_another.svg";

import Button from "../components/elements/Button";
import styled from "styled-components";
import media from "styled-media-query";
import ImagePartContent from "../components/blocks/Authentication/ImagePartContent";
import {
  ExtraTexts,
  OverallAuthContainer,
  FormPart,
} from "../components/blocks/Authentication";

import LogoAbsolutePlacer from "../components/elements/LogoAbsolutePlacer";
import LogoContainerOther from "../components/elements/LogoContainerOther";
import Validator from "../utils/Validator";

const ResetViaEmailBox = styled.div`
  background: #ffffff;
  border: 2px solid rgba(41, 128, 151, 0.7);
  box-shadow: 0px 10px 15px rgba(41, 128, 151, 0.1);
  border-radius: 14px;
  padding: 5%;
  ${media.greaterThan("768px")`
  
  `};
  ${media.greaterThan("1024px")`

  `};
  ${media.greaterThan("1440px")`

  `};

  /* #contain {
    ::placeholder {
      color: "#C4C4C4";
    }
  } */
`;

const NeededFlexed = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  .icon_overall {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: #f8ad15;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .texts {
    display: flex;
    flex-direction: column;
    gap: 11px;
    justify-content: space-between;

    .title {
      font-family: "ApercuProMediumNormal";
      font-size: 18px;
      line-height: 22px;
      color: #000000;
    }

    .subtitle {
      font-size: 14px;
      line-height: 17px;
      color: #c4c4c4;
    }
  }
`;

const ExtraBox = styled.div`
  width: 100%;
  ${media.greaterThan("768px")`
    width: 80%;
    margin: 0 auto;

  `};
  ${media.greaterThan("1024px")`
      max-width: 400px;
      /* padding-top: 90px; */
  `};
  ${media.greaterThan("1440px")`
    max-width: 552px;
  `};
`;

const ResetViaSms = styled.div`
  border: 1.3px solid rgba(196, 196, 196, 0.5);
  filter: drop-shadow(0px 10px 15px rgba(151, 151, 151, 0.08));
  border-radius: 14px;
  padding: 5%;
`;

const IconContainer = styled.div`
  position: relative;
  width: 20px;
  height: 18px;
`;

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);

  // const [email, setEmail] = useState("");
  const [data, setData] = useState({
    email: "",
  });

  const [dataError, setDataError] = useState({
    email: [],
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDataError({ email: [] });

    const validatorMethods = {
      email: "required|email",
    };

    const validator = new Validator(data, validatorMethods);
    validator.result
      .then(() => {
        setSubmitting(true);
        const formData = new FormData();
        formData.append("email", data.email);

        axios
          .post("/password-reset-request", formData)
          .then((res) => {
            setSubmitting(false);
            localStorage.setItem("email_reset", data.email);
            navigate("/reset-password");
          })
          .catch((err) => {
            console.log("this is the err from forgot-password: ", err);
            setSubmitting(false);
            toast.error(err?.response?.message);
          });
      })
      .catch((error) => {
        setSubmitting(false);
        setDataError(error.error);
      });
  };

  return (
    <OverallAuthContainer>
      <div style={{ position: "relative" }} className="form_part">
        <LogoAbsolutePlacer>
          <LogoContainerOther onClick={() => navigate.push("/")}>
            <img
              src={logoAnother}
              alt=""
              style={{
                // position: "absolute",
                // objectFit: "contain",
                maxInlineSize: "100%",
                blockSize: "auto",
              }}
            />
          </LogoContainerOther>
        </LogoAbsolutePlacer>
        <FormPart onSubmit={handleSubmit}>
          <h2 style={{ textAlign: "center", color: "#000" }}>
            Forgot Password
          </h2>
          <p className="subheading">
            Please select option to send link reset password
          </p>

          <ResetViaEmailBox>
            <NeededFlexed>
              <div className="icon_overall">
                <IconContainer>
                  <img
                    src={messageIcon}
                    alt=""
                    style={{
                      // position: "absolute",
                      // objectFit: "contain",
                      maxInlineSize: "100%",
                      blockSize: "auto",
                    }}
                  />
                </IconContainer>
              </div>
              <div className="texts">
                <p className="title">Reset via Email</p>
                <p className="subtitle">
                  We will send a link to reset your password
                </p>
              </div>
            </NeededFlexed>
            <div id="contain" className="input_label_container">
              <label
                style={{ position: "absolute", left: "-999999px" }}
                htmlFor="email"
              >
                Email
              </label>
              <input
                style={{
                  background: "#F5F7FB",
                }}
                type={"text"}
                id="email"
                placeholder="Email Address"
                className="form_input"
                // value={email}
                // onChange={(e) => setEmail(e.target.value)}
                value={data.email}
                onChange={(e) =>
                  setData((prev) => ({
                    ...prev,
                    email: e.target.value,
                  }))
                }
              />
              <p className="error message" style={{ textAlign: "left" }}>
                {dataError.email.join(", ")}
              </p>
            </div>
          </ResetViaEmailBox>

          {/* <ResetViaSms>
            <NeededFlexed>
              <div className="icon_overall">
                <IconContainer>
                  <Image src={phoneIcon} alt="" fill />
                </IconContainer>
              </div>
              <div className="texts">
                <p className="title">Reset via SMS</p>
                <p className="subtitle">
                  We will send a link to reset your phone
                </p>
              </div>
            </NeededFlexed>
          </ResetViaSms> */}

          <Button
            type="submit"
            bg={"#F8AD15"}
            clr="#FFFFFF"
            style={{
              width: "100%",
              justifyContent: "center",
              height: "65px",
            }}
          >
            {submitting && (
              <Circles
                height="30"
                width="30"
                color="#fff"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            )}
            Send Link Reset Password
          </Button>
        </FormPart>
        <ExtraBox>
          <Link to={"/signin"}>
            <ExtraTexts
              style={{
                marginTop: "20px",
                display: "flex",
                alignItems: "center",
                gap: "5px",
                alignSelf: "flex-start",
                // background: "#ff0000",
                // position: "absolute",
                // left: "0",
              }}
            >
              <TiArrowBack size={24} />
              <span>Back to sign in</span>
            </ExtraTexts>
          </Link>
        </ExtraBox>
      </div>
      <div className="picture_part">
        <ImagePartContent />
      </div>
    </OverallAuthContainer>
  );
};

export default ForgotPassword;

// ForgotPassword.getLayout = function PageLayout(page) {
//   return <>{page}</>;
// };
