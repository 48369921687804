import App from "../App";
import BlankLayout from "../components/blocks/Layouts/BlankLayout";
import GeneralLayout from "../components/blocks/Layouts/GeneralLayout";
import HeaderOnlyLayout from "../components/blocks/Layouts/HeaderOnlyLayout";
import Blogs from "../pages/Blogs";
import BlogContent from "../pages/Blogs/BlogContent";
import Entrepreneurs from "../pages/Entrepreneurs";
import FinanceWithLandlordstech from "../pages/FinanceWithLandlordsTech";
import ForgotPassword from "../pages/ForgotPassword";
import Home from "../pages/Home";
import KycVerification from "../pages/KycVerification";
import Login from "../pages/Login";
import Privacy from "../pages/Privacy";
import Register from "../pages/Register";
import ResetPassword from "../pages/ResetPassword";
import Startups from "../pages/Startups";
import Support from "../pages/Support";
import WhyUs from "../pages/WhyUs";
import DashboardLayout from "../components/layout/dashboardLayout";
import Index from "../pages/dashboard";
import Developer from "../pages/dashboard/developer";
import Profile from "../pages/dashboard/profile";
import Rental from "../pages/dashboard/rental-loan";
import IdentityVerification from "../pages/verify-identity";
import About from "../pages/About";
import FooterOnlyLayout from "../components/blocks/Layouts/FooterOnlyLayout";
import Developers from "../pages/developers";
import Quickstart from "../pages/developers/quickstart";
import CustomerVerification from "../pages/developers/customer-verification";
import Payment from "../pages/dashboard/payment";
import Invoice from "../pages/dashboard/invoice";
import Properties from "../pages/dashboard/properties";
import UploadProperty from "../pages/dashboard/upload-property";
import Landlords from "../pages/Landlords";
import DateRange from "../utils/DateRange";
import VerifyEmail from "../pages/VerifyEmail";
import Rough from "../pages/Rough";
// import WhyUs from "../pages/WhyUs";

const Navigation = [
  {
    path: "/",
    element: <GeneralLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },

      {
        path: "/home",
        element: <Home />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/why-us",
        element: <WhyUs />,
      },
      {
        path: "/support",
        element: <Support />,
      },
      {
        path: "/blogs",
        element: <Blogs />,
      },
      {
        path: "blogs/:blogId",
        element: <BlogContent />,
      },
      {
        path: "/startups",
        element: <Startups />,
      },
      {
        path: "/entrepreneurs",
        element: <Entrepreneurs />,
      },
      {
        path: "/landlords",
        element: <Landlords />,
      },
      {
        path: "/finance-with-landlordstech",
        element: <FinanceWithLandlordstech />,
      },
      // {
      //   path: "/kyc-verification",
      //   element: <KycVerification />,
      // },
    ],
  },
  {
    element: <BlankLayout />,
    children: [
      {
        path: "/signin",
        element: <Login />,
      },
      {
        path: "/verify-email",
        element: <VerifyEmail />,
      },
      {
        path: "/register",
        element: <Register />,
      },

      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/reset-password",
        element: <ResetPassword />,
      },
      {
        path: "/verify-identity",
        element: <IdentityVerification />,
      },
      // {
      //   path: "/rough",
      //   element: <DateRange />,
      // },
    ],
  },

  {
    element: <HeaderOnlyLayout />,
    children: [
      {
        path: "/privacy",
        element: <Privacy />,
      },
      {
        path: "/rough",
        element: <Rough />,
      },
    ],
  },
  {
    element: <FooterOnlyLayout />,
    children: [
      {
        path: "/developers",
        element: <Developers />,
      },
      {
        path: "/developers/quickstart",
        element: <Quickstart />,
      },
      {
        path: "/developers/customer-verification",
        element: <CustomerVerification />,
      },
    ],
  },

  {
    path: "/dashboard",
    element: <DashboardLayout />,
    // element: <Index />,
    children: [
      {
        path: "/dashboard",
        element: <Index />,
      },
      {
        path: "/dashboard/developer",
        element: <Developer />,
      },
      {
        path: "/dashboard/profile",
        element: <Profile />,
      },
      {
        path: "/dashboard/rental-loan",
        element: <Rental />,
      },
      {
        path: "/dashboard/payment",
        element: <Payment />,
      },
      {
        path: "/dashboard/repayment",
        element: <Payment />,
      },
      {
        path: "/dashboard/invoice",
        element: <Invoice />,
      },
      {
        path: "/dashboard/properties",
        element: <Properties />,
      },
      {
        path: "/dashboard/upload-property",
        element: <UploadProperty />,
      },
    ],
  },
];

export default Navigation;
