import styled from "styled-components";
import media from "styled-media-query";

const TitleText = styled.div`
  font-family: "Tiempos Headline";
  font-size: 20px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.03em;
  color: #1a191e;
  ${media.greaterThan("768px")`
    font-size: 24px;
  `};
  ${media.greaterThan("1024px")`
    /* font-size: 26px; */
  `};
  ${media.greaterThan("1440px")`
    font-size: 30px;
  `};
`;

export default TitleText;
