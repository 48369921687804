import styled from "styled-components";
import media from "styled-media-query";

const SubTitle = styled.div`
  font-family: "ApercuProBoldNormal";
  font-size: 18px;
  line-height: 310%;
  letter-spacing: -0.03em;
  color: #000000;
  ${media.greaterThan("768px")`
    font-size: 20px;
  `};
  ${media.greaterThan("1024px")`
  font-size: 22px;
  `};
  ${media.greaterThan("1440px")`
    font-size: 25px;
  `};

  /* span {
    color: #fbbd29;
  } */
`;

export default SubTitle;
