import styled from "styled-components";
import media from "styled-media-query";
import AuthenticationNav from "../../components/blocks/developer/AuthenticationNav";
import BvnServiceNav from "../../components/blocks/developer/customer_verification/BvnServiceNav";
import DriversLicenseNav from "../../components/blocks/developer/customer_verification/DriversLicenseNav";
import EncryptionNav from "../../components/blocks/developer/EncryptionNav";
import QuickstartNav from "../../components/blocks/developer/QuickstartNav";
import WebhooksNav from "../../components/blocks/developer/WebhooksNav";
import Footer from "../../components/blocks/Footer";
import SideMenuHolderContainer from "../../components/blocks/SideMenuHolderContainer";
import DynamicScrollToTop from "../../utils/DynamicScrollToTop";

const Styles = styled.section`
  margin-bottom: 200px;
  ${media.greaterThan("768px")`
  margin-bottom:150px;

  `};
  ${media.greaterThan("1024px")`

  `};
  ${media.greaterThan("1440px")`

  `};
`;
const CustomerVerification = () => {
  const navs = [
    "BVN Service",
    "NIN Verification Services",
    "International Passport",
    "Driver's License",
    "Errors",
    "Encryption",
  ];

  const navContents = [
    <BvnServiceNav key={0} />,
    "",
    "",
    <DriversLicenseNav key={3} />,
    "",
    <EncryptionNav key={5} />,
  ];

  return (
    <Styles>
      <DynamicScrollToTop />
      <SideMenuHolderContainer navs={navs} navContents={navContents} />
    </Styles>
  );
};

export default CustomerVerification;
