import React from "react";
import { useEffect } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { Outlet } from "react-router-dom";

import Header from "./Header";
import Sidebar from "./Sidebar";
import axios from "axios";

const LayoutStyle = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  .side-bar {
    min-width: 236px;
    max-width: 256px;
    width: 256px;
    background: white;

    overflow-y: scroll; //medium
    ${media.lessThan("1024px")`
      display: none;
    `}
  }
  .right-side {
    flex: 1;
    height: 100%;
    position: relative;
    /* ${media.greaterThan("medium")`
  max-width: calc(100vw - 236px);
  `} */
    /* ${media.lessThan("medium")`
  max-width: none;
  `} */
  }
  .header {
  }
  .content {
    height: 100%;
  }
`;

const DashboardLayout = ({ children }) => {
  useEffect(() => {
    axios
      .get("state/list")
      .then((res) => {
        localStorage.setItem("states", JSON.stringify(res.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <LayoutStyle>
      <div className="side-bar nobar">
        <Sidebar />
      </div>
      <div className="right-side">
        <div className="content">
          <Outlet />
        </div>
      </div>
    </LayoutStyle>
  );
};

export default DashboardLayout;
