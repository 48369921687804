import React, { useState } from "react";
import styled from "styled-components";
import media from "styled-media-query";

const Style = styled.div`
  ${(props) =>
    props.password === true &&
    `width: 90%;
    `}
  ${media.lessThan("medium")`
    width: 100% !important;
    `}


  .text-input {
    height: 65px;
    border: 1.3px solid rgba(196, 196, 196, 0.5);
    border-radius: 14px;
    padding: 22px 24px;
    margin-bottom: 2px;
    ${media.lessThan("medium")`
    
    height: 40px;
    padding: 16px 16px;

    `}
  }
  display: flex;
  flex-direction: column;
  label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 15px;
    position: relative;
    color: #919499;
    width: 100%;
  }
  textarea {
    border: 1.3px solid rgba(196, 196, 196, 0.5);
    border-radius: 14px;
    padding: 20px 24px;
  }
  .eye {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
  }
  .holder {
    display: flex;
    width: 100%;
    position: relative;
  }
`;
const Input = (props) => {
  const [customType, setCustomType] = useState(false);
  return (
    <Style password={props.password}>
      <div className="holder">
        <label>
          {props.label}
          {props.star === "true" && <span style={{ color: "red" }}>*</span>}
        </label>
        {props.password === "true" && !props.noeye === "true" && (
          <svg
            onClick={() => {
              // alert("ds");

              setCustomType(!customType);
            }}
            className="eye"
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.4784 5.25857C14.817 5.70143 14.817 6.29929 14.4784 6.74143C13.412 8.13357 10.8534 11 7.86629 11C4.87915 11 2.32057 8.13357 1.25415 6.74143C1.08942 6.52938 1 6.26851 1 6C1 5.73149 1.08942 5.47062 1.25415 5.25857C2.32057 3.86643 4.87915 1 7.86629 1C10.8534 1 13.412 3.86643 14.4784 5.25857V5.25857Z"
              stroke="#C4C4C4"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.86649 8.14314C9.04996 8.14314 10.0093 7.18375 10.0093 6.00028C10.0093 4.81681 9.04996 3.85742 7.86649 3.85742C6.68302 3.85742 5.72363 4.81681 5.72363 6.00028C5.72363 7.18375 6.68302 8.14314 7.86649 8.14314Z"
              stroke="#C4C4C4"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </div>
      {props.textarea === "true" ? (
        <textarea {...props} />
      ) : (
        <input
          type={customType === true ? "text" : props.itype}
          placeholder={props.placeholder}
          {...props}
          className="text-input"
        />
      )}
    </Style>
  );
};

export default Input;
