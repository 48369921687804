import styled from "styled-components";
import media from "styled-media-query";

import quickStartImage from "../../../Assets/images/developers/quickstart_image.png";

import sdkAndPluginsImage from "../../../Assets/images/developers/sdk_and_plugins_image.png";
import verificationIdentityImage from "../../../Assets/images/developers/verification_identity_image.png";
import otherWaysImage from "../../../Assets/images/developers/other_ways_image.png";
import { Link } from "react-router-dom";

const Styles = styled.section`
  padding-bottom: 290px;
  ${media.greaterThan("768px")`

  `};
  ${media.greaterThan("1024px")`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10%;
  grid-row-gap: 10%;

  `};
  ${media.greaterThan("1440px")`

  `};

  .left_texts_part {
  }
`;

const SelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 5px; */
  align-items: flex-start;
  width: 100%;
  max-width: 402px;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  /* max-width: 402px; */
  height: 213px;
`;

const Title = styled.h5`
  font-size: 20px;
  line-height: 310%;
  letter-spacing: -0.03em;
  color: #000000;
  font-family: "ApercuProBoldNormal";

  ${media.greaterThan("768px")`

  `};
  ${media.greaterThan("1024px")`
    /* font-size: 25px; */
  `};
  ${media.greaterThan("1440px")`
    font-size: 25px;
  `};
`;

const Subtitle = styled.p`
  font-size: 16px;
  line-height: 180%;
  color: #1a191e;

  ${media.greaterThan("768px")`
    font-size: 18px;
  `};
  ${media.greaterThan("1024px")`
    font-size: 20px;
  `};
  ${media.greaterThan("1440px")`

  `};
`;

const HomeNav = () => {
  return (
    <Styles>
      <Link to={"/developers/quickstart"}>
        <SelectionContainer>
          <ImageContainer>
            <img
              src={quickStartImage}
              alt="quickstart"
              style={{
                position: "",
                maxInlineSize: "100%",
                blockSize: "auto",
              }}
            />
          </ImageContainer>
          <Title>Quickstart</Title>
          <Subtitle>
            Let’s get you setup and ready to move money around the globe.
          </Subtitle>
        </SelectionContainer>
      </Link>

      <SelectionContainer>
        <ImageContainer>
          <img
            src={sdkAndPluginsImage}
            alt="sdk and plugins"
            style={{
              position: "",
              maxInlineSize: "100%",
              blockSize: "auto",
            }}
          />
        </ImageContainer>
        <Title>SDKs and Plugins</Title>
        <Subtitle>
          Easily integrate Landlords Technology with your platform using any of
          our SDKs.
        </Subtitle>
      </SelectionContainer>

      <Link to={"/developers/customer-verification"}>
        <SelectionContainer>
          <ImageContainer>
            <img
              src={verificationIdentityImage}
              alt="verification identify your customers"
              style={{
                position: "",
                maxInlineSize: "100%",
                blockSize: "auto",
              }}
            />
          </ImageContainer>
          <Title>Verification Identify Your Customers</Title>
          <Subtitle>
            Verify phone numbers, bank accounts or Bank Verification Numbers
            (BVN)
          </Subtitle>
        </SelectionContainer>
      </Link>
      <SelectionContainer>
        <ImageContainer>
          <img
            src={otherWaysImage}
            alt="other ways"
            style={{
              position: "",
              maxInlineSize: "100%",
              blockSize: "auto",
            }}
          />
        </ImageContainer>
        <Title>Other ways to use Landlords Tech</Title>
        <Subtitle>
          Explore plugins, libraries and tools for accepting payments without
          the API
        </Subtitle>
      </SelectionContainer>
    </Styles>
  );
};

export default HomeNav;
