// import "./styles.css";
import styles from "./Modal.module.css";
import React from "react";

const Modal = (props) => {
  if (!props.show) {
    return null;
  }
  return (
    <div className={styles.modal} onClick={props.onClose}>
      <div
        className={styles.modal_content}
        onClick={(e) => e.stopPropagation()}
      >
        {props.children}
      </div>
    </div>
  );
};

export default Modal;
