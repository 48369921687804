import { useState, useRef, useEffect } from "react";
// import { useRouter } from "next/router";
import Popup from "reactjs-popup";

import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { Link } from "react-router-dom";
import { ButtonBasic } from "./buttons.styles";
import { CircleBox } from "../../ui/ui-component-styles";

const ShowMore = ({ title, options, bg }) => {
  const ref = useRef();
  const closeTooltip = () => ref.current.close();
  const [showMore, setShowMore] = useState(false);
  // const router = useRouter();

  return (
    <ButtonBasic style={{ position: "relative" }}>
      <Popup
        ref={ref}
        trigger={
          <div className="dropdown-head" bg={bg} style={{ cursor: "pointer" }}>
            {title} <MdOutlineKeyboardArrowDown size={14} />
          </div>
        }
      >
        <ul className="dropdown-more">
          {options.map((option, index) => {
            return (
              <Link to={option.link} key={index} onClick={closeTooltip}>
                <div
                  className="small-collection-solo"
                  // no_box
                  // no_bg
                  style={{ cursor: "pointer" }}
                >
                  <CircleBox bg={"#FBBD29"}>
                    <div className="icon-container">
                      <img
                        src={option.image}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "absolute",
                        }}
                      />
                    </div>
                  </CircleBox>
                  <div className="card_texts">
                    <p className="title">{option.title}</p>
                    <p className="subtitle">{option.subtitle}</p>
                  </div>
                </div>
              </Link>
            );
          })}
        </ul>
      </Popup>

      <div className="mobile-menu-more">
        {options.map((option, index) => {
          return (
            <Link
              to={option.link}
              key={index}
              onClick={() => setShowMore(false)}
            >
              <div style={{ paddingLeft: "15px" }} className="card_texts">
                <p style={{ paddingBottom: "5px" }} className="title">
                  {option.title}
                </p>
                {/* <p className="subtitle">{option.subtitle}</p> */}
              </div>
            </Link>
          );
        })}
      </div>
    </ButtonBasic>
  );
};

export default ShowMore;
