import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import styled from "styled-components";
import media from "styled-media-query";
import axios from "axios";
import { Circles } from "react-loader-spinner";
import { toast } from "react-toastify";

import eyeOpen from "../Assets/images/eye_open_icon.svg";
import eyeClosed from "../Assets/images/eye_closed_icon.svg";
import logoAnother from "../Assets/images/logo_another.svg";

import Button from "../components/elements/Button";
import {
  FormPart,
  AuthenticationContainer,
  ExtraTexts,
  OverallAuthContainer,
} from "../components/blocks/Authentication";
import ImagePartContent from "../components/blocks/Authentication/ImagePartContent";
import LogoAbsolutePlacer from "../components/elements/LogoAbsolutePlacer";
import LogoContainerOther from "../components/elements/LogoContainerOther";
import Validator from "../utils/Validator";
import { setCookie } from "../utils/cookiesHelper";
import useAppHook from "../hooks/useAppHook";
import { useEffect } from "react";

const FormPartSolo = styled.form`
  display: flex;
  flex-direction: column;
  /* gap: 27px; */
  width: 100%;
  /* padding-bottom: 80px; */
  padding-top: 50px;

  ${media.greaterThan("768px")`
    width: 80%;
    margin: 0 auto;

  `};
  ${media.greaterThan("1024px")`
      max-width: 400px;
      padding-top: 90px;
  `};
  ${media.greaterThan("1440px")`
    max-width: 552px;
  `};

  .sub_container {
    display: flex;
    flex-direction: column;
    gap: 27px;

    ${media.greaterThan("768px")`
    flex-direction: row;
    align-items: center;
    gap: 5%;
  `};
    ${media.greaterThan("1024px")`
  
  `};
    ${media.greaterThan("1440px")`
  `};
  }

  .input_label_container {
    display: flex;
    flex-direction: column;
    position: relative;

    .forgot_password {
      position: absolute;
      top: 0;
      right: 20px;
      font-size: 14px;
      line-height: 17px;

      color: #f8ad15;
    }
  }

  label {
    font-family: "ApercuProMediumNormal";
    font-size: 14px;
    line-height: 17px;
    color: #000;
  }
  .form_input {
    border: 1.3px solid rgba(196, 196, 196, 0.5);
    border-radius: 14px;
    margin-top: 19px;
    height: 60px;
    padding: 16px 24px 16px 24px;
    width: 100%;

    ${media.greaterThan("768px")`
    height: 50px;
    padding: 16px 24px 16px 24px;
    
  `};
    ${media.greaterThan("1024px")`
    height: 65px;
    padding: 20px 34px 20px 34px;
  `};
    ${media.greaterThan("1440px")`
  `};
  }

  .form_input.message {
    height: 124px;
  }

  h2 {
    font-family: "Tiempos Headline";
    font-size: 30px;
    line-height: 39px;
    color: #034b5e;
    margin-bottom: 40px;

    ${media.greaterThan("768px")`
    margin-bottom: 48px;
    
  `};
    ${media.greaterThan("1024px")`
    margin-bottom: 55px;
  `};
    ${media.greaterThan("1440px")`
    margin-bottom: 65px;
  `};
  }

  .subheading {
    font-family: "ApercuProMediumNormal";
    font-size: 14px;
    line-height: 17px;
    color: #c4c4c4;
    text-align: center;
  }

  .extra_button {
    border: none;
    border-radius: 5px;
    margin-bottom: 8px;
    padding: 4px 8px;
    cursor: pointer;
  }
  .extra_button:hover {
    /* background: #f8ad15; */
    background: #000;
    color: #fff;
  }
  .extra_button:active {
    background: #f8ad15;

    color: #fff;
  }
`;

const VerifyEmail = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { avatar, setAvatar } = useAppHook();
  const [showVerificationInput, setShowVerificationInput] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [notVerified, setNotVerified] = useState(false);

  const [loginDetails, setLoginDetails] = useState({
    // full_name: "",
    email: "",
    password: "",
  });

  const [loginError, setLoginError] = useState({
    // full_name: [],
    email: [],
    password: [],
  });

  const [loginData, setLoginData] = useState({
    // full_name: "",
    email: "",
    password: "",
  });

  useEffect(() => {
    const result = localStorage.getItem("unverified");
    const registerEmail = localStorage.getItem("registeringEmail");
    if (result === "yes") {
      setNotVerified(true);
      setLoginDetails((prev) => ({
        ...prev,
        email: registerEmail,
        // password: registerDetails.password,
      }));
      // setLoginData((prev) => ({
      //   ...prev,
      //   email: registerDetails.email,
      //   password: registerDetails.password,
      // }));
    } else {
      setNotVerified(false);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validatorMethods = {
      // full_name: "required|min,2",
      email: "required|email",
      password: "required",
    };

    const validator = new Validator(loginDetails, validatorMethods);
    validator.result
      .then(async () => {
        setSubmitting(true);
        const formData = new FormData();
        formData.append("email", loginDetails.email);
        formData.append("password", loginDetails.password);

        axios
          .post("/login", formData, {
            headers: {
              "content-type": "multipart/form-data",
            },
          })
          .then((res) => {
            setCookie("user_token", res.data.data.token);
            console.log(
              "this is the data from trying to log in: ",
              res.data.data
            );
            const response = res.data.data;
            console.log("the response after email verification: ", response);
            //don't forget to change the equivalence sign
            // if (
            //   response.need_document_verification === true ||
            //   response.need_facial_verification === true
            // ) {
            //   localStorage.setItem(
            //     "login_formData",
            //     JSON.stringify(loginDetails)
            //   );
            //   navigate("/verify-identity");
            //   setSubmitting(false);
            // } else {
            //   localStorage.setItem(
            //     "userInfo",
            //     JSON.stringify(res.data.data.user)
            //   );
            //   // setAvatar(res?.data?.data?.user?.avatar);
            navigate("/dashboard/profile");
            localStorage.removeItem("login_formData");
            setSubmitting(false);
            // }
          })
          .catch((err) => {
            const errMsg = err.response?.data?.message;
            // const errMsg2 = err.response?.data?.data?.message;
            if (
              errMsg ===
                "Unauthorized: User not verified. Check your email for verification code" ||
              errMsg.status === 403
            ) {
              setShowVerificationInput(true);
            }
            console.log(errMsg);
            toast.error(errMsg);

            setSubmitting(false);
          });
      })
      .catch((error) => {
        setSubmitting(false);
        setLoginError((prev) => ({
          ...prev,
          email: error?.error.email,
          password: error?.error.password,
        }));
      });
  };

  const handleVerifyEmail = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    const formData = new FormData();
    formData.append("email", loginData.email);
    formData.append("verification_token", verificationCode);

    axios
      .post("/user-verification", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        setCookie("user_token", res.data.data.token);
        console.log("this is the data from trying to log in: ", res.data.data);
        // const response = res.data.data;

        localStorage.setItem("userInfo", JSON.stringify(res?.data?.data?.user));
        navigate("/dashboard/profile");
        localStorage.removeItem("login_formData");
        setSubmitting(false);
      })
      .catch((err) => {
        const errMsg = err.response?.data?.message;
        // const errMsg2 = err.response?.data?.data?.message;
        // if (
        //   errMsg ===
        //   "Unauthorized: User not verified. Check your email for verification code"
        // ) {
        //   setShowVerificationInput(true);
        // }
        // console.log(errMsg);
        // toast.error(errMsg);
        toast.error(`An error occurred: ${errMsg}`);

        setSubmitting(false);
        console.log("the email verification error: ", errMsg);
      });
  };

  // const handleVerifyAccount = () => {};
  const handleResendVerificationCode = async () => {
    axios
      .post(`resend-verification?email=${loginDetails.email}`)
      .then((res) => {
        console.log("response from resend-verification mail: ", res);
        setSubmitting(false);
      })
      .catch((error) => {
        console.log("error from resnd-verification: ", error);
        setSubmitting(false);
      });
  };

  return (
    <OverallAuthContainer>
      <div className="form_part">
        <LogoAbsolutePlacer>
          <LogoContainerOther onClick={() => navigate("/")}>
            <img
              src={logoAnother}
              alt=""
              style={{
                // position: "absolute",
                // objectFit: "contain",
                maxInlineSize: "100%",
                blockSize: "auto",
              }}
            />
          </LogoContainerOther>
        </LogoAbsolutePlacer>
        <FormPartSolo>
          <h2 style={{ color: "#000" }}>Nice, to have you here!</h2>
          <div
            className="input_label_container "
            style={{ marginBottom: "25px" }}
          >
            <label htmlFor="email_or_username">Email or username</label>
            <input
              type="email"
              id="email_or_username"
              placeholder="Email or username"
              className="form_input"
              value={loginDetails.email}
              onChange={(e) =>
                setLoginDetails((prev) => ({
                  ...prev,
                  email: e.target.value,
                }))
              }
            />
            <p className="error message" style={{ textAlign: "left" }}>
              {loginError.email.join(", ")}
            </p>
          </div>

          <div className="input_label_container">
            <label htmlFor="password">Verification Code</label>
            <input
              type={"text"}
              placeholder="Code"
              className="form_input"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "40px",
            }}
          >
            <button
              type="button"
              onClick={handleResendVerificationCode}
              className="extra_button"
            >
              resend verification code
            </button>

            <Button
              type="submit"
              onClick={(e) => handleVerifyEmail(e)}
              bg={"#F8AD15"}
              clr="#FFFFFF"
              style={{
                width: "100%",
                justifyContent: "center",
                height: "65px",
                marginTop: "20px",
              }}
            >
              {submitting && (
                <Circles
                  height="30"
                  width="30"
                  color="#fff"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              )}
              Continue
            </Button>
          </div>
        </FormPartSolo>
        <ExtraTexts style={{ marginTop: "20px" }}>
          You dont Have an Account?{" "}
          <Link to={"/register"}>
            <span>Sign Up</span>
          </Link>
        </ExtraTexts>
      </div>
      <div className="picture_part">
        <ImagePartContent />
      </div>
    </OverallAuthContainer>
  );
};

export default VerifyEmail;

// Login.getLayout = function PageLayout(page) {
//   return <>{page}</>;
// };
