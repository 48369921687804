import styled from "styled-components";
import media from "styled-media-query";

export const CircleBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: ${(props) => (props.width ? props.width : "26px")};
  height: ${(props) => (props.height ? props.height : "26px")};
  background: ${(props) => (props.bg ? props.bg : "#e8f2ee")};
  border-radius: 50%;

  ${media.greaterThan("768px")`


  `}
  ${media.greaterThan("1024px")`


  `}
`;
