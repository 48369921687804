import styled from "styled-components";
import media from "styled-media-query";
import React from "react";
import { IoCheckmarkSharp } from "react-icons/io5";
import Button from "../../elements/Button";
import { RiPhoneFill } from "react-icons/ri";
import { ImWhatsapp } from "react-icons/im";
import { MdOutlineMarkEmailUnread } from "react-icons/md";
import { Link } from "react-router-dom";

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start; */
  justify-content: flex-start;
  align-items: center;
  background: #fbbd29;
  border-radius: 8px;
  width: 100%;

  padding: 32px 40px 32px 32px;
  position: absolute;
  right: 0;
  top: -70px;
  z-index: 1;

  ${media.greaterThan("768px")`
  
    
  `}
  ${media.greaterThan("1024px")`
  
  
    max-width: 1024px
    
  `}
  ${media.greaterThan("1440px")`
    width: 75%;
    flex-direction: row;
    align-items: center
    max-width: 1236px;
    justify-content: center;
    
  `}

  .headings {
    ${media.greaterThan("768px")`
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;

    `}
    ${media.greaterThan("1440px")`
      margin-right: 10px;

    `}
  }

  .others {
    display: flex;
    flex-direction: column;
    align-items: center;

    ${media.greaterThan("1024px")`
      flex-direction: row;
      flex-direction: row;
      align-items: center;
      width: 70%;
    `}
    ${media.greaterThan("1440px")`
      /* flex-direction: row;
      flex-direction: row;
      align-items: center; */
    `}

    .small_cards_container {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      ${media.greaterThan("768px")`
        flex-direction: row;
        align-items: center
      `}
      ${media.greaterThan("1024px")`
        margin-bottom: 0px;
        width: 100%;
      
      `}
      ${media.greaterThan("1440px")`
    
      `}
    }

    .button_container {
      width: 100%;
      max-width: 280px;

      /* margin: 0 auto; */
      ${media.greaterThan("768px")`
        
      `}
      ${media.greaterThan("1024px")`
        max-width: 149px;
      
      `}
      ${media.greaterThan("1440px")`
    
      `}
    }
  }
`;

const SmallCardLocal = styled.div`
  background: ${(props) => (props.no_bg ? "none" : "#ffffff")};
  box-shadow: ${(props) =>
    props.no_box ? "none" : "0px 4px 4px rgba(0, 0, 0, 0.25)"};
  border-radius: 11px;
  display: flex;
  /* gap: 17px; */
  align-items: center;
  /* padding: 15px 13px; */
  /* width: 100%; */
  max-width: ${(props) => (props.no_box ? "320px" : "271px")};
  margin-bottom: 15px;

  ${media.greaterThan("1024px")`
    margin-bottom: 0px;
  `};

  .card_texts {
    display: flex;
    flex-direction: column;
    /* gap: 5px; */
    margin-left: 17px;

    .title {
      font-family: "ApercuProBoldNormal";
      font-size: 16px;
      line-height: 110%;
      letter-spacing: -0.03em;
      color: #1a191e;
      margin-bottom: 5px;
    }

    .subtitle {
      font-size: 10px;
      line-height: 180%;
      color: #1a191e;
    }
  }
`;

export const MarkCircle = styled.div`
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 26px;
  height: 26px;
  background: ${(props) => (props.bg ? props.bg : "#e8f2ee")};
  border-radius: 100px;
`;

const Title = styled.p`
  font-family: "Tiempos Headline";
  font-size: 28px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #000000;
`;

const Description = styled.p`
  font-family: "ApercuProMediumNormal";
  font-size: 18px;
  line-height: 180%;
  color: #000000;
  opacity: 0.7;
`;

const StillHaveQuestions = () => {
  return (
    <Styles>
      <div className="headings">
        <Title>Still have questions?</Title>
        <Description>We are here to help.</Description>
      </div>
      <div className="others">
        <div className="small_cards_container">
          <SmallCardLocal no_box no_bg style={{ marginRight: "40px" }}>
            <MarkCircle style={{ background: "rgba(0, 0, 0, 0.2)" }}>
              <ImWhatsapp color="#fff" />
            </MarkCircle>
            <div className="card_texts">
              <p className="title" style={{ whiteSpace: "nowrap" }}>
                +234 809 990 8909
              </p>
              <p className="subtitle">WhatsApp Support Hotline</p>
            </div>
          </SmallCardLocal>
          <SmallCardLocal no_box no_bg>
            <MarkCircle style={{ background: "rgba(0, 0, 0, 0.2)" }}>
              <MdOutlineMarkEmailUnread color="#fff" />
            </MarkCircle>
            <div className="card_texts">
              <Link
                to=""
                onClick={(e) => {
                  window.location.href =
                    "mailto:support@landlordstechnology.com";
                  e.preventDefault();
                }}
              >
                <p className="title">
                  Support
                  <span style={{ textDecoration: "underline" }}>
                    @landlordstechnology.com
                  </span>
                </p>
              </Link>
              <p className="subtitle">Support Email</p>
            </div>
          </SmallCardLocal>
        </div>
        <div className="button_container">
          <Button
            bg={"#1A191E"}
            clr={"#FFFFFF"}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <a
              href="https://wa.me/2348099908909"
              target="_blank"
              rel="noopener noreferrer"
            >
              Chat with us
            </a>
          </Button>
        </div>
      </div>
    </Styles>
  );
};

export default StillHaveQuestions;
