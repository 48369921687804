import styled from "styled-components";
import media from "styled-media-query";

export const FooterAnchorTag = styled.p`
  cursor: pointer;
  /* font-family: ApercuProNormal; */
  font-size: 16px;
  line-height: 160%;
  /* color: rgba(26, 25, 30, 0.5); */
  color: #fff;
`;

export const FooterTitle = styled.p`
  font-family: ApercuProMediumNormal;
  font-size: 24px;
  line-height: 140%;
  letter-spacing: -0.03em;
  /* color: #1a191e; */
  color: #fff;
  padding-bottom: 32px;
`;

export const FooterContainer = styled.div`
  padding: 0 5%;
  padding-top: ${(props) => (props.isHomepage ? "20px" : "200px")};

  ${media.greaterThan("768px")`
    padding-top: ${(props) => (props.isHomepage ? "10px" : "150px")};
  `};
  ${media.greaterThan("1024px")`
    padding-top: ${(props) => (props.isHomepage ? "45px" : "90px")};
    /* display: flex; */
  `};
  ${media.greaterThan("1440px")`
    /* padding-top: 0; */
  `};

  .containing_container {
    ${media.greaterThan("768px")`
    
  `};
    ${media.greaterThan("1024px")`
    display: flex
    
  `};
    ${media.greaterThan("1440px")`
    
  `};
  }
`;
