import { useState, useEffect } from "react";
// import { useRouter } from "next/router";
import {
  MdMenu,
  MdOutlineArrowBack,
  MdOutlineArrowForward,
} from "react-icons/md";
import styled from "styled-components";
import media from "styled-media-query";

import Hamburger from "hamburger-react";
import { AnimatePresence, motion } from "framer-motion";

import Logo from "../../../Assets/images/logo.svg";

// import NavBar from "./NavBar";
// import NavMobile from "./NavMobile";
import LogoContainer from "../../elements/LogoContainer";
import useOutsideClick from "../../../hooks/useOutsideClick";

// import useUserHook from "../../../hooks/useUserHook";

import { Link } from "react-router-dom";
import NavBar from "./NavBar";
import NavMobile from "./NavMobile";
import axios from "axios";
import useAppHook from "../../../hooks/useAppHook";

const Styles = styled.div`
  background: #fff;

  padding-bottom: 20px;
  position: fixed;
  top: 0;
  z-index: 20;
  max-width: 1728px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 19.5%;
  padding-top: 10px;
  padding-left: 8.1%;
  padding-right: 4%;
  width: 100%;
  /* max-width: 1600px; */
  margin: 0 auto;

  ${media.greaterThan("1024px")`
      gap: 9%;
      padding-left: 4.1%;
      padding-right: 2%;
      padding-bottom: 40px;
      padding-top: 20px;

  `}
  ${media.greaterThan("1440px")`
      gap: 19.5%;
      /* padding-left: 8.1%;
      padding-right: 4%; */
  `}

  .white_bg {
    /* background: #ffffff; */
    background: #f0f;
  }

  .desktop_nav_display {
    display: none;

    ${media.greaterThan("1024px")`
    display: block;

    flex: 1;
    `}
  }

  .side-barz {
    min-width: 256px;
    max-width: 256px;
    width: 256px;
    background: white;
    /* overflow-y: scroll; */
    display: block;
  }

  /* .burger {
    display: none;
    ${media.lessThan("1024")`
    display: block;
    `}
  } */
  .burger {
    display: block;
    ${media.greaterThan("1024px")`
    display: none;
    `}
  }

  .motion-div {
    /* overflow-y: auto; */
    width: 100%;
    max-width: 256px;
    position: absolute;
    right: 0;
    top: 0;
    /* bottom: 0; */
    background: #fff;
    padding-left: 20px;
    padding-top: 50px;
    height: 100vh;
  }
`;

// const MobileNav = styled.div`
//   position: relative;

//   ${media.greaterThan("1024px")`

//   display: none;
// `}

//   .mobile_nav_display {
//     background: #fff;
//     position: fixed;
//     height: 100vh;
//     width: 70%;
//     max-width: 300px;
//     padding-left: 5%;
//     padding-top: 100px;
//     right: 0;
//     top: 0;
//     z-index: 1;

//     .back_arrow {
//       position: absolute;
//       top: 15px;
//     }
//   }
// `;

const Header = () => {
  // const router = useRouter();
  // const { loggedIn, setLoggedIn } = useUserHook();
  // const { loggedIn, setLoggedIn } = UserProvider();
  // const { loggedIn, setLoggedIn } = UserContext;
  const { setGeneralSettings } = useAppHook();
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const [colored, setColored] = useState(false);

  const [ref] = useOutsideClick(() => setOpen(false));
  const [isOpen, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setColored(window.pageYOffset > 200)
      );
    }
  }, []);
  // useEffect(() => {
  //   axios
  //     .get(`/general-settings`)
  //     .then((res) => {
  //       setGeneralSettings(res?.data?.data?.data);
  //       localStorage.setItem(
  //         "general_settings",
  //         JSON.stringify(res?.data?.data?.data)
  //       );
  //     })
  //     .catch((err) => {
  //       console.log("this is the error from general settings: ", err.response);
  //     });
  // }, []);

  // const closeTooltip = () => ref.current.close();
  return (
    <Styles
    // colored={colored}
    // className={` ${colored ? "white_bg" : ""}`}
    // style={`${colored ? { background: "#f0f" } : { background: "none" }}`}
    >
      <Link to={"/"}>
        <LogoContainer
          // onClick={() => router.push("/")}
          style={{ cursor: "pointer" }}
        >
          {/* <Image src={Logo} alt="Landlords Technology Logo" fill /> */}
          <img
            src={Logo}
            alt="Landlords Technology Logo"
            style={{ width: "100%", height: "100%", position: "" }}
          />
        </LogoContainer>
      </Link>

      <div className="burger">
        <Hamburger toggled={isOpen} size={20} toggle={setOpen} />
        <AnimatePresence>
          {isOpen && (
            <motion.div
              className="motion"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ ease: "easeInOut", duration: "0.5" }}
            >
              <motion.div
                ref={ref}
                className="motion-div"
                initial={{ x: 1000 }}
                animate={{ x: 0 }}
                exit={{ x: 1000 }}
                transition={{ ease: "easeInOut", duration: "0.5" }}
              >
                <div className="side-barz nobar">
                  <NavMobile setOpen={setOpen} />
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <div className="desktop_nav_display">
        <NavBar />{" "}
      </div>
    </Styles>
  );
};

export default Header;
