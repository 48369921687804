import { get } from "lodash";

let resolvePromise;
let rejectPromise;

class Validator {
  component = this;

  fields = {};

  errors = {};

  result = new Promise((resolve) => (resolvePromise = resolve));

  state = { error: [] };

  methods = {
    required: { name: "required", errorMessage: "The :field-name is required" },
    "is-alpha": {
      name: "isAlpha",
      errorMessage: ":field-name may contain only alphabets",
    },
    "has-alpha": {
      name: "hasAlpha",
      errorMessage: ":field-name must contain an alphabet",
    },
    "is-num": {
      name: "isNum",
      errorMessage: ":field-name may contain only numbers",
    },
    "has-num": {
      name: "hasNum",
      errorMessage: ":field-name must contain a number",
    },
    "is-sym": {
      name: "isSym",
      errorMessage: ":field-name may contain only symbols",
    },
    "has-sym": {
      name: "hasSym",
      errorMessage: ":field-name must contain a symbol",
    },
    min: {
      name: "min",
      errorMessage: ":field-name must be at least :param-1 character long",
    },
    max: {
      name: "max",
      errorMessage: ":field-name must not be longer than :param-1 characters",
    },
    between: {
      name: "between",
      errorMessage:
        "The length of :field-name must be between :param-1 and :param-1",
    },
    "min-num": {
      name: "minNum",
      errorMessage: ":field-name minimum is :param-1",
    },
    "max-num": {
      name: "maxNum",
      errorMessage: ":field-name maximum is :param-1",
    },
    "between-num": {
      name: "betweenNum",
      errorMessage: ":field-name must be between :param-1 and :param-1",
    },
    email: { name: "email", errorMessage: "Invalid email address" },
    phone: { name: "phone", errorMessage: "Invalid phone number" },
    date: { name: "date", errorMessage: "Invalid date" },
    url: { name: "url", errorMessage: "Invalid url" },
    "min-date": { name: "minDate", errorMessage: ":field-name is too early" },
    "max-date": { name: "maxDate", errorMessage: ":field-name is too late" },
    "same-as": {
      name: "sameAs",
      errorMessage: ":field-name does not match :param-1",
    },
  };

  constructor(fields, methods, component, methodErrorMessages) {
    this.component = component || this;
    methodErrorMessages = methodErrorMessages || {};
    this.result = new Promise((resolve, reject) => {
      resolvePromise = resolve;
      rejectPromise = reject;
    });

    for (const fieldName in methods) {
      if (methods.hasOwnProperty(fieldName)) {
        const fieldNames = this.getFieldNames(fieldName, fields);
        const methodsList = methods[fieldName].split("|");
        for (const fieldName of fieldNames) {
          this.errors[fieldName] = [];
          this.fields[fieldName] = { value: get(fields, fieldName) || "" };
          this.fields[fieldName]["methodErrorMessages"] =
            methodErrorMessages[fieldName] || {};
          this.fields[fieldName]["methods"] = [];
          for (const i in methodsList) {
            if (methodsList.hasOwnProperty(i)) {
              const methodParams = [this.fields[fieldName].value];
              let [methodId, _methodParams] = methodsList[i].split(",", 2);
              _methodParams = _methodParams ? _methodParams.split(",") : [];
              for (const j in _methodParams) {
                if (_methodParams.hasOwnProperty(j)) {
                  methodParams.push(_methodParams[j].replace(/^:/, ""));
                  if (_methodParams[j].match(/^:[a-zA-Z_-]+$/)) {
                    methodParams.push(
                      this.fields[_methodParams[j].replace(/^:/, "")].value
                    );
                  }
                }
              }
              if (!this.fields[fieldName]) {
              }
              this.fields[fieldName].methods.push({
                id: methodId,
                params: methodParams,
              });
            }
          }
        }
      }
    }

    this.validate();
    return this;
  }

  setState(state, callback) {
    this.state = { ...this.state, ...state };
    if (typeof callback === "function") {
      callback.apply(undefined, []);
    }
  }

  getFieldNames(fieldName, fields) {
    const _fieldNames = [];
    let fieldNames = [];
    if (fieldName.match(/\[]/)) {
      const prefix = fieldName.replace(/\[].*?$/, "");
      const suffix = fieldName.replace(/^.*?\[]/, "");
      const parent = get(fields, prefix);
      if (typeof parent === "object") {
        for (const i in parent) {
          if (parent.hasOwnProperty(i)) {
            _fieldNames.push(prefix + "[" + i + "]" + suffix);
          }
        }
        if (suffix) {
          for (const i in _fieldNames) {
            if (_fieldNames.hasOwnProperty(i)) {
              fieldNames = [
                ...fieldNames,
                ...this.getFieldNames(_fieldNames[i], fields),
              ];
            }
          }
        } else {
          fieldNames = _fieldNames;
        }
      }
    } else {
      fieldNames.push(fieldName);
    }
    return fieldNames;
  }

  validate() {
    for (const fieldName in this.fields) {
      if (this.fields.hasOwnProperty(fieldName)) {
        const errorFieldName = fieldName.replace(/\[(.*?)]/g, ".$1");
        this.errors[errorFieldName] = [];
        for (const i in this.fields[fieldName].methods) {
          if (
            this.fields[fieldName].methods.hasOwnProperty(i) &&
            this.methods[this.fields[fieldName].methods[i]["id"]]
          ) {
            const methodName =
              this.methods[this.fields[fieldName].methods[i]["id"]].name;
            const method = this[methodName];
            const valid =
              typeof method === "function" &&
              method.apply(
                undefined,
                this.fields[fieldName]["methods"][i]["params"]
              );
            if (!valid) {
              this.errors[errorFieldName].push(
                this.fields[fieldName].methodErrorMessages[
                  this.fields[fieldName].methods[i].id
                ] ||
                  this.methods[
                    this.fields[fieldName].methods[i].id
                  ].errorMessage
                    .replace(
                      /:field-name/gi,
                      fieldName.replace(/[^A-Za-z0-9]/g, " ")
                    )
                    .replace(/:value/gi, this.fields[fieldName].value)
                    .replace(
                      /:param-(\d+)/gi,
                      (param, index) =>
                        this.fields[fieldName].methods[i].params[index]
                    )
              );
            }
          }
        }
      }
    }
    const result = { valid: true, error: {} };
    result.error = this.errors;
    if (typeof resolvePromise === "function") {
      if (this.component) {
        this.component.setState(
          { error: { ...this.component.state.error, ...result.error } },
          () => {
            for (let i in this.fields) {
              if (this.fields.hasOwnProperty(i)) {
                if (this.errors[i].length) {
                  result.valid = false;
                  break;
                }
              }
            }
            if (result.valid) {
              resolvePromise(result);
            } else {
              const errorMessageElement = Array.from(
                document.querySelectorAll("form .error.message")
              ).filter((element) => !!element.innerText.trim())[0];
              if (errorMessageElement) {
                errorMessageElement.scrollIntoView({ behavior: "smooth" });
              }
              rejectPromise(result);
            }
          }
        );
      }
    }
  }

  required(value) {
    return typeof value === "number" || !!value.toString();
  }

  isAlpha(value) {
    return (
      (typeof value !== "number" && !value) ||
      (typeof value === "string" && !!value.match(/^[a-zA-Z]+$/))
    );
  }

  hasAlpha(value) {
    return (
      (typeof value !== "number" && !value) ||
      (typeof value === "string" && !!value.toString().match(/[a-zA-Z]/))
    );
  }

  isNum(value) {
    return (
      !value ||
      parseInt(value) === 0 ||
      (value.toString().match(/^[0-9.]+$/) &&
        value.toString().split(".").length < 3)
    );
  }

  hasNum(value) {
    return (
      !value ||
      parseInt(value) === 0 ||
      (value.toString().match(/[0-9.]/) &&
        !value.toString().match(/[^\d]\.|\.[^\d]/))
    );
  }

  isInt(value) {
    return !value || value.toString().match(/^[0-9]+$/);
  }

  hasInt(value) {
    return !value || value.toString().match(/[0-9]/);
  }

  isSym(value) {
    return !value || value.toString().match(/^[^A-Za-z0-9]+$/);
  }

  hasSym(value) {
    return !value || value.toString().match(/[^A-Za-z0-9]/);
  }

  min(value, limit) {
    return (
      !value ||
      ((typeof value === "number" || typeof value === "string") &&
        value.toString().length >= limit) ||
      (typeof value === "object" &&
        value &&
        ((value.constructor.name === "Array" && value.length >= limit) ||
          Object.keys(value).length >= limit))
    );
  }

  max(value, limit) {
    return (
      !value ||
      ((typeof value === "number" || typeof value === "string") &&
        value.toString().length <= limit) ||
      (typeof value === "object" &&
        value &&
        ((value.constructor.name === "Array" && value.length <= limit) ||
          Object.keys(value).length <= limit))
    );
  }

  between(value, min, max) {
    return (
      !value ||
      (value.toString().length <= min && value.toString().length >= max)
    );
  }

  minNum(value, limit) {
    return !value || parseFloat(value) >= limit;
  }

  maxNum(value, limit) {
    return !value || parseFloat(value) <= limit;
  }

  betweenNum(value, min, max) {
    return !value || (parseFloat(value) <= min && parseFloat(value) >= max);
  }

  minDate(value, minDate) {
    return (
      !value ||
      new Date(value.split("-")[0], value.split("-")[1], value.split("-")[2]) >=
        new Date(
          minDate.split("-")[0],
          minDate.split("-")[1],
          minDate.split("-")[2]
        )
    );
  }

  maxDate(value, maxDate) {
    return (
      !value ||
      new Date(value.split("-")[0], value.split("-")[1], value.split("-")[2]) <=
        new Date(
          maxDate.split("-")[0],
          maxDate.split("-")[1],
          maxDate.split("-")[2]
        )
    );
  }

  email(value) {
    return (
      !value ||
      value
        .toString()
        .match(/^[a-zA-Z0-9_.-]{2,32}@[a-zA-Z]{2,32}\.[a-zA-Z]{2,8}$/gi)
    );
  }

  phone(value) {
    return !value || value.toString().match(/^\+?[0-9]{8,15}$/gi);
  }

  url(value) {
    return (
      !value ||
      value
        .toString()
        .match(/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})(\/\w \.-]*)*\/?$/gi)
    );
  }

  date(value) {
    return (
      !value ||
      (value.toString().match(/^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}/gi) &&
        new Date(
          value.split("-")[0],
          value.split("-")[1],
          value.split("-")[2]
        ).getDate())
    );
  }

  sameAs(value, fieldName, fieldValue) {
    return !value || value === fieldValue;
  }
}

export default Validator;
