import styled from "styled-components";
import media from "styled-media-query";
import AuthenticationNav from "../../components/blocks/developer/AuthenticationNav";
import EncryptionNav from "../../components/blocks/developer/EncryptionNav";
import QuickstartNav from "../../components/blocks/developer/QuickstartNav";
import WebhooksNav from "../../components/blocks/developer/WebhooksNav";
import Footer from "../../components/blocks/Footer";
import SideMenuHolderContainer from "../../components/blocks/SideMenuHolderContainer";
import DynamicScrollToTop from "../../utils/DynamicScrollToTop";

const Styles = styled.section`
  padding-top: 5px;
  padding-bottom: 100px;
  ${media.greaterThan("768px")`
  /* padding-top: 50px; */

  `};
  ${media.greaterThan("1024px")`
  padding-bottom: 400px;


  `};
  ${media.greaterThan("1440px")`

  `};
`;
const Quickstart = () => {
  const navs = [
    "Quickstart",
    "Authentication",
    "Webhooks",
    "Testing",
    "Errors",
    "Encryption",
  ];

  const navContents = [
    <QuickstartNav key={0} />,
    <AuthenticationNav key={1} />,
    <WebhooksNav key={2} />,
    "",
    "",
    <EncryptionNav key={5} />,
  ];

  return (
    <Styles>
      <DynamicScrollToTop />
      <SideMenuHolderContainer navs={navs} navContents={navContents} />
    </Styles>
  );
};

export default Quickstart;
