import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import DashboardLayout from "../../components/layout/dashboardLayout";
import Header from "../../components/layout/Header";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Menu, Tab, Transition } from "@headlessui/react";
import media from "styled-media-query";
import axios from "axios";

import headerSearchIcon from "../../Assets/images/header-search-icon.svg";
import chev from "../../Assets/images/chev.svg";
import card from "../../Assets/images/card.svg";
import up from "../../Assets/images/up.svg";
import requestRentalFinanceNotification from "../../Assets/images/request_rental_finance_notification.svg";
import Button from "../../components/elements/Button";
import { FormPart } from "../../components/blocks/Authentication";
import Modal from "../../components/blocks/Modal";
import { FormContainer } from ".";
import removeExtraCharacter from "../../utils/removeExtraCharacter";
import numberWithCommas from "../../utils/numberWithCommas";
import DateRange from "../../utils/DateRange";
import { useNavigate } from "react-router-dom";

const Style = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  .box1 {
    height: 100px;
    width: 167px;
    background: #f8f8f8;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    margin-right: 8px;
  }
  .pill {
    width: 72px;
    height: 15px;
    background: #f8ad15;
    border-radius: 8px;
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
  }
  .white {
    color: white !important;
  }
  .line-header {
    display: flex;
    align-items: center;
    margin-bottom: 32.5px;
    p {
      font-family: "Apercu Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      /* identical to box height */
      margin-right: 5px;
      color: #000000;
    }
    div {
      flex: 1;
      border-bottom: 1.3px solid #cc8d2b;
      height: 1px;
    }
  }
  .pay {
    height: 43px;
    left: 1088px;
    top: 636px;
    margin-top: 4px;
    background: #f8ad15;
    border-radius: 5px;
    border: none;
    outline: none;
    appearance: none;
    width: 100%;
    color: white;
  }
  .mapper {
    display: flex;
    margin-bottom: 37px;
    align-items: center;
  }
  .card-holder {
    /* width: 10%; */
    display: none;

    ${media.greaterThan("425px")`
      display: block;
      margin-right: 2%;
    `};
    ${media.greaterThan("1024px")`
      display: block;
    `};
  }
  .card {
    width: 24.4px;
    height: 16px;
    ${media.greaterThan("425px")`
      display: block;
    `};
    ${media.greaterThan("1024px")`
      width: 29.4px;
      height: 21px;
    `};
  }
  .paid-holder {
    /* width: 20%; */
    flex: 1;
  }
  .paid {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    color: #474747;

    ${media.greaterThan("768px")`
      
    `};
    ${media.greaterThan("1024px")`
      font-size: 16px;
    line-height: 24px;
    `};
    ${media.greaterThan("1440px")`
      font-size: 18px;
    line-height: 27px;
    `};
  }

  .date-holder {
    width: 20%;

    ${media.greaterThan("768px")`
      
    `};
    ${media.greaterThan("1024px")`
      
    `};
    ${media.greaterThan("1440px")`
      width: 25%;
    `};
  }
  .date_table {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;

    color: #c4c4c4;

    ${media.greaterThan("768px")`
      font-size: 10px;
      line-height: 16px;
    `};
    ${media.greaterThan("1024px")`
      font-size: 10px;
      line-height: 16px;
    `};
    ${media.greaterThan("1440px")`
      font-size: 12px;
      line-height: 18px;
    `};
  }
  .amount-holder {
    width: 20%;

    ${media.greaterThan("768px")`
      /* width: 30%; */
    `};
    ${media.greaterThan("1024px")`
      
    `};
    ${media.greaterThan("1440px")`
      width: 25%;
    `};
  }
  .show {
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;

    color: #c4c4c4;
  }
  .amount {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    color: #474747;

    ${media.greaterThan("768px")`
      
    `};
    ${media.greaterThan("1024px")`
      font-size: 16px;
    line-height: 24px;
    `};
    ${media.greaterThan("1440px")`
      font-size: 18px;
    line-height: 27px;
    `};
  }

  .open-holder {
    /* flex: 1; */
    display: flex;
    /* justify-content: end; */
  }
  .open-button {
    /* width: 90px;
    height: 32px; */
    border-radius: 15.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    padding: 4px 8px;

    ${media.greaterThan("768px")`
      padding: 6px 16px;
      
    `};
    ${media.greaterThan("1024px")`
      font-size: 12px;
      line-height: 16px;
      padding: 8px 24px;
    `};
    ${media.greaterThan("1440px")`
      font-size: 14px;
      line-height: 21px;
    `};
  }
  .closed {
    color: #d70000b2;
    background: rgba(255, 88, 88, 0.4);
  }
  .opened {
    background: #007d50;
    background: rgba(174, 244, 202, 0.4);
  }

  .header-holder {
    margin-bottom: 49px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .search-holder {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .circle {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f8f8f8;
  }
  .dropdown-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    width: 105px;
    height: 39px;
    left: 876px;
    top: 185px;
    background: #f8f8f8;
    border-radius: 25px;
    outline: none;
    border: none;
    margin-left: 21px;
  }
  .toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 34px;
    p {
      margin-right: 11px;

      font-family: "DM Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 100%;
      /* identical to box height, or 13px */

      letter-spacing: -0.03em;

      /* background/primary */

      color: #fbbd29;
    }
  }
  .entry {
    background: #f8f8f8;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px 12px 12px;
    gap: 16px;
    height: 65px;
    margin-bottom: 10px;
    justify-content: space-between;
  }
  .rent {
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 160%;
    /* or 19px */

    text-align: center;

    /* text/default */

    color: #1a191e;
  }
  .fee {
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 120%;
    /* identical to box height, or 36px */

    letter-spacing: -0.03em;

    color: #000000;
  }
  .box2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 2px;

    width: 167px;
    height: 100px;

    background: #cc8d2b;
    border-radius: 10px;
  }
  .box-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
  }
  .tab {
    margin-right: 24px;
    background: white;
    appearance: none;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding-left: 0;
  }
  .tab-list {
    margin-bottom: 39px;
  }
  .selected {
    color: #fcba2d;
  }
  .deselected {
    color: #919499;
  }
  .main-content {
    overflow: scroll;
    flex: 1;
    padding: 46px 36px 0;
    background: #f8f8f8;
    ${media.lessThan("medium")`
    padding: 46px 16px 0;
  `}
  }
  .right-sidebar {
    display: none;
    overflow: scroll;
    /* width: 375px; */
    height: 100%;
    background: white;
    padding: 48px 18px;

    ${media.greaterThan("768px")`
      display: block;
      width: 33%;
    `};
    ${media.greaterThan("1024px")`

    `};
    ${media.greaterThan("1440px")`
      max-width: 375px;

    `};
  }
  .graph-section {
    background: white;
    border-radius: 15px;
    padding: 22px 5%;
    display: flex;
    flex-direction: column;

    ${media.greaterThan("768px")`
      padding: 22px 27px;
    `};
    ${media.greaterThan("1024px")`

    `};
    ${media.greaterThan("1440px")`
      

    `};
  }
  .graph-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
  }
  .dot {
    background: #fbbd29;
    height: 12px;
    width: 12px;
    border-radius: 100%;
    margin-right: 8px;
  }
  .graph-title {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    display: flex;
    align-items: center;
  }
  .graph {
    flex: 1;
  }
  .invoice-section {
    height: 279px;
    background: white;
    border-radius: 15px;
    padding: 23px;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
  }
  .enter-icon {
    margin-left: 19px;
  }
  invoice-title {
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    /* identical to box height, or 24px */

    letter-spacing: -0.03em;

    /* text/default */

    color: #1a191e;
  }
  .view {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */

    letter-spacing: -0.03em;
    cursor: pointer;
    color: #fbbd29;
    display: flex;
    align-items: center;
  }
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  .inv {
    display: flex;
    justify-content: space-between;
    align-items: end;
  }
  .month {
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */

    letter-spacing: -0.03em;

    /* text/default */

    color: #1a191e;
  }
  .date {
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 160%;
    /* or 19px */

    color: rgba(26, 25, 30, 0.6);
  }
  .amount {
    p {
      font-family: "Apercu Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 160%;
      /* identical to box height, or 26px */

      /* text/default */

      color: #1a191e;
    }
  }
  .rep-title {
    margin-bottom: 19px;
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    /* or 25px */

    letter-spacing: -0.03em;

    /* text/default */

    color: #1a191e;
  }
  .collection {
    width: 50%;
  }
  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .light-input {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
    color: #c4c4c4;
  }
  .bold-input {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    color: #474747;
  }

  .rental_loan_sidebar_heading {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #034b5e;
  }
  .rental_loan_sidebar_box {
    background: #effcff;
    border: 2px solid rgba(161, 229, 248, 0.7);
    box-shadow: 0px 10px 15px rgba(41, 128, 151, 0.1);
    border-radius: 14px;
    padding: 14px;
    padding-bottom: 18px;
    margin-top: 12px;

    .orange_image_container {
      width: 32px;
      height: 32px;
    }

    p {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 25px;
      padding-top: 6px;
      padding-left: 10px;

      color: #040404;
    }
  }

  .mobile_view_only {
    ${media.greaterThan("768px")`
      display: none;
    `}
  }
  .naira_text {
    display: none;
    ${media.greaterThan("768px")`
      display: block;
    `}
  }

  .filterDate {
    border: none;
  }
  .date_overall_container {
    position: relative;
    /* background: red; */
  }
  .absolute_date_range {
    position: absolute;
    right: 0;
  }
`;

const Rental = () => {
  const invoice = [
    {
      month: "June",
      dueDate: "01/01/2022 02:34",
      amount: "₦70,299.00",
    },
    {
      month: "June",
      paymentDate: "01/01/2022 02:34",
      amount: "₦70,299.00",
    },
    {
      month: "June",
      paymentDate: "01/01/2022 02:34",
      amount: "₦70,299.00",
    },
  ];

  const items = [
    {
      status: "Paid",
      date: "15 Jan 2022",
      amount: "NGN 87,900",
      opened: "Closed",
    },
    {
      status: "Paid",
      date: "15 Jan 2022",
      amount: "NGN 87,900",
      opened: "Closed",
    },
    {
      status: "Paid",
      date: "15 Jan 2022",
      amount: "NGN 87,900",
      opened: "Closed",
    },
    {
      status: "Unpaid",
      date: "15 Jan 2022",
      amount: "NGN 87,900",
      opened: "Opened",
    },
  ];
  const [userInfo, setUserInfo] = useState({});

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const navigate = useNavigate();
  const [graphInfo, setGraphInfo] = useState([]);
  const [rentalLoanInfo, setRentalLoanInfo] = useState([]);
  const [repaymentInfo, setRepaymentInfo] = useState([]);
  const [paymentInfo, setPaymentInfo] = useState([]);
  const [duepaymentInfo, setDuePaymentInfo] = useState([]);
  const [openDate, setOpenDate] = useState(false);

  useEffect(() => {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    setUserInfo(info);
    if (info === null || info === undefined) {
      navigate("/signin");
    }
    axios
      .get("/tenant/rental-stats")
      .then((res) => {
        setGraphInfo(res.data.data);
      })
      .catch((err) => {
        console.log(err.response);
      });

    axios
      .get("/tenant/rental-loans?limit=10&offset=0&search")
      .then((res) => {
        setRentalLoanInfo(res.data?.data?.records);
        axios
          .get(
            `/tenant/rental-loan-repayments?rental_loan_id=${
              res.data.data.records.filter(
                (fi) => fi.status === "disbursed"
              )?.[0].id
            }`
          )
          .then((res) => {
            setRepaymentInfo(res.data.data);
          })
          .catch((err) => {
            console.log(err.response);
          });
        axios
          .get(
            `/tenant/rental-due-payment?rental_loan_id=${res.data.data.records[0].id}`
          )
          .then((res) => {
            setPaymentInfo(res.data.data);
          })
          .catch((err) => {
            console.log(err.response);
          });
        axios
          .get(
            `/tenant/rental-due-payment?rental_loan_id=${res.data.data.records[0].id}`
          )
          .then((res) => {
            setDuePaymentInfo(res.data.data);
          })
          .catch((err) => {
            console.log(err.response);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "Novermber",
    "December",
  ];

  const [openRentalFinanceModal, setOpenRentalFinanceModal] = useState(false);
  const handleRequestRentalFinance = () => {
    setOpenRentalFinanceModal(true);
    // alert("request clicked!!");
  };

  console.log("this is the rental loan info: ", rentalLoanInfo);
  return (
    <Style>
      <div className="main-content">
        <div className="header">
          <Header
            title="Rental Loan"
            subTitle="You may also edit your profile"
          />
        </div>
        <div>
          <div className="graph-section">
            <div className="header-holder">
              <p>Payment History</p>
              <div className="search-holder">
                {/* <div className="circle">
                  <img
                    src={headerSearchIcon}
                    // src="/header-search-icon.svg"
                    alt=""
                    style={{
                      position: "",
                      maxInlineSize: "100%",
                      blockSize: "auto",
                    }}
                  />
                </div> */}
                <div>
                  {/* <Menu as="div" className="">
                    <div>
                      <Menu.Button className="dropdown-button">
                        Date
                        <img
                          alt=""
                          className=""
                          src={chev}
                          aria-hidden="true"
                          style={{
                            position: "",
                            maxInlineSize: "100%",
                            blockSize: "auto",
                          }}
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="">
                        <div className="">
                          <Menu.Item>
                            {({ active }) => (
                              <div>
                                <DateRange />
                              </div>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu> */}

                  <div className="date_overall_container">
                    <div
                      className="dropdown-button"
                      onClick={() => setOpenDate(!openDate)}
                    >
                      Date
                      <img
                        alt=""
                        className=""
                        src={chev}
                        aria-hidden="true"
                        style={{
                          position: "",
                          maxInlineSize: "100%",
                          blockSize: "auto",
                        }}
                      />
                    </div>
                    {openDate && (
                      <div className="absolute_date_range">
                        <DateRange />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div>
              {repaymentInfo.map((item, i) => (
                <div key={i} className="mapper">
                  <div className="card-holder">
                    <img
                      alt=""
                      src={card}
                      className="card"
                      style={{
                        position: "",
                        maxInlineSize: "100%",
                        blockSize: "auto",
                      }}
                    />
                  </div>
                  <div className="paid-holder">
                    <p className="paid" style={{ textTransform: "capitalize" }}>
                     
                      Rental Finance
                    </p>
                  </div>
                  <div className="date-holder">
                    <p className="date">{item.due_date}</p>
                  </div>
                  <div className="amount-holder">
                    <p className="amount">
                      {removeExtraCharacter(item.amount, ".")}
                    </p>
                  </div>
                  <div className="open-holder">
                    <button
                      className={`open-button ${
                        item.opened === "Opened" ? "opened" : "closed"
                      }`}
                    >
                      {item.status === "paid"
                        ? "Closed"
                        : item.status === "unpaid"
                        ? "Opened"
                        : "Verifying"}
                    </button>
                  </div>
                </div>
              ))}
              <div>
                <p className="show">Show all</p>
              </div>
            </div> */}

            <div>
              {rentalLoanInfo.map((item, i) => (
                <div key={i} className="mapper">
                  <div className="card-holder">
                    <img
                      alt=""
                      src={card}
                      className="card"
                      style={{
                        position: "",
                        maxInlineSize: "100%",
                        blockSize: "auto",
                      }}
                    />
                  </div>
                  <div className="paid-holder">
                    <p className="paid" style={{ textTransform: "capitalize" }}>
                      {/* {item.status} */}
                      Rental Finance
                    </p>
                  </div>
                  <div className="date-holder">
                    <p className="date_table">
                      {removeExtraCharacter(item.created_at, " ")}
                    </p>
                  </div>
                  <div className="amount-holder">
                    <p className="amount">
                      {/* <span className="naira_text">NGN </span> */}
                      {numberWithCommas(removeExtraCharacter(item.amount, "."))}
                    </p>
                  </div>
                  <div className="open-holder">
                    <button
                      className={`open-button ${
                        item.status === "disbursed" ? "opened" : "closed"
                      }`}
                    >
                      {item.status === "disbursed"
                        ? "Approved"
                        : // : item.status === "unpaid"
                          // ? "Opened"
                          "Disapproved"}
                    </button>
                  </div>
                </div>
              ))}
              <div>
                <p className="show">Show all</p>
              </div>
            </div>

            <div className="mobile_view_only" style={{ marginTop: "14px" }}>
              <button onClick={handleRequestRentalFinance} className="pay">
                Request Rental Finance
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="right-sidebar">
        {/* <p className="rep-title">Repayments</p>
        <div>
          <Tab.Group>
            <Tab.List className="tab-list">
              <Tab
                className={({ selected }) =>
                  classNames("tab", selected ? "selected" : "deselected")
                }
              >
                Transaction
              </Tab>
              <Tab
                className={({ selected }) =>
                  classNames("tab", selected ? "selected" : "deselected")
                }
              >
                {({ selected }) => selected && "zzzii"}
                Details
              </Tab>
            </Tab.List>
            <Tab.Panels className="mt-2">
              <Tab.Panel className={classNames("", "")}>
                <div>
                  <div className="box-container">
                    <div className="box1">
                      <p className="pill">Financed Cost</p>
                      <p className="rent">Rental Finance</p>
                      <p className="fee">
                        ₦
                        {
                          rentalLoanInfo?.records?.filter(
                            (rec) => rec.status === "disbursed"
                          )?.[0].principal_amount
                        }
                      </p>
                    </div>
                    <div className="box2">
                      <p className="rent white">Repayment</p>
                      <p className="fee white">
                        ₦
                        {rentalLoanInfo?.records?.filter(
                          (rec) => rec.status === "disbursed"
                        )?.[0].principal_amount -
                          (repaymentInfo?.filter(
                            (pay) => pay.status === "paid"
                          )?.[0]?.amount || 0)}
                      </p>
                    </div>
                  </div>
                  <div className="line-header">
                    <p>Payment Breakdown</p>
                    <div className="line" />
                  </div>
                  <div>
                    {repaymentInfo.slice(0, 3).map((inv, i) => (
                      <div key={i} className="entry">
                        <div>
                          <p className="month">
                            {months[Number(inv.due_date.split("-")[1]) - 1]}
                          </p>
                          <p className="date">Payment date: {inv.due_date}</p>
                        </div>
                        <div className="amount">
                          <p>{inv.amount}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="toggle">
                    <p>Hide Previous Payments</p>
                    <img
                      alt=""
                      src={up}
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "",
                        maxInlineSize: "100%",
                        blockSize: "auto",
                      }}
                    />
                  </div>
                  <div className="line-header">
                    <p>Due Payment </p>
                    <div />
                  </div>
                  <div>
                    {paymentInfo.map((inv, i) => (
                      <div key={i} className="entry">
                        <div>
                          <p className="month">
                            {months[Number(inv.due_date.split("-")[1]) - 1]}
                          </p>
                          <p className="date">Due date: {inv.due_date}</p>
                        </div>
                        <div className="amount">
                          <p>{inv.amount}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div>
                    <button className="pay">Make Payment</button>
                  </div>
                </div>
              </Tab.Panel>
              <Tab.Panel className={classNames("", "")}>
                <div className="row">
                  <div className="collection">
                    <p className="light-input">Account number</p>
                    <p className="bold-input">(603) 555-0123</p>
                  </div>
                  <div className="collection">
                    <p className="light-input text-right">Account name</p>
                    <p className="bold-input text-right">GTBank</p>
                  </div>
                </div>

                <div className="row">
                  <div className="collection">
                    <p className="light-input">BVN</p>
                    <p className="bold-input">****************</p>
                  </div>
                  <div className="collection">
                    <p className="light-input text-right">Branch</p>
                    <p className="bold-input text-right">****************</p>
                  </div>
                </div>
                <div className="row">
                  <div className="collection">
                    <p className="light-input">Credit interest rate</p>
                    <p className="bold-input">20%</p>
                  </div>
                  <div className="collection">
                    <p className="light-input text-right">Branch</p>
                    <p className="bold-input text-right">7,658%</p>
                  </div>
                </div>
                <div>
                  <button className="pay">Edit Information</button>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div> */}
        <p className="rental_loan_sidebar_heading">Request Rental Finance</p>
        <div className="rental_loan_sidebar_box">
          <div className="orange_image_container">
            <img
              src={requestRentalFinanceNotification}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                position: "",
                maxInlineSize: "100%",
                blockSize: "auto",
              }}
            />
          </div>
          <p>
            Kindly note that, not all application or request might be
            successful, we reserve the right to disqualify any request not
            meeting up to our due diligence test, use the below button to send a
            rental finance request.
          </p>
        </div>
        <div style={{ marginTop: "14px" }}>
          <button onClick={handleRequestRentalFinance} className="pay">
            Request Rental Finance
          </button>
        </div>
        <Modal
          onClose={() => {
            setOpenRentalFinanceModal(false);
          }}
          show={openRentalFinanceModal}
        >
          <FormContainer>
            <FormPart>
              <h2 style={{ color: "#000" }}>Apply For Rental Finance</h2>
              <div className="input_label_container">
                <label htmlFor="description" style={{ color: "#919499" }}>
                  Property Description
                </label>
                <input
                  type={"text"}
                  id="description"
                  placeholder="2bed Room Apartment"
                  className="form_input"
                />
              </div>
              <div className="input_label_container">
                <label htmlFor="amount_requested" style={{ color: "#919499" }}>
                  Amount Request *
                </label>
                <input
                  type={"text"}
                  id="amount_requested"
                  placeholder="NGN 340,000"
                  className="form_input"
                />
              </div>

              <Button
                bg={"#F8AD15"}
                clr="#FFFFFF"
                style={{
                  width: "100%",
                  justifyContent: "center",
                  height: "65px",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenRentalFinanceModal(false);
                }}
              >
                Request NGN 340,000
              </Button>
            </FormPart>
          </FormContainer>
        </Modal>
      </div>
    </Style>
  );
};

// Rental.layout = DashboardLayout;

export default Rental;
