import styled from "styled-components";
import media from "styled-media-query";

import { CiSearch } from "react-icons/ci";
import SideMenuHolderContainer from "../../components/blocks/SideMenuHolderContainer";

import HomeNav from "../../components/blocks/developer/HomeNav";
import Header from "../../components/blocks/Header";
import DynamicScrollToTop from "../../utils/DynamicScrollToTop";
// import StillHaveQuestions from "../../components/blocks/StillHaveQuestions";

import LTLogo from "../../Assets/images/LT LOGO.png";

const Styles = styled.div`
  position: relative;
  .padding_top {
    /* height: 111px; */
    height: 63px;

    ${media.greaterThan("1024px")`
    height: 111px;
    `}
  }
`;

const Section = styled.section`
  padding: 80px 5% 60px 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: rgba(183, 123, 47, 0.1);
  margin-bottom: 50px;

  ${media.greaterThan("768px")`
  
  `};
  ${media.greaterThan("1024px")`
    padding: 100px 5% 75px 5%;
  `};
  ${media.greaterThan("1440px")`
    padding: 150px 5% 98px 5%;
  `};

  .searchbox_container {
    width: 100%;
    margin-top: 53px;
    position: relative;
    max-width: 825px;

    ${media.greaterThan("768px")`

  `};
    ${media.greaterThan("1024px")`
    margin-bottom: 99px;

  `};
    ${media.greaterThan("1440px")`

  `};

    label {
      position: absolute;
      left: -999999px;
    }
    input {
      background: #ffffff;
      box-shadow: 0px 10px 15px rgba(151, 151, 151, 0.08);
      border-radius: 10px;
      width: 100%;
      height: 50px;
      border: none;
      padding: 10px 50px;
      font-size: 14px;

      ${media.greaterThan("768px")`
      height: 60px;
      font-size: 16px;
      padding: 10px 65px;

  `};
      ${media.greaterThan("1024px")`
      height: 74px;
      font-size: 18px;
      padding: 10px 75px;
  `};
      ${media.greaterThan("1440px")`
      font-size: 20px;

  `};
    }
  }
`;

const Title = styled.h2`
  font-family: "Tiempos Headline";
  font-size: 40px;
  line-height: 110%;
  letter-spacing: -0.03em;
  color: #1a191e;
  padding-bottom: 30px;
  max-width: 844px;

  ${media.greaterThan("768px")`
    font-size: 50px;
  `};
  ${media.greaterThan("1024px")`
    font-size: 60px;
      margin: 0 auto;
  `};
  ${media.greaterThan("1440px")`
  `};
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
  color: #1a191e;
  max-width: 650px;

  ${media.greaterThan("768px")`
    font-size: 18px;
  `};
  ${media.greaterThan("1024px")`
    font-size: 20px;
     margin: 0 auto;
  `};
  ${media.greaterThan("1440px")`
  `};
`;

const ComingSoon = styled.div`
  /* position: relative; */

  padding: 200px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: medium;

  p {
    padding-top: 5px;
  }

  ${media.greaterThan("1024px")`
    padding: 250px 0;
    font-size: 50px;
    `};
`;

const Developers = () => {
  const navs = [
    "Home",
    "Integration Guides",
    "Verifications",
    "SDK's and Plugins",
    "API Reference",
  ];

  const comingSoon = true;

  const navContents = [<HomeNav key={0} />];

  return (
    <>
      <Header />
      {comingSoon ? (
        <ComingSoon>
          <img src={LTLogo} alt="logo" />
          <p>Coming Soon</p>
        </ComingSoon>
      ) : (
        <Styles>
          <DynamicScrollToTop />
          <div className="padding_top"></div>
          <Section>
            <Title>Landlords Technology Developer Documentation</Title>
            <Description>
              You don&apos;t have to wait for your tenants delayed payments, we
              pay on their behalf, integrate with us and we savage the rental
              issues
            </Description>

            <div className="searchbox_container">
              <CiSearch
                size={20}
                style={{
                  position: "absolute",
                  left: "3%",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              />

              <label htmlFor="search">Search</label>
              <input type={"text"} id="search" placeholder="Search..." />
            </div>
          </Section>
          <SideMenuHolderContainer
            basic
            navs={navs}
            navContents={navContents}
          />
        </Styles>
      )}
    </>
  );
};

export default Developers;
