import React, { useEffect } from "react";

import styled from "styled-components";
import media from "styled-media-query";
import { IoCheckmarkSharp } from "react-icons/io5";
import { HiOutlineArrowSmRight } from "react-icons/hi";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { RiPhoneFill } from "react-icons/ri";
import { MdOutlineMarkEmailUnread } from "react-icons/md";
import { Link, ScrollRestoration, useNavigate } from "react-router-dom";

import twoPhones from "../Assets/images/two_phones.png";
import secondSlideImage from "../Assets/images/second_slide_image_new.svg";
import reportingStats from "../Assets/images/reporting_stats.png";
import easyIntegration from "../Assets/images/easy_integration.png";
import partnersNetwork from "../Assets/images/partners_network.png";
import twoHands from "../Assets/images/two_hands_shaking.svg";
import haapLogo from "../Assets/images/haapLogo.svg";
import reicLogo from "../Assets/images/reicLogo.svg";
import propBuyLogo from "../Assets/images/propBuyLogo.svg";
import ExploreOurVerificationApi from "../Assets/images/explore_our_verification_api.svg";
import seemlessApiPic from "../Assets/images/api_integration_ad_image.png";
import halfPhone from "../Assets/images/half_phone.svg";
import laravelIcon from "../Assets/images/integrations/laravel.svg";
import wooCommerce from "../Assets/images/integrations/woo_commerce.svg";
import drupalIcon from "../Assets/images/integrations/drupal.svg";
import xeroIcon from "../Assets/images/integrations/xero.svg";
import zapierIcon from "../Assets/images/integrations/zapier.svg";
import wordpressIcon from "../Assets/images/integrations/wordpress.svg";
import zohoIcon from "../Assets/images/integrations/zoho.svg";
import standardRoomAdPic from "../Assets/images/standard_room_ad_picture.svg";
import backgroundDecorator from "../Assets/images/line_background.png";
import verificationAdvertImage from "../Assets/images/verification_transparent_image.svg";

// import OrangeButton from "../components/elements/OrangeButton";
// import LogoContainer from "../components/elements/LogoContainer";
import Button from "../components/elements/Button";
import LogoContainerOther from "../components/elements/LogoContainerOther";
import axios from "axios";
import NeedHelp from "../components/blocks/NeedHelp";
import DynamicScrollToTop from "../utils/DynamicScrollToTop";
// import NeedHelp from "../components/blocks/NeedHelp";
// import BaseApi from "../Api/BaseApi";

const Style = styled.main`
  .slider_section {
    padding-top: 60px;
    /* margin-top: 120px; */
  }

  .space {
    ${media.greaterThan("medium")`
    margin-top: 180px;
    `}
  }
  display: flex;
  width: 100vw;
  /* max-width: 400px; */
  flex-direction: column;
  margin: 0 auto;

  ${media.greaterThan("600px")`
    max-width: 100%;
  `}
  ${media.greaterThan("1024px")`

  `}
  ${media.greaterThan("1440px")`
    max-width: 1728px;
  `}

  .real_estate_finance_section {
    /* width: 100px; */
    ${media.greaterThan("600px")`
    
  `}
    ${media.greaterThan("1024px")`
      padding-top: 250px;
      padding-bottom: 270px;
  `}
  ${media.greaterThan("1440px")`
    max-width: 1728px;
  `}
  }

  .flexed_section_container {
    margin-bottom: 100px;
    ${media.greaterThan("600px")`
      margin-bottom: 150px;
    `}
    ${media.greaterThan("1024px")`
      margin-bottom: 200px;
    `}
    ${media.greaterThan("1440px")`
    
      margin-bottom: 280px;
    `}
  }

  .sliding_box_container {
    position: relative;
  }
  .background_decorator {
    position: absolute;
    /* block-size: auto; */
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: -webkit-fill-available;
    /* max-inline-size: 100%; */
    /* block-size: auto; */

    object-fit: cover;
    z-index: -1;
    display: none;

    ${media.greaterThan("600px")`
    
    `}
    ${media.greaterThan("1024px")`
      max-inline-size: 100%;
      display: block
    `}
    ${media.greaterThan("1440px")`
      block-size: auto;
      /* max-height: 700px; */
    `}
  }
`;

export const FlexedSection = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 40px 5%;
  width: 100%;
  height: 100%;
  max-width: 400px;
  background: ${(props) => (props.bg ? props.bg : "#fff")};
  /* gap: 50px; */
  gap: 0px;
  z-index: 1;

  ${media.greaterThan("600px")`
    /* padding: 80px 10%; */
    max-width: 100%;
  `}
  ${media.greaterThan("1024px")`
    flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
    max-width: 100%;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    /* gap: 50px; */
    gap: 15%
    
  `}
  ${media.greaterThan("1440px")`
    /* flex-direction: row; */
    max-width: 100%
    gap: 20%
    
  `}

  .first_texts_part {
    /* background-color: #fff; */
    max-width: 692px;
    flex: 1;

    ul {
      margin-top: 23px;
      margin-bottom: 45px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      ${media.greaterThan("600px")`
        /* max-width: 50%; */
      `}
      ${media.greaterThan("1024px")`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      `}
    }
  }

  .first_picture_part {
    position: relative;
    width: 100%;
    height: 300px;
    margin: 0 auto;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: contain;

    ${media.greaterThan("600px")`
      margin-top: 70px;
      max-width: 450px;
      height: 555px;

    `};
    ${media.greaterThan("1024px")`
      /* width: 40%; */
      max-width: 40%;
      height: 555px;
    `};

    img {
      ${media.greaterThan("600px")`
      
      `};
    }
  }

  .third_picture_part {
    position: relative;
    /* flex: 1; */
    width: 100%;
    height: 280px;
    /* margin: 0 auto; */
    margin: 50px auto;

    ${media.greaterThan("600px")`
      /* margin-top: 0; */
      /* width: 50%; */
      margin: 80px auto;
      max-width: 420px;
      height: 320px;

    `};
    ${media.greaterThan("1024px")`
      flex: 1;
      width: 45%;
      /* max-width: 4%; */
      height: 400px;
      margin-top: 0px;

    `};
    ${media.greaterThan("1440px")`
      flex: 1;
      width: 40%;
      max-width: 40%;
      height: 450px;

    `};
  }

  .fourth_picture_container {
    position: relative;
    width: 100%;
    max-width: 450px;
    height: 400px;
    margin: 0 auto;

    ${media.greaterThan("600px")`
      /* max-width: 50% */
      height: 503px;

    `};
    ${media.greaterThan("1024px")`
      

    `};
  }
`;

const ValidateGovernmentTextPart = styled.div`
  width: 100%;
  max-width: 692px;

  ${media.greaterThan("768px")`

`};
  ${media.greaterThan("1024px")`
/* margin-top: 180px; */
      font-size: 17.9551px;
      margin-bottom: 20px;
      /* max-width: 647px; */
      /* max-width: 60%; */
  `};
`;

const SeemlessApiNormalText = styled.p`
  font-size: 14px;
  line-height: 180%;
  color: #1a191e;
  margin-bottom: 15px;

  ${media.greaterThan("768px")`
    
  `};
  ${media.greaterThan("1024px")`
      font-size: 17.9551px;
      margin-bottom: 20px;
  `};
`;

const TrustedBy = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 4%;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 80px;

  ${media.greaterThan("600px")`
      max-width: 60%;
      margin: 0 auto;
      margin-top: 80px;
      marginBottom: 120px;

    `};
  ${media.greaterThan("1024px")`
  margin-top: 100px;
  margin-bottom: 250px;
    `};

  p {
    font-family: "ApercuProBoldNormal";
    font-size: 14px;
    line-height: 25px;
    color: #000000;

    ${media.greaterThan("1024px")`
      font-size: 18px;
    `};
    ${media.greaterThan("1440px")`
      font-size: 20px;
    `};
  }

  .title {
    margin-bottom: 20px;

    ${media.greaterThan("600px")`
      margin-bottom: 24px;
    `};
    ${media.greaterThan("1024px")`
      margin-bottom: 32px;
    `};
  }
`;

const SecondPictureContainer = styled.div`
  position: relative;
  width: 100%;
  /* height: 301px; */
  max-width: 450px;

  ${media.greaterThan("600px")`
      font-size: 40px;
      /* width: 45%; */
      max-width: 100%;
  `}

  ${media.greaterThan("1024px")`
      font-size: 50px;
      max-width: 530px;
      /* max-width: 45%; */

  `}

  .second_picture_part {
    /* position: relative; */
    width: 100%;
  }

  .small_cards_container {
    display: flex;
    flex-direction: column;
    /* gap: 20px; */

    ${media.greaterThan("600px")`
      flex-direction: row;
      /* gap: 15px; */
      justify-content: space-between;
  `}
    ${media.greaterThan("1024px")`
      flex-direction: row;
      /* gap: 15px; */
  `}

  .first_card {
      margin-bottom: 20px;

      ${media.greaterThan("600px")`
        margin-bottom: 0px;
      `}
    }
  }

  .explore_image_container {
    position: relative;
    margin-top: 40px;

    ${media.greaterThan("768px")`
      margin-top: 60px;
    `}
    ${media.greaterThan("1024px")`
      margin-top: 0px;
    `}
  }
`;

const FinanceReality = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5%;
  /* gap: 50px; */

  .headings {
    width: 100%;
    max-width: 800px;
    text-align: center;
    margin-bottom: 35px;

    ${media.greaterThan("1024px")`
      /* margin-top:180px; */
      margin-bottom: 74px;

    `}
  }
`;

const FlexedContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 120px;

  width: 100%;
  padding: 0 5%;
  padding-top: 50px;
  max-width: 400px;

  ${media.greaterThan("768px")`
      
    `} ${media.greaterThan("1024px")`
      padding-top: 70px;
      max-width: 100%;
      flex-direction: row-reverse;
      gap: 32px
      max-width: 1200px;
    `} 
    
    .pictures_relative_container {
    position: relative;
    width: 100%;
    height: 400px;

    ${media.greaterThan("768px")`

    `}
    ${media.greaterThan("1024px")`
    height: 500px
    
    `};

    .picture_container_minor {
      position: absolute;
      z-index: 1;
      top: 80.6%;
      left: 8.5%;

      width: 100%;
      height: 180px;

      .network_picture_container {
        width: 100%;
        height: 200px;
      }
    }
  }

  .second_picture_container {
    position: relative;
    width: 100%;
    height: 500px;
    top: 15px;
    ${media.greaterThan("768px")`
    height: 699px;
    `}
    ${media.greaterThan("1024px")`
    
    `};
  }
`;

const BlueContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  background: #f4f9fc;
  border-radius: 20px;
  .title {
    font-family: "ApercuProMediumNormal";
    font-size: 20px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.03em;
    color: #1a191e;

    ${media.greaterThan("600px")`
      font-size: 30px;
    `}

    ${media.greaterThan("1024px")`
      font-size: 40px;

    `}
  }

  .description {
    font-size: 14px;
    line-height: 180%;
    text-align: center;
    color: rgba(26, 25, 30, 0.5);
    width: 100%;
    max-width: 400px;

    ${media.greaterThan("600px")`
      font-size: 16px;
    `}

    ${media.greaterThan("1024px")`
      font-size: 18px;

    `}
  }
`;

export const BigTitle = styled.h1`
  font-family: "Tiempos Headline";
  font-size: 30px;
  line-height: 110%;
  letter-spacing: -0.03em;
  color: #000000;
  margin-bottom: 24px;

  ${media.greaterThan("600px")`
      font-size: 40px;
    `}

  ${media.greaterThan("1024px")`
      font-size: 50px;

    `}
`;

const NormalTexts = styled.p`
  font-family: "ApercuProMediumNormal";
  font-size: 16px;
  line-height: 142.8%;
  color: #000000;

  ${media.greaterThan("1024px")`
      font-size: 20px;
    `}
`;

const PointListed = styled.li`
  display: flex;
  align-items: center;
  gap: 10px;

  .point_text {
    font-family: "ApercuProMediumNormal";
    font-size: 18px;
    line-height: 180%;
    color: #1a191e;
  }
`;

export const MarkCircle = styled.div`
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 26px;
  height: 26px;
  background: ${(props) => (props.bg ? props.bg : "#e8f2ee")};
  border-radius: 100px;
`;

const LogosFlex = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 0 auto;

  ${media.greaterThan("600px")`
    max-width: 569px
  `}
`;

export const SmallCard = styled.div`
  background: ${(props) => (props.no_bg ? "none" : "#ffffff")};
  box-shadow: ${(props) =>
    props.no_box ? "none" : "0px 4px 4px rgba(0, 0, 0, 0.25)"};
  border-radius: 11px;
  display: flex;
  gap: 17px;
  align-items: center;
  padding: 15px 13px;
  /* width: 100%; */
  max-width: ${(props) => (props.no_box ? "320px" : "271px")};

  .card_texts {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .title {
      font-family: "ApercuProBoldNormal";
      font-size: 16px;
      line-height: 110%;
      letter-spacing: -0.03em;
      color: #1a191e;
    }

    .subtitle {
      font-size: 10px;
      line-height: 180%;
      color: #1a191e;
    }
  }
`;

const VerificationSmallCard = styled.div`
  background: ${(props) => (props.no_bg ? "none" : "#ffffff")};
  box-shadow: ${(props) =>
    props.no_box ? "none" : "0px 4px 4px rgba(0, 0, 0, 0.25)"};
  border-radius: 11px;
  display: flex;
  align-items: center;
  padding: 15px 13px;
  width: 100%;
  max-width: ${(props) => (props.no_box ? "320px" : "100%")};

  ${media.greaterThan("600px")`
      width: 45%
  `}
  ${media.greaterThan("1024px")`
      width: 48%
  `}

  .card_texts {
    display: flex;
    flex-direction: column;
    /* gap: 5px; */

    .title {
      font-family: "ApercuProBoldNormal";
      font-size: 16px;
      line-height: 110%;
      letter-spacing: -0.03em;
      color: #1a191e;
    }

    .subtitle {
      font-size: 10px;
      line-height: 180%;
      color: #1a191e;
    }
  }
`;

const OrangeBoxContainer = styled.div`
  position: relative;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 33px 0px;
  background: #fbbd29;
  padding: 100px 5%;
  margin: 0 5%;
  top: 30px;
  z-index: 2;
  ${media.greaterThan("600px")`
    padding: 100px 5%;
  `};

  .headings {
    max-width: 600px;
    text-align: center;
    margin-bottom: 80px;
  }

  .content_all {
    /* width: 60%; */
    max-width: 700px;
    /* background: green; */
    margin: 0 auto;
    ${media.greaterThan("1024px")`
      width: 80%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    `};
    ${media.greaterThan("1440px")`
      max-width: 60%;
  
    `};

    .content {
      display: flex;
      flex-direction: column;
      /* gap: 10px; */
      max-width: 1000px;
      width: 100%;
      ${media.greaterThan("768px")`
      flex-direction: row;
      align-items: center;
      `};
      ${media.greaterThan("1024px")`
      flex-direction: row;
      align-items: center;
      `};
    }

    .button_container {
      width: 100%;
      ${media.greaterThan("1024px")`
        max-width: 149px;
        
      `};
    }
  }
`;

const SmallCardLocal = styled.div`
  background: ${(props) => (props.no_bg ? "none" : "#ffffff")};
  box-shadow: ${(props) =>
    props.no_box ? "none" : "0px 4px 4px rgba(0, 0, 0, 0.25)"};
  border-radius: 11px;
  display: flex;
  /* gap: 17px; */
  align-items: center;
  /* padding: 15px 13px; */
  /* width: 100%; */
  max-width: ${(props) => (props.no_box ? "320px" : "271px")};
  margin-bottom: 15px;

  ${media.greaterThan("1024px")`
    margin-bottom: 0px;
  `};

  .card_texts {
    display: flex;
    flex-direction: column;
    /* gap: 5px; */
    margin-left: 17px;

    .title {
      font-family: "ApercuProBoldNormal";
      font-size: 16px;
      line-height: 110%;
      letter-spacing: -0.03em;
      color: #1a191e;
      margin-bottom: 5px;
    }

    .subtitle {
      font-size: 10px;
      line-height: 180%;
      color: #1a191e;
    }
  }
`;

const BlueBackgroundBoxesContainer = styled.div`
  position: relative;
  bottom: -12px;
  display: flex;
  flex-direction: column;
  /* background: #ffdd22; */
  width: 100%;
  align-items: center;
  /* padding: 0 5%; */
  /* gap: 100px; */
  justify-content: center;
  ${media.greaterThan("1024px")`
    flex-direction: row-reverse;
    gap: 32px;
    align-items: flex-start;
    justify-content: center;
  `};

  .containing_container {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 400px;
    ${media.greaterThan("1024px")`
      flex: 1;
      max-width: 584px;
    `};
  }

  .containing_container:first-child {
    margin-bottom: 100px;
    ${media.greaterThan("1024px")`
    margin-bottom: 0;
  `};
  }
`;

const BlueBackground = styled.div`
  background: #f4f9fc;
  border-radius: 20px;
  padding: 0 5%;
  padding-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  ${media.greaterThan("768px")`

  `};
  ${media.greaterThan("1024px")`
  padding-left: 0;
  padding-right: 0;
      
  `};
  ${media.greaterThan("1440px")`
      
  `};

  .title {
    font-size: 25px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.03em;
    color: #1a191e;
    padding-bottom: 8px;

    ${media.greaterThan("768px")`
      font-size: 30px;
    `};
    ${media.greaterThan("1024px")`
      font-size: 40px;
    `};
    ${media.greaterThan("1440px")`
      
    `};
  }
  .common_text {
    font-size: 14px;
    line-height: 180%;
    text-align: center;
    color: rgba(26, 25, 30, 0.5);

    ${media.greaterThan("768px")`
      font-size: 16px;
    `};
    ${media.greaterThan("1024px")`
      font-size: 18px;
    `};
    ${media.greaterThan("1440px")`
      
    `};
  }

  .half_phone_container {
    position: relative;
    width: 100%;
    max-width: 380px;
    height: 300px;
    ${media.greaterThan("600px")`
      height: 420px;
    `};
    ${media.greaterThan("768px")`
      height: 420px;
    `};
    ${media.greaterThan("1024px")`
      height: 450px;
    `};
    ${media.greaterThan("1440px")`
      
    `};
  }

  .half_phone {
    /* margin: 0 auto; */
    /* height: 100px; */

    /* max-inline-size: 100%; */
    /* block-size: auto; */
    /* object-fit: contain; */
  }
`;

const Integrations = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
  padding-bottom: 150px;

  .icon_container {
    background: #fff;
    border-radius: 10px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const PartnersNetworkContainer = styled.div`
  position: absolute;
  background: #f096a0;
  border-radius: 20px;
  padding-left: 41px;
  padding-top: 37px;
  padding-bottom: 60px;
  bottom: -80px;
  width: 100%;
  ${media.greaterThan("600px")`
      left: 47px;
      
  `};
  ${media.greaterThan("1024px")`
      left: 47px;
      
  `};

  .hands_container {
    display: none;
    ${media.greaterThan("600px")`
      
  `};
    ${media.greaterThan("1024px")`
      /* display: block */
      
  `};
    ${media.greaterThan("1280px")`
      display: block
      
  `};
  }
  /* min-width: 249px; */
`;

const Home = () => {
  const navigate = useNavigate();

  const handleChatClick = () => {
    // navigate.push("https://api.whatsapp.com/send?phone=+2348099908909&text=hi");
    navigate("https://api.whatsapp.com/send?phone=+2348099908909&text=hi");
  };

  useEffect(() => {
    const fetchCountriesList = async () => {
      axios
        .get("/country/list")
        .then(async (res) => {
          const countriesList = await res?.data.data;
          localStorage.setItem("countriesList", JSON.stringify(countriesList));
        })
        .catch((err) => {
          console.log("this is the error from countries List: ", err);
        });
    };

    fetchCountriesList();
  }, []);

  useEffect(() => {
    const fetchConstants = async () => {
      axios.get("/constants").then(async (res) => {
        const constants = await res.data.data.customer;
        localStorage.setItem("constants", JSON.stringify(constants));
      });
    };

    fetchConstants();
  }, []);

  useEffect(() => {
    const fetchFaqs = async () => {
      axios
        .get("/faqs?limit=20&offset=0")
        .then(async (res) => {
          const faq = await res?.data.data;
          localStorage.setItem("faq", JSON.stringify(faq));
        })
        .catch((err) => {
          console.log("this is the error from countries List: ", err);
        });
    };

    fetchFaqs();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    // autoplaySpeed: 5000,
    autoplaySpeed: 5000,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Style>
      <DynamicScrollToTop />
      <div className="slider_section sliding_box_container">
        {/*} <img
          src={backgroundDecorator}
          alt=""
          className="background_decorator"
  /> */}
        <Slider {...settings}>
          <div>
            <div className="">
              <FlexedSection bg="transparent">
                <div className="first_texts_part">
                  <NormalTexts>B2C Solutions</NormalTexts>
                  <BigTitle>
                    Need Rental loan ? We Pay For You In Full or Split, Your
                    Choice
                  </BigTitle>
                  <NormalTexts>
                    Whether you are paying monthly or annually, we have got you
                    covered for your rent financing{" "}
                  </NormalTexts>
                  <ul>
                    <PointListed>
                      <MarkCircle>
                        <IoCheckmarkSharp color="#5BB5A2" />
                      </MarkCircle>
                      <p className="point_text">Rental Finance</p>
                    </PointListed>
                    <PointListed>
                      <MarkCircle>
                        <IoCheckmarkSharp color="#5BB5A2" />
                      </MarkCircle>
                      <p className="point_text">Affordable Payment</p>
                    </PointListed>
                    <PointListed>
                      <MarkCircle>
                        <IoCheckmarkSharp color="#5BB5A2" />
                      </MarkCircle>
                      <p className="point_text">Convenient</p>
                    </PointListed>
                    <PointListed>
                      <MarkCircle>
                        <IoCheckmarkSharp color="#5BB5A2" />
                      </MarkCircle>
                      <p className="point_text">100% Flexible</p>
                    </PointListed>
                  </ul>
                  <div style={{ display: "flex", gap: "5%" }}>
                    <Link to="/register">
                      <Button bg={"#fbbd29"} clr={"#fff"}>
                        Open Account
                      </Button>
                    </Link>

                    <Link to="/blogs">
                      <Button clr={"#fbbd29"}>
                        Learn More <HiOutlineArrowSmRight size={20} />
                      </Button>
                    </Link>
                  </div>
                </div>
                <div className="first_picture_part">
                  <img
                    src={twoPhones}
                    alt="main"
                    style={{
                      // width: "100%",
                      height: "100%",
                      position: "absolute",
                      maxInlineSize: "100%",
                      // blockSize: "auto",
                      objectFit: "contain",
                    }}
                  />
                </div>
              </FlexedSection>
            </div>
          </div>
          <div>
            <div>
              <FlexedSection bg="transparent">
                <div className="first_texts_part">
                  <NormalTexts>B2B Solutions</NormalTexts>
                  <BigTitle>
                    Are You A Business Seeking Property Rental Financing For
                    your Customers ?
                  </BigTitle>
                  <NormalTexts>
                    Deploy Our Application Programming Interface (APIs) to
                    enable flexible property rental financing and repayments on
                    your existing rental portals.
                  </NormalTexts>
                  <div
                    style={{ display: "flex", gap: "5%", marginTop: "45px" }}
                  >
                    <Link to="/register">
                      <Button bg={"#fbbd29"} clr={"#fff"}>
                        Open Dev Account
                      </Button>
                    </Link>
                    <Link to="/blogs">
                      <Button clr={"#fbbd29"}>
                        Learn More <HiOutlineArrowSmRight size={20} />
                      </Button>
                    </Link>
                  </div>
                </div>
                <div className="first_picture_part">
                  <img
                    src={secondSlideImage}
                    alt="main"
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      maxInlineSize: "100%",
                      blockSize: "auto",
                    }}
                  />
                </div>
              </FlexedSection>
            </div>
          </div>
        </Slider>
      </div>
      <TrustedBy>
        <p className="title"> Trusted by startups and businesses</p>
        <LogosFlex>
          <LogoContainerOther>
            <img
              src={haapLogo}
              alt=""
              style={{ width: "100%", height: "100%", position: "absolute" }}
            />
          </LogoContainerOther>
          <LogoContainerOther>
            <img
              src={reicLogo}
              alt=""
              style={{ width: "100%", height: "100%", position: "absolute" }}
            />
          </LogoContainerOther>
          <LogoContainerOther>
            <img
              src={propBuyLogo}
              alt=""
              style={{ width: "100%", height: "100%", position: "absolute" }}
            />
          </LogoContainerOther>
        </LogosFlex>
      </TrustedBy>

      {/*<div className="flexed_section_container">
        <FlexedSection reverse>
          <ValidateGovernmentTextPart>
            <NormalTexts>Real Estate, Proptech KYC</NormalTexts>
            <BigTitle>Validate Government data for Customers</BigTitle>
            <NormalTexts style={{ fontFamily: "ApercuProRegularNormal" }}>
              Ensure compliance in Nigeria by verifying that the data provided
              by users match government-issued IDs.
            </NormalTexts>
            <div style={{ display: "flex", gap: "5%", marginTop: "35px" }}>
              <Link to="/register">
                <Button bg={"#FBBD29"} clr={"#fff"}>
                  Open Account
                </Button>
              </Link>

              <Link to={"/register"}>
                <Button clr={"#FBBD29"}>
                  Get Started
                  <HiOutlineArrowSmRight size={20} />
                </Button>
              </Link>
            </div>
          </ValidateGovernmentTextPart>
          <SecondPictureContainer>
            <div className="explore_image_container">
              <img
                src={ExploreOurVerificationApi}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  position: "",
                  maxInlineSize: "100%",
                  blockSize: "auto",
                }}
              />
            </div>
            <div className="small_cards_container">
              <VerificationSmallCard className="first_card">
                <MarkCircle>
                  <IoCheckmarkSharp color="#5BB5A2" />
                </MarkCircle>
                <div className="card_texts">
                  <p className="title">Your KYC Verification</p>
                  <p className="subtitle">Integrate our API for your KYC</p>
                </div>
              </VerificationSmallCard>
              <VerificationSmallCard>
                <MarkCircle bg={"#FBBD29"}>
                  <IoCheckmarkSharp color="#5BB5A2" />
                </MarkCircle>
                <div className="card_texts">
                  <p className="title">Verify Documents +more</p>
                  <p className="subtitle">No Delay with Landlords Technology</p>
                </div>
              </VerificationSmallCard>
            </div>
          </SecondPictureContainer>
        </FlexedSection>
      </div> */}

      <div className="flexed_section_container sliding_box_container">
        {/*}  <img
          src={backgroundDecorator}
          alt=""
          className="background_decorator"
    /> */}
        <FlexedSection bg={"transparent"}>
          <div
            style={{ maxWidth: "692px", marginTop: "0px" }}
            className="first_texts_part space"
          >
            <NormalTexts>Tools</NormalTexts>
            <BigTitle>Seemless API integration</BigTitle>
            <SeemlessApiNormalText>
              Developers love our well-documented APIs that let you to build
              everything from simple rental financial projects, to complex
              rental financial products serving hundreds of thousands of
              customers. If you can imagine it, you can build it with Landlords
              Technology.
            </SeemlessApiNormalText>
            <SeemlessApiNormalText>
              Give your customers the gift of modern, frictionless, painless
              financial rental experience. Integrate our rental solution and let
              handle the rest on your behalf.
            </SeemlessApiNormalText>
            <Integrations style={{ paddingBottom: "0px" }}>
              <div
                style={{
                  paddingLeft: "10px",
                  paddingRight: "13px",
                  background: "#F8F8F8",
                }}
                className="icon_container"
              >
                <img src={laravelIcon} alt="" />
              </div>
              <div
                style={{
                  paddingLeft: "32px",
                  paddingRight: "32px",
                  background: "#F8F8F8",
                }}
                className="icon_container"
              >
                <img src={wooCommerce} alt="" />
              </div>
              <div
                style={{
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  background: "#F8F8F8",
                }}
                className="icon_container"
              >
                <img src={drupalIcon} alt="" />
              </div>
              <div
                style={{
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  background: "#F8F8F8",
                }}
                className="icon_container"
              >
                <img src={xeroIcon} alt="" />
              </div>
              <div
                style={{
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  background: "#F8F8F8",
                }}
                className="icon_container"
              >
                <img src={zapierIcon} alt="" />
              </div>
              <div
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  background: "#F8F8F8",
                }}
                className="icon_container"
              >
                <img src={wordpressIcon} alt="" />
              </div>
              <div
                style={{
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  background: "#F8F8F8",
                }}
                className="icon_container"
              >
                <img src={zohoIcon} alt="" />
              </div>
            </Integrations>
          </div>
          <div className="third_picture_part space">
            <img
              src={seemlessApiPic}
              alt=""
              style={{ maxInlineSize: "100%", blockSize: "auto" }}
            />
          </div>
        </FlexedSection>
      </div>
      <FinanceReality>
        <div className="headings">
          <BigTitle>All in one Rental Finance Reality.</BigTitle>
          <NormalTexts>
            All inclusive rental financing for individuals, startups and
            entrepreneurs.
          </NormalTexts>
        </div>
        <BlueBackgroundBoxesContainer
        // style={{ maxWidth: "1200px", padding: "0" }}
        >
          <div className="containing_container" style={{}}>
            <BlueBackground style={{ paddingLeft: "4%" }}>
              <h2 style={{ textAlign: "left" }} className="title">
                Easy integration
              </h2>
              <p
                style={{ textAlign: "left", marginBottom: "60px" }}
                className="common_text"
              >
                Integrate swiftly with our packages
              </p>
              <Integrations style={{}}>
                <div
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "13px",
                  }}
                  className="icon_container"
                >
                  <img src={laravelIcon} alt="" />
                </div>
                <div
                  style={{
                    paddingLeft: "32px",
                    paddingRight: "32px",
                  }}
                  className="icon_container"
                >
                  <img src={wooCommerce} alt="" />
                </div>
                <div
                  style={{
                    paddingLeft: "16px",
                    paddingRight: "16px",
                  }}
                  className="icon_container"
                >
                  <img src={drupalIcon} alt="" />
                </div>
                <div
                  style={{
                    paddingLeft: "16px",
                    paddingRight: "16px",
                  }}
                  className="icon_container"
                >
                  <img src={xeroIcon} alt="" />
                </div>
                <div
                  style={{
                    paddingLeft: "16px",
                    paddingRight: "16px",
                  }}
                  className="icon_container"
                >
                  <img src={zapierIcon} alt="" />
                </div>
                <div
                  style={{
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                  className="icon_container"
                >
                  <img src={wordpressIcon} alt="" />
                </div>
                <div
                  style={{
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  }}
                  className="icon_container"
                >
                  <img src={zohoIcon} alt="" />
                </div>
              </Integrations>
            </BlueBackground>
            <PartnersNetworkContainer>
              <div style={{ maxWidth: "335px" }}>
                <h2
                  style={{ color: "#fff", textAlign: "left" }}
                  className="title"
                >
                  Partnerships
                </h2>
                <p
                  style={{
                    color: "#fff",
                    textAlign: "left",
                    padding: "9px 0px",
                    lineHeight: "180%",
                  }}
                  className="common_text"
                >
                  The prefect handshake that works, we believe in building
                  together for a better future with technology
                </p>
              </div>
              <div
                className="hands_container"
                style={{ position: "absolute", right: "0", top: "0" }}
              >
                <img src={twoHands} alt="" />
              </div>
            </PartnersNetworkContainer>
          </div>

          <div className="containing_container">
            <BlueBackground style={{ position: "relative", botto: "-14px" }}>
              <h2 className="title">Reporting Statistics</h2>
              <p className="common_text" style={{ marginBottom: "65px" }}>
                Transparent breakdown of transaction reports
              </p>

              <div className={"half_phone_container"}>
                <img
                  src={halfPhone}
                  alt=""
                  style={{ maxInlineSize: "100%", blockSize: "auto" }}
                  // className="half_phone"
                />
              </div>
            </BlueBackground>
          </div>
        </BlueBackgroundBoxesContainer>
      </FinanceReality>
      <FlexedSection bg={"#E8F2EE"} className="real_estate_finance_section">
        <div style={{ background: "none" }} className="first_texts_part">
          <NormalTexts>Rental Finance</NormalTexts>
          <BigTitle>#1 B2B, B2C rental finance solution</BigTitle>
          <NormalTexts>
            Irrespective of who you are, we’ve got the prefect solution to make
            your rental finance platform a success
          </NormalTexts>
        </div>
        <div className="fourth_picture_container">
          <img
            src={standardRoomAdPic}
            alt=""
            style={{ maxInlineSize: "100%", blockSize: "auto" }}
          />
        </div>
      </FlexedSection>
      <NeedHelp bg={"#fff"} />

      <ScrollRestoration />
    </Style>
  );
};

export default Home;

//  <OrangeBoxContainer>
//    <div className="headings">
//      <BigTitle>Get started with Landlords Technology</BigTitle>
//      <NormalTexts style={{ color: "rgba(26, 25, 30, 0.5)" }}>
//        We are here to help
//      </NormalTexts>
//    </div>
//    <div className="content_all">
//      <div className="content">
//        <SmallCardLocal no_box no_bg style={{ marginRight: "40px" }}>
//          <MarkCircle style={{ background: "rgba(0, 0, 0, 0.2)" }}>
//            <RiPhoneFill color="#fff" />
//          </MarkCircle>
//          <div className="card_texts">
//            <p className="title" style={{ whiteSpace: "nowrap" }}>
//              +234 809 990 8909
//            </p>
//            <p className="subtitle">Support Hotline</p>
//          </div>
//        </SmallCardLocal>
//        <SmallCardLocal no_box no_bg>
//          <MarkCircle style={{ background: "rgba(0, 0, 0, 0.2)" }}>
//            <MdOutlineMarkEmailUnread color="#fff" />
//          </MarkCircle>
//          <div className="card_texts">
//            <p className="title">help@landlordstechnology.com</p>
//            <p className="subtitle">Support Email</p>
//          </div>
//        </SmallCardLocal>
//      </div>
//      <div className="button_container">
//        <Button
//          bg={"#1A191E"}
//          clr={"#FFFFFF"}
//          style={{
//            display: "flex",
//            justifyContent: "center",
//            alignItems: "center",
//            width: "100%",
//            whiteSpace: "nowrap",
//          }}
//        >
//          <a
//            href="https://wa.me/2348099908909"
//            target="_blank"
//            rel="noopener noreferrer"
//          >
//            Chat with us
//          </a>
//        </Button>
//      </div>
//    </div>
//  </OrangeBoxContainer>;
