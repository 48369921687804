import styled from "styled-components";
import media from "styled-media-query";
import CodingFieldImageContainer from "./CodingFieldImageContainer";
import CommonText from "./CommonText";
import HelpfulEnough from "./HelpfulEnough";
import ListingContainer from "./ListingContainer";
import Numbering from "./Numbering";
import SubTitle from "./SubTitle";
import TitleText from "./TitleText";

import AuthImageOne from "../../../Assets/images/developers/authentication_image_one.png";
import AuthImageTwo from "../../../Assets/images/developers/authentication_image_two.png";

const Styles = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${media.greaterThan("768px")`

  `};
  ${media.greaterThan("1024px")`

  `};
  ${media.greaterThan("1440px")`

  `};

  .last_section {
    padding-bottom: 103px;
  }
`;

const AuthenticationNav = () => {
  return (
    <Styles>
      <TitleText>Authentication</TitleText>
      <div>
        <SubTitle>Test Mode vs Live Mode</SubTitle>
        <CommonText>
          There are two &quot;modes&quot; of operation for your Landlords
          Technology account:
        </CommonText>
        <ListingContainer>
          <li>
            <Numbering>1</Numbering>
            <CommonText>
              <span>Live Mode: Real money,</span> real transactions, real
              effects. Only switch to this after you&apos;ve tested your
              integration thoroughly.
            </CommonText>
          </li>
          <li>
            <Numbering>2</Numbering>
            <CommonText>
              Test Mode: No real transaction is involved. Only a test
              transaction. We&apos;ll still send webhooks and email
              notifications, and most of the API functions the same.
            </CommonText>
          </li>
        </ListingContainer>
      </div>
      <div>
        <SubTitle>API keys</SubTitle>
        <CommonText>
          When you create a Landlords Technology account, you&apos;re given
          three kinds of API keys:
        </CommonText>

        <ListingContainer>
          <li>
            <Numbering size={"33px"}>1</Numbering>
            <CommonText>
              <span className="bold_text">Secret key:</span> The most powerful
              type of key. It can authorize any action on your account, so it
              should never be exposed to the public.
            </CommonText>
          </li>
          <li>
            <Numbering size={"33px"}>2</Numbering>
            <CommonText>
              <span className="bold_text">Public key:</span> The key you&apos;ll
              use in &quot;public&quot; scenarios, such as in front-end
              JavaScript code
            </CommonText>
          </li>
          <li>
            <Numbering size={"33px"}>3</Numbering>
            <CommonText>
              <span className="bold_text">Encryption key:</span> Only used with
              the direct bank charge endpoint. See the encryption guide for
              details.
            </CommonText>
          </li>
        </ListingContainer>
      </div>

      <div className="last_section">
        <SubTitle style={{ textAlign: "left" }}>Authorizing API calls</SubTitle>
        <CommonText>
          All API calls on Landlords Technology are authenticated. API requests
          made without authorization will fail with the status code 401:
          Unauthorized.
        </CommonText>

        <div style={{ paddingTop: "350px" }}>
          <CommonText>
            To authorize API calls from your server, pass your secret key as a
            bearer token. This means passing an Authorization header with a
            value of &quot;Bearer: YOUR_SECRET_KEY&quot;.
          </CommonText>
          <CommonText>
            For example, an API call could look like this in Node.js:
          </CommonText>
          <CodingFieldImageContainer
            height={"130px"}
            mdHeight={"200px"}
            lgHeight={"255px"}
          >
            <img
              src={AuthImageOne}
              alt=""
              style={{
                position: "",
                maxInlineSize: "100%",
                blockSize: "auto",
              }}
            />
          </CodingFieldImageContainer>
          <div
            style={{
              width: "90%",
              height: "6px",
              background: "#D9D9D9",
              borderRadius: "2.5px",
              marginTop: "7px",
            }}
          ></div>

          <CommonText>
            If you&apos;re using one of our backend SDKs, you don&apos;t need to
            pass the header manually; instead you&apos;ll provide your keys when
            initialising the library.
          </CommonText>
          <CodingFieldImageContainer
            height={"200px"}
            mdHeight={"260px"}
            lgHeight={"304px"}
          >
            <img
              src={AuthImageTwo}
              alt=""
              style={{
                position: "",
                maxInlineSize: "100%",
                blockSize: "auto",
              }}
            />
          </CodingFieldImageContainer>
        </div>
      </div>

      <HelpfulEnough />
    </Styles>
  );
};

export default AuthenticationNav;
