import React, { useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import { createBrowserRouter, RouterProvider, Router } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { getCookie, setCookie } from "./cookiesHelper";
import { nanoid } from "nanoid";
import Login from "../pages/Login";

const router = createBrowserRouter([
  {
    path: "/signin",
    element: <Login />,
  },
]);

const Interceptors = ({ children }) => {
  // Add a request interceptor
  // const navigate = useNavigate();
  // const location = useLocation();
  // const path = location.pathname;
  axios.interceptors.request.use(
    function (config) {
      const header = config.headers;
      console.log(header);
      config.baseURL = process.env.REACT_APP_API_BASE_URL;
      // alert(process.env.REACT_APP_API_BASE_URL);
      config.headers = {
        Authorization: `Bearer ${getCookie("user_token")}`,
        // "Access-Control-Allow-Origin": '*',

        "Content-Type": "application/json",

        ...header,
      };
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      // console.log(response);
      return response;
    },
    function (error) {
      console.log("errr", error);
      if (error.response?.status === 401) {
        localStorage.removeItem("userInfo");
        localStorage.removeItem("loginResponse");
        // navigate("/signin");
        window.location.replace("/signin");
      }
      // alert("yoo");
      // console.log(error.response);

      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      // debounce(refreshToken(), 1000);
      // alert("api failed");
      // if (error && error.response !== undefined) {
      //   if (error.response.status === 401)
      //     router.push(`/dashboard/apilogin?redirect=${path}`);
      //   console.log("token expired");
      // }
      // console.log("got maliq errrr cookie");

      //   console.log(error.response);
      return Promise.reject(error);
    }
  );

  // intercept();
  // Add a response interceptor
  // useEffect(() => {
  // interceptorResponse();

  // setInterval(() => {
  //   checkNotification();
  // }, 10000);
  // }, []);

  return <>{children}</>;
};
const mapDispatchToProps = (dispatch) => ({
  // getpaymentLinks: () => dispatch(updatePaymentLinkTable()),
  // refreshToken: () => dispatch(refreshToken()),
  // checkNotification: () => dispatch(checkNotification()),
  // ejectSession: () => dispatch(ejectSession()),
  // endSession: () => dispatch(endSession()),
});

export default Interceptors;
// export default React.memo(connect(null, mapDispatchToProps)(Interceptors));
// export default Interceptors;
