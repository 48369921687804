import styled from "styled-components";
import media from "styled-media-query";
import { useNavigate } from "react-router-dom";
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from "react-share";

import womanLookingLaptop from "../../Assets/images/woman_looking_desktop.png";
import facebookIcon from "../../Assets/images/facebook_icon.svg";
import twitterIcon from "../../Assets/images/twitter_icon.svg";
import instagramIcon from "../../Assets/images/instagram_icon.svg";
import whatsappIcon from "../../Assets/images/whatsapp_icon.svg";

import Button from "../../components/elements/Button";
import DynamicScrollToTop from "../../utils/DynamicScrollToTop";
import useAppHook from "../../hooks/useAppHook";
import Sanitizer from "../../utils/Sanitizer";
import { useEffect, useState } from "react";
// import StillHaveQuestions from "../../components/blocks/StillHaveQuestions";

const Styles = styled.section`
  position: relative;
  padding-bottom: 150px;

  ${media.greaterThan("768px")`
    padding-bottom: 180px;
  `};
  ${media.greaterThan("1024px")`

  `};
  ${media.greaterThan("1440px")`
    padding-bottom: 350px;
  `};

  .left_texts_part {
  }

  .large_image {
    width: 100%;
    max-inline-size: 100%;
    block-size: auto;
    max-height: 672px;
    object-fit: cover;
    /* object-position: top center; */
  }
`;

const LargeImage = styled.div`
  position: relative;
  width: 100%;
  height: 672px;
`;

const BodyContainer = styled.div`
  font-family: "ApercuProMediumNormal";
  padding: 0 5%;

  .categories {
    display: flex;
    gap: 18px;
    margin-top: 20px;
    margin-bottom: 15px;

    ${media.greaterThan("768px")`
          
      `};
    ${media.greaterThan("1024px")`
          margin-top: 35px;
    margin-bottom: 25px;
      `};
    ${media.greaterThan("1440px")`
        margin-top: 45px;
        margin-bottom: 34px;
    `};
  }

  .images {
    /* margin-top: 60px; */
    display: flex;
    flex-direction: column;
    /* gap: 68px; */
    align-items: center;

    ${media.greaterThan("768px")`
  gap: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
  /* grid-row-gap: 68px; */

  `};
    ${media.greaterThan("1024px")`
  grid-template-columns: repeat(3, 1fr)

  `};
    ${media.greaterThan("1440px")`

  `};
  }

  .text_content {
    /* display: flex;
    flex-direction: column; */
    /* gap: 20px; */
    padding-bottom: 50px;
    font-size: 16px;
    line-height: 2;
    letter-spacing: -0.03em;
    color: rgba(0, 0, 0, 0.6);
  }
`;

const BlogTitle = styled.h2`
  font-size: 40px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #1a191e;

  ${media.greaterThan("768px")`
        font-size: 45px;
      `};
  ${media.greaterThan("1024px")`
        font-size: 52px;
      `};
  ${media.greaterThan("1440px")`
        font-size: 60px;
      `};
`;
const SpecialText = styled.p`
  font-size: 16px;
  line-height: 160%;
  color: #9bb984;
  margin: 24px 0;

  ${media.greaterThan("768px")`
      font-size: 18px;
  `};
  ${media.greaterThan("1024px")`
    font-size: 20px;
  `};
  ${media.greaterThan("1440px")`
    font-size: 23px;
  `};

  .big_apos {
    font-size: 30px;
    ${media.greaterThan("768px")`
      font-size: 40px;
  `};
    ${media.greaterThan("1024px")`
      font-size: 50px;
  `};
    ${media.greaterThan("1440px")`
      font-size: 60px;
  `};
  }
`;
const ContentText = styled.p`
  font-size: 16px;
  line-height: 2;
  letter-spacing: -0.03em;
  color: rgba(0, 0, 0, 0.6);
`;

const ImageContainer = styled.div`
  width: 100%;
  max-width: 378px;
  /* height: 378px; */
  height: 250px;
  background: #f8f8f8;
  border-radius: 16px;
  position: relative;
  margin-bottom: 24px;
  object-fit: cover;

  ${media.greaterThan("1024px")`
      /* height: 330px; */
      height: 300px;
    `};
  ${media.greaterThan("1440px")`
      height: 378px;
    `};
`;

const Category = styled.button`
  background: #f8f8f8;
  border-radius: 4px;
  padding: 4px 10px;
  border: none;
`;

const ExtraButtons = styled.div`
  position: relative;
  padding: 0 5%;
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: flex-start;
  justify-content: space-between;
  align-items: center;
  /* background: red; */
  padding-top: 80px;

  ${media.greaterThan("768px")`
    /* flex-direction: row; */
    padding-top: 43px;
    padding-bottom: 43px;
    
  `};
  ${media.greaterThan("1024px")`

  `};
  ${media.greaterThan("1440px")`

  `};

  .social_medias {
    position: absolute;
    /* background: blue; */
    display: flex;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    gap: 12px;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 1;
    ${media.greaterThan("768px")`
      top: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
    
  `};
  }
`;

const MediaIconContainer = styled.div`
  position: relative;
  width: 42px;
  height: 42px;
  cursor: pointer;
`;

const BlogContent = () => {
  const navigate = useNavigate();
  const [blogContent, setBlogContent] = useState();
  const [blogIndex, setBlogIndex] = useState();
  const [allBlogs, setAllBlogs] = useState([]);

  useEffect(() => {
    const blogResult = JSON.parse(localStorage.getItem("blogContent")) || null;
    if (blogResult) {
      console.log(
        "this is the blogResult from the local storage: ",
        blogResult
      );
      setBlogContent(blogResult);
      setBlogIndex(blogResult?.blogIndex);
    }
    const allBlogsResult = JSON.parse(localStorage.getItem("allBlogs")) || [];
    if (allBlogsResult) {
      setAllBlogs(allBlogsResult);
    }
  }, [navigate]);

  const handleNextClick = () => {
    console.log("Next button clicked!!!");
    const nextIndex = blogIndex + 1;
    // setBlogIndex(nextIndex);
    if (nextIndex <= allBlogs.length) {
      localStorage.setItem(
        "blogContent",
        JSON.stringify({
          blogContentMain: allBlogs[nextIndex],
          blogIndex: nextIndex,
        })
      );
    }
    navigate(`/blogs/${allBlogs[nextIndex].slug}`);
    // window.location.reload();
  };

  const handlePrevClick = () => {
    console.log("Prev button clicked!!!");
    const prevIndex = blogIndex - 1;
    // setBlogIndex(prevIndex);
    if (prevIndex >= 0) {
      localStorage.setItem(
        "blogContent",
        JSON.stringify({
          blogContentMain: allBlogs[prevIndex],
          blogIndex: prevIndex,
        })
      );
    }
    navigate(`/blogs/${allBlogs[prevIndex].slug}`);
    // window.location.reload();
  };

  return (
    <>
      {blogContent ? (
        <Styles>
          <DynamicScrollToTop />
          <>
            <img
              src={blogContent?.blogContentMain?.media[0]?.original_url}
              alt=""
              className="large_image"
            />
          </>
          <BodyContainer>
            <div className="categories">
              {blogContent?.blogContentMain?.categories?.map((category) => {
                return (
                  <Category key={category.id}>{category.category}</Category>
                );
              })}
            </div>
            <BlogTitle>{blogContent?.blogContentMain.title}</BlogTitle>
            {/*  <SpecialText>
              <span className="big_apos">“</span>Lorem ipsum dolor sit amet,
              consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua. Dui accumsan sit amet nulla
              facilisi morbi. “
            </SpecialText> */}
            {/* <div className="images">
              <ImageContainer>
                <img
                  src={blog.media[0]?.original_url}
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </ImageContainer>
              <ImageContainer>
                <img
                  src={blog.media[0]?.original_url}
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </ImageContainer>
              <ImageContainer>
                <img
                  src={blog.media[0]?.original_url}
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </ImageContainer>
            </div> */}
            <div className="text_content">
              <div
                dangerouslySetInnerHTML={{
                  __html: blogContent?.blogContentMain.content,
                }}
              />
            </div>
          </BodyContainer>
          <ExtraButtons>
            <div className="social_medias">
              <p
                style={{
                  marginRight: "10px",
                }}
              >
                share this
              </p>

              <FacebookShareButton
                url="/rough"
                // quote={props.joke.setup + props.joke.punchline}
                // hashtag="#programing joke"
              >
                <MediaIconContainer>
                  <img
                    src={facebookIcon}
                    alt="facebook"
                    style={{ width: "100%", height: "100%" }}
                  />
                </MediaIconContainer>
              </FacebookShareButton>

              <TwitterShareButton
                url="/rough"
                // quote={props.joke.setup + props.joke.punchline}
                // hashtag="#programing joke"
              >
                <MediaIconContainer>
                  <img
                    src={twitterIcon}
                    alt="twitter"
                    style={{ width: "100%", height: "100%" }}
                  />
                </MediaIconContainer>
              </TwitterShareButton>

              <LinkedinShareButton
                url="/rough"
                // quote={props.joke.setup + props.joke.punchline}
                // hashtag="#programing joke"
              >
                <MediaIconContainer>
                  <img
                    src={instagramIcon}
                    alt="instagram"
                    style={{ width: "100%", height: "100%" }}
                  />
                </MediaIconContainer>
              </LinkedinShareButton>

              <WhatsappShareButton
                url="/rough"
                // quote={props.joke.setup + props.joke.punchline}
                // hashtag="#programing joke"
              >
                <MediaIconContainer>
                  <img
                    src={whatsappIcon}
                    alt="whatsapp"
                    style={{ width: "100%", height: "100%" }}
                  />
                </MediaIconContainer>
              </WhatsappShareButton>
            </div>
            <Button
              bg={"#FBBD29"}
              onClick={handlePrevClick}
              disabled={blogIndex <= 0}
              style={{
                opacity: `${blogIndex <= 0 ? "50%" : "100%"},`,
                cursor: "pointer",
                zIndex: "1",
              }}
            >
              &lt;&lt; Previous Article
            </Button>
            <Button
              bg={" #1A1A1A"}
              clr={"#FBBD29"}
              onClick={handleNextClick}
              disabled={blogIndex >= allBlogs.length - 1}
              style={{
                opacity: `${blogIndex >= allBlogs.length - 1 ? "50%" : "100%"}`,
                cursor: "pointer",
                zIndex: "1",
              }}
            >
              Next Article &gt;&gt;
            </Button>
          </ExtraButtons>
        </Styles>
      ) : null}
    </>
  );
};

export default BlogContent;
