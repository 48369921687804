import { HiOutlineArrowSmRight } from "react-icons/hi";
import styled from "styled-components";
import media from "styled-media-query";

import { Link, useNavigate } from "react-router-dom";
import NeedHelp from "../components/blocks/NeedHelp";
import Button from "../components/elements/Button";
import DynamicScrollToTop from "../utils/DynamicScrollToTop";
import useAppHook from "../hooks/useAppHook";
import Validator from "../utils/Validator";

import { send } from "emailjs-com";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const Styles = styled.div`
  position: relative;
`;

const Section = styled.section`
  padding: 160px 5% 117px 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: rgba(183, 123, 47, 0.1);

  .buttons {
    display: flex;
    align-items: center;
    margin-top: 30px;
    gap: 10px;
  }
`;

const Title = styled.h2`
  font-family: "Tiempos Headline";
  font-size: 40px;
  line-height: 110%;
  letter-spacing: -0.03em;
  color: #1a191e;
  padding-bottom: 30px;
  max-width: 844px;

  ${media.greaterThan("768px")`
    font-size: 50px;
  `};
  ${media.greaterThan("1024px")`
    font-size: 60px;
      margin: 0 auto;
  `};
  ${media.greaterThan("1440px")`
  `};
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
  color: #1a191e;
  max-width: 650px;

  ${media.greaterThan("768px")`
    font-size: 18px;
  `};
  ${media.greaterThan("1024px")`
    font-size: 20px;
    margin: 0 auto;
  `};
  ${media.greaterThan("1440px")`
  `};
`;

const FlexedSection = styled.section`
  display: flex;
  flex-direction: column-reverse;
  padding: 150px 5%;
  gap: 50px;

  ${media.greaterThan("768px")`

  `};
  ${media.greaterThan("1024px")`
      flex-direction: row;
      align-items: center;
      gap: 5%;
  `};
  ${media.greaterThan("1440px")`
    gap: 10%;
  `};

  .texts_part {
    ${media.greaterThan("768px")`

  `};
    ${media.greaterThan("1024px")`
      width: 50%
  `};
    ${media.greaterThan("1440px")`
      width: 45%
  `};
  }
`;

const FormPart = styled.form`
  display: flex;
  flex-direction: column;
  gap: 27px;
  width: 100%;
  flex: 1;

  ${media.greaterThan("768px")`

  `};
  ${media.greaterThan("1024px")`
      /* max-width: 400px; */
  `};
  ${media.greaterThan("1300px")`
      /* max-width: 480px; */
  `};
  ${media.greaterThan("1440px")`
    /* max-width: 552px; */
  `};

  .sub_container {
    display: flex;
    flex-direction: column;
    gap: 27px;

    ${media.greaterThan("768px")`
    flex-direction: row;
    align-items: center;
    gap: 5%;
  `};
    ${media.greaterThan("1024px")`
   
  
  `};
    ${media.greaterThan("1440px")`
  `};
  }

  label {
    font-family: "ApercuProMediumNormal";
    font-size: 14px;
    line-height: 17px;
    color: #919499;
  }
  .form_input {
    border: 1.3px solid rgba(196, 196, 196, 0.5);
    border-radius: 14px;
    margin-top: 19px;
    height: 60px;
    /* padding: 16px 24px 16px 24px; */
    padding: 16px 14px 16px 14px;
    width: 100%;

    ${media.greaterThan("768px")`
    height: 65px;
    /* padding: 20px 34px 20px 34px; */
    padding: 20px 24px 20px 24px;
    
  `};
    ${media.greaterThan("1024px")`
    height: 65px;
    padding: 20px 34px 20px 34px;
  `};
    ${media.greaterThan("1440px")`
  `};
  }

  .form_input.message {
    height: 124px;
    text-align: left;
  }

  .submit_button {
    padding: 12px 0;
    ${media.greaterThan("768px")`
      padding: 16px 0px;
    `};
  }
`;

const Startups = () => {
  const navigate = useNavigate();
  const { setRegisterCustomerType } = useAppHook();

  const handleOpenAccount = () => {
    setRegisterCustomerType("developer");
    navigate("/register");
  };
  const [firstLoad, setFirstLoad] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [supportFormData, setSupportFormData] = useState({
    full_name: "",
    email: "",
    company_name: "",
    phone_number: "",
    business_type: "",
    message: "",
  });

  useEffect(() => {
    setFirstLoad(false);
  }, []);

  const handleChange = (e) => {
    setSupportFormData({
      ...supportFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSupportFormSubmit = (e) => {
    e.preventDefault();

    const serviceID = "service_y8mjts6";
    const templateID = "template_7vknma6";
    const userId = "GUaoa0Y5B9epy6nKi";

    const validatorMethods = {
      full_name: "required|min,2",
      email: "required|email",
      company_name: "required",
      phone_number: "required",
      business_type: "required",
      message: "required",
    };

    const validator = new Validator(supportFormData, validatorMethods);
    validator.result
      .then(async () => {
        setSubmitting(true);
        console.log("send message clicked!");
        const formData = new FormData();
        formData.append("full_name", supportFormData.full_name);
        formData.append("email", supportFormData.email);
        formData.append("company_name", supportFormData.company_name);
        formData.append("phone_number", supportFormData.phone_number);
        formData.append("business_type", supportFormData.business_type);
        formData.append("message", supportFormData.message);

        // axios
        //   .post("/login", formData, {
        //     headers: {
        //       "content-type": "multipart/form-data",
        //     },
        //   })
        //   .then((res) => {
        //     setCookie("user_token", res.data.data.token);
        //     console.log(
        //       "this is the data from trying to log in: ",
        //       res.data.data
        //     );
        //     const response = res.data.data;
        //     //don't forget to change the equivalence sign
        //     if (
        //       response.need_document_verification === true ||
        //       response.need_facial_verification === true
        //     ) {
        //       localStorage.setItem(
        //         "login_formData",
        //         JSON.stringify(loginDetails)
        //       );
        //       navigate("/verify-identity");
        //       setSubmitting(false);
        //     } else {
        //       localStorage.setItem(
        //         "userInfo",
        //         JSON.stringify(res.data.data.user)
        //       );
        //       // setAvatar(res?.data?.data?.user?.avatar);
        //       navigate("/dashboard");
        //       localStorage.removeItem("login_formData");
        //       setSubmitting(false);
        //     }
        //   })
        //   .catch((err) => {
        //     const errMsg = err.response?.data?.message;
        //     // const errMsg2 = err.response?.data?.data?.message;
        //     if (
        //       errMsg ===
        //         "Unauthorized: User not verified. Check your email for verification code" ||
        //       errMsg.status === 403
        //     ) {
        //       setShowVerificationInput(true);
        //     }
        //     console.log(errMsg);
        //     toast.error(errMsg);

        //     setSubmitting(false);
        //   });

        send(serviceID, templateID, formData, userId)
          .then((response) => {
            console.log("SUCCESS!", response.status, response.text);
            if (response.status === 200 || response.text === "OK") {
              toast.success("Message sent successfully");
              setSupportFormData({
                full_name: "",
                email: "",
                company_name: "",
                phone_number: "",
                business_type: "",
                message: "",
              });
            }
          })
          .catch((err) => {
            console.log("FAILED...", err);
            toast.error("An error occurred, message not sent.");
          });
      })
      .catch((error) => {
        setSubmitting(false);
        toast.error("Please fill all the form inputs");
      });
  };

  return (
    <Styles>
      {firstLoad && <DynamicScrollToTop />}
      <Section>
        <Title>Contact Our Startup Programs Team</Title>
        <Description>
          Lets help you scale, by providing rental support for your platform
        </Description>
        <div className="buttons">
          <Link to="/register">
            <Button onClick={handleOpenAccount} bg={"#FBBD29"} clr={"#fff"}>
              Open Developer Account
            </Button>
          </Link>
          <Link to="/blogs">
            <Button>
              Learn More <HiOutlineArrowSmRight size={20} />
            </Button>
          </Link>
        </div>
      </Section>
      <FlexedSection>
        <FormPart onSubmit={handleSupportFormSubmit}>
          <div>
            <label htmlFor="fullname">Full Name*</label>
            <input
              id="fullname"
              type={"text"}
              placeholder="Full name  inclu. First & Last Name"
              className="form_input"
              value={supportFormData.full_name}
              onChange={(e) =>
                setSupportFormData((prev) => ({
                  ...prev,
                  full_name: e.target.value,
                }))
              }
            />
          </div>
          <div>
            <label htmlFor="company">Company Name</label>
            <input
              id="company"
              type={"text"}
              placeholder="Company Name"
              className="form_input"
              value={supportFormData.company_name}
              onChange={(e) =>
                setSupportFormData((prev) => ({
                  ...prev,
                  company_name: e.target.value,
                }))
              }
            />
          </div>
          <div>
            <label htmlFor="email">Email*</label>
            <input
              id="email"
              type={"text"}
              placeholder="Email Address"
              className="form_input"
              value={supportFormData.email}
              onChange={(e) =>
                setSupportFormData((prev) => ({
                  ...prev,
                  email: e.target.value,
                }))
              }
            />
          </div>

          <div className="sub_container">
            <div style={{ flex: "1" }}>
              <label htmlFor="phone">Phone Number</label>
              <input
                id="phone"
                type={"text"}
                placeholder="Phone number"
                className="form_input"
                value={supportFormData.phone_number}
                onChange={(e) =>
                  setSupportFormData((prev) => ({
                    ...prev,
                    phone_number: e.target.value,
                  }))
                }
              />
            </div>
            <div style={{ flex: "1" }}>
              <label htmlFor="business_type">Business Type</label>
              <input
                id="business_type"
                type={"text"}
                placeholder="Business Type"
                className="form_input"
                value={supportFormData.business_type}
                onChange={(e) =>
                  setSupportFormData((prev) => ({
                    ...prev,
                    business_type: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          <div>
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              type={""}
              placeholder="Message"
              className="form_input message"
              value={supportFormData.message}
              onChange={(e) =>
                setSupportFormData((prev) => ({
                  ...prev,
                  message: e.target.value,
                }))
              }
            />
          </div>

          <Button
            bg={"#F8AD15"}
            clr={"#fff"}
            style={{ justifyContent: "center" }}
          >
            Send Message
          </Button>
        </FormPart>
        <div className="texts_part">
          <Title>
            Inability/Capacity Of Proptech Platforms To Meet Rental Loan Demands
          </Title>
          <Description>
            Most Proptech startups with monthly rental business models are
            unable to support their customers with rental financing. Market
            analysis shows that over 65% of monthly rental customers requires
            rental financing, this is where we come in.
          </Description>
        </div>
      </FlexedSection>
      <NeedHelp />
    </Styles>
  );
};

export default Startups;
