import Cookies from "universal-cookie";
// require("dotenv").config();
export const cookies = new Cookies();

let domain = "";
export const getCookie = (property) => {
  // let path = "/";
  // let domain=".vercel.apphttps://figo-web-b6e2uh6dm-figo-team.vercel.app/"
  if (process.env.NODE_ENV === "development") {
    // path = "/";
    domain = "";
  }
  return cookies.get(property);
};

export const removeCookie = (property) => {
  let path = "/";
  // let domain="https://figo-web-b6e2uh6dm-figo-team.vercel.app/"
  // let domain = "https://figopayment.com/"

  if (process.env.NODE_ENV === "development") {
    path = "/";
    domain = "";
  }
  return cookies.remove(property, { path, domain });
};

export const setCookie = (property, data) => {
  // let domain="https://figo-web-b6e2uh6dm-figo-team.vercel.app/"
  // let domain = "https://figopayment.com/"
  let path = "/";
  let maxAge = 86400;
  if (process.env.NODE_ENV === "development") {
    path = "/";
    domain = "";
    maxAge = 86400;
  }
  return cookies.set(property, data, { path, domain, maxAge });
};
