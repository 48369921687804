import styled from "styled-components";
import media from "styled-media-query";

const Numbering = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fbbd29;
  color: #000000;
  width: 100%;
  max-width: ${(props) => (props.size ? props.size : "62px")};
  height: ${(props) => (props.size ? props.size : "62px")};
  border-radius: 50%;
  ${media.greaterThan("768px")`

  `};
  ${media.greaterThan("1024px")`
  

  `};
  ${media.greaterThan("1440px")`

  `};
`;

export default Numbering;
