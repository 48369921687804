import styled from "styled-components";
import media from "styled-media-query";

const LogoContainerOther = styled.div`
  cursor: pointer;
  position: relative;
  width: 100%;
  max-width: 200px;
  height: 32px;
  object-fit: "contain";
  ${media.greaterThan("1024px")`
  max-width: 300px;
  height: 39px;
  `};
  ${media.greaterThan("1440px")`
  max-width: 400px;
  height: 43px;
  `};
`;

export default LogoContainerOther;
