import MonoConnect from "@mono.co/connect.js";
// import useAppHook from "../hooks/useAppHook";

// const { setGeneralSettings } = useAppHook();
// const mono_key = "test_pk_dSsAkmnAqnQUdap01AgW";

const monoKey = "test_pk_c17duss97o4ffg2bnt57";

const monoConfig = (monoPbKeys) => {
  console.log("this is within the monoConfig. the pk value: ", monoPbKeys);
  const monoInstance = new MonoConnect({
    onClose: () => console.log("Widget closed"),
    onLoad: () => console.log("Widget loaded successfully"),
    onSuccess: ({ code }) => console.log(`Linked successfully: ${code}`),
    key: "test_pk_c17duss97o4ffg2bnt57",
  });

  return monoInstance;
};

export default monoConfig;

// monoInstance.setup();
