import React from "react";
import { InputBasic } from "./inputs.styles";

const SelectInput = ({
  options = [],
  errorMsg = [],
  value,
  valueChange,
  label,
  hints,
  inputFor,
  required,
  placeholder = "select",
}) => {
  return (
    <InputBasic className="input_label_contain">
      <label htmlFor={inputFor}>
        {label} {required ? <span className="star-required">*</span> : ""}
      </label>

      <div className="input-main-container">
        <select
          value={value}
          name={inputFor}
          className="input-main"
          style={{
            color: "",
          }}
          onChange={valueChange}
        >
          <option value="">{placeholder}</option>
          {options.map((option) => (
            <option
              key={option.value || option.id}
              value={option.value || option.id}
              style={{ paddingBottom: "5px", paddingLeft: "10px" }}
            >
              {option.name || option.display}
            </option>
          ))}
        </select>
      </div>
      <div className="extra-texts-container">
        {hints !== "" && errorMsg.length <= 0 ? (
          <p className="password-hint">{hints}</p>
        ) : (
          <p className="error-message">{errorMsg.join(", ")}</p>
        )}
      </div>
    </InputBasic>
  );
};

export default SelectInput;
