import styled from "styled-components";
import media from "styled-media-query";

import { HiOutlineArrowSmRight } from "react-icons/hi";

import peopleInMeeting from "../Assets/images/people-in-meeting.png";
import handsShaking from "../Assets/images/hands-shaking.png";
import benefittingWithWoman from "../Assets/images/benefitting-with-woman.png";
import imageDecorator from "../Assets/images/pattern_back_label.png";

import Button from "../components/elements/Button";
import DynamicScrollToTop from "../utils/DynamicScrollToTop";
import { Link } from "react-router-dom";

const Styles = styled.div`
  padding: 0 5%;
  padding-top: 80px;
  padding-bottom: 100px;

  ${media.greaterThan("768px")`
    
  `}
  ${media.greaterThan("800px")`
    
  `}
  ${media.greaterThan("1024px")`
    /* padding-top: 150px; */
     padding-top: 120px;
    padding-bottom: 200px;
  `}
  ${media.greaterThan("1440px")`
    
  `} 

  .title {
    font-family: "Tiempos Headline";
    font-style: normal;
    font-weight: 900;
    font-size: 35px;
    line-height: 110%;
    letter-spacing: -0.03em;
    color: #1a191e;

    ${media.greaterThan("768px")`
      font-size: 45px;
    `}
    ${media.greaterThan("800px")`
      font-size: 40px;
    `}
    ${media.greaterThan("1024px")`
      font-size: 60px;
    `}
    ${media.greaterThan("1440px")`
    
    `}
  }

  .main_title {
    ${media.greaterThan("768px")`
      
    `}
    ${media.greaterThan("800px")`
    
    `}
    ${media.greaterThan("1024px")`
      
    `}
    ${media.greaterThan("1440px")`
    
    `}
  }

  .other_title {
    margin-bottom: 20px;
    ${media.greaterThan("768px")`
      margin-bottom: 20px;
    `}
    ${media.greaterThan("1024px")`
      margin-bottom: 30px;
    `}
    ${media.greaterThan("1440px")`
    
    `}
  }

  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    color: #1a191e;
    margin-bottom: 40px;

    ${media.greaterThan("768px")`
      font-size: 18px;
    `}
    ${media.greaterThan("1024px")`
      font-size: 20px;
      margin-bottom: 0px;
    `}
    ${media.greaterThan("1440px")`
    
    `}
  }

  .text_main {
    margin: 30px 0;
    ${media.greaterThan("768px")`
    
    `}
    ${media.greaterThan("1024px")`
      margin: 50px 0;
    `}
    ${media.greaterThan("1440px")`
    
    `}
  }

  .buttons-holder {
    display: flex;
  }

  .flexed_container {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 90px;

    ${media.greaterThan("768px")`

    `}
    ${media.greaterThan("800px")`
      flex-direction: row;
      padding-top: 130px;
      justify-content: center;
      align-items: center;
    `}
    ${media.greaterThan("1024px")`
      flex-direction: row;
      padding-top: 130px;
      justify-content: center;
      align-items: center;
    `}
    ${media.greaterThan("1440px")`
    
    `}
  }

  .reversed {
    flex-direction: column-reverse;
    ${media.greaterThan("800px")`
      flex-direction: row-reverse;
    `}
    ${media.greaterThan("1024px")`
      
    `}
  }

  .texts_container {
    width: 100%;

    ${media.greaterThan("768px")`

    `}
    ${media.greaterThan("800px")`
    width: 50%;
    padding: 0 20px;
      
    `}
    ${media.greaterThan("1024px")`
    width: 50%;
    padding: 0 49px;
      
    `}
    ${media.greaterThan("1440px")`
    
    `}
  }
  .image_container {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    /* padding: 39px 69px; */

    ${media.greaterThan("768px")`
    max-width: 600px
    margin: 0 auto;

    `}
    ${media.greaterThan("800px")`
      width: 50%;
      padding: 0px 20px;
      max-width: 700px;
      justify-content: right;
      
    `}
    ${media.greaterThan("1024px")`
      width: 50%;
      padding: 0px 69px;
      max-width: 700px;
      
    `}
    ${media.greaterThan("1440px")`
    
    `}
  }

  .image {
    max-inline-size: 100%;
    block-size: auto;
    object-fit: cover;
    right: 0;
  }
  .image_decorator {
    position: absolute;
    bottom: -39px;
    left: -39999999px;
    z-index: -1;
    max-inline-size: 100%;
    block-size: auto;
    ${media.greaterThan("800px")`
    left: 0;
    height: -webkit-fill-available;

    `}
  }
`;

const About = () => {
  return (
    <Styles>
      <DynamicScrollToTop />
      <section className="first-section" style={{ maxWidth: "856px" }}>
        <h1 className="title">
          API driven Rental finance platform for individuals, businesses, rental
          platforms and landlords
        </h1>
        <p className="text text_main">
          Landlords technology deploys Application Programming Interface (APIs)
          to enable rental platform meet the rental demands of their customers,
          in few and simple steps.
        </p>
        <div className="buttons-holder">
          <Link to={"/register"}>
            <Button bg={"#FBBD29"} clr={"#fff"}>
              Get Started Today
            </Button>
          </Link>
          <Link to={"/blogs"}>
            <Button>
              Learn More <HiOutlineArrowSmRight />
            </Button>
          </Link>
        </div>
      </section>
      <section className="flexed_container ">
        <div className="image_container">
          <img src={benefittingWithWoman} alt="" className="image" />
          <img src={imageDecorator} alt="" className="image_decorator" />
        </div>
        <div className="texts_container">
          <h2 className="title other_title">
            How Does it Benefit Individuals and Businesses ?
          </h2>
          <p className="text">
            With our B2C solution, individuals and businesses can now access
            rental loans and allow for smaller repayments. Gone are the days of
            inability to meet your rental due dates, we help you with fixing
            your rents.
          </p>
        </div>
      </section>
      <section className="flexed_container reversed">
        <div className="image_container">
          <img src={handsShaking} alt="" className="image" />
          <img src={imageDecorator} alt="" className="image_decorator" />
        </div>
        <div className="texts_container">
          <h2 className="title other_title">
            What Are the Benefits of Using This Platform?
          </h2>
          <p className="text">
            The benefits of using Landlords Technology are numerous. It provides
            individuals, businesses and landlords with increased flexibility and
            speedy access to rent settlement And for rental platforms it creates
            the opportunity to help them meet the rental demands of their
            customers.
          </p>
        </div>
      </section>
      <section className="flexed_container ">
        <div className="image_container">
          <img src={peopleInMeeting} alt="" className="image" />
          <img src={imageDecorator} alt="" className="image_decorator" />
        </div>
        <div className="texts_container">
          <h2 className="title other_title">
            How Can African Businesses Adapt This Financing Model?
          </h2>
          <p className="text">
            African businesses can leverage the technology offered by Landlords
            Technology to their advantage. By adapting this financing model,
            African businesses can access rental loan. The platform allows them
            to easily create split rental repayments that are tailored to their
            comfort, providing much greater flexibility.
          </p>
        </div>
      </section>
    </Styles>
  );
};

export default About;
