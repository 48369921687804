import styled from "styled-components";
import media from "styled-media-query";
import { useState, useRef, useEffect } from "react";
// import { useRouter } from "next/router";
import Popup from "reactjs-popup";

import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { Link } from "react-router-dom";

const DropdownHead = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  border-radius: 6px;
  background: none;
  border: none;
  font-size: 18px;

  ${media.greaterThan("1024px")`
    padding: 8px 8px 8px 12px;
    font-size: 14px;
      background: ${(props) => props.bg};
    `}
  ${media.greaterThan("1440px")`

    font-size: 16px;

    `}
`;
const DropdownMore = styled.ul`
  /* display: flex; */
  display: none;
  /* left: -200000px; */
  flex-direction: column;
  align-items: left;
  gap: 30px;
  padding-left: 31px;
  padding-top: 30px;
  padding-bottom: 60px;
  border-radius: 6px;
  background: #fff;
  /* background: #f0f; */
  text-decoration: none;
  list-style: none;
  margin: 0;

  width: 376px;
  z-index: 1;

  ${media.greaterThan("920px")`
    /* position: absolute; */
    display: flex;
    top: 110%;
    left: -20px;
  `}
`;

export const SmallCollection = styled.div`
  background: #fff;
  display: flex;
  gap: 17px;
  align-items: center;
  width: 100%;

  .card_texts {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .title {
      font-family: "ApercuProBoldNormal";
      font-size: 20px;
      line-height: 110%;
      letter-spacing: -0.03em;
      color: #1a191e;
    }

    .subtitle {
      font-size: 17px;
      line-height: 142.8%;
      color: rgba(0, 0, 0, 0.5);
    }
  }
`;

const SmallCollectionSolo = styled.div`
  background: #fff;
  display: flex;
  gap: 17px;
  align-items: center;
  width: 100%;

  .card_texts {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .title {
      font-family: "ApercuProBoldNormal";
      font-size: 20px;
      line-height: 110%;
      letter-spacing: -0.03em;
      color: #1a191e;
    }

    .subtitle {
      font-size: 17px;
      line-height: 142.8%;
      color: rgba(0, 0, 0, 0.5);
    }
  }
`;

const IconContainer = styled.div`
  position: relative;
  width: 20px;
  height: 20px;

  ${media.lessThan("1024px")`
    width: 35px;
    height: 35px;
  `}
`;

const MarkCircle = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 26px;
  height: 26px;
  background: ${(props) => (props.bg ? props.bg : "#e8f2ee")};
  border-radius: 100px;

  ${media.greaterThan("1024px")`
    display: flex;

    `}
`;

const MobileMenuMore = styled.div`
  ${media.greaterThan("920px")`
   display: none;
  `}
`;

const ShowMoreButton = ({ title, options, bg }) => {
  const ref = useRef();
  const closeTooltip = () => ref.current.close();
  const [showMore, setShowMore] = useState(false);
  // const router = useRouter();

  return (
    <div style={{ position: "relative" }}>
      <Popup
        ref={ref}
        trigger={
          <DropdownHead bg={bg} style={{ cursor: "pointer" }}>
            {title} <MdOutlineKeyboardArrowDown size={14} />
          </DropdownHead>
        }
      >
        <DropdownMore>
          {options.map((option, index) => {
            return (
              <Link to={option.link} key={index} onClick={closeTooltip}>
                <SmallCollectionSolo no_box no_bg style={{ cursor: "pointer" }}>
                  <MarkCircle bg={"#FBBD29"}>
                    <IconContainer>
                      <img
                        src={option.image}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "absolute",
                        }}
                      />
                    </IconContainer>
                  </MarkCircle>
                  <div className="card_texts">
                    <p className="title">{option.title}</p>
                    <p className="subtitle">{option.subtitle}</p>
                  </div>
                </SmallCollectionSolo>
              </Link>
            );
          })}
        </DropdownMore>
      </Popup>

      <MobileMenuMore>
        {options.map((option, index) => {
          return (
            <Link
              to={option.link}
              key={index}
              onClick={() => setShowMore(false)}
            >
              <div style={{ paddingLeft: "15px" }} className="card_texts">
                <p style={{ paddingBottom: "5px" }} className="title">
                  {option.title}
                </p>
                {/* <p className="subtitle">{option.subtitle}</p> */}
              </div>
            </Link>
          );
        })}
      </MobileMenuMore>
    </div>
  );
};

export default ShowMoreButton;
