import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { FormPart } from "../components/blocks/Authentication";
// import Header from "../components/blocks/Header";
import Button from "../components/elements/Button";
import DynamicScrollToTop from "../utils/DynamicScrollToTop";
import numberWithCommas from "../utils/numberWithCommas";
import Validator from "../utils/Validator";

import { useMemo } from "react";
import monoConfig from "../services/MonoInstance";
import { useNavigate } from "react-router-dom";
// import monoConfig from "../../services/MonoInstance";

const Styles = styled.div`
  position: relative;
  width: 100%;
  ${media.greaterThan("768px")`
  /* left: 5%; */
  `};

  input {
    color: red;
  }
`;

const FirstSection = styled.div`
  background: rgba(183, 123, 47, 0.1);
  /* padding-top: 176px;
  padding-bottom: 200px; */
  padding: 176px 5% 200px 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media.greaterThan("768px")`

  `};
  ${media.greaterThan("1024px")`
    padding-top: 250px;
  `};
  ${media.greaterThan("1440px")`
    
  `};
`;

const TitleText = styled.h2`
  font-family: "Tiempos Headline";
  font-size: 40px;
  line-height: 110%;
  letter-spacing: -0.03em;
  padding-bottom: 16px;
  text-align: center;
  color: ${(props) => (props.clr ? props.clr : "#1a191e")};

  ${media.greaterThan("768px")`
    font-size: 45px;
    padding-bottom: 20px;
  `};
  ${media.greaterThan("1024px")`
    font-size: 55px;
    padding-bottom: 24px;
  `};
  ${media.greaterThan("1440px")`
    font-size: 60px;
    padding-bottom: 28px;
  `};
`;

const CommonText = styled.p`
  font-size: 16px;
  line-height: 2;
  text-align: center;
  color: ${(props) => (props.clr ? props.clr : "#1a191e")};

  ${media.greaterThan("768px")`
    /* font-size: 45px; */
  `};
  ${media.greaterThan("1024px")`
    font-size: 18px;
  `};
  ${media.greaterThan("1440px")`
    font-size: 20px;
  `};
`;

const FormContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 10%;
  background: #ffffff;
  border-radius: 20px;
  top: -152px;
  ${media.greaterThan("768px")`
  padding: 0;
    max-width: 80%;
    margin: 0 auto;
  `};
  ${media.greaterThan("1024px")`
    max-width: 723px;
    margin: 0 auto;
  `};
`;

const FinanceWithLandlordstech = () => {
  const [propertyDesc, setPropertyDesc] = useState("");
  const [propertyDescError, setPropertyDescError] = useState([]);
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState([]);

  const [submitting, setSubmitting] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const navigate = useNavigate();

  const [data, setData] = useState({
    property_description: "",
    amount: "",
  });
  const [dataError, setDataError] = useState({
    property_description: [],
    amount: [],
  });

  const mono_key = "test_pk_dSsAkmnAqnQUdap01AgW";
  // const mono_key = "dSsAkmnAqnQUdap01AgW";

  const monoConnect = useMemo(() => {
    // monoConfig(monoPbKeys).setup();
    // monoConfig(mono_key).setup();
    monoConfig(mono_key).setup();
    // monoConfig.setup();
    return monoConfig;
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // const userExist = JSON.parse(localStorage.getItem("userInfo")) || null;
    const userLoginResponse =
      JSON.parse(localStorage.getItem("loginResponse")) || null;

    const userInfo = JSON.parse(localStorage.getItem("userInfo")) || null;

    setDataError({
      property_description: [],
      amount: [],
    });

    const validatorMethods = {
      property_description: "required|min,3",
      amount: "required|min,3",
    };

    const validator = new Validator(data, validatorMethods);
    validator.result
      .then(async () => {
        setSubmitting(true);
        const formData = new FormData();
        formData.append("property", data.property_description);
        formData.append("amount", data.amount);

        if (userInfo !== null && userLoginResponse !== null) {
          // monoConnect(mono_key).open();
          if (userLoginResponse?.isProfileCompleted === false) {
            navigate("/dashboard/profile");
          } else if (
            userLoginResponse.need_document_verification === true ||
            userLoginResponse.need_facial_verification === true
          ) {
            navigate("/verify-identity");
          } else {
            monoConnect(mono_key).open();
          }
        } else {
          navigate("/signin");
        }

        console.log("Finance with llTech data: ", data);
      })
      .catch((error) => {
        setSubmitting(false);
        setDataError((prev) => ({
          ...prev,
          property_description: error?.error.property_description,
          amount: error?.error.amount,
        }));
      });
  };

  useEffect(() => {
    setFirstLoad(false);
  }, []);

  return (
    <Styles>
      {firstLoad && <DynamicScrollToTop />}
      <FirstSection>
        <TitleText>
          Get Rental Finance With
          <br /> Landlords Technology
        </TitleText>
        <CommonText style={{ maxWidth: "956px" }}>
          Are you experiencing difficulty with rental payments, don’t worry as
          Landlords Technology helps you with that, we pay on your behalf and
          split your repayments in bits for comfort and ease.
        </CommonText>
      </FirstSection>
      <FormContainer>
        <FormPart>
          <h2 style={{ color: "#000" }}>Apply For Rental Finance</h2>
          <div className="input_label_container">
            <label htmlFor="description" style={{ color: "#919499" }}>
              Property Description
            </label>
            <input
              type={"text"}
              id="description"
              placeholder="2bed Room Apartment"
              className="form_input finance_with"
              value={data.property_description}
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  property_description: e.target.value,
                }))
              }
            />

            <p className="error message" style={{ textAlign: "left" }}>
              {dataError.property_description.join(", ")}
            </p>
          </div>
          <div className="input_label_container">
            <label htmlFor="amount_requested" style={{ color: "#919499" }}>
              Amount Request <span style={{ color: "#FF0000" }}>*</span>
            </label>
            <input
              type={"number"}
              id="amount_requested"
              placeholder="340,000"
              className="form_input finance_with"
              value={data.amount}
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  amount: e.target.value,
                }))
              }
            />

            <p className="error message" style={{ textAlign: "left" }}>
              {dataError.amount.join(", ")}
            </p>
          </div>

          <Button
            bg={"#F8AD15"}
            clr="#FFFFFF"
            style={{
              width: "100%",
              justifyContent: "center",
              height: "65px",
            }}
            onClick={(e) => handleSubmit(e)}
          >
            Request{" "}
            {data.amount === ""
              ? "NGN 340,000"
              : `NGN ${numberWithCommas(data.amount)}`}
          </Button>
        </FormPart>
      </FormContainer>
    </Styles>
  );
};

export default FinanceWithLandlordstech;

// FinanceWithLandlordstech.getLayout = function PageLayout(page) {
//   return (
//     <>
//       <Header />
//       {page}
//     </>
//   );
// };
