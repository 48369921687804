import styled from "styled-components";
import media from "styled-media-query";
// import Header from "../Header";
// import Footer from "../Footer";
// import { Component } from "react";

import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
// import { UserProvider } from "../context/UserProviderMain";
// import AppContext, { AppProvider } from "../context/AppProvider";
// import useUserHook from "../../../hooks/useUserHook";

import { Outlet } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";

const Styles = styled.div`
  position: relative;
  max-width: 1728px;
  margin-left: auto;
  margin-right: auto;
  /* padding: auto; */
  height: 100vh;

  .padding_top {
    /* height: 111px; */
    height: 63px;

    ${media.greaterThan("1024px")`
    height: 111px;
    `}
  }
`;

const GeneralLayout = ({ children }) => {
  // const { loggedIn, setLoggedIn } = useUserHook();

  // useEffect(() => {
  //   const fetchCountriesList = async () => {
  //     axios
  //       .get("/country/list")
  //       .then(async (res) => {
  //         const countriesList = await res?.data.data;
  //         localStorage.setItem("countriesList", JSON.stringify(countriesList));
  //       })
  //       .catch((err) => {
  //         console.log("this is the error from countries List: ", err);
  //       });
  //   };

  //   fetchCountriesList();
  // }, []);

  // useEffect(() => {
  //   const fetchConstants = async () => {
  //     axios.get("/constants").then(async (res) => {
  //       const constants = await res.data.data.customer;
  //       localStorage.setItem("constants", JSON.stringify(constants));
  //     });
  //   };

  //   fetchConstants();
  // }, []);

  // useEffect(() => {
  //   const fetchFaqs = async () => {
  //     axios
  //       .get("/faqs?limit=20&offset=0")
  //       .then(async (res) => {
  //         const faq = await res?.data.data;
  //         localStorage.setItem("faq", JSON.stringify(faq));
  //       })
  //       .catch((err) => {
  //         console.log("this is the error from countries List: ", err);
  //       });
  //   };

  //   fetchFaqs();
  // }, []);

  return (
    <Styles>
      <Header />
      {/* <h1>Header</h1> */}
      <div className="padding_top"></div>
      {/* {children} */}
      <Outlet />
      <Footer />
      {/* <h1>Footer</h1> */}
    </Styles>
  );
};

export default GeneralLayout;
