import styled from "styled-components";
import media from "styled-media-query";
import FooterColumn from "./FooterColumn";

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  /* padding-top: 200px; */
  padding-bottom: 20px;
  margin-top: 30px;
  ${media.greaterThan("768px")`
    flex-direction: row;
    margin-top: 0px;
    `}
  ${media.greaterThan("1024px")`
    max-width: 70%;
    margin: 0 0 0 auto;
    padding-bottom: 80px;
    `}
`;

const FooterContentContainer = () => {
  const contents = [
    {
      id: 1,
      title: "Learn",
      anchorLinks: [
        { name: "Why Choose Us", link: "/why-us" },
        { name: "For Startups", link: "/startups" },
        { name: "For Landlords", link: "/landlords" },
      ],
    },
    {
      id: 2,
      title: "Developers",
      anchorLinks: [
        // { name: "Overview", link: "/developers" },
        { name: "Overview", link: "/developers" },
        { name: "Documentation", link: "/developers" },
        { name: "Integrations", link: "/developers" },
      ],
    },
    {
      id: 3,
      title: "Support",
      anchorLinks: [
        { name: "Help Desk", link: " mailto:info@landlordstech.com" },
        { name: "Contact Us", link: "/support" },
        // { name: "Why Was I Debited?", link: "#" },
      ],
      // anchorLink: ["Help Desk", "Contact Us", "Why Was I Debited?"],
    },
    {
      id: 4,
      title: "About",
      anchorLinks: [
        { name: "Company", link: "/about" },
        // { name: "Compliance", link: "/about" },
        { name: "Privacy & Terms", link: "/privacy" },
      ],
      // anchorLink: ["Company", "Compliance", "Privacy & Terms"],
    },
  ];
  return (
    <Styles>
      {contents.map((content) => {
        return <FooterColumn key={content.id} content={content} />;
      })}
    </Styles>
  );
};

export default FooterContentContainer;
