import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { InputBasic } from "./inputs.styles";

import eyeOpen from "../../../../assets/images/eye_open_icon.svg";
import eyeClosed from "../../../../assets/images/eye_closed_icon.svg";

const Input = ({
  label,
  inputFor,
  hints,
  type,
  password,
  placeholder,
  required,
  errorMsg = [],
  value,
  valueChange,
  textAreaInput,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <InputBasic errorMsg={errorMsg} type={type} showPassword={showPassword}>
      <label htmlFor={inputFor}>
        {label} {required ? <span className="star-required">*</span> : ""}
      </label>
      <Link to={"/forgot-password"} className="forgot-password">
        <p>Forgot Password?</p>
      </Link>

      <div className={``}>
        {textAreaInput ? (
          <textarea
            type={
              password === true && showPassword === false ? "password" : type
            }
            id={inputFor}
            placeholder={placeholder}
            className=" input-main-container input-mai"
            value={value}
            onChange={valueChange}
          />
        ) : (
          <div className={`input-main-container`}>
            <input
              type={
                password === true && showPassword === false ? "password" : type
              }
              id={inputFor}
              placeholder={placeholder}
              className="input-main"
              value={value}
              onChange={valueChange}
            />
            {password ? (
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="password_visibility"
              >
                {showPassword ? (
                  <img
                    src={eyeOpen}
                    alt="password visible"
                    width={15}
                    height={15}
                  />
                ) : (
                  <img
                    src={eyeClosed}
                    alt="password hidden"
                    width={15}
                    height={15}
                  />
                )}
              </button>
            ) : null}
          </div>
        )}
      </div>
      {/* {errorMsg.length > 0 ? (
        <p className="error-message">{errorMsg.join(", ")}</p>
      ) : (
        <p className="password-hint">{hints}</p>
      )} */}
      <div className="extra-texts-container">
        {hints !== "" && errorMsg.length <= 0 ? (
          <p className="password-hint">{hints}</p>
        ) : (
          <p className="error-message">{errorMsg.join(", ")}</p>
        )}
      </div>

      {/* <p className="error-message">{errorMsg.join(", ")}</p> */}
    </InputBasic>
  );
};

export default Input;

//props
// label, inputFor, type, placeholder, errorMsg, value, onChange
