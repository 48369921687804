import styled from "styled-components";
import media from "styled-media-query";

import { Link } from "react-router-dom";

import { HiOutlineArrowSmRight } from "react-icons/hi";

import laptopApiImage from "../Assets/images/laptop_api.png";
import customerIdImage from "../Assets/images/customer_id.png";
import womanLookingDesktopImage from "../Assets/images/woman_looking_desktop.png";
import imageDecorator from "../Assets/images/pattern_back_label.png";

import Button from "../components/elements/Button";
import NeedHelp from "../components/blocks/NeedHelp";
import StillHaveQuestions from "../components/blocks/StillHaveQuestions";
import DynamicScrollToTop from "../utils/DynamicScrollToTop";
import GoToTop from "../utils/GoToTop";

const Styles = styled.div`
  padding: 0 5%;
  /* padding-top: 120px; */
  padding-bottom: 100px;

  ${media.greaterThan("768px")`
    
  `}
  ${media.greaterThan("800px")`
    
  `}
  ${media.greaterThan("1024px")`
    /* padding-top: 200px; */
    padding-bottom: 200px;
  `}
  ${media.greaterThan("1440px")`
    
  `} 

  .title {
    font-family: "Tiempos Headline";
    font-style: normal;
    font-weight: 900;
    font-size: 35px;
    line-height: 110%;
    letter-spacing: -0.03em;
    color: #1a191e;

    ${media.greaterThan("768px")`
      font-size: 45px;
    `}
    ${media.greaterThan("800px")`
      font-size: 40px;
    `}
    ${media.greaterThan("1024px")`
      font-size: 60px;
    `}
    ${media.greaterThan("1440px")`
    
    `}
  }

  .main_title {
    ${media.greaterThan("768px")`
      
    `}
    ${media.greaterThan("800px")`
    
    `}
    ${media.greaterThan("1024px")`
      
    `}
    ${media.greaterThan("1440px")`
    
    `}
  }

  .other_title {
    margin-bottom: 20px;
    ${media.greaterThan("768px")`
      margin-bottom: 20px;
    `}
    ${media.greaterThan("1024px")`
      margin-bottom: 30px;
    `}
    ${media.greaterThan("1440px")`
    
    `}
  }

  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    color: #1a191e;
    margin-bottom: 20px;
    margin-bottom: 15px;

    ${media.greaterThan("768px")`
      font-size: 18px;
    `}
    ${media.greaterThan("1024px")`
      font-size: 20px;
      margin-bottom: 30px;
    `}
    ${media.greaterThan("1440px")`
    
    `}
  }

  .text_main {
    margin: 30px 0;
    ${media.greaterThan("768px")`
    
    `}
    ${media.greaterThan("1024px")`
      margin: 50px 0;
    `}
    ${media.greaterThan("1440px")`
    
    `}
  }

  .buttons-holder {
    display: flex;
  }

  .buttons {
    display: flex;
    gap: 32px;
    align-items: center;
    margin-bottom: 40px;

    ${media.greaterThan("1024px")`
      margin-bottom: 0px;
      `}
  }

  .flexed_container {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 90px;

    ${media.greaterThan("768px")`

    `}
    ${media.greaterThan("800px")`
      flex-direction: row;
      padding-top: 130px;
      justify-content: center;
      align-items: center;
    `}
    ${media.greaterThan("1024px")`
      flex-direction: row;
      padding-top: 130px;
      justify-content: center;
      align-items: center;
    `}
    ${media.greaterThan("1440px")`
    
    `}
  }

  .reversed {
    flex-direction: column-reverse;
    ${media.greaterThan("800px")`
      flex-direction: row-reverse;
    `}
    ${media.greaterThan("1024px")`
      
    `}
  }

  .texts_container {
    width: 100%;

    ${media.greaterThan("768px")`

    `}
    ${media.greaterThan("800px")`
    width: 50%;
    padding: 0 20px;
      
    `}
    ${media.greaterThan("1024px")`
    width: 50%;
    padding: 0 49px;
      
    `}
    ${media.greaterThan("1440px")`
    
    `}
  }
  .image_container {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    /* padding: 39px 69px; */

    ${media.greaterThan("768px")`
    max-width: 600px
    margin: 0 auto;

    `}
    ${media.greaterThan("800px")`
      width: 50%;
      padding: 0px 20px;
      max-width: 700px;
      justify-content: right;
      
    `}
    ${media.greaterThan("1024px")`
      width: 50%;
      padding: 0px 49px;
      max-width: 700px;
      
    `}
    ${media.greaterThan("1440px")`
      padding: 0px 69px;
    `}
  }

  .image {
    max-inline-size: 100%;
    block-size: auto;
    object-fit: cover;
    right: 0;
  }
  .image_decorator {
    position: absolute;
    bottom: -39px;
    left: -39999999px;
    z-index: -1;
    max-inline-size: 100%;
    block-size: auto;
    ${media.greaterThan("800px")`
    left: 0;
    height: -webkit-fill-available;

    `}
  }

  .detailing_container {
    margin-bottom: 95px;
  }
`;

const FirstSection = styled.section`
  background: rgba(183, 123, 47, 0.1);
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  text-align: center;
  padding: 0 10%;
  padding-top: 80px;
  padding-bottom: 60px;
  gap: 50px;

  ${media.greaterThan("768px")`
    /* padding-top: 100px; */
    
  `};
  ${media.greaterThan("1024px")`
    /* padding-top: 100px; */
    padding-top: 150px;
    padding-bottom: 100px;
    
  `};
  ${media.greaterThan("1440px")`
    /* padding-top: 100px; */
    
  `};
`;

// const FlexedSectionContainer = styled.section`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   padding: 0 8%;
// `;

const FlexedSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10%;
  margin-top: 100px;
  gap: 20px;

  ${media.greaterThan("768px")`
    gap: 0;
    padding: 0;
    flex-direction: ${(props) => (props.reversed ? "row-reverse" : "row")};
  `};

  .texts_part {
    width: 100%;
    text-align: left;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 50px;

    ${media.greaterThan("768px")`
    /* height: 866px; */
    padding-top: 0px;
    width: 50%
    gap: 50px;
  `};

    .contents_container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 30px;
      width: 100%;

      /* padding: 0 5%; */

      ${media.greaterThan("768px")`
        max-width: 90%;


      `}
      ${media.greaterThan("1024px")`
      `}
      ${media.greaterThan("1440px")`
        gap: 50px;
        max-width: 625px;
      `}
    }

    .buttons {
      display: flex;
      gap: 32px;
      align-items: center;
    }
  }

  .picture_part {
    img {
      object-fit: cover;
      /* object-fit: contain; */
    }
    width: 100%;
    /* margin: 0 5%; */
    position: relative;
    height: 250px;
    ${media.greaterThan("500px")`  
    height: 350px;
  `};
    ${media.greaterThan("768px")`
    /* height: 666px; */
    flex: 1;
    width: 50%
    height: 500px;
  `};
    ${media.greaterThan("1024px")`
    height: 766px;
  
  `};
    ${media.greaterThan("7440px")`
    height: 866px;
  
  `};
  }
`;

const Title = styled.h2`
  font-family: "Tiempos Headline";
  font-size: 40px;
  line-height: 110%;
  text-align: left;
  letter-spacing: -0.03em;
  color: #1a191e;

  ${media.greaterThan("768px")`
    /* font-size: 45px; */

  `};
  ${media.greaterThan("1024px")`
    font-size: 50px;
    max-width: 844px;
  `};
  ${media.greaterThan("1440px")`
    font-size: 60px;

  `};
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 180%;
  text-align: ${(props) => (props.start ? "left" : "center")};
  color: #1a191e;

  ${media.greaterThan("768px")`
    font-size: 18px;
  `};
  ${media.greaterThan("1024px")`
    font-size: 20px;
    max-width: 956px;
  `};
`;

const WhyUs = () => {
  return (
    <>
      <DynamicScrollToTop />
      <div
        style={{
          position: "relative",
        }}
      >
        <FirstSection>
          <Title>
            Enabling flexible rental repayment for businesses in Africa
          </Title>
          <Description>
            Landlords Technology is deploying a B2C & B2B solutions for property
            rental financing. Our B2C are rental loans processed under strict
            KYC procedures and risk management protocols. The B2B solutions
            deploys Application Programming Interface (APIs) to enable property
            rental platforms integrate our flexible rental financing and
            repayments on their existing rental portals.
          </Description>
        </FirstSection>
      </div>

      <Styles>
        <div className="detailing_container">
          <section
            className="flexed_container reversed"
            id="rental-finance-api"
          >
            <div className="image_container">
              <img src={laptopApiImage} alt="" className="image" />
              <img src={imageDecorator} alt="" className="image_decorator" />
            </div>
            <div className="texts_container">
              <h2 className="title other_title">Rental Finance API</h2>
              <p className="text">
                Rental finance platforms can now leverage our solution to
                provide rental loans to its customers. By doing this, we take
                the burden of sourcing finance to meet customers rental loan
                demand on these platform. Integrate with our Rental Finance API
                and push the responsibility of providing rental payment of your
                customers to us.
              </p>
              <div className="buttons">
                <Link to="/register">
                  <Button bg={"#FBBD29"} clr={"#fff"}>
                    Open Account
                  </Button>
                </Link>

                <Link to={"/blogs"}>
                  <Button clr={"#fbbd29"}>
                    Learn More <HiOutlineArrowSmRight size={20} />
                  </Button>
                </Link>
              </div>
            </div>
          </section>
          <section className="flexed_container ">
            <div className="image_container">
              <img src={womanLookingDesktopImage} alt="" className="image" />
              <img src={imageDecorator} alt="" className="image_decorator" />
            </div>
            <div className="texts_container">
              <h2 className="title other_title">Rental loans</h2>
              <p className="text">
                Access Rental loans on our portal with scheduled repayment
                timelines, we work out suitable repayment plans for our
                customers, making these loan easily accessible by all.
              </p>
              <div className="buttons">
                <Link to="/register">
                  <Button bg={"#FBBD29"} clr={"#fff"}>
                    Open Account
                  </Button>
                </Link>

                <Link to={"/blogs"}>
                  <Button clr={"#fbbd29"}>
                    Learn More <HiOutlineArrowSmRight size={20} />
                  </Button>
                </Link>
              </div>
            </div>
          </section>
          {/* <section className="flexed_container ">
          <div className="image_container">
            <img src={customerIdImage} alt="" className="image" />
            <img src={imageDecorator} alt="" className="image_decorator" />
          </div>
          <div className="texts_container">
            <h2 className="title other_title">
              Validate Government data for Customers
            </h2>
            <p className="text">
              Ensure compliance in Nigeria by verifying that the data provided
              by users match government-issued IDs.
            </p>
            <div className="buttons">
              <Link to="/register">
                <Button bg={"#FBBD29"} clr={"#fff"}>
                  Open Account
                </Button>
              </Link>

              <Link to={"/blogs"}>
                <Button clr={"#fbbd29"}>
                  Learn More <HiOutlineArrowSmRight size={20} />
                </Button>
              </Link>
            </div>
          </div>
        </section> */}
        </div>
      </Styles>
      <NeedHelp />
      {/* <GoToTop /> */}
    </>
  );
};

export default WhyUs;
