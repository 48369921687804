import styled from "styled-components";
import media from "styled-media-query";
import CodingFieldImageContainer from "../CodingFieldImageContainer";
import CommonText from "../CommonText";
import HelpfulEnough from "../HelpfulEnough";
import ListingContainer from "../ListingContainer";
import Numbering from "../Numbering";
import SubTitle from "../SubTitle";
import TitleText from "../TitleText";

import DriverLicenseImageOne from "../../../../Assets/images/developers/drivers_license_image_one.png";
import DriverLicenseImageTwo from "../../../../Assets/images/developers/drivers_license_image_two.png";

const Styles = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${media.greaterThan("768px")`

  `};
  ${media.greaterThan("1024px")`

  `};
  ${media.greaterThan("1440px")`

  `};
`;

const DriversLicenseNav = () => {
  return (
    <Styles>
      <TitleText>Drivers License Full Details</TitleText>
      <div>
        <CommonText>
          The Drivers License Verification service accepts the Drivers License
          Number, Date of Birth from the customer and returns detailed
          information on the drivers license verification. This article will
          guide you on how to integrate to this Service
        </CommonText>
      </div>

      <div style={{ width: "100%", paddingBottom: "120px" }}>
        <SubTitle>API EndPoint</SubTitle>
        <CommonText style={{ paddingBottom: "29px" }}>
          https://api.landlordstech.com/v3/id-service/
        </CommonText>

        <CodingFieldImageContainer
          height={"150px"}
          mdHeight={"165px"}
          lgHeight={"188px"}
        >
          <img
            src={DriverLicenseImageOne}
            alt=""
            style={{
              position: "",
              maxInlineSize: "100%",
              blockSize: "auto",
            }}
          />
        </CodingFieldImageContainer>

        <CodingFieldImageContainer
          height={"400px"}
          mdHeight={"600px"}
          lgHeight={"843px"}
          style={{
            marginTop: "90px",
          }}
        >
          <img
            src={DriverLicenseImageTwo}
            alt=""
            style={{
              position: "",
              maxInlineSize: "100%",
              blockSize: "auto",
            }}
          />
        </CodingFieldImageContainer>
      </div>

      <HelpfulEnough />
    </Styles>
  );
};

export default DriversLicenseNav;
