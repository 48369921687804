import styled from "styled-components";
import media from "styled-media-query";
import { useState } from "react";

import { TiArrowBack } from "react-icons/ti";
import { CiSearch } from "react-icons/ci";
import Button from "../../elements/Button";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const Styles = styled.section`
  ${media.greaterThan("768px")`
  display: flex;

  `};
  ${media.greaterThan("1024px")`

  `};
  ${media.greaterThan("1440px")`

  `};

  .nav_part {
    background: none;

    ${media.greaterThan("768px")`
    width: 26.38%;
    max-width: 456px;
    padding-left: 5.12%;
    border-right: 1px solid #fbbd29;
    height: 1039px;

  `};
    ${media.greaterThan("1024px")`

  `};
    ${media.greaterThan("1440px")`

  `};
  }

  .nav_contents_display {
    /* position: relative; */
    padding: 0 3%;
    /* background: #ff2277; */
    display: flex;
    flex-direction: column;
    /* padding-top: ${(props) => (props.basic ? "192px" : "0px")}; */
    align-items: center;

    ${media.greaterThan("768px")`
      flex: 1;
  `};
    ${media.greaterThan("1024px")`
    margin-bottom: 99px;

  `};
    ${media.greaterThan("1440px")`

  `};
  }
`;

const NavContainer = styled.ul`
  display: flex;
  gap: 10px;
  align-items: center;
  overflow-x: scroll;
  flex-direction: row;
  list-style-type: none;
  font-size: 16px;
  line-height: 310%;
  color: #1a191e;
  letter-spacing: -0.03em;
  width: 100%;
  padding-left: 5%;
  margin-bottom: 80px;
  ${media.greaterThan("768px")`
    flex-direction: column;
    align-items:flex-start;
    overflow-x: hidden;
    font-size: 18px;
    padding-left: 0;
  
  `};
  ${media.greaterThan("1024px")`
    font-size: 22px;
  `};
  ${media.greaterThan("1440px")`
    font-size: 25px;
  `};

  li.active {
    color: #fbbd29;
  }
`;

const SearchIconContainer = styled.div`
  /* width: 100%; */
  /* margin-top: 53px; */
  position: relative;
  flex: 1;
  /* background-color: #fe2f; */
  max-width: 825px;

  ${media.greaterThan("768px")`

  `};
  ${media.greaterThan("1024px")`
    /* margin-bottom: 99px; */

  `};
  ${media.greaterThan("1440px")`

  `};

  label {
    position: absolute;
    left: -999999px;
  }
  input {
    background: #ffffff;
    box-shadow: 0px 10px 15px rgba(151, 151, 151, 0.08);
    border-radius: 10px;
    width: 100%;
    height: 50px;
    border: none;
    padding: 10px 50px;
    font-size: 14px;

    ${media.greaterThan("768px")`
      height: 60px;
      font-size: 16px;
      padding: 10px 65px;
      /* width: 90%; */

  `}
  }
`;

const HeadingSubNav = styled.div`
  /* position: absolute; */
  /* background: #ff0000; */
  width: 100%;
  margin-bottom: 50px;

  ${media.greaterThan("768px")`
  display: flex;
  align-items: center;
  justify-content: start;

  `};
  ${media.greaterThan("1024px")`
    /* margin-bottom: 99px; */

  `};
  ${media.greaterThan("1440px")`

  `};

  .buttons {
    display: flex;
    justify-content: flex-end;

    min-width: 170px;
    gap: 10px;
    align-items: center;
  }
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  background: none;
  border: none;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 50px;
`;

const SideMenuHolderContainer = ({ basic, navs, navContents }) => {
  const [activeNav, setActiveNav] = useState(0);
  const [userPresent, setUserPresent] = useState(false);

  const handleNavClick = (index) => {
    setActiveNav(index);
  };

  useEffect(() => {
    const userAvailable = localStorage.getItem("userInfo");
    if (userAvailable) {
      setUserPresent(true);
    } else {
      setUserPresent(false);
    }
  }, []);

  return (
    <Styles>
      <div className="nav_part">
        {basic ? null : (
          <Link to={"/developers"}>
            <BackButton>
              <TiArrowBack size={20} />
              Back to Menu
            </BackButton>
          </Link>
        )}

        <NavContainer>
          {navs.map((nav, index) => {
            return (
              <li
                style={{
                  cursor: "pointer",
                  minWidth: "50%",
                }}
                key={index}
                onClick={() => handleNavClick(index)}
                className={`${activeNav === index ? "active" : ""}`}
              >
                {nav}
              </li>
            );
          })}
        </NavContainer>
      </div>
      <div className="nav_contents_display">
        {basic ? null : (
          <HeadingSubNav>
            <SearchIconContainer>
              <CiSearch
                size={20}
                style={{
                  position: "absolute",
                  left: "3%",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              />

              <label htmlFor="search">Search</label>
              <input type={"text"} id="search" placeholder="Search..." />
            </SearchIconContainer>
            {userPresent ? (
              <Link to="/dashboard">
                <Button
                  bg={"#FBBD29"}
                  clr={"#fff"}
                  style={{ marginLeft: "20px" }}
                >
                  Dashboard
                </Button>
              </Link>
            ) : (
              <div className="buttons">
                <Link to="/signin">
                  <Button>Login</Button>
                </Link>

                <Link to="/register">
                  <Button bg={"#FBBD29"} clr={"#fff"}>
                    Open Account
                  </Button>
                </Link>
              </div>
            )}
          </HeadingSubNav>
        )}

        {navContents[activeNav]}
      </div>
    </Styles>
  );
};

export default SideMenuHolderContainer;
