import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import media from "styled-media-query";

// import propertyImage from "../../Assets/images/property_image.png";
import propertyImage from "../../Assets/images/laptop_api.png";
import propertyTypeApartment from "../../Assets/images/property_type_apartment.svg";
import Modal from "../blocks/Modal";

const Styles = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  margin-bottom: 20px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: left;
  position: relative;

  ${media.greaterThan("600px")`
    flex-direction: row;
    align-items: center;
    padding: 6.5px 9.5px;
  `};
  ${media.greaterThan("1024px")`
    
  `};

  .property_image {
    max-inline-size: 100%;
    block-size: auto;
    object-fit: cover;
    height: 100%;
    max-height: 300px;
    width: 100%;
    border-radius: 20px 20px 0 0;
    cursor: pointer;

    ${media.greaterThan("600px")`
      border-radius: 20px 20px 20px 20px;
      min-width: 261px;
      min-height: 195px;
      max-width: 261px;
      max-height: 195px;
    `};
    ${media.greaterThan("1024px")`
    
    `};
  }

  .property_details {
    margin-top: 30px;
    padding: 0 10px;
    cursor: pointer;

    ${media.greaterThan("600px")`
    margin-top: 0px;
    flex: 1;
    padding-left: 10%
    /* padding: 0 10px; */
    
    `};
    ${media.greaterThan("1024px")`
    
    `};
  }

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 136%;
    color: #3e4958;
  }

  .quantities_info {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 136%;
    color: #3e4958;

    margin-top: 14px;
    margin-bottom: 16px;
  }

  .available_space {
    display: flex;
    align-items: center;
  }

  .available_space_image_container {
    width: 28px;
    height: 28px;
    background: #faa21b;
    border-radius: 50%;
    margin-right: 16px;
  }

  .available_image_space_image {
  }

  .available_space_text {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 136%;
    margin-left: 16px;
    /* or 20px */

    color: #3e4958;
  }

  .delete_property {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: solid 1px #000;
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    background: #c9d0d2;
    font-size: 20px;
    font-weight: 600;
  }
  .delete_property:hover {
    color: white;
    background: #fbbd29;
    border: none;
  }
  .delete_property:active {
    color: white;
    background: #000;
  }

  .modal-main {
    background: white;
    padding: 20px;
    border-radius: 10px;
  }
`;

const Property = ({ property, propertiesList, setPropertiesList }) => {
  const [submitting, setSubmitting] = useState(false);
  const [openPropertyInfo, setOpenPropertyInfo] = useState(false);

  const deleteProperty = (id, title) => {
    //  /landlord/delete-property
    setSubmitting(true);
    const newList = propertiesList.filter(
      (sample) => sample.id !== property.id
    );
    setPropertiesList(newList);
    axios
      .post("/landlord/delete-property", { id: id })
      .then((res) => {
        setSubmitting(false);
        toast.success(`${title} deleted successfully`);
      })
      .catch((err) => {
        setSubmitting(false);
        const errMsg = err.response.data.message;
        toast.error(errMsg);
      });
  };

  const propertyInfo = () => {
    setOpenPropertyInfo(false);
  };

  return (
    <>
      <Styles>
        <img
          // src={propertyImage} alt="property"
          src={
            property?.photo[0]?.original_url
              ? property?.photo[0]?.original_url
              : propertyImage
          }
          alt="property"
          className="property_image"
          onClick={propertyInfo}
        />

        <div className="property_details" onClick={propertyInfo}>
          <p className="title">{property.title}</p>
          <div className="quantities_info">
            {property.guest} {property.guest > 1 ? "guests" : "guest"} |{" "}
            {property.bedrooms} {property.bedrooms > 1 ? "bedrooms" : "bedroom"}{" "}
            | {property.bathrooms}{" "}
            {property.bathrooms > 1 ? "bathrooms" : "bathroom"}
          </div>

          <div className="available_space">
            <div className="">
              <img
                src={propertyTypeApartment}
                // src={property.available_space_image}
                alt=""
                className="available_space_image"
              />
            </div>
            <p className="available_space_text">{property.property_type}</p>
          </div>
        </div>
        <button
          onClick={() => deleteProperty(property.id, property.title)}
          className="delete_property"
        >
          X
        </button>
        <Modal
          onClose={() => {
            setOpenPropertyInfo(false);
          }}
          show={openPropertyInfo}
        >
          <div className="modal-main">
            <div>
              <p>Property Name: {property.title}</p>
              <p>Bedrooms: {property.bedrooms}</p>
              <p>Bathrooms: {property.bathrooms}</p>
              <p>Guest: {property.guest}</p>
              <p>City: {property.city}</p>
              <p>Amount: {property.amount}</p>
              <p>Description: {property.description}</p>
              <p>Features: {property.features}</p>
              <p>Tenor: {property.tenor}</p>
            </div>
          </div>
        </Modal>
      </Styles>
    </>
  );
};

export default Property;
