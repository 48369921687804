import styled from "styled-components";
import media from "styled-media-query";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import womanLookingLaptop from "../../Assets/images/woman_looking_desktop.png";
import DynamicScrollToTop from "../../utils/DynamicScrollToTop";
import axios from "axios";
import Sanitizer from "../../utils/Sanitizer";
import ContentPreview from "../../utils/ContentPreview";
import useAppHook from "../../hooks/useAppHook";
// import NeedHelp from "../../components/blocks/NeedHelp";
// import StillHaveQuestions from "../../components/blocks/StillHaveQuestions";

const Styles = styled.section`
  position: relative;
  padding: 0 5%;
  padding-top: 30px;
  padding-bottom: 250px;
  ${media.greaterThan("768px")`
    padding-top: 50px;
  `};
  ${media.greaterThan("1024px")`
   /* padding-top: 120px; */
    padding-top: 80px;

  `};
  ${media.greaterThan("1440px")`

  `};

  h1 {
    font-family: "Tiempos Headline";
    font-size: 55px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #1a191e;
    text-align: center;
    margin-bottom: 50px;

    ${media.greaterThan("768px")`
      font-size: 70px;
    `};
    ${media.greaterThan("1024px")`
    margin-bottom: 96px;

    `};
    ${media.greaterThan("1440px")`

    `};
  }

  .left_texts_part {
  }
`;

const FiltersContainer = styled.div`
  display: none;
  gap: 32px;
  align-items: center;
  ${media.greaterThan("768px")`
    display: flex;
  `};
  ${media.greaterThan("1024px")`

  `};
  ${media.greaterThan("1440px")`

  `};

  p {
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    letter-spacing: -0.03em;
    color: #1a191e;
  }

  .buttons_container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    position: absolute;
    bottom: 0;

    ${media.greaterThan("1024px")`
      gap: 16px;

    `};

    .category {
      background: #f8f8f8;
      border-radius: 4px;
      padding: 4px 10px;
      border: none;

      font-size: 13px;
      line-height: 180%;
      color: #1a191e;
    }
  }

  .buttons_container_filter {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    bottom: 0;

    ${media.greaterThan("1024px")`
      gap: 16px;

    `};
  }
`;

const BlogsContainer = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 68px;

  ${media.greaterThan("768px")`
  gap: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 68px;
  

  `};
  ${media.greaterThan("1024px")`
  grid-template-columns: repeat(3, 1fr)

  `};
  ${media.greaterThan("1440px")`

  `};
`;

const Blog = styled.div`
  width: 100%;
  max-width: 378px;
  cursor: pointer;
  /* background: green; */
  height: 550px;
  position: relative;
  margin: 0 auto;

  ${media.greaterThan("768px")`
      height: 620px; 
  `};
  ${media.greaterThan("1024px")`
      height: 700px; 
  `};
  ${media.greaterThan("1440px")`
      height: 750px;
  `};

  .image {
    width: 100%;
    max-width: 378px;
    /* height: 378px; */
    height: 250px;
    background: #f8f8f8;
    border-radius: 16px;
    position: relative;
    margin-bottom: 24px;
    object-fit: cover;

    ${media.greaterThan("1024px")`
      /* height: 330px; */
      height: 300px;
    `};
    ${media.greaterThan("1440px")`
      height: 378px;
    `};
  }
  .preview_image {
    max-inline-size: 100%;
    block-size: auto;
    object-fit: cover;
    border-radius: 16px;
    width: 100%;
    height: 100%;
  }

  .body {
    font-family: "ApercuProMediumNormal";
    .title {
      font-size: 28px;
      line-height: 120%;
      letter-spacing: -0.03em;
      color: #1a191e;
    }

    .mini_detail {
      font-size: 16px;
      line-height: 160%;
      color: rgba(0, 0, 0, 0.6);
    }

    .categories {
      display: flex;
      gap: 8px;
    }
  }
`;

const Blogs = () => {
  // const router = useRouter();
  const navigate = useNavigate();
  const [blogsList, setBlogsList] = useState([]);
  const { setBlogContentObj, setAllBlogsObj, blogContent, blogContentObj } =
    useAppHook();

  useEffect(() => {
    axios
      // .get(`/${info.customer_type}/payment-invoices?limit=10&offset=10`)
      .get("/blogs?limit=10&offset=0&search")
      .then((res) => {
        const result = res.data?.data?.records;
        setBlogsList(result);
        localStorage.setItem("allBlogs", JSON.stringify(result));
        // setAllBlogsObj(result);
        // console.log(
        //   "response from the blog endpoint: ",
        //   res.data?.data?.records
        // );
      })
      .catch((err) => {
        console.log("error from the blogs endpoint: ", err.response);
      });
  }, []);

  // useEffect(() => {
  //   console.log("is there a blogContent yet?: ", blogContent);
  //   console.log("is there a blogContent yet obj?: ", blogContentObj);
  // }, [blogContent, blogContentObj]);

  const handleBlogClick = (item, index) => {
    // setBlogContentObj({
    //   blogContentMain: item,
    //   blogIndex: index,
    // });
    localStorage.setItem(
      "blogContent",
      JSON.stringify({
        blogContentMain: item,
        blogIndex: index,
      })
    );
    navigate(`/blogs/${item.slug}`);
  };

  return (
    <Styles>
      <DynamicScrollToTop />
      <h1>Blog</h1>
      <FiltersContainer>
        <p>Categories</p>
        <div className="buttons_container_filter">
          <button>All</button>
          <button>Product</button>
          <button>Technology</button>
          <button>App</button>
        </div>
      </FiltersContainer>
      <BlogsContainer>
        {blogsList.map((blog, index) => {
          return (
            <Blog key={blog.id}>
              <div className="image">
                <img
                  src={blog.media[0].original_url}
                  alt=""
                  className="preview_image"
                />
              </div>

              <div onClick={() => handleBlogClick(blog, index)}>
                <div className="body">
                  <div className="title">{blog.title}</div>
                  <div className="mini_detail">
                    {ContentPreview(blog.content, 0, 250)}...
                  </div>
                  <FiltersContainer>
                    <div
                      // style={{ paddingTop: "24px" }}
                      className="buttons_container"
                    >
                      {blog.categories.map((category) => {
                        return (
                          <p className="category" key={category.id}>
                            {category.category}
                          </p>
                        );
                      })}
                    </div>
                  </FiltersContainer>
                </div>
              </div>
            </Blog>
          );
        })}
      </BlogsContainer>
    </Styles>
  );
};

export default Blogs;
