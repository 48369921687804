import React, { useEffect } from "react";
import styled from "styled-components";
import DashboardLayout from "../../components/layout/dashboardLayout";
import Header from "../../components/layout/Header";

import copyIcon from "../../Assets/images/copy_icon.svg";
import useCopyToClipboard from "../../utils/CopyToClipboard";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Tab } from "@headlessui/react";
import media from "styled-media-query";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Style = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  .box1 {
    height: 100px;
    width: 167px;
    background: #f8f8f8;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    margin-right: 8px;
  }
  .pill {
    width: 72px;
    height: 15px;
    background: #f8ad15;
    border-radius: 8px;
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
  }
  .white {
    color: white !important;
  }
  .line-header {
    display: flex;
    align-items: center;
    margin-bottom: 32.5px;
    p {
      font-family: "Apercu Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      /* identical to box height */
      margin-right: 5px;
      color: #000000;
    }
    div {
      flex: 1;
      border-bottom: 1.3px solid #cc8d2b;
      height: 1px;
    }
  }
  .pay {
    height: 43px;
    left: 1088px;
    top: 636px;
    margin-top: 4px;
    background: #f8ad15;
    border-radius: 5px;
    border: none;
    outline: none;
    appearance: none;
    width: 100%;
    color: white;
  }
  .toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 34px;
    p {
      margin-right: 11px;

      font-family: "DM Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 100%;
      /* identical to box height, or 13px */

      letter-spacing: -0.03em;

      /* background/primary */

      color: #fbbd29;
    }
  }
  .entry {
    background: #f8f8f8;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px 12px 12px;
    gap: 16px;
    height: 65px;
    margin-bottom: 10px;
    justify-content: space-between;
  }
  .rent {
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 160%;
    /* or 19px */

    text-align: center;

    /* text/default */

    color: #1a191e;
  }
  .fee {
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 120%;
    /* identical to box height, or 36px */

    letter-spacing: -0.03em;

    color: #000000;
  }
  .box2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 2px;

    width: 167px;
    height: 100px;

    background: #cc8d2b;
    border-radius: 10px;
  }
  .box-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
  }
  .tab {
    margin-right: 24px;
    background: white;
    appearance: none;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding-left: 0;
  }
  .tab-list {
    margin-bottom: 39px;
  }
  .selected {
    color: #fcba2d;
  }
  .deselected {
    color: #919499;
  }
  .main-content {
    ${media.lessThan("medium")`
    padding: 46px 16px 0;
  `}
    overflow: scroll;
    flex: 1;
    padding: 46px 36px 0;
    background: #f8f8f8;
  }
  .right-sidebar {
    ${media.lessThan("medium")`
    display: none;
  `}
    overflow: scroll;
    width: 375px;
    height: 100%;
    background: white;
    padding: 48px 18px;
  }

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  .inv {
    display: flex;
    justify-content: space-between;
    align-items: end;
  }
  .month {
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */

    letter-spacing: -0.03em;

    /* text/default */

    color: #1a191e;
  }
  .date {
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 160%;
    /* or 19px */

    color: rgba(26, 25, 30, 0.6);
  }
  .amount {
    p {
      font-family: "Apercu Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 160%;
      /* identical to box height, or 26px */

      /* text/default */

      color: #1a191e;
    }
  }
  .rep-title {
    margin-bottom: 19px;
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    /* or 25px */

    letter-spacing: -0.03em;

    /* text/default */

    color: #1a191e;
  }

  .developer-section {
    margin-top: 60px;
  }

  .active-tab {
    color: #ffffff !important;
    background: #f8ad15 !important;
  }
  .inactive-tab {
  }
  .tab1-container {
    padding: 42px;
    height: 548px;
    background: #ffffff;
    border-radius: 15px;
  }
  .text-group {
    display: flex;
    .text-1 {
      font-family: "Apercu Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      margin-right: 12px;
      color: #298097;
    }
    .text-2 {
      font-family: "Apercu Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #f8ad15;
    }
  }
  .section-finance {
    height: 410px;
    margin-top: 26px;
    border: 1.3px solid rgba(196, 196, 196, 0.5);
    border-radius: 15px;
    padding: 25px 30px;
    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #919499;
      margin-bottom: 15px;
    }
  }
  .tablet {
    border: none;
    outline: none;
    appearance: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    // padding: 8px 8px 8px 12px;
    justify-content: center;
    width: auto;
    height: 43px;
    padding: 0 8px;
    margin-right: 16px;
    background: #dee7ec;
    border-radius: 6px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 170%;
    cursor: pointer;
    color: #1a191e;
  }
  .key-input {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #919499;
  }
  .top-space {
    margin-top: 15px;
  }
  .key-pill {
    height: 65px;
    padding: 25px 27px;
    border: 1.3px solid rgba(196, 196, 196, 0.5);
    border-radius: 14px;
    position: relative;

    p {
      font-family: "Apercu Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;

      /* Black */

      color: #000000;
    }
    .copy_icon_within {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      border: none;
      background: transparent;
      cursor: pointer;
    }
  }
  .tablet-list {
    display: flex;
    margin-bottom: 23px;
  }
  .collection {
    width: 50%;
  }
  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .light-input {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    color: #c4c4c4;
  }
  .bold-input {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    color: #474747;
  }
  // .text-right {
  //   text-align: right;
  // }

  .button-tab {
    border: none;
    background: none;
    color: #f8ad15;
  }

  .button-tab.selected-button {
    color: #298097;
  }

  button.active {
    color: #298097;
  }

  .text-button {
    border: none;
    background: none;
    color: #f8ad15;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    cursor: pointer;
  }
`;

const Developer = () => {
  const navigate = useNavigate();
  const [showApi, setShowApi] = useState(false);
  const [apiKeys, setApiKeys] = useState({
    live_encryption: "",
    live_public_key: "",
    live_secret_key: "",
    test_encryption: "",
    test_public_key: "",
    test_secret_key: "",
  });

  const [activeTab, setActiveTab] = useState(0);
  const [value, copy] = useCopyToClipboard();

  const handleGenerateApiKeys = () => {
    axios
      .post("/developer/generate-api-key", {
        "content-type": "application/json",
      })
      // .post("/developer/get-api-key", {
      //   accept: "application/json",
      // })
      .then((res) => {
        console.log("this is the res from generate click: ", res);
        if (res.status === 200) {
          setShowApi(true);
          setApiKeys((prev) => ({
            ...prev,
            live_encryption: res.data.data.live_encryption,
            live_public_key: res.data.data.live_public_key,
            live_secret_key: res.data.data.live_secret_key,
            test_encryption: res.data.data.test_encryption,
            test_public_key: res.data.data.test_public_key,
            test_secret_key: res.data.data.test_secret_key,
          }));
        }
      })
      .catch((err) => {
        console.log("this is the err from generate key", err);
        toast.error(err.message);
      });
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    // setUserDetail(info);
    if (info === null || info === undefined) {
      navigate("/signin");
    }
  }, []);

  return (
    <Style>
      <div className="main-content">
        <div className="header">
          <Header title="" subTitle="" />
        </div>
        <div className="developer-section">
          <div>
            <Tab.Group>
              <Tab.List className="tablet-list">
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "tablet",
                      selected ? "active-tab" : "inactive-tab"
                    )
                  }
                >
                  Finance API
                </Tab>
                {/* <Tab
                  className={({ selected }) =>
                    classNames(
                      "tablet",
                      selected ? "active-tab" : "inactive-tab"
                    )
                  }
                >
                  Gov ID Verification
                </Tab> */}
                {/* <Tab
                  className={({ selected }) =>
                    classNames(
                      "tablet",
                      selected ? "active-tab" : "inactive-tab"
                    )
                  }
                >
                  Document Verification
                </Tab> */}
                {/* <Tab
                  className={({ selected }) =>
                    classNames(
                      "tablet",
                      selected ? "active-tab" : "inactive-tab"
                    )
                  }
                >
                  BVN Verification
                </Tab> */}
              </Tab.List>
              <Tab.Panels className="mt-2">
                <Tab.Panel className={classNames("", "")}>
                  {showApi ? (
                    <div className="tab1-container">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="text-group">
                          <button
                            onClick={() => setActiveTab(0)}
                            className={`text-button ${
                              activeTab === 0 ? "active" : "inactive"
                            }`}
                            style={{ marginRight: "14px" }}
                          >
                            Test API Keys{" "}
                          </button>
                          <button
                            onClick={() => setActiveTab(1)}
                            className={`text-button ${
                              activeTab === 1 ? "active" : "inactive"
                            }`}
                          >
                            Live API Keys{" "}
                          </button>
                        </div>
                        {/* <button
                          onClick={() => {
                            activeTab === 0
                              ? copy(
                                  `${apiKeys.test_public_key}\n${apiKeys.test_secret_key}\n${apiKeys.test_encryption}`
                                )
                              : copy(
                                  `${apiKeys.live_public_key}\n${apiKeys.live_secret_key}\n${apiKeys.live_encryption}`
                                );
                          }}
                          className=""
                          style={{ border: "none", background: "transparent" }}
                        >
                          <img src={copyIcon} alt="copy" />
                        </button> */}
                      </div>

                      <div className="section-finance">
                        <p className="key-input top-space">Public Key</p>
                        <div className="key-pill">
                          {/* <p>LTP-dhhfushhff-njbcehcebnd</p> */}
                          {activeTab === 0 ? (
                            <p>{apiKeys.test_public_key}</p>
                          ) : (
                            <p>{apiKeys.live_public_key}</p>
                          )}
                          <button
                            onClick={() => {
                              activeTab === 0
                                ? copy(apiKeys.test_public_key)
                                : copy(apiKeys.live_public_key);
                            }}
                            className="copy_icon_within"
                          >
                            <img src={copyIcon} alt="copy" />
                          </button>
                        </div>
                        <p className="key-input top-space">Secret Key</p>
                        <div className="key-pill">
                          {activeTab === 0 ? (
                            <p>{apiKeys.test_secret_key}</p>
                          ) : (
                            <p>{apiKeys.live_secret_key}</p>
                          )}
                          <button
                            onClick={() => {
                              activeTab === 0
                                ? copy(apiKeys.test_secret_key)
                                : copy(apiKeys.live_secret_key);
                            }}
                            className="copy_icon_within"
                          >
                            <img src={copyIcon} alt="copy" />
                          </button>
                        </div>
                        <p className="key-input top-space">Encryption key</p>
                        <div className="key-pill">
                          {activeTab === 0 ? (
                            <p>{apiKeys.test_encryption}</p>
                          ) : (
                            <p>{apiKeys.live_encryption}</p>
                          )}
                          <button
                            onClick={() => {
                              activeTab === 0
                                ? copy(apiKeys.test_encryption)
                                : copy(apiKeys.live_encryption);
                            }}
                            className="copy_icon_within"
                          >
                            <img src={copyIcon} alt="copy" />
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="tab1-container">
                      {/* <p>Generate Api Key</p> */}
                      <button
                        className="pay"
                        style={{ maxWidth: "300px", margin: "auto" }}
                        onClick={handleGenerateApiKeys}
                      >
                        Generate Api Key
                      </button>
                    </div>
                  )}
                </Tab.Panel>
                <Tab.Panel className={classNames("", "")}>yoo </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </div>
      {/* <div className="right-sidebar">
        <p className="rep-title">Repayments</p>
        <div>
          <Tab.Group>
            <Tab.List className="tab-list">
              <Tab
                className={({ selected }) =>
                  classNames("tab", selected ? "selected" : "deselected")
                }
              >
                Transaction
              </Tab>
              <Tab
                className={({ selected }) =>
                  classNames("tab", selected ? "selected" : "deselected")
                }
              >
                {({ selected }) => selected && "zzzii"}
                Details
              </Tab>
            </Tab.List>
            <Tab.Panels className="mt-2">
              <Tab.Panel className={classNames("", "")}>
                <div>
                  <div className="box-container">
                    <div className="box1">
                      <p className="pill">Financed Cost</p>
                      <p className="rent">Rental Finance</p>
                      <p className="fee">₦12,150,50</p>
                    </div>
                    <div className="box2">
                      <p className="rent white">Repayment</p>
                      <p className="fee white">₦6,150,50</p>
                    </div>
                  </div>
                  <div className="line-header">
                    <p>Payment Breakdown</p>
                    <div className="line" />
                  </div>
                  <div>
                    {invoice
                      .filter((inv) => inv.paymentDate)
                      .map((inv, i) => (
                        <div key={i} className="entry">
                          <div>
                            <p className="month">{inv.month}</p>
                            <p className="date">
                              {inv.paymentDate
                                ? `Payment date: ${inv.paymentDate}`
                                : `Due date: ${inv.dueDate}`}
                            </p>
                          </div>
                          <div className="amount">
                            <p>{inv.amount}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="toggle">
                    <p>Hide Previous Payments</p>
                    <img alt="" src="up.svg" />
                  </div>
                  <div className="line-header">
                    <p>Due Payment </p>
                    <div />
                  </div>
                  <div>
                    {invoice
                      .filter((inv) => inv.dueDate)
                      .map((inv, i) => (
                        <div key={i} className="entry">
                          <div>
                            <p className="month">{inv.month}</p>
                            <p className="date">
                              {inv.paymentDate
                                ? `Payment date: ${inv.paymentDate}`
                                : `Due date: ${inv.dueDate}`}
                            </p>
                          </div>
                          <div className="amount">
                            <p>{inv.amount}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div>
                    <button className="pay">Make Payment</button>
                  </div>
                </div>
              </Tab.Panel>
              <Tab.Panel className={classNames("", "")}>
                <div className="row">
                  <div className="collection">
                    <p className="light-input">Account number</p>
                    <p className="bold-input">(603) 555-0123</p>
                  </div>
                  <div className="collection">
                    <p className="light-input text-right">Account name</p>
                    <p className="bold-input text-right">GTBank</p>
                  </div>
                </div>

                <div className="row">
                  <div className="collection">
                    <p className="light-input">BVN</p>
                    <p className="bold-input">****************</p>
                  </div>
                  <div className="collection">
                    <p className="light-input text-right">Branch</p>
                    <p className="bold-input text-right">****************</p>
                  </div>
                </div>
                <div className="row">
                  <div className="collection">
                    <p className="light-input">Credit interest rate</p>
                    <p className="bold-input">20%</p>
                  </div>
                  <div className="collection">
                    <p className="light-input text-right">Branch</p>
                    <p className="bold-input text-right">7,658%</p>
                  </div>
                </div>
                <div>
                  <button className="pay">Edit Information</button>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div> */}
    </Style>
  );
};

// Developer.layout = DashboardLayout;

export default Developer;
