import styled from "styled-components";
import media from "styled-media-query";

const CodingFieldImageContainer = styled.section`
  width: 100%;
  position: relative;
  height: ${(props) => (props.height ? props.height : "188px")};
  ${media.greaterThan("768px")`
  height: ${(props) => (props.mdHeight ? props.mdHeight : "188px")};

  `};
  ${media.greaterThan("1024px")`
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "860px")};
  height: ${(props) => (props.lgHeight ? props.lgHeight : "188px")};

  `};
  ${media.greaterThan("1440px")`

  `};

  .left_texts_part {
  }
`;

export default CodingFieldImageContainer;
