import styled from "styled-components";
import media from "styled-media-query";

import { HiOutlineArrowSmRight } from "react-icons/hi";
import { IoCheckmarkSharp } from "react-icons/io5";
import { BiPlus } from "react-icons/bi";
import { RiPhoneFill } from "react-icons/ri";
import {
  BigTitle,
  FlexedSection,
  MarkCircle,
  SmallCard,
} from "../../../pages/Home";
import Button from "../../elements/Button";
import { useEffect, useState } from "react";
import StillHaveQuestions from "../StillHaveQuestions";
import MoreQuestionsContainer from "../StillHaveQuestions/MoreQuestionsContainer";
// import BaseApi from "../../../Api/BaseApi";
import axios from "axios";
import { MdOutlineMarkEmailUnread } from "react-icons/md";
import { Link } from "react-router-dom";

const Styles = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 110px 5%;
  padding-bottom: 150px;
  background: ${(props) => (props.bg ? props.bg : "#E8F2EE")};
  position: relative;

  ${media.greaterThan("768px")`
  flex-direction: row;
  `};
  ${media.greaterThan("1024px")`

  `};
  ${media.greaterThan("1440px")`

  `};

  .left_texts_part {
    width: 100%;
    ${media.greaterThan("768px")`
      flex: 1;
    `};
  }
`;

const TotalQuestioningsContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 65px; */
  width: 100%;
  margin-top: 50px;
  ${media.greaterThan("768px")`
  flex: 1;
  margin-top: 0px;
  `};
  ${media.greaterThan("1024px")`

  `};
  ${media.greaterThan("1440px")`

  `};

  .ques_and_ans {
    ${media.greaterThan("768px")`
      /* margin-top: 0px; */
  `};
    ${media.greaterThan("1024px")`
  
  `};
    ${media.greaterThan("1440px")`
 
  `};

    .horizontal_bar {
      margin: 32px 0;
    }
  }
`;

const QuesTexts = styled.div`
  font-family: "ApercuProMediumNormal";
  font-size: 16px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #1a191e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  ${media.greaterThan("768px")`
  /* font-size: 20px; */
  `};
  ${media.greaterThan("1024px")`
  font-size: 18px;
  `};
  ${media.greaterThan("1440px")`
  font-size: 20px;
  `};
`;

const AnsTexts = styled.p`
  /* display: ${(props) =>
    props.chosenId === props.ansId ? "block" : "none"}; */
  /* display: ${(props) => (props.showAns === true ? "block" : "none")}; */
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  color: rgba(26, 25, 30, 0.5);
  padding-top: 16px;

  ${media.greaterThan("768px")`
  /* max-width: 180px; */
  `};
  ${media.greaterThan("1024px")`
  font-size: 14px;
  `};
  ${media.greaterThan("1440px")`
  font-size: 16px;
  `};
`;

const NeedHelp = ({ bg }) => {
  const [faq, setFaq] = useState([]);

  useEffect(() => {
    const faqs = JSON.parse(localStorage.getItem("faq"));
    if (faqs !== null && faqs.length >= 1) {
      setFaq(faqs);
    } else {
      setFaq([]);
    }
  }, []);
  const sampleQueAns = [
    {
      id: 1,
      question: "How do I create a Landlords Technology account?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dui accumsan sit amet nulla facilisi morbi. Eget gravida cum sociis natoque penatibus et magnis dis parturient.",
    },
    {
      id: 2,
      question: "How do I process my repayments",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dui accumsan sit amet nulla facilisi morbi. Eget gravida cum sociis natoque penatibus et magnis dis parturient.",
    },
    {
      id: 3,
      question: "How do i apply for rental refinance",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dui accumsan sit amet nulla facilisi morbi. Eget gravida cum sociis natoque penatibus et magnis dis parturient.",
    },
    {
      id: 4,
      question: "How does landlords techology works?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dui accumsan sit amet nulla facilisi morbi. Eget gravida cum sociis natoque penatibus et magnis dis parturient.",
    },
    {
      id: 5,
      question: "How can i untilize the refinance API Service?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dui accumsan sit amet nulla facilisi morbi. Eget gravida cum sociis natoque penatibus et magnis dis parturient.",
    },
  ];

  // const [showAns, setShowAns] = useState(true);
  const [chosenId, setChosenId] = useState();
  // const [ansVisibilityArray, setAnsVisibilityArray] = useState([]);

  const [ansVisibilityArray, setAnsVisibilityArray] = useState([]);

  // useEffect(() => {
  //   const ansVisibleArray = new Array(sampleQueAns.length).fill(false);
  //   setAnsVisibilityArray(ansVisibleArray);
  // }, [sampleQueAns.length]);

  useEffect(() => {
    let ansArray = [];
    faq.map((_, index) => {
      ansArray.push({
        id: index + 1,
        state: false,
      });
    });

    setAnsVisibilityArray(ansArray);
  }, [faq]);

  useEffect(() => {}, [ansVisibilityArray]);

  const handleQuestionClick = (id) => {
    console.log("this is the id of the question clicked: ", id);
    setChosenId(id);

    ansVisibilityArray.map((value) => {
      if (value.id === id) {
        if (value.state === true) {
          value.state = false;
        } else if (value.state === false) {
          value.state = true;
        }
      } else {
        value.state = false;
      }
    });
    // ansVisibilityArray.map((value) => {
    //   if (value.id === id) {
    //     if (value.state === true) {
    //       value.state = false;
    //     } else {
    //       value.state = true;
    //     }
    //   } else {
    //     value.state = false;
    //   }
    // });
  };
  // console.log("this is visibility: ", ansVisibilityArray);

  return (
    <Styles bg={bg}>
      <div className="left_texts_part">
        <BigTitle>Need help?</BigTitle>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "18px",
          }}
        >
          <SmallCard no_box no_bg>
            <MarkCircle>
              <RiPhoneFill color="#5BB5A2" />
            </MarkCircle>
            <div className="card_texts">
              <p className="title" style={{ whiteSpace: "nowrap" }}>
                +234 809 990 8909
              </p>
              <p className="subtitle">Support Hotline</p>
            </div>
          </SmallCard>
          <SmallCard no_box no_bg>
            <MarkCircle>
              <MdOutlineMarkEmailUnread color="#5BB5A2" />
            </MarkCircle>

            <div className="card_texts">
              <Link
                to=""
                onClick={(e) => {
                  window.location.href =
                    "mailto:support@landlordstechnology.com";
                  e.preventDefault();
                }}
              >
                <p className="title">
                  Support
                  <span style={{ textDecoration: "underline" }}>
                    @landlordstechnology.com
                  </span>
                </p>
              </Link>

              <p className="subtitle">Support Email</p>
            </div>
          </SmallCard>
        </div>
        <Link to={"/support"}>
          <Button style={{ marginTop: "40px" }} clr={"#FBBD29"}>
            Support <HiOutlineArrowSmRight />
          </Button>
        </Link>
      </div>
      <TotalQuestioningsContainer>
        {faq.map((queAns) => {
          return (
            <div key={queAns.id} className="ques_and_ans">
              <QuesTexts onClick={() => handleQuestionClick(queAns.id)}>
                <p>{queAns.question}</p>
                <BiPlus />
              </QuesTexts>
              {ansVisibilityArray[queAns.id - 1]?.state === true ? (
                <AnsTexts>{queAns.answer}</AnsTexts>
              ) : null}
              <hr className="horizontal_bar" />
            </div>
          );
        })}

        {/* <div className="questions_divider"></div> */}
      </TotalQuestioningsContainer>

      <MoreQuestionsContainer>
        {/* <StillHaveQuestions /> */}
      </MoreQuestionsContainer>
    </Styles>
  );
};

export default NeedHelp;
