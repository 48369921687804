import styled from "styled-components";
import media from "styled-media-query";

import { Link, useLocation } from "react-router-dom";
// import { useLocation } from "react-router-dom";

import LogoContainer from "../../elements/LogoContainer";

import { FooterContainer } from "./FooterStylings";
import FooterContentContainer from "./FooterContentContainer";
// import Logo from "../../../public/logo.svg";
import LogoFooter from "../../../Assets/images/logo_footer.svg";
// import { usePathname } from "../../../hooks/usePathname";
import StillHaveQuestions from "../StillHaveQuestions";
import { useEffect, useState } from "react";
// import { usePathname } from "../../../hooks/usePathname";

const FooterStyles = styled.section`
  position: relative;
  padding: 0 1.5%;
  padding-top: 100px;
  background: #000;
  z-index: 1;
  /* margin-top: 500px; */
  /* max-width: 1400px; */
  /* display: flex; */
  /* flex-direction: column; */
  ${media.greaterThan("1024px")`
  /* flex-direction: row; */
  `}

  .footer_bottom {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-top: solid 1px #e8e8e8;
    ${media.greaterThan("1024px")`
    max-width: 85%
    `}

    .footer_subtexts {
      display: flex;
      align-items: center;
      justify-content: space-between;
      /* color: rgba(26, 25, 30, 0.5); */
      color: #fff;
      font-size: 10px;
      line-height: 160%;
      font-family: "ApercuProMediumNormal";
      padding-bottom: 48px;

      ${media.greaterThan("768px")`
        font-size: 14px;
    `}
    }
  }

  .main_footer_part {
    padding-bottom: 30px;
    ${media.greaterThan("1024px")`
        padding-bottom: 0px;
    `}
  }

  .more_footer_text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    padding-top: 12px;

    /* color: rgba(26, 25, 30, 0.5); */
    color: #fff;

    ${media.greaterThan("768px")`
        font-size: 14px;
    `}
    ${media.greaterThan("1024px")`
        font-size: 15px;
        max-width: 200px;
        padding-top: 14px;
    `}
    ${media.greaterThan("1200px")`
        max-width: 280px;
    `}
    ${media.greaterThan("1440px")`
        font-size: 15px;
        padding-top: 16px;
        max-width: 320px;
    `}
  }
`;

const Footer = () => {
  // const router = useRouter();
  const [isHomepage, setIsHomepage] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "/home") {
      setIsHomepage(true);
    } else {
      setIsHomepage(false);
    }
    // location.pathname === "/" || location.pathname === "/home" ? (
    //   setIsHomepage(true)
    // ) : (
    //   setIsHomepage(false)
  }, [location.pathname]);

  return (
    <FooterStyles>
      {/* {router.pathname === "/" ? "" : <StillHaveQuestions />} */}
      {location.pathname === "/" || location.pathname === "/home" ? (
        ""
      ) : (
        <StillHaveQuestions />
      )}

      <hr
        style={{
          width: "95%",
          color: "#E8E8E8",
          position: "absolute",
          top: "0",
          left: "50%",
          transform: "translateX(-50%)",
          padding: "0",
          margin: "0",
        }}
      />
      <FooterContainer isHomepage={isHomepage}>
        <div className="containing_container">
          <LogoContainer>
            <img
              src={LogoFooter}
              alt="Landlords Technology Logo"
              style={{ width: "100%", height: "100%", position: "" }}
            />
          </LogoContainer>
          <FooterContentContainer />
        </div>
        <div className="footer_bottom">
          <div className="footer_subtexts">
            <p>© Landlordstechnology</p>
            <div style={{ display: "flex", alignItems: "center", gap: "32px" }}>
              <p>Cookie policy</p>
              <Link to={"/privacy"}>
                <p>Privacy policy</p>
              </Link>
            </div>
          </div>
        </div>
      </FooterContainer>
    </FooterStyles>
  );
};

export default Footer;
