import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

import messageIcon from "../Assets/images/message_icon.svg";
import phoneIcon from "../Assets/images/phone_icon.svg";
import logoAnother from "../Assets/images/logo_another.svg";
import eyeOpen from "../Assets/images/eye_open_icon.svg";
import eyeClosed from "../Assets/images/eye_closed_icon.svg";

import Button from "../components/elements/Button";
import styled from "styled-components";
import media from "styled-media-query";
import ImagePartContent from "../components/blocks/Authentication/ImagePartContent";
import {
  ExtraTexts,
  OverallAuthContainer,
  FormPart,
} from "../components/blocks/Authentication";
import { TiArrowBack } from "react-icons/ti";
import LogoContainerOther from "../components/elements/LogoContainerOther";
import LogoAbsolutePlacer from "../components/elements/LogoAbsolutePlacer";
import Validator from "../utils/Validator";
import { Circles } from "react-loader-spinner";
import { useEffect } from "react";

const ResetViaEmailBox = styled.div`
  background: #ffffff;
  border: 2px solid rgba(41, 128, 151, 0.7);
  box-shadow: 0px 10px 15px rgba(41, 128, 151, 0.1);
  border-radius: 14px;
  padding: 5%;
  ${media.greaterThan("768px")`
  
  `};
  ${media.greaterThan("1024px")`

  `};
  ${media.greaterThan("1440px")`

  `};

  /* #contain {
    ::placeholder {
      color: "#C4C4C4";
    }
  } */
`;

const NeededFlexed = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  .icon_overall {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: #f8ad15;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .texts {
    display: flex;
    flex-direction: column;
    gap: 11px;
    justify-content: space-between;

    .title {
      font-family: "ApercuProMediumNormal";
      font-size: 18px;
      line-height: 22px;
      color: #000000;
    }

    .subtitle {
      font-size: 14px;
      line-height: 17px;
      color: #c4c4c4;
    }
  }
`;

const ResetViaSms = styled.div`
  border: 1.3px solid rgba(196, 196, 196, 0.5);
  filter: drop-shadow(0px 10px 15px rgba(151, 151, 151, 0.08));
  border-radius: 14px;
  padding: 5%;
`;

const IconContainer = styled.div`
  position: relative;
  width: 20px;
  height: 18px;
`;

const ResetPassword = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [emailReset, setEmailReset] = useState("");

  useEffect(() => {
    const email = localStorage.getItem("email_reset");
    setEmailReset(email);
  }, []);

  const [data, setData] = useState({
    // full_name: "",
    code: "",
    password: "",
    confirm_password: "",
  });

  const [dataError, setDataError] = useState({
    code: [],
    password: [],
    confirm_password: [],
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    setDataError({
      code: [],
      password: [],
      confirm_password: [],
    });

    const validatorMethods = {
      code: "required",
      password: "required",
      confirm_password: "required|same-as,:password",
    };

    const validator = new Validator(data, validatorMethods);
    validator.result
      .then(async () => {
        setSubmitting(true);

        const codeCheck = new FormData();
        codeCheck.append("code", data.code);
        codeCheck.append("email", emailReset);

        const formData = new FormData();
        formData.append("code", data.code);
        formData.append("password", data.password);
        formData.append("password_confirmation", data.confirm_password);

        axios
          .post("/confirm-reset-code", codeCheck, {
            headers: {
              "content-type": "multipart/form-data",
            },
          })
          .then((res) => {
            // const resStatus = res.response.data.status_code;
            // if (resStatus === 200) {
            axios
              .post("/password-reset", formData)
              .then((res) => {
                toast.success("Password reset successful");
                console.log(
                  "this is the response after successful password update: ",
                  res
                );
                setSubmitting(false);
                localStorage.removeItem("email_reset");
                navigate("/signin");
              })
              .catch((err) => {
                setSubmitting(false);
                toast.error("An error occurred");
                console.log("this is the error from password reset: ", err);
              });
            // }
          })
          .catch((err) => {
            setSubmitting(false);
            toast.error(err?.response?.data?.message);
            console.log("this is response from the confirm-reset-code: ", err);
          });
      })
      .catch((error) => {
        setSubmitting(false);
        console.log(error);
        setDataError((prev) => ({
          ...prev,
          code: error?.error.code,
          password: error?.error.password,
          confirm_password: error?.error.confirm_password,
        }));
      });
  };

  return (
    <OverallAuthContainer>
      <div className="form_part">
        <LogoAbsolutePlacer>
          <LogoContainerOther onClick={() => navigate.push("/")}>
            <img
              src={logoAnother}
              alt=""
              style={{
                // position: "absolute",
                // objectFit: "contain",
                maxInlineSize: "100%",
                blockSize: "auto",
              }}
            />
          </LogoContainerOther>
        </LogoAbsolutePlacer>

        {/* <FormPart onClick={handleSubmit}> */}
        <FormPart>
          <h2 style={{ textAlign: "center" }}>Reset Password</h2>
          <p className="subheading">Create your new password</p>

          <div className="input_label_container">
            <label htmlFor="reset_code">Reset Token</label>
            <input
              type={"text"}
              id="reset_code"
              placeholder="Reset Code"
              className="form_input"
              value={data.code}
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  code: e.target.value,
                }))
              }
            />
            <p className="error message" style={{ textAlign: "left" }}>
              {dataError.code.join(", ")}
            </p>
          </div>
          <div className="input_label_container">
            <label htmlFor="new_password">New Password</label>
            <input
              type={showPassword ? "text" : "password"}
              id="new_password"
              placeholder="New Password"
              className="form_input"
              value={data.password}
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  password: e.target.value,
                }))
              }
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="password_visibility"
            >
              {showPassword ? (
                <img
                  src={eyeOpen}
                  alt="password visible"
                  width={15}
                  height={15}
                />
              ) : (
                <img
                  src={eyeClosed}
                  alt="password hidden"
                  width={15}
                  height={15}
                />
              )}
            </button>
            <p className="error message" style={{ textAlign: "left" }}>
              {dataError.password.join(", ")}
            </p>
          </div>
          <div className="input_label_container">
            <label htmlFor="confirm_password">Password</label>

            <input
              type={showConfirmPassword ? "text" : "password"}
              id="confirm_password"
              placeholder="Confirm New Password"
              className="form_input"
              value={data.confirm_password}
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  confirm_password: e.target.value,
                }))
              }
            />
            <button
              type="button"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              className="password_visibility"
            >
              {showConfirmPassword ? (
                <img
                  src={eyeOpen}
                  alt="password visible"
                  width={15}
                  height={15}
                />
              ) : (
                <img
                  src={eyeClosed}
                  alt="password hidden"
                  width={15}
                  height={15}
                />
              )}
            </button>
            <p className="error message" style={{ textAlign: "left" }}>
              {dataError.confirm_password.join(", ")}
            </p>
          </div>

          <Button
            type="submit"
            onClick={(e) => handleSubmit(e)}
            bg={"#F8AD15"}
            clr="#FFFFFF"
            style={{
              width: "100%",
              justifyContent: "center",
              height: "65px",
            }}
          >
            {submitting && (
              <Circles
                height="30"
                width="30"
                color="#fff"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            )}
            Reset Password
          </Button>
        </FormPart>
      </div>
      <div className="picture_part">
        <ImagePartContent />
      </div>
    </OverallAuthContainer>
  );
};

export default ResetPassword;

ResetPassword.getLayout = function PageLayout(page) {
  return <>{page}</>;
};
