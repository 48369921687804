import styled from "styled-components";
import media from "styled-media-query";

export const AuthenticationContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 5%;
  /* background: #ff0000; */
  height: 100vh;
  ${media.greaterThan("768px")`
  flex-direction: row;
  padding: 0;

  `};
  ${media.greaterThan("1024px")`

  `};
  ${media.greaterThan("1440px")`

  `};
`;

export const ExtraTexts = styled.div`
  /* display: flex;
  flex-direction: column; */
  font-family: "ApercuProMediumNormal";
  font-size: 14px;
  line-height: 17px;
  color: #c4c4c4;
  text-align: center;
  margin-top: 25px;

  ${media.greaterThan("768px")`

  `};
  ${media.greaterThan("1024px")`
  margin-top: 40px;

  `};
  ${media.greaterThan("1440px")`

  `};

  span {
    color: #000000;
  }
`;

export const FormPart = styled.form`
  /* display: flex; */
  display: grid;
  /* flex-direction: column; */
  grid-template-columns: 1;
  gap: 30px;
  width: 100%;
  margin: 0 auto;
  /* padding-bottom: 80px; */
  padding-top: 50px;

  ${media.greaterThan("768px")`
    width: 80%;
    margin: 0 auto;

  `};
  ${media.greaterThan("1024px")`
      max-width: 400px;
      padding-top: 90px;
  `};
  ${media.greaterThan("1440px")`
    max-width: 552px;
  `};

  .sub_container {
    display: flex;
    flex-direction: column;
    gap: 27px;

    ${media.greaterThan("768px")`
    flex-direction: row;
    align-items: center;
    gap: 5%;
  `};
    ${media.greaterThan("1024px")`
  
  `};
    ${media.greaterThan("1440px")`
  `};
  }

  .input_label_container {
    display: flex;
    flex-direction: column;
    position: relative;

    .forgot_password {
      position: absolute;
      top: 0;
      right: 20px;
      font-size: 14px;
      line-height: 17px;

      color: #f8ad15;
    }

    /* .password_visibility {
      position: absolute;
      cursor: pointer;
      right: 4%;
      bottom: 20%;
      bottom: 30%;
      transform: translateY(0, -50%);
    } */
  }

  label {
    font-family: "ApercuProMediumNormal";
    font-size: 14px;
    line-height: 17px;
    color: #000;
  }
  .form_input {
    border: 1.3px solid rgba(196, 196, 196, 0.5);
    border-radius: 14px;
    margin-top: 19px;
    height: 60px;
    padding: 16px 24px 16px 24px;
    width: 100%;
    color: #0c0c0c;

    ${media.greaterThan("768px")`
    height: 50px;
    padding: 16px 24px 16px 24px;
    
  `};
    ${media.greaterThan("1024px")`
    height: 65px;
    padding: 20px 34px 20px 34px;
  `};
    ${media.greaterThan("1440px")`
  `};
  }
  .form_input::placeholder {
    color: #c4c4c4;
  }

  .finance_with {
    color: #474747;
  }

  .finance_with::placeholder {
    color: #c4c4c4;
  }

  .form_input.message {
    height: 124px;
  }

  h2 {
    font-family: "Tiempos Headline";
    font-size: 30px;
    line-height: 39px;
    color: #034b5e;
  }

  .subheading {
    font-family: "ApercuProMediumNormal";
    font-size: 14px;
    line-height: 17px;
    color: #c4c4c4;
    text-align: center;
  }

  select {
    /* for Firefox */
    -moz-appearance: none;
    /* for Safari, Chrome, Opera */
    -webkit-appearance: none;
  }

  /* for IE10 */
  select::-ms-expand {
    display: none;
  }
`;

export const OverallAuthContainer = styled.div`
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  padding: 0 5%;
  width: 100%;

  min-height: 100vh;
  ${media.greaterThan("768px")`
  flex-direction: row;
  padding: 0;
  width: 55%

  `};
  ${media.greaterThan("1024px")`

  `};
  ${media.greaterThan("1440px")`

  `};

  .form_part {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding-bottom: 80px;
    justify-content: center;

    align-items: center;
  }
  .picture_part {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #034b5e;
    height: 100%;
    min-height: 100vh;

    ${media.greaterThan("768px")`
      width: 45%;
    `};
  }
`;

export const FormPartContainer = styled.div`
  width: 100%;

  ${media.greaterThan("768px")`


  `};
  ${media.greaterThan("1024px")`


  `};
  ${media.greaterThan("1440px")`

  `};
`;
