import styled from "styled-components";
import media from "styled-media-query";

const OrangeButton = styled.button`
  /* padding: 8px 12px; */
  cursor: pointer;
  border-radius: 6px;
  background: none;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 5px;
  border: none;
  font-size: 18px;

  ${media.greaterThan("1024px")`
    padding: 10px 14px;
    font-size: 14px;
    gap: 6px;
    background: ${(props) => (props.bg ? "#fbbd29" : "#fff")};
    /* padding: ${(props) => (props.padding_off ? "0px" : "8px 12px")}; */
    color: ${(props) => (props.bg ? "#fff" : "#fbbd29")};
  `}
  ${media.greaterThan("1440px")`
    font-size: 16px;
    padding: 16px 24px;

    `}
`;

export default OrangeButton;
