import styled from "styled-components";
import media from "styled-media-query";

const MoreQuestionsContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

export default MoreQuestionsContainer;
