import styled from "styled-components";
import media from "styled-media-query";
import SubTitle from "./SubTitle";

import happyEmoji from "../../../Assets/images/developers/happy_emoji.svg";
import sadEmoji from "../../../Assets/images/developers/sad_emoji.svg";

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 0 5%;

  ${media.greaterThan("1024px")`
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    /* max-width: 715px; */
  `};

  .emojis_emojis {
    display: flex;
    align-items: center;
    gap: 37px;

    .small_container {
      display: flex;
      gap: 12px;
      align-items: center;

      .emoji_container {
        position: relative;
        width: 40px;
        height: 40px;
        background: #fbbd29;
        border-radius: 50%;
      }
    }
  }
`;

const HelpfulEnough = () => {
  return (
    <Styles>
      <SubTitle>Did you find this page helpful enough?</SubTitle>
      <div className="emojis_emojis">
        <div className="small_container">
          <div className="emoji_container">
            <img
              src={happyEmoji}
              alt="it's helpful"
              style={{
                position: "",
                maxInlineSize: "100%",
                blockSize: "auto",
              }}
            />
          </div>
          <SubTitle>Yes</SubTitle>
        </div>
        <div className="small_container">
          <div className="emoji_container">
            <img
              src={sadEmoji}
              alt="it's not helpful"
              style={{
                position: "",
                maxInlineSize: "100%",
                blockSize: "auto",
              }}
            />
          </div>
          <SubTitle>No</SubTitle>
        </div>
      </div>
    </Styles>
  );
};

export default HelpfulEnough;
