import styled from "styled-components";
import media from "styled-media-query";

import { Link, useNavigate } from "react-router-dom";

import { HiOutlineArrowSmRight } from "react-icons/hi";

import laptopApiImage from "../Assets/images/laptop_api.png";
import customerIdImage from "../Assets/images/customer_id.png";
import womanLookingDesktopImage from "../Assets/images/woman_looking_desktop.png";
import handHoldingPhone from "../Assets/images/hand_holding_phone_landlord.png";
import imageDecorator from "../Assets/images/pattern_back_label.png";

import Button from "../components/elements/Button";
import NeedHelp from "../components/blocks/NeedHelp";
import StillHaveQuestions from "../components/blocks/StillHaveQuestions";
import DynamicScrollToTop from "../utils/DynamicScrollToTop";
import GoToTop from "../utils/GoToTop";
import useAppHook from "../hooks/useAppHook";

const Styles = styled.div`
  padding: 0 5%;
  /* padding-top: 120px; */
  padding-bottom: 100px;

  ${media.greaterThan("768px")`
    
  `}
  ${media.greaterThan("800px")`
    
  `}
  ${media.greaterThan("1024px")`
    /* padding-top: 200px; */
    padding-bottom: 200px;
  `}
  ${media.greaterThan("1440px")`
    
  `} 

  .title {
    font-family: "Tiempos Headline";
    font-style: normal;
    font-weight: 900;
    font-size: 35px;
    line-height: 110%;
    letter-spacing: -0.03em;
    color: #1a191e;

    ${media.greaterThan("768px")`
      font-size: 45px;
    `}
    ${media.greaterThan("800px")`
      font-size: 40px;
    `}
    ${media.greaterThan("1024px")`
      font-size: 60px;
    `}
    ${media.greaterThan("1440px")`
    
    `}
  }

  .main_title {
    ${media.greaterThan("768px")`
      
    `}
    ${media.greaterThan("800px")`
    
    `}
    ${media.greaterThan("1024px")`
      
    `}
    ${media.greaterThan("1440px")`
    
    `}
  }

  .other_title {
    margin-bottom: 20px;
    ${media.greaterThan("768px")`
      margin-bottom: 20px;
    `}
    ${media.greaterThan("1024px")`
      margin-bottom: 30px;
    `}
    ${media.greaterThan("1440px")`
    
    `}
  }

  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    color: #1a191e;
    margin-bottom: 20px;

    ${media.greaterThan("768px")`
      font-size: 18px;
    `}
    ${media.greaterThan("1024px")`
      font-size: 20px;
      margin-bottom: 0px;
    `}
    ${media.greaterThan("1440px")`
    
    `}
  }

  .text_main {
    margin: 30px 0;
    ${media.greaterThan("768px")`
    
    `}
    ${media.greaterThan("1024px")`
      margin: 50px 0;
    `}
    ${media.greaterThan("1440px")`
    
    `}
  }

  .flexed_container {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 90px;

    ${media.greaterThan("768px")`

    `}
    ${media.greaterThan("800px")`
      flex-direction: row;
      padding-top: 130px;
      justify-content: center;
      align-items: center;
    `}
    ${media.greaterThan("1024px")`
      flex-direction: row;
      padding-top: 130px;
      justify-content: center;
      align-items: center;
    `}
    ${media.greaterThan("1440px")`
    
    `}
  }

  .reversed {
    flex-direction: column-reverse;
    ${media.greaterThan("800px")`
      flex-direction: row-reverse;
    `}
    ${media.greaterThan("1024px")`
      
    `}
  }

  .texts_container {
    width: 100%;

    ${media.greaterThan("768px")`

    `}
    ${media.greaterThan("800px")`
    width: 50%;
    padding: 0 20px;
      
    `}
    ${media.greaterThan("1024px")`
    width: 50%;
    padding: 0 49px;
      
    `}
    ${media.greaterThan("1440px")`
    
    `}
  }
  .image_container {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    /* padding: 39px 69px; */

    ${media.greaterThan("768px")`
    max-width: 600px
    margin: 0 auto;

    `}
    ${media.greaterThan("800px")`
      width: 50%;
      padding: 0px 20px;
      max-width: 700px;
      justify-content: right;
      
    `}
    ${media.greaterThan("1024px")`
      width: 50%;
      padding: 0px 40px;
      max-width: 700px;
      
    `}
    ${media.greaterThan("1440px")`
      padding: 0px 69px;
    `}
  }

  .image {
    max-inline-size: 100%;
    block-size: auto;
    object-fit: cover;
    right: 0;
  }
  .image_decorator {
    position: absolute;
    bottom: -39px;
    left: -39999999px;
    z-index: -1;
    max-inline-size: 100%;
    block-size: auto;
    ${media.greaterThan("800px")`
    left: 0;
    height: -webkit-fill-available;

    `}
  }

  .detailing_container {
    margin-bottom: 95px;
  }
`;

const FirstSection = styled.section`
  background: rgba(183, 123, 47, 0.1);
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  text-align: center;
  padding: 0 10%;
  padding-top: 80px;
  padding-bottom: 60px;
  gap: 50px;

  ${media.greaterThan("768px")`
    /* padding-top: 100px; */
    
  `};
  ${media.greaterThan("1024px")`
    /* padding-top: 100px; */
    padding-top: 150px;
    padding-bottom: 100px;
    
  `};
  ${media.greaterThan("1440px")`
    /* padding-top: 100px; */
    
  `};

  .buttons-holder {
    display: flex;
  }

  .buttons {
    display: flex;
    /* gap: 32px; */
    align-items: center;
    margin-bottom: 40px;

    ${media.greaterThan("600px")`
        /* flex-direction: row; */
      `}
    ${media.greaterThan("1024px")`
      margin-bottom: 0px;
      `}
  }
`;

const Title = styled.h2`
  font-family: "Tiempos Headline";
  font-size: 40px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.03em;
  color: #1a191e;

  ${media.greaterThan("768px")`
    /* font-size: 45px; */

  `};
  ${media.greaterThan("1024px")`
    font-size: 50px;
    max-width: 844px;
  `};
  ${media.greaterThan("1440px")`
    font-size: 60px;

  `};
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 180%;
  text-align: ${(props) => (props.start ? "left" : "center")};
  color: #1a191e;

  ${media.greaterThan("768px")`
    font-size: 18px;
  `};
  ${media.greaterThan("1024px")`
    font-size: 20px;
    max-width: 956px;
  `};
`;

const Landlords = () => {
  const navigate = useNavigate();
  const { setRegisterCustomerType } = useAppHook();
  const handleOpenAccount = () => {
    setRegisterCustomerType("landlord");
    navigate("/register");
  };
  return (
    <>
      <DynamicScrollToTop />
      <div
        style={{
          position: "relative",
        }}
      >
        <FirstSection>
          <Title>Get paid quickly, with our rental collection tools</Title>
          <Description>
            You don’t have to wait for your tenants delayed payments. We help
            landlords with rental collections and we also help pay for your
            property ahead. register with us
          </Description>
          <div className="buttons">
            {/* <Link to="/register"> */}
            <Button onClick={handleOpenAccount} bg={"#FBBD29"} clr={"#fff"}>
              Open A Landlords Account
            </Button>
            {/* </Link> */}

            <Link to={"/blogs"}>
              <Button clr={"#000"}>
                Learn More <HiOutlineArrowSmRight size={20} />
              </Button>
            </Link>
          </div>
        </FirstSection>
      </div>

      <Styles>
        <div className="detailing_container">
          <section
            className="flexed_container reversed"
            id="rental-finance-api"
          >
            <div className="image_container">
              <img src={handHoldingPhone} alt="" className="image" />
              <img src={imageDecorator} alt="" className="image_decorator" />
            </div>
            <div className="texts_container">
              <h2 className="title other_title">
                Push the responsibility of collecting your rentals to Landlords
                Technology
              </h2>
              <p className="text">
                We pay you upfront for your rentals, thus helping you address
                the issues of tenant non payment of rental as at when due. We
                take the burden away, and help you manage your property and it's
                rentals. Now you can worry less about renter’s
                financial capacity.
              </p>
            </div>
          </section>
        </div>
      </Styles>
      <NeedHelp />
    </>
  );
};

export default Landlords;
